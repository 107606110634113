import {
  Box,
  Typography,
  Divider,
  TextField,
  Button,
  useMediaQuery,
  FormControl,
} from "@mui/material"

import { CreditApplication } from "src/types"
import { ArrowForward } from "@mui/icons-material"
import ReactPhoneInput from "react-phone-input-material-ui"
import Address from "../components/Address"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"
import { useLocation, useParams } from "react-router-dom"
import { useCreditApplication } from "src/queries/credit/useCreditApplication"
import { OWNER_CONFIGS, PAGE_LABEL_OWNERS } from "./constants"
import InputAdornment from "@mui/material/InputAdornment"
import ReusableCustomQuestions from "../components/ReusableCustomQuestions"
import {
  isAddressEnabled,
  isContactsEnabled,
  isDobEnabled,
  isLicenseEnabled,
  isOwnersEnabled,
  isPhoneNumberEnabled,
  isSsnEnabled,
  isOwnersTitleEnabled,
  isOwnersPercentageEnabled,
  isOwnersTitleRequired,
  isOwnersPercentageRequired,
  isDobRequired,
  isLicenseRequired,
  isSsnRequired,
  getNumberOfContacts,
  isControllerEnabled,
  isPhoneNumberRequired,
  isContactsRequired,
} from "./schemas/OwnersSectionSchema"
import FormLabel from "src/components/label/FormLabel"
import MobileNavButtonsBlock from "src/components/mobile-nav-buttons-block/MobileNavButtonsBlock"
import {
  isPageRequired,
  isSubSectionEnabled,
  isSubSectionRequired,
} from "./template_helpers"
import { CUSTOM_WORD_MAPPINGS, customWordMapping } from "src/statics"
import { hasCustomAddressQuestions } from "../utils"

const PAGE_LABEL = PAGE_LABEL_OWNERS

type Props = {
  data: CreditApplication["data"]
  errors: CreditApplication["data"] | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (key: string, value: any) => void
  onContinue: () => void
  onSkip?: () => void
  onCustomChange: (key: string, value: any) => void
  setFilesInMemory: (files: Map<string, File[]>) => void
  filesInMemory: Map<string, File[]>
  handleBack: () => void
  activeStep: number
}
export default ({
  data,
  errors,
  onChange,
  onContinue,
  onSkip,
  onCustomChange,
  filesInMemory,
  setFilesInMemory,
  handleBack,
  activeStep,
}: Props) => {
  const params = useParams()
  const { id } = params
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const businessId = queryParams.get("business_id")

  const { data: application } = useCreditApplication(id || "")

  const { data: template, businessTemplate } = useApplicationTemplate(
    id ? application?.seller?.id : businessId || "",
    true,
    id ? !application?.seller?.id : !businessId,
    id,
    data,
    true,
  )

  const matches = useMediaQuery("(max-width: 899px)")
  const matchesXL = useMediaQuery("(max-width: 1199px)")

  return (
    <>
      {!matchesXL && (
        <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
          {customWordMapping(
            CUSTOM_WORD_MAPPINGS.OWNERS_CONTACTS,
            businessTemplate,
          )}
          {isPageRequired(PAGE_LABEL, template, data)
            ? ""
            : ` (${customWordMapping(
                CUSTOM_WORD_MAPPINGS.OPTIONAL,
                businessTemplate,
              )})`}
        </Typography>
      )}

      <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
        Please enter the details of the business owners and/or contacts
      </Typography>
      {!matches && <Divider style={{ margin: "16px 0 16px 0" }} />}

      {isOwnersEnabled(template) &&
        Array.from(Array(2).keys()).map((i) => (
          <Box key={i}>
            <Typography
              component="h1"
              variant="h5"
              style={{ marginTop: "8px" }}
              sx={{ fontSize: "1.125rem" }}
            >
              {customWordMapping(
                CUSTOM_WORD_MAPPINGS.OWNER_PRINCIPAL,
                businessTemplate,
              )}{" "}
              {i + 1}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: matches ? "column" : "row",
                width: "100%",
                gap: "5%",
              }}
            >
              <FormControl
                sx={{ width: "100%" }}
                margin={matches ? "normal" : "none"}
              >
                <FormLabel
                  labelName="Full Name"
                  idFor={"owner-name" + (i + 1)}
                  required={
                    isPageRequired(PAGE_LABEL, template, data) && i === 0
                  }
                />
                <TextField
                  id={"owner-name" + (i + 1)}
                  margin={matches ? "none" : "normal"}
                  fullWidth
                  placeholder={matches ? "Enter Name" : ""}
                  label={matches ? "" : "Full Name"}
                  required={
                    isPageRequired(PAGE_LABEL, template, data) && i === 0
                  }
                  inputProps={
                    isAddressEnabled(template) ||
                    hasCustomAddressQuestions(
                      template?.customFields,
                      PAGE_LABEL,
                      data,
                    )
                      ? {
                          style: { height: "56px" },
                        }
                      : {}
                  }
                  value={data[`ownerName${i + 1}`]}
                  onChange={(event) => {
                    onChange("ownerName" + (i + 1), event.target.value)
                  }}
                  error={!!errors && Boolean(errors[`ownerName${i + 1}`])}
                />
              </FormControl>
              <FormControl
                sx={{ width: "100%" }}
                margin={matches ? "normal" : "none"}
              >
                <FormLabel
                  labelName="Email"
                  required={
                    isPageRequired(PAGE_LABEL, template, data) && i === 0
                  }
                  idFor={"owner-email" + (i + 1)}
                />
                <TextField
                  id={"owner-email" + (i + 1)}
                  fullWidth
                  margin={matches ? "none" : "normal"}
                  placeholder={matches ? "Enter Email" : ""}
                  label={matches ? "" : "Email"}
                  required={
                    isPageRequired(PAGE_LABEL, template, data) && i === 0
                  }
                  inputProps={
                    isAddressEnabled(template) ||
                    hasCustomAddressQuestions(
                      template?.customFields,
                      PAGE_LABEL,
                      data,
                    )
                      ? {
                          style: { height: "56px" },
                        }
                      : {}
                  }
                  value={data[`ownerEmail${i + 1}`]}
                  onChange={(event) => {
                    onChange("ownerEmail" + (i + 1), event.target.value)
                  }}
                  error={!!errors && Boolean(errors[`ownerEmail${i + 1}`])}
                />
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "5%",
              }}
            >
              {isOwnersTitleEnabled(template) && (
                <TextField
                  id={"owner-title" + (i + 1)}
                  margin={matches ? "none" : "normal"}
                  fullWidth
                  label={matches ? "" : "Title/Position"}
                  placeholder={matches ? "Enter Title/Position" : ""}
                  required={isOwnersTitleRequired(template, data) && i === 0}
                  inputProps={
                    isAddressEnabled(template) ||
                    hasCustomAddressQuestions(
                      template?.customFields,
                      PAGE_LABEL,
                      data,
                    )
                      ? {
                          style: { height: "56px" },
                        }
                      : {}
                  }
                  value={data[`ownerTitle${i + 1}`]}
                  onChange={(event) => {
                    onChange("ownerTitle" + (i + 1), event.target.value)
                  }}
                  error={!!errors && Boolean(errors[`ownerTitle${i + 1}`])}
                />
              )}
              {isOwnersPercentageEnabled(template) && (
                <TextField
                  id={"owner-percentage" + (i + 1)}
                  margin="normal"
                  type="number"
                  fullWidth
                  label={matches ? "" : "Owner Percentage"}
                  placeholder={matches ? "Enter Percentage" : ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  required={
                    isOwnersPercentageRequired(template, data) && i === 0
                  }
                  inputProps={
                    isAddressEnabled(template) ||
                    hasCustomAddressQuestions(
                      template?.customFields,
                      PAGE_LABEL,
                      data,
                    )
                      ? {
                          style: { height: "56px" },
                        }
                      : {}
                  }
                  value={data[`ownerPercentage${i + 1}`]}
                  onChange={(event) => {
                    onChange("ownerPercentage" + (i + 1), event.target.value)
                  }}
                  error={!!errors && Boolean(errors[`ownerPercentage${i + 1}`])}
                  helperText={!!errors && errors[`ownerPercentage${i + 1}`]}
                />
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "5%",
              }}
            >
              {isDobEnabled(template) && (
                <FormControl
                  sx={{ width: "100%" }}
                  margin={matches ? "normal" : "none"}
                >
                  <FormLabel
                    labelName="Date of Birth"
                    idFor={"owner-dob" + (i + 1)}
                  />
                  <TextField
                    type="date"
                    id={"owner-dob" + (i + 1)}
                    margin={matches ? "none" : "normal"}
                    fullWidth
                    label={matches ? "" : "Date of Birth"}
                    placeholder={matches ? "Enter a Date" : ""}
                    required={isDobRequired(template, data) && i === 0}
                    inputProps={
                      isAddressEnabled(template) ||
                      hasCustomAddressQuestions(
                        template?.customFields,
                        PAGE_LABEL,
                        data,
                      )
                        ? {
                            style: { height: "56px" },
                          }
                        : {}
                    }
                    value={data[`ownerDob${i + 1}`]}
                    onChange={(event) => {
                      onChange("ownerDob" + (i + 1), event.target.value)
                    }}
                    error={!!errors && Boolean(errors[`ownerDob${i + 1}`])}
                  />
                </FormControl>
              )}
              {isPhoneNumberEnabled(template) && (
                <FormControl
                  sx={{ width: "100%" }}
                  margin={matches ? "normal" : "none"}
                >
                  <FormLabel labelName="Phone Number" />
                  <ReactPhoneInput
                    value={data[`ownerPhoneNumber${i + 1}`]}
                    country={"us"}
                    onChange={(event) => {
                      onChange(`ownerPhoneNumber${i + 1}`, "+" + event)
                    }}
                    countryCodeEditable={false}
                    label={matches ? "" : "Phone Number"}
                    component={TextField}
                    containerStyle={{
                      width: "100%",
                      marginTop: "8px",
                      height: "56px",
                    }}
                    inputProps={{
                      id: `owner-phone-number${i + 1}`,
                      // required: isPageRequired(PAGE_LABEL, template),
                      required: Boolean(isPhoneNumberRequired(template)) && i == 0,
                      error:
                        !!errors && Boolean(errors[`ownerPhoneNumber${i + 1}`]),
                      inputProps:
                        isAddressEnabled(template) ||
                        hasCustomAddressQuestions(
                          template?.customFields,
                          PAGE_LABEL,
                          data,
                        )
                          ? {
                              style: { height: "56px" },
                            }
                          : {},
                    }}
                  />
                </FormControl>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "5%",
              }}
            >
              {isLicenseEnabled(template) && (
                <FormControl
                  sx={{ width: "100%" }}
                  margin={matches ? "normal" : "none"}
                >
                  <FormLabel
                    labelName="Driver License Number"
                    idFor={"owner-driver-license" + (i + 1)}
                    required={isLicenseRequired(template, data) && i === 0}
                  />
                  <TextField
                    id={"owner-driver-license" + (i + 1)}
                    margin={matches ? "none" : "normal"}
                    fullWidth
                    label={matches ? "" : "Driver License Number"}
                    placeholder={matches ? "Enter Number" : ""}
                    required={isLicenseRequired(template, data) && i === 0}
                    inputProps={
                      isAddressEnabled(template) ||
                      hasCustomAddressQuestions(
                        template?.customFields,
                        PAGE_LABEL,
                        data,
                      )
                        ? {
                            style: { height: "56px" },
                          }
                        : {}
                    }
                    value={data[`ownerDriverLicense${i + 1}`]}
                    onChange={(event) => {
                      onChange(
                        "ownerDriverLicense" + (i + 1),
                        event.target.value,
                      )
                    }}
                    error={
                      !!errors && Boolean(errors[`ownerDriverLicense${i + 1}`])
                    }
                  />
                </FormControl>
              )}

              {isSsnEnabled(template) && (
                <FormControl
                  sx={{ width: "100%" }}
                  margin={matches ? "normal" : "none"}
                >
                  <FormLabel
                    labelName="SSN / SIN"
                    idFor={"owner-ssn" + (i + 1)}
                    required={isSsnRequired(template, data) && i === 0}
                  />
                  <TextField
                    id={"owner-ssn" + (i + 1)}
                    margin={matches ? "none" : "normal"}
                    fullWidth
                    label={matches ? "" : "SSN / SIN"}
                    placeholder={matches ? "Enter Number" : ""}
                    required={isSsnRequired(template, data) && i === 0}
                    inputProps={
                      isAddressEnabled(template) ||
                      hasCustomAddressQuestions(
                        template?.customFields,
                        PAGE_LABEL,
                        data,
                      )
                        ? {
                            style: { height: "56px" },
                          }
                        : {}
                    }
                    value={data[`ownerSsn${i + 1}`]}
                    onChange={(event) => {
                      onChange("ownerSsn" + (i + 1), event.target.value)
                    }}
                    error={!!errors && Boolean(errors[`ownerSsn${i + 1}`])}
                  />
                </FormControl>
              )}
            </Box>
            {isAddressEnabled(template) && (
              <FormControl
                sx={{ width: "100%" }}
                margin={matches ? "normal" : "none"}
              >
                <FormLabel labelName="Owner Address" required={i === 0} />

                <Address
                  keyPrefix={"owner"}
                  keySuffix={String(i + 1)}
                  data={data}
                  onChange={onChange}
                  errors={errors}
                  required={i === 0}
                  defaults={{ country: application?.seller?.country || "" }}
                />
              </FormControl>
            )}
          </Box>
        ))}
      {isContactsEnabled(template) && (
        <>
          <Typography
            component="h1"
            variant="h5"
            style={{ marginTop: "8px", fontSize: "1.125rem" }}
          >
            Accounts Payable Contacts
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: matches ? "column" : "row",
              width: "100%",
              gap: "5%",
            }}
          >
            <FormControl
              margin={matches ? "normal" : "none"}
              sx={{ width: "100%" }}
            >
              <FormLabel
                labelName="Name"
                required={
                  isPageRequired(PAGE_LABEL, template, data) &&
                  isContactsRequired(template)
                }
                idFor="ap-name"
              />
              <TextField
                id={"ap-name"}
                margin={matches ? "none" : "normal"}
                fullWidth
                required={
                  isPageRequired(PAGE_LABEL, template, data) &&
                  isContactsRequired(template)
                }
                placeholder={matches ? "Enter Name" : ""}
                label={matches ? "" : "Name"}
                variant="outlined"
                value={data["apName"]}
                inputProps={
                  isAddressEnabled(template) ||
                  hasCustomAddressQuestions(
                    template?.customFields,
                    PAGE_LABEL,
                    data,
                  )
                    ? {
                        style: { height: "56px" },
                      }
                    : {}
                }
                onChange={(event) => {
                  onChange("apName", event.target.value)
                }}
                error={Boolean(errors?.apName)}
              />
            </FormControl>
            <FormControl
              margin={matches ? "normal" : "none"}
              sx={{ width: "100%" }}
            >
              <FormLabel
                labelName="Position"
                required={
                  isPageRequired(PAGE_LABEL, template, data) &&
                  isContactsRequired(template)
                }
                idFor="ap-position"
              />
              <TextField
                id={"ap-position"}
                margin={matches ? "none" : "normal"}
                fullWidth
                required={
                  isPageRequired(PAGE_LABEL, template, data) &&
                  isContactsRequired(template)
                }
                placeholder={matches ? "Enter Position" : ""}
                label={matches ? "" : "Position"}
                variant="outlined"
                value={data["apPosition"]}
                inputProps={
                  isAddressEnabled(template) ||
                  hasCustomAddressQuestions(
                    template?.customFields,
                    PAGE_LABEL,
                    data,
                  )
                    ? {
                        style: { height: "56px" },
                      }
                    : {}
                }
                onChange={(event) => {
                  onChange("apPosition", event.target.value)
                }}
                error={Boolean(errors?.apPosition)}
              />
            </FormControl>
            <FormControl
              margin={matches ? "normal" : "none"}
              sx={{ width: "100%" }}
            >
              <FormLabel
                labelName="Email"
                required={
                  isPageRequired(PAGE_LABEL, template, data) &&
                  isContactsRequired(template)
                }
                idFor="ap-email"
              />
              <TextField
                id={"ap-email"}
                margin={matches ? "none" : "normal"}
                fullWidth
                required={
                  isPageRequired(PAGE_LABEL, template, data) &&
                  isContactsRequired(template)
                }
                placeholder={matches ? "Enter Email" : ""}
                label={matches ? "" : "Email"}
                variant="outlined"
                value={data["apEmail"]}
                inputProps={
                  isAddressEnabled(template) ||
                  hasCustomAddressQuestions(
                    template?.customFields,
                    PAGE_LABEL,
                    data,
                  )
                    ? {
                        style: { height: "56px" },
                      }
                    : {}
                }
                onChange={(event) => {
                  onChange("apEmail", event.target.value)
                }}
                error={Boolean(errors?.apEmail)}
              />
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              flexDirection: matches ? "column" : "row",
              gap: matches ? "" : "32px",
            }}
            margin={matches ? "normal" : "none"}
          >
            <FormControl
              margin={matches ? "normal" : "none"}
              sx={{ width: "100%" }}
            >
              <FormLabel
                labelName="Phone Number"
                required={
                  isPageRequired(PAGE_LABEL, template, data) &&
                  isContactsRequired(template)
                }
              />
              <ReactPhoneInput
                value={data.apPhoneNumber}
                country={"us"}
                onChange={(event) => {
                  onChange("apPhoneNumber", "+" + event)
                }}
                countryCodeEditable={false}
                placeholder={matches ? "Enter Number" : ""}
                label={matches ? "" : "Phone Number"}
                component={TextField}
                containerStyle={{
                  width: "100%",
                  margin: matches ? "0" : "16px 0 8px",
                  height: "56px",
                }}
                inputProps={{
                  id: "ap-phone-number",
                  required:
                    isPageRequired(PAGE_LABEL, template, data) &&
                    isContactsRequired(template),
                  error: Boolean(errors?.apPhoneNumber),
                  inputProps:
                    isAddressEnabled(template) ||
                    hasCustomAddressQuestions(
                      template?.customFields,
                      PAGE_LABEL,
                      data,
                    )
                      ? {
                          style: { height: "56px" },
                        }
                      : {},
                }}
              />
            </FormControl>
            <FormControl
              margin={matches ? "normal" : "none"}
              sx={{ width: "100%" }}
            >
              <FormLabel labelName="Extension" idFor="ap-phone-number-ext" />
              <TextField
                id={"ap-phone-number-ext"}
                fullWidth
                margin={matches ? "none" : "normal"}
                label={matches ? "" : "Extension"}
                placeholder={matches ? "Extension" : ""}
                type="text"
                inputProps={
                  isAddressEnabled(template) ||
                  hasCustomAddressQuestions(
                    template?.customFields,
                    PAGE_LABEL,
                    data,
                  )
                    ? {
                        style: { height: "56px" },
                      }
                    : {}
                }
                value={data.apPhoneNumberExt}
                onChange={(event) => {
                  onChange("apPhoneNumberExt", event.target.value)
                }}
              />
            </FormControl>
          </Box>

          {getNumberOfContacts(template) > 1 &&
            new Array(getNumberOfContacts(template) - 1).fill(0).map((i) => (
              <>
                <Typography
                  component="h1"
                  variant="h5"
                  style={{ marginTop: "8px" }}
                >
                  Contact {i + 2}{" "}
                  {isSubSectionRequired(
                    template,
                    PAGE_LABEL,
                    OWNER_CONFIGS.AP_2_BASE,
                    data,
                  )
                    ? ""
                    : `(${customWordMapping(
                        CUSTOM_WORD_MAPPINGS.OPTIONAL,
                        businessTemplate,
                      )})`}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    gap: "5%",
                  }}
                >
                  {isSubSectionEnabled(
                    template,
                    PAGE_LABEL_OWNERS,
                    OWNER_CONFIGS.AP_2_NAME,
                  ) && (
                    <FormControl
                      margin={matches ? "normal" : "none"}
                      sx={{ width: "100%" }}
                    >
                      <FormLabel
                        labelName="Name"
                        required={isPageRequired(PAGE_LABEL, template, data)}
                        idFor={"ap-name-" + (i + 2)}
                      />
                      <TextField
                        id={"ap-name-" + (i + 2)}
                        margin={matches ? "none" : "normal"}
                        fullWidth
                        required={isSubSectionRequired(
                          template,
                          PAGE_LABEL_OWNERS,
                          OWNER_CONFIGS.AP_2_NAME,
                          data,
                        )}
                        placeholder={matches ? "Enter Name" : ""}
                        label={matches ? "" : "Name"}
                        variant="outlined"
                        value={data["apName" + (i + 2)]}
                        inputProps={
                          isAddressEnabled(template) ||
                          hasCustomAddressQuestions(
                            template?.customFields,
                            PAGE_LABEL,
                            data,
                          )
                            ? {
                                style: { height: "56px" },
                              }
                            : {}
                        }
                        onChange={(event) => {
                          onChange("apName" + (i + 2), event.target.value)
                        }}
                        error={Boolean(errors?.["apName" + (i + 2)])}
                      />
                    </FormControl>
                  )}

                  {isSubSectionEnabled(
                    template,
                    PAGE_LABEL_OWNERS,
                    OWNER_CONFIGS.AP_2_ROLE,
                  ) && (
                    <FormControl
                      margin={matches ? "normal" : "none"}
                      sx={{ width: "100%" }}
                    >
                      <FormLabel
                        labelName="Position"
                        required={isPageRequired(PAGE_LABEL, template, data)}
                        idFor={"ap-position-" + (i + 2)}
                      />
                      <TextField
                        id={"ap-position-" + (i + 2)}
                        margin={matches ? "none" : "normal"}
                        fullWidth
                        required={isSubSectionRequired(
                          template,
                          PAGE_LABEL_OWNERS,
                          OWNER_CONFIGS.AP_2_ROLE,
                        )}
                        placeholder={matches ? "Enter Position" : ""}
                        label={matches ? "" : "Position"}
                        variant="outlined"
                        value={data["apPosition" + (i + 2)]}
                        inputProps={
                          isAddressEnabled(template) ||
                          hasCustomAddressQuestions(
                            template?.customFields,
                            PAGE_LABEL,
                            data,
                          )
                            ? {
                                style: { height: "56px" },
                              }
                            : {}
                        }
                        onChange={(event) => {
                          onChange("apPosition" + (i + 2), event.target.value)
                        }}
                        error={Boolean(errors?.["apPosition" + (i + 2)])}
                      />
                    </FormControl>
                  )}

                  {isSubSectionEnabled(
                    template,
                    PAGE_LABEL_OWNERS,
                    OWNER_CONFIGS.AP_2_EMAIL,
                  ) && (
                    <FormControl
                      margin={matches ? "normal" : "none"}
                      sx={{ width: "100%" }}
                    >
                      <FormLabel
                        labelName="Email"
                        required={isPageRequired(PAGE_LABEL, template, data)}
                        idFor={"ap-email-" + (i + 2)}
                      />
                      <TextField
                        id={"ap-email-" + (i + 2)}
                        margin={matches ? "none" : "normal"}
                        fullWidth
                        required={isSubSectionRequired(
                          template,
                          PAGE_LABEL_OWNERS,
                          OWNER_CONFIGS.AP_2_EMAIL,
                        )}
                        placeholder={matches ? "Enter Email" : ""}
                        label={matches ? "" : "Email"}
                        variant="outlined"
                        value={data["apEmail" + (i + 2)]}
                        inputProps={
                          isAddressEnabled(template) ||
                          hasCustomAddressQuestions(
                            template?.customFields,
                            PAGE_LABEL,
                            data,
                          )
                            ? {
                                style: { height: "56px" },
                              }
                            : {}
                        }
                        onChange={(event) => {
                          onChange("apEmail" + (i + 2), event.target.value)
                        }}
                        error={Boolean(errors?.["apEmail" + (i + 2)])}
                      />
                    </FormControl>
                  )}
                </Box>
                {isSubSectionEnabled(
                  template,
                  PAGE_LABEL_OWNERS,
                  OWNER_CONFIGS.AP_2_PHONE,
                ) && (
                  <Box
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      flexDirection: matches ? "column" : "row",
                      gap: matches ? "" : "32px",
                    }}
                  >
                    <FormControl
                      margin={matches ? "normal" : "none"}
                      sx={{ width: "100%" }}
                    >
                      <FormLabel
                        labelName="Phone Number"
                        required={isPageRequired(PAGE_LABEL, template, data)}
                      />
                      <ReactPhoneInput
                        value={data[`apPhoneNumber${i + 2}`]}
                        country={"us"}
                        onChange={(event) => {
                          onChange("apPhoneNumber" + (i + 2), "+" + event)
                        }}
                        countryCodeEditable={false}
                        placeholder={matches ? "Enter Number" : ""}
                        label={matches ? "" : "Phone Number"}
                        component={TextField}
                        containerStyle={{
                          width: "100%",
                          margin: matches ? "0" : "16px 0 8px",
                          height: "56px",
                        }}
                        inputProps={{
                          id: "ap-phone-number-" + (i + 2),
                          required: isSubSectionRequired(
                            template,
                            PAGE_LABEL_OWNERS,
                            OWNER_CONFIGS.AP_2_PHONE,
                          ),
                          error: Boolean(errors?.[`apPhoneNumber${i + 2}`]),
                          inputProps:
                            isAddressEnabled(template) ||
                            hasCustomAddressQuestions(
                              template?.customFields,
                              PAGE_LABEL,
                              data,
                            )
                              ? {
                                  style: { height: "56px" },
                                }
                              : {},
                        }}
                      />
                    </FormControl>
                    <FormControl
                      margin={matches ? "normal" : "none"}
                      sx={{ width: "100%" }}
                    >
                      <FormLabel
                        labelName="Extension"
                        idFor={"ap-phone-number-ext-" + (i + 2)}
                      />
                      <TextField
                        id={"ap-phone-number-ext-" + (i + 2)}
                        fullWidth
                        margin={matches ? "none" : "normal"}
                        label={matches ? "" : "Extension"}
                        placeholder={matches ? "Extension" : ""}
                        type="text"
                        inputProps={
                          isAddressEnabled(template) ||
                          hasCustomAddressQuestions(
                            template?.customFields,
                            PAGE_LABEL,
                            data,
                          )
                            ? {
                                style: { height: "56px" },
                              }
                            : {}
                        }
                        value={data[`apPhoneNumberExt${i + 2}`]}
                        onChange={(event) => {
                          onChange(
                            "apPhoneNumberExt" + (i + 2),
                            event.target.value,
                          )
                        }}
                      />
                    </FormControl>
                  </Box>
                )}
              </>
            ))}
        </>
      )}

      {isControllerEnabled(template) && (
        <>
          <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
            Controller
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
            }}
          >
            {isSubSectionEnabled(
              template,
              PAGE_LABEL,
              OWNER_CONFIGS.CONTROLLER_NAME,
            ) && (
              <FormControl
                margin={matches ? "normal" : "none"}
                sx={{ width: "100%" }}
              >
                <FormLabel
                  labelName="Name"
                  required={isPageRequired(PAGE_LABEL, template, data)}
                  idFor={"controller-name"}
                />
                <TextField
                  id={"controller-name"}
                  margin={matches ? "none" : "normal"}
                  fullWidth
                  required={isSubSectionRequired(
                    template,
                    PAGE_LABEL,
                    OWNER_CONFIGS.CONTROLLER_NAME,
                    data,
                  )}
                  placeholder={matches ? "Enter Name" : ""}
                  label={matches ? "" : "Name"}
                  variant="outlined"
                  value={data["controllerName"]}
                  inputProps={
                    isAddressEnabled(template) ||
                    hasCustomAddressQuestions(
                      template?.customFields,
                      PAGE_LABEL,
                      data,
                    )
                      ? {
                          style: { height: "56px" },
                        }
                      : {}
                  }
                  onChange={(event) => {
                    onChange("controllerName", event.target.value)
                  }}
                  error={Boolean(errors?.controllerName)}
                />
              </FormControl>
            )}

            {isSubSectionEnabled(
              template,
              PAGE_LABEL,
              OWNER_CONFIGS.CONTROLLER_ROLE,
            ) && (
              <FormControl
                margin={matches ? "normal" : "none"}
                sx={{ width: "100%" }}
              >
                <FormLabel
                  labelName="Position"
                  required={isPageRequired(PAGE_LABEL, template, data)}
                  idFor={"controller-position"}
                />
                <TextField
                  id={"controller-position"}
                  margin={matches ? "none" : "normal"}
                  fullWidth
                  required={isSubSectionRequired(
                    template,
                    PAGE_LABEL,
                    OWNER_CONFIGS.CONTROLLER_ROLE,
                    data,
                  )}
                  placeholder={matches ? "Enter Position" : ""}
                  label={matches ? "" : "Position"}
                  variant="outlined"
                  value={data["controllerPosition"]}
                  inputProps={
                    isAddressEnabled(template) ||
                    hasCustomAddressQuestions(
                      template?.customFields,
                      PAGE_LABEL,
                      data,
                    )
                      ? {
                          style: { height: "56px" },
                        }
                      : {}
                  }
                  onChange={(event) => {
                    onChange("controllerPosition", event.target.value)
                  }}
                  error={Boolean(errors?.controllerPosition)}
                />
              </FormControl>
            )}
            {isSubSectionEnabled(
              template,
              PAGE_LABEL,
              OWNER_CONFIGS.CONTROLLER_EMAIL,
            ) && (
              <FormControl
                margin={matches ? "normal" : "none"}
                sx={{ width: "100%" }}
              >
                <FormLabel
                  labelName="Email"
                  required={isPageRequired(PAGE_LABEL, template, data)}
                  idFor={"controller-email"}
                />
                <TextField
                  id={"controller-email"}
                  margin={matches ? "none" : "normal"}
                  fullWidth
                  required={isSubSectionRequired(
                    template,
                    PAGE_LABEL,
                    OWNER_CONFIGS.CONTROLLER_EMAIL,
                    data,
                  )}
                  placeholder={matches ? "Enter Email" : ""}
                  label={matches ? "" : "Email"}
                  variant="outlined"
                  value={data["controllerEmail"]}
                  inputProps={
                    isAddressEnabled(template) ||
                    hasCustomAddressQuestions(
                      template?.customFields,
                      PAGE_LABEL,
                      data,
                    )
                      ? {
                          style: { height: "56px" },
                        }
                      : {}
                  }
                  onChange={(event) => {
                    onChange("controllerEmail", event.target.value)
                  }}
                  error={Boolean(errors?.controllerEmail)}
                />
              </FormControl>
            )}
          </Box>
          {isSubSectionEnabled(
            template,
            PAGE_LABEL,
            OWNER_CONFIGS.CONTROLLER_PHONE,
          ) && (
            <Box
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                flexDirection: matches ? "column" : "row",
                gap: matches ? "" : "32px",
              }}
            >
              <FormControl
                margin={matches ? "normal" : "none"}
                sx={{ width: "100%" }}
              >
                <FormLabel
                  labelName="Phone Number"
                  required={isPageRequired(PAGE_LABEL, template, data)}
                />
                <ReactPhoneInput
                  value={data.controllerPhoneNumber}
                  country={"us"}
                  onChange={(event) => {
                    onChange("controllerPhoneNumber", "+" + event)
                  }}
                  countryCodeEditable={false}
                  placeholder={matches ? "Enter Number" : ""}
                  label={matches ? "" : "Phone Number"}
                  component={TextField}
                  containerStyle={{
                    width: "100%",
                    margin: matches ? "0" : "16px 0 8px",
                    height: "56px",
                  }}
                  inputProps={{
                    id: "controller-phone-number",
                    required: isSubSectionRequired(
                      template,
                      PAGE_LABEL,
                      OWNER_CONFIGS.CONTROLLER_PHONE,
                      data,
                    ),
                    error: Boolean(errors?.controllerPhoneNumber),
                    inputProps:
                      isAddressEnabled(template) ||
                      hasCustomAddressQuestions(
                        template?.customFields,
                        PAGE_LABEL,
                        data,
                      )
                        ? {
                            style: { height: "56px" },
                          }
                        : {},
                  }}
                />
              </FormControl>
              <FormControl
                margin={matches ? "normal" : "none"}
                sx={{ width: "100%" }}
              >
                <FormLabel
                  labelName="Extension"
                  idFor={"controller-phone-number-ext"}
                />
                <TextField
                  id={"controller-phone-number-ext"}
                  fullWidth
                  margin={matches ? "none" : "normal"}
                  label={matches ? "" : "Extension"}
                  placeholder={matches ? "Extension" : ""}
                  type="text"
                  inputProps={
                    isAddressEnabled(template) ||
                    hasCustomAddressQuestions(
                      template?.customFields,
                      PAGE_LABEL,
                      data,
                    )
                      ? {
                          style: { height: "56px" },
                        }
                      : {}
                  }
                  value={data.controllerPhoneNumberExt}
                  onChange={(event) => {
                    onChange("controllerPhoneNumberExt", event.target.value)
                  }}
                />
              </FormControl>
            </Box>
          )}
        </>
      )}

      {template?.customFields && (
        <ReusableCustomQuestions
          application={application}
          associatedPage={PAGE_LABEL_OWNERS}
          data={data["customFields"]}
          dataKeyResponses={data}
          errors={errors}
          onChange={onCustomChange}
          filesInMemory={filesInMemory}
          setFilesInMemory={setFilesInMemory}
          overrideHeight={isAddressEnabled(template) ? true : false}
        />
      )}
      {matches ? (
        <MobileNavButtonsBlock
          click={onContinue}
          disable={false}
          handleBack={handleBack}
          activeStep={activeStep}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "2rem",
          }}
        >
          <Button
            id="continue-owner"
            onClick={() => {
              onContinue()
            }}
            size="large"
            fullWidth
            endIcon={<ArrowForward />}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Save & Continue
          </Button>
          {template?.formTemplate.pages.find(
            (step) => step.label === PAGE_LABEL,
          )?.skippable &&
            onSkip && (
              <Button
                id="continue-owner"
                onClick={() => {
                  if (onSkip) onSkip()
                }}
                size="large"
                variant="outlined"
                sx={{ mt: 3, mb: 2 }}
              >
                Skip
              </Button>
            )}
        </Box>
      )}
    </>
  )
}
