// component
import { Settings } from "@mui/icons-material"
import { Icon } from "@iconify/react"
import { Group } from "src/types"
import { Chip } from "@mui/material"


// ----------------------------------------------------------------------

const navConfig = [
  {
    title: "dashboard",
    path: "/credit-dashboard",
    icon: <Icon icon="mdi:view-dashboard-outline" width={24} />,
    info: <Chip label="Beta" variant="outlined" sx={{ mr: 2 }} />,
  },
  {
    title: "credit applications",
    path: "/applications",
    icon: <Icon icon="mdi:hand-coin-outline" width={24} />,
  },
  {
    title: "Cash-on-Delivery (COD)",
    path: "/cash-applications",
    icon: <Icon icon="mdi:credit-card-outline" width={24} />,
  },
  {
    title: "financial analysis",
    path: "/financial-analysis",
    icon: <Icon icon="mdi:chart-line" width={24} />,
    info: <Chip label="Beta" variant="outlined" sx={{ mr: 2 }} />,
  },
  {
    title: "settings",
    path: "/settings",
    icon: <Settings />,
  },
  // {
  //   title: "monitoring",
  //   path: "/monitoring",
  //   icon: <Icon icon="mdi:monitor-eye" width={24} />,
  // },
]

const getNavConfig = (user: any, businessServices: string[]) => {
  let config = navConfig
  if (user) {
    if (!user.groups.includes(Group.CreditManager)) {
      config = config.filter((item) => item.title !== "settings")
      config = config.filter((item) => item.title !== "dashboard")
      config = config.filter((item) => item.title !== "financial analysis")
    }
  }
  if (businessServices && !businessServices.includes("cod")) {
    config = config.filter((item) => item.title !== "Cash-on-Delivery (COD)")
  }

  return config
}

export default getNavConfig
