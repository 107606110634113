import { useQuery } from "react-query"
import { Business } from "../../types"

export function useAnonymousBusiness(id: string) {
  return useQuery<
    Pick<
      Business,
      | "color"
      | "name"
      | "terms"
      | "country"
      | "preferences"
      | "supportedLanguages"
    > & {
      logo?: string
      stores?: { id: string; name: string }[]
    }
  >(`/application/seller/${id}`, {
    enabled: !!id,
    retry: (failureCount, error) => {
      if (failureCount < 1) {
        return true
      }
      // do not retry on 404
      return (error as any)?.response?.status !== 404
    },
    meta: { anonymous: true },
  })
}
