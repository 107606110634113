import { QueryClient } from "react-query"
import { TradeReference, TradeReferenceContactReassign } from "src/types"
import { useTypedMutation } from "../useTypedMutation"

/**
 *  Use to add a new trade reference to an existing application
 * @param onSuccess
 * @returns
 */
export function usePostReassignTradeReferenceContact(
  onSuccess?: (data: TradeReference) => void,
) {
  const ENDPOINT = "/application/trade_reference_reassigned_contact"
  const post = useTypedMutation<TradeReferenceContactReassign>(
    "post/application/trade_reference_reassigned_contact",
    onSuccess,
  )

  const execute = (
    body: TradeReferenceContactReassign,
    onSuccess = () => {
      return
    },
    onError = () => {
      return
    },
  ) => {
    post.mutate(
      {
        endpoint: ENDPOINT,
        body,
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
        onError,
      },
    )
  }
  return { ...post, execute }
}