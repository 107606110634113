import {
  Button,
  Container,
  Stack,
  Card,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material"
import { useState } from "react"
import { TableHeadCustom } from "src/components/table"
import { useInternalStores } from "src/queries/internal/useInternalStores"
import AddStoreDialog from "./components/AddStoreDialog"

export default function ClientStoresDetails({ id }: { id: string }) {
  const { data: storeData, refetch } = useInternalStores(id)
  const [addStoreDialogOpen, setAddStoreDialogOpen] = useState(false)
  const TABLE_HEAD = [
    { id: "name", label: "Name", align: "left" },
    { id: "manager", label: "Default Credit Manager", align: "left" },
    { id: "sales_reps", label: "Sales Reps", align: "left" },
  ]
  return (
    <Container sx={{ p: 2 }}>
      <Paper variant="outlined">
        <Card sx={{ width: 1 }}>
          <CardHeader title="Stores" />
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={2}
            sx={{ p: 2 }}
          >
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                setAddStoreDialogOpen(true)
              }}
            >
              Add Store
            </Button>
          </Stack>
          <TableContainer sx={{ mt: 3, overflow: "unset" }}>
            <Table sx={{ minWidth: 800 }}>
              <TableHeadCustom headLabel={TABLE_HEAD} />
              <TableBody>
                {storeData?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="left">{row.name}</TableCell>

                    <TableCell align="left">
                      {row.defaultAssignee?.email || "None"}
                    </TableCell>
                    <TableCell align="left">
                      {row.salesReps?.length || 0}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Paper>
      {addStoreDialogOpen && (
        <AddStoreDialog
          businessId={id}
          onClose={() => {
            setAddStoreDialogOpen(false)
            refetch()
          }}
        />
      )}
    </Container>
  )
}
