import { Formik, FormikProps } from "formik"
import {
  Button,
  FormControl,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
} from "@mui/material"
import {
  PAGE_LABEL_OWNERS,
  OWNER_CONFIGS,
} from "src/sections/@dashboard/intake_sections/constants"
import { ApplicationTemplate, Config, Page } from "src/types"
import {
  getConfigFromPage,
  getPageFromTemplate,
  setUndefinedAsTrue,
} from "src/utils/utils"
import { usePatchCreditApplicationTemplatePage } from "src/queries/internal/usePatchCreditApplicationTemplatePage"
import { enqueueSnackbar } from "notistack"
import SectionWhenComponent from "../SectionWhenComponent"

export default function CreditSectionOwnerAndContactsDetails({
  applicationTemplate,
  refetch,
}: {
  applicationTemplate: ApplicationTemplate
  refetch: () => void
}) {
  const page = getPageFromTemplate(applicationTemplate, PAGE_LABEL_OWNERS)

  const { execute, isLoading } = usePatchCreditApplicationTemplatePage(
    applicationTemplate.id || "",
    () => {
      enqueueSnackbar("Application Template Updated", { variant: "success" })
    },
  )
  const getConfigFormControlValueField = (
    value: boolean,
    name = "",
    label: string,
    props: FormikProps<Page>,
  ) => {
    if (name === "") {
      label = name.replaceAll(" ", "").toLowerCase() + "Value"
    }
    return (
      <FormControlLabel
        control={
          <Switch
            size="small"
            name={name}
            checked={value}
            onChange={(event) => {
              const config = getConfigFromPage(props.values, label) as Config
              if (config) {
                props.setFieldValue(
                  "config",
                  props.values.config.map((c) => {
                    if (c.label === label) {
                      c.value = event.target.checked
                    }
                    return c
                  }),
                )
              } else {
                props.values.config.push({
                  label: label,
                  description: label,
                  value: event.target.checked,
                })
              }
            }}
          />
        }
        label={"Enable " + label}
      />
    )
  }

  const getConfigFormControlRequiredField = (
    value: boolean,
    name = "",
    label: string,
    props: FormikProps<Page>,
  ) => {
    if (name === "") {
      name = label.replaceAll(" ", "").toLowerCase() + "Required"
    }
    return (
      <FormControlLabel
        control={
          <Switch
            size="small"
            name={name}
            checked={value}
            onChange={(event) => {
              const config = getConfigFromPage(props.values, label) as Config
              if (config) {
                props.setFieldValue(
                  "config",
                  props.values.config.map((c) => {
                    if (c.label === label) {
                      c.required = event.target.checked
                    }
                    return c
                  }),
                )
              } else {
                props.values.config.push({
                  label: label,
                  description: label,
                  required: event.target.checked,
                  value: true,
                })
              }
            }}
          />
        }
        label={"Require " + label}
      />
    )
  }
  return (
    <>
      <Stack sx={{ p: 0.3 }}>
        <Formik
          initialValues={page ? page : ({} as Page)}
          onSubmit={(values) => {
            execute({ ...values }, () => {
              refetch()
            })
          }}
        >
          {(props) => (
            <FormControl component="fieldset" variant="standard">
              <Stack spacing={3}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="enabled"
                      checked={props.values.enabled}
                      onChange={props.handleChange}
                    />
                  }
                  label="Enable Section"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="required"
                      checked={props.values.required as boolean}
                      onChange={props.handleChange}
                    />
                  }
                  label="Require Section"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="owners"
                      checked={
                        getConfigFromPage(props.values, OWNER_CONFIGS.OWNERS)
                          ?.value as boolean
                      }
                      onChange={(event) => {
                        const config = getConfigFromPage(
                          props.values,
                          OWNER_CONFIGS.OWNERS,
                        ) as Config
                        if (config) {
                          props.setFieldValue(
                            "config",
                            props.values.config.map((c) => {
                              if (c.label === OWNER_CONFIGS.OWNERS) {
                                c.value = event.target.checked
                              }
                              return c
                            }),
                          )
                        } else {
                          props.values.config.push({
                            label: OWNER_CONFIGS.OWNERS,
                            description: "Owner information",
                            value: event.target.checked,
                          })
                        }
                      }}
                    />
                  }
                  label="Enable Owner information"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="ownersRequired"
                      checked={
                        getConfigFromPage(props.values, OWNER_CONFIGS.OWNERS)
                          ?.required as boolean
                      }
                      onChange={(event) => {
                        const config = getConfigFromPage(
                          props.values,
                          OWNER_CONFIGS.OWNERS,
                        ) as Config
                        if (config) {
                          props.setFieldValue(
                            "config",
                            props.values.config.map((c) => {
                              if (c.label === OWNER_CONFIGS.OWNERS) {
                                c.required = event.target.checked
                              }
                              return c
                            }),
                          )
                        } else {
                          props.values.config.push({
                            label: OWNER_CONFIGS.OWNERS,
                            description: "Owner information",
                            value: true,
                            required: event.target.checked,
                          })
                        }
                      }}
                    />
                  }
                  label="Require Owner information"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="owners"
                      checked={
                        getConfigFromPage(
                          props.values,
                          OWNER_CONFIGS.DRIVER_LICENSE,
                        )?.value as boolean
                      }
                      onChange={(event) => {
                        const config = getConfigFromPage(
                          props.values,
                          OWNER_CONFIGS.DRIVER_LICENSE,
                        ) as Config
                        if (config) {
                          props.setFieldValue(
                            "config",
                            props.values.config.map((c) => {
                              if (c.label === OWNER_CONFIGS.DRIVER_LICENSE) {
                                c.value = event.target.checked
                              }
                              return c
                            }),
                          )
                        } else {
                          props.values.config.push({
                            label: OWNER_CONFIGS.DRIVER_LICENSE,
                            description: "Owner driver license",
                            value: event.target.checked,
                          })
                        }
                      }}
                    />
                  }
                  label="Enable Owner driver license"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="driversLicenseRequired"
                      checked={
                        setUndefinedAsTrue(
                          getConfigFromPage(
                            props.values,
                            OWNER_CONFIGS.DRIVER_LICENSE,
                          )?.required,
                        ) as boolean
                      }
                      onChange={(event) => {
                        const config = getConfigFromPage(
                          props.values,
                          OWNER_CONFIGS.DRIVER_LICENSE,
                        ) as Config
                        if (config) {
                          props.setFieldValue(
                            "config",
                            props.values.config.map((c) => {
                              if (c.label === OWNER_CONFIGS.DRIVER_LICENSE) {
                                c.required = event.target.checked
                              }
                              return c
                            }),
                          )
                        } else {
                          props.values.config.push({
                            label: OWNER_CONFIGS.DRIVER_LICENSE,
                            description: "Owners Driver License",
                            value: true,
                            required: event.target.checked,
                          })
                        }
                      }}
                    />
                  }
                  label="Require Driver License"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="ssn/ssn"
                      checked={
                        getConfigFromPage(props.values, OWNER_CONFIGS.SSN_SIN)
                          ?.value as boolean
                      }
                      onChange={(event) => {
                        const config = getConfigFromPage(
                          props.values,
                          OWNER_CONFIGS.SSN_SIN,
                        ) as Config
                        if (config) {
                          props.setFieldValue(
                            "config",
                            props.values.config.map((c) => {
                              if (c.label === OWNER_CONFIGS.SSN_SIN) {
                                c.value = event.target.checked
                              }
                              return c
                            }),
                          )
                        } else {
                          props.values.config.push({
                            label: OWNER_CONFIGS.SSN_SIN,
                            description: "SSN (US) or SIN (Canada)",
                            value: event.target.checked,
                          })
                        }
                      }}
                    />
                  }
                  label="Enable SSN (US) or SIN (Canada)"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="ssn/ssnRequired"
                      checked={
                        setUndefinedAsTrue(
                          getConfigFromPage(props.values, OWNER_CONFIGS.SSN_SIN)
                            ?.required,
                        ) as boolean
                      }
                      onChange={(event) => {
                        const config = getConfigFromPage(
                          props.values,
                          "SSN/SIN",
                        ) as Config
                        if (config) {
                          props.setFieldValue(
                            "config",
                            props.values.config.map((c) => {
                              if (c.label === OWNER_CONFIGS.SSN_SIN) {
                                c.required = event.target.checked
                              }
                              return c
                            }),
                          )
                        } else {
                          props.values.config.push({
                            label: OWNER_CONFIGS.SSN_SIN,
                            description: "SSN (US) or SIN (Canada)",
                            value: true,
                            required: event.target.checked,
                          })
                        }
                      }}
                    />
                  }
                  label="Require SSN (US) or SIN (Canada)"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="ownerPhoneNumber"
                      checked={
                        getConfigFromPage(
                          props.values,
                          OWNER_CONFIGS.OWNER_PHONE_NUMBER,
                        )?.value as boolean
                      }
                      onChange={(event) => {
                        const config = getConfigFromPage(
                          props.values,
                          OWNER_CONFIGS.OWNER_PHONE_NUMBER,
                        ) as Config
                        if (config) {
                          props.setFieldValue(
                            "config",
                            props.values.config.map((c) => {
                              if (
                                c.label === OWNER_CONFIGS.OWNER_PHONE_NUMBER
                              ) {
                                c.value = event.target.checked
                              }
                              return c
                            }),
                          )
                        } else {
                          props.values.config.push({
                            label: OWNER_CONFIGS.OWNER_PHONE_NUMBER,
                            description: "Owner Phone Number",
                            value: event.target.checked,
                          })
                        }
                      }}
                    />
                  }
                  label="Enable Owner Phone Number"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="ownerPhoneNumberRequired"
                      checked={
                        getConfigFromPage(
                          props.values,
                          OWNER_CONFIGS.OWNER_PHONE_NUMBER,
                        )?.required as boolean
                      }
                      onChange={(event) => {
                        const config = getConfigFromPage(
                          props.values,
                          OWNER_CONFIGS.OWNER_PHONE_NUMBER,
                        ) as Config
                        if (config) {
                          props.setFieldValue(
                            "config",
                            props.values.config.map((c) => {
                              if (
                                c.label === OWNER_CONFIGS.OWNER_PHONE_NUMBER
                              ) {
                                c.required = event.target.checked
                              }
                              return c
                            }),
                          )
                        } else {
                          props.values.config.push({
                            label: OWNER_CONFIGS.OWNER_PHONE_NUMBER,
                            description: "Owner Phone Number",
                            value: true,
                            required: event.target.checked,
                          })
                        }
                      }}
                    />
                  }
                  label="Require Owner Phone Number"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="ownerTitle"
                      checked={
                        getConfigFromPage(
                          props.values,
                          OWNER_CONFIGS.OWNER_TITLE,
                        )?.value as boolean
                      }
                      onChange={(event) => {
                        const config = getConfigFromPage(
                          props.values,
                          OWNER_CONFIGS.OWNER_TITLE,
                        ) as Config
                        if (config) {
                          props.setFieldValue(
                            "config",
                            props.values.config.map((c) => {
                              if (c.label === OWNER_CONFIGS.OWNER_TITLE) {
                                c.value = event.target.checked
                              }
                              return c
                            }),
                          )
                        } else {
                          props.values.config.push({
                            label: OWNER_CONFIGS.OWNER_TITLE,
                            description: "Owners Title",
                            value: event.target.checked,
                          })
                        }
                      }}
                    />
                  }
                  label="Enable Owners Title"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="ownerTitleRequired"
                      checked={
                        setUndefinedAsTrue(
                          getConfigFromPage(
                            props.values,
                            OWNER_CONFIGS.OWNER_TITLE,
                          )?.required,
                        ) as boolean
                      }
                      onChange={(event) => {
                        const config = getConfigFromPage(
                          props.values,
                          OWNER_CONFIGS.OWNER_TITLE,
                        ) as Config
                        if (config) {
                          props.setFieldValue(
                            "config",
                            props.values.config.map((c) => {
                              if (c.label === OWNER_CONFIGS.OWNER_TITLE) {
                                c.required = event.target.checked
                              }
                              return c
                            }),
                          )
                        } else {
                          props.values.config.push({
                            label: OWNER_CONFIGS.OWNER_TITLE,
                            description: "Owners Title",
                            required: event.target.checked,
                            value: true,
                          })
                        }
                      }}
                    />
                  }
                  label="Require Owners Title"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="ownerPercentage"
                      checked={
                        getConfigFromPage(
                          props.values,
                          OWNER_CONFIGS.OWNER_PERCENTAGE,
                        )?.value as boolean
                      }
                      onChange={(event) => {
                        const config = getConfigFromPage(
                          props.values,
                          OWNER_CONFIGS.OWNER_PERCENTAGE,
                        ) as Config
                        if (config) {
                          props.setFieldValue(
                            "config",
                            props.values.config.map((c) => {
                              if (c.label === OWNER_CONFIGS.OWNER_PERCENTAGE) {
                                c.value = event.target.checked
                              }
                              return c
                            }),
                          )
                        } else {
                          props.values.config.push({
                            label: OWNER_CONFIGS.OWNER_PERCENTAGE,
                            description: "Owners Percentage",
                            value: event.target.checked,
                          })
                        }
                      }}
                    />
                  }
                  label="Enable Owners Percentage"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="ownerPercentageRequired"
                      checked={
                        setUndefinedAsTrue(
                          getConfigFromPage(
                            props.values,
                            OWNER_CONFIGS.OWNER_PERCENTAGE,
                          )?.required,
                        ) as boolean
                      }
                      onChange={(event) => {
                        const config = getConfigFromPage(
                          props.values,
                          OWNER_CONFIGS.OWNER_PERCENTAGE,
                        ) as Config
                        if (config) {
                          props.setFieldValue(
                            "config",
                            props.values.config.map((c) => {
                              if (c.label === OWNER_CONFIGS.OWNER_PERCENTAGE) {
                                c.required = event.target.checked
                              }
                              return c
                            }),
                          )
                        } else {
                          props.values.config.push({
                            label: OWNER_CONFIGS.OWNER_PERCENTAGE,
                            description: "Owners Percentage",
                            required: event.target.checked,
                            value: true,
                          })
                        }
                      }}
                    />
                  }
                  label="Require Owners Percentage"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="dateOfBirth"
                      checked={
                        getConfigFromPage(
                          props.values,
                          OWNER_CONFIGS.DATE_OF_BIRTH,
                        )?.value as boolean
                      }
                      onChange={(event) => {
                        const config = getConfigFromPage(
                          props.values,
                          OWNER_CONFIGS.DATE_OF_BIRTH,
                        ) as Config
                        if (config) {
                          props.setFieldValue(
                            "config",
                            props.values.config.map((c) => {
                              if (c.label === OWNER_CONFIGS.DATE_OF_BIRTH) {
                                c.value = event.target.checked
                              }
                              return c
                            }),
                          )
                        } else {
                          props.values.config.push({
                            label: OWNER_CONFIGS.DATE_OF_BIRTH,
                            description: "Date of Birth",
                            value: event.target.checked,
                          })
                        }
                      }}
                    />
                  }
                  label="Enable Date of Birth"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="dateOfBirthRequired"
                      checked={
                        setUndefinedAsTrue(
                          getConfigFromPage(
                            props.values,
                            OWNER_CONFIGS.DATE_OF_BIRTH,
                          )?.required,
                        ) as boolean
                      }
                      onChange={(event) => {
                        const config = getConfigFromPage(
                          props.values,
                          OWNER_CONFIGS.DATE_OF_BIRTH,
                        ) as Config
                        if (config) {
                          props.setFieldValue(
                            "config",
                            props.values.config.map((c) => {
                              if (c.label === OWNER_CONFIGS.DATE_OF_BIRTH) {
                                c.required = event.target.checked
                              }
                              return c
                            }),
                          )
                        } else {
                          props.values.config.push({
                            label: OWNER_CONFIGS.DATE_OF_BIRTH,
                            description: "Date of Birth",
                            value: true,
                            required: event.target.checked,
                          })
                        }
                      }}
                    />
                  }
                  label="Require Date of Birth"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="address"
                      checked={
                        getConfigFromPage(props.values, OWNER_CONFIGS.ADDRESS)
                          ?.value as boolean
                      }
                      onChange={(event) => {
                        const config = getConfigFromPage(
                          props.values,
                          OWNER_CONFIGS.ADDRESS,
                        ) as Config
                        if (config) {
                          props.setFieldValue(
                            "config",
                            props.values.config.map((c) => {
                              if (c.label === OWNER_CONFIGS.ADDRESS) {
                                c.value = event.target.checked
                              }
                              return c
                            }),
                          )
                        } else {
                          props.values.config.push({
                            label: OWNER_CONFIGS.ADDRESS,
                            description: "Owner home address",
                            value: event.target.checked,
                          })
                        }
                      }}
                    />
                  }
                  label="Enable Owner home address"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="addressRequired"
                      checked={
                        getConfigFromPage(props.values, OWNER_CONFIGS.ADDRESS)
                          ?.required as boolean
                      }
                      onChange={(event) => {
                        const config = getConfigFromPage(
                          props.values,
                          OWNER_CONFIGS.ADDRESS,
                        ) as Config
                        if (config) {
                          props.setFieldValue(
                            "config",
                            props.values.config.map((c) => {
                              if (c.label === OWNER_CONFIGS.ADDRESS) {
                                c.required = event.target.checked
                              }
                              return c
                            }),
                          )
                        } else {
                          props.values.config.push({
                            label: OWNER_CONFIGS.ADDRESS,
                            description: "Owner home address",
                            required: event.target.checked,
                            value: true,
                          })
                        }
                      }}
                    />
                  }
                  label="Require Owner home address"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="apContacts"
                      checked={
                        getConfigFromPage(
                          props.values,
                          OWNER_CONFIGS.AP_CONTACTS,
                        )?.value as boolean
                      }
                      onChange={(event) => {
                        const config = getConfigFromPage(
                          props.values,
                          OWNER_CONFIGS.AP_CONTACTS,
                        ) as Config
                        if (config) {
                          props.setFieldValue(
                            "config",
                            props.values.config.map((c) => {
                              if (c.label === OWNER_CONFIGS.AP_CONTACTS) {
                                c.value = event.target.checked
                              }
                              return c
                            }),
                          )
                        } else {
                          props.values.config.push({
                            label: OWNER_CONFIGS.AP_CONTACTS,
                            description: "AP contact information",
                            value: event.target.checked,
                          })
                        }
                      }}
                    />
                  }
                  label="Enable AP contacts information"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="apContactsRequired"
                      checked={setUndefinedAsTrue(
                        getConfigFromPage(
                          props.values,
                          OWNER_CONFIGS.AP_CONTACTS,
                        )?.required,
                      )}
                      onChange={(event) => {
                        const config = getConfigFromPage(
                          props.values,
                          OWNER_CONFIGS.AP_CONTACTS,
                        ) as Config
                        if (config) {
                          props.setFieldValue(
                            "config",
                            props.values.config.map((c) => {
                              if (c.label === OWNER_CONFIGS.AP_CONTACTS) {
                                c.required = event.target.checked
                              }
                              return c
                            }),
                          )
                        } else {
                          props.values.config.push({
                            label: OWNER_CONFIGS.AP_CONTACTS,
                            description: "AP contacts information",
                            value: true,
                            required: event.target.checked,
                          })
                        }
                      }}
                    />
                  }
                  label="Require AP contact information"
                />
                {getConfigFormControlValueField(
                  getConfigFromPage(props.values, OWNER_CONFIGS.AP_2_BASE)
                    ?.value as boolean,
                  "ap2BaseValue",
                  OWNER_CONFIGS.AP_2_BASE,
                  props,
                )}
                {getConfigFormControlRequiredField(
                  getConfigFromPage(props.values, OWNER_CONFIGS.AP_2_BASE)
                    ?.required as boolean,
                  "ap2BaseRequired",
                  OWNER_CONFIGS.AP_2_BASE,
                  props,
                )}
                {getConfigFormControlValueField(
                  getConfigFromPage(props.values, OWNER_CONFIGS.AP_2_EMAIL)
                    ?.value as boolean,
                  "ap2EmailValue",
                  OWNER_CONFIGS.AP_2_EMAIL,
                  props,
                )}
                {getConfigFormControlRequiredField(
                  getConfigFromPage(props.values, OWNER_CONFIGS.AP_2_EMAIL)
                    ?.required as boolean,
                  "ap2EmailRequired",
                  OWNER_CONFIGS.AP_2_EMAIL,
                  props,
                )}
                {getConfigFormControlValueField(
                  getConfigFromPage(props.values, OWNER_CONFIGS.AP_2_PHONE)
                    ?.value as boolean,
                  "ap2PhoneValue",
                  OWNER_CONFIGS.AP_2_PHONE,
                  props,
                )}
                {getConfigFormControlRequiredField(
                  getConfigFromPage(props.values, OWNER_CONFIGS.AP_2_PHONE)
                    ?.required as boolean,
                  "ap2PhoneRequired",
                  OWNER_CONFIGS.AP_2_PHONE,
                  props,
                )}
                {getConfigFormControlValueField(
                  getConfigFromPage(props.values, OWNER_CONFIGS.AP_2_ROLE)
                    ?.value as boolean,
                  "ap2RoleValue",
                  OWNER_CONFIGS.AP_2_ROLE,
                  props,
                )}
                {getConfigFormControlRequiredField(
                  getConfigFromPage(props.values, OWNER_CONFIGS.AP_2_ROLE)
                    ?.required as boolean,
                  "ap2RoleRequired",
                  OWNER_CONFIGS.AP_2_ROLE,
                  props,
                )}
                {getConfigFormControlValueField(
                  getConfigFromPage(props.values, OWNER_CONFIGS.CONTROLLER_BASE)
                    ?.value as boolean,
                  "controllerBaseValue",
                  OWNER_CONFIGS.CONTROLLER_BASE,
                  props,
                )}
                {getConfigFormControlRequiredField(
                  getConfigFromPage(props.values, OWNER_CONFIGS.CONTROLLER_BASE)
                    ?.required as boolean,
                  "controllerBaseRequired",
                  OWNER_CONFIGS.CONTROLLER_BASE,
                  props,
                )}
                {getConfigFormControlValueField(
                  getConfigFromPage(props.values, OWNER_CONFIGS.CONTROLLER_NAME)
                    ?.value as boolean,
                  "controllerNameValue",
                  OWNER_CONFIGS.CONTROLLER_NAME,
                  props,
                )}
                {getConfigFormControlRequiredField(
                  getConfigFromPage(props.values, OWNER_CONFIGS.CONTROLLER_NAME)
                    ?.required as boolean,
                  "controllerNameRequired",
                  OWNER_CONFIGS.CONTROLLER_NAME,
                  props,
                )}
                {getConfigFormControlValueField(
                  getConfigFromPage(
                    props.values,
                    OWNER_CONFIGS.CONTROLLER_EMAIL,
                  )?.value as boolean,
                  "controllerEmailValue",
                  OWNER_CONFIGS.CONTROLLER_EMAIL,
                  props,
                )}
                {getConfigFormControlRequiredField(
                  getConfigFromPage(
                    props.values,
                    OWNER_CONFIGS.CONTROLLER_EMAIL,
                  )?.required as boolean,
                  "controllerEmailRequired",
                  OWNER_CONFIGS.CONTROLLER_EMAIL,
                  props,
                )}
                {getConfigFormControlValueField(
                  getConfigFromPage(
                    props.values,
                    OWNER_CONFIGS.CONTROLLER_PHONE,
                  )?.value as boolean,
                  "controllerPhoneValue",
                  OWNER_CONFIGS.CONTROLLER_PHONE,
                  props,
                )}
                {getConfigFormControlRequiredField(
                  getConfigFromPage(
                    props.values,
                    OWNER_CONFIGS.CONTROLLER_PHONE,
                  )?.required as boolean,
                  "controllerPhoneRequired",
                  OWNER_CONFIGS.CONTROLLER_PHONE,
                  props,
                )}
                {getConfigFormControlValueField(
                  getConfigFromPage(props.values, OWNER_CONFIGS.CONTROLLER_ROLE)
                    ?.value as boolean,
                  "controllerRoleValue",
                  OWNER_CONFIGS.CONTROLLER_ROLE,
                  props,
                )}
                {getConfigFormControlRequiredField(
                  getConfigFromPage(props.values, OWNER_CONFIGS.CONTROLLER_ROLE)
                    ?.required as boolean,
                  "controllerRoleRequired",
                  OWNER_CONFIGS.CONTROLLER_ROLE,
                  props,
                )}
                <TextField
                  type="number"
                  name="NumberOfApContacts"
                  label={OWNER_CONFIGS.NUMBER_OF_AP_CONTACTS}
                  value={
                    getConfigFromPage(
                      props.values,
                      OWNER_CONFIGS.NUMBER_OF_AP_CONTACTS,
                    )
                      ? (getConfigFromPage(
                          props.values,
                          OWNER_CONFIGS.NUMBER_OF_AP_CONTACTS,
                        )?.value as number)
                      : 0
                  }
                  onChange={(event) => {
                    const config = getConfigFromPage(
                      props.values,
                      OWNER_CONFIGS.NUMBER_OF_AP_CONTACTS,
                    ) as Config
                    if (config) {
                      props.setFieldValue(
                        "config",
                        props.values.config.map((c) => {
                          if (c.label === OWNER_CONFIGS.NUMBER_OF_AP_CONTACTS) {
                            c.value = Number(event.target.value)
                          }
                          return c
                        }),
                      )
                    } else {
                      props.values.config.push({
                        label: OWNER_CONFIGS.NUMBER_OF_AP_CONTACTS,
                        value: Number(event.target.value),
                      })
                    }
                  }}
                />
              </Stack>
              <Stack sx={{ marginY: 2 }}>
                <Button
                  variant="contained"
                  disabled={isLoading}
                  onClick={() => {
                    props.handleSubmit()
                  }}
                >
                  Save
                </Button>
              </Stack>
            </FormControl>
          )}
        </Formik>
        {page && (
          <SectionWhenComponent
            page={page}
            template={applicationTemplate}
            refetch={refetch}
          />
        )}
      </Stack>
    </>
  )
}
