import Card from "@mui/material/Card"
import Typography from "@mui/material/Typography"
import emitter from "src/services/emitter-service"
import { useEffect, useState } from "react"
import { useOpenCorporateGetCompanies } from "src/queries/vendors/useOpenCorporate"
import {
  Box,
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material"
import { usePDF, Margin } from "react-to-pdf"
import { Close } from "@mui/icons-material"
import OpenCorporateCompanyData from "./open-corporate/OpenCorporateCompanyData"
import { OpenCorporateGetCompaniesInterface } from "src/types"

interface OpenCorporateCompaniesDataProps {
  applicationId: string
  openCompanyDataDialogs: boolean
  onClose: () => void
}
const OpenCorporateCompaniesData = ({
  applicationId,
  openCompanyDataDialogs,
  onClose,
}: OpenCorporateCompaniesDataProps) => {
  const [jurisdictionCode, setJurisdictionCode] = useState("")
  const [companyNumber, setCompanyNumber] = useState("")
  const [timeStamp, setTimeStamp] = useState(0)
  const [hasCompanyData, setHasCompanyData] = useState(false)

  const {
    data: companyData,
    refetch: refetchCompanyData,
    isLoading: isLoadingData,
  } = useOpenCorporateGetCompanies(
    applicationId,
    jurisdictionCode,
    companyNumber,
    1,
    1,
  )
  const { toPDF, targetRef } = usePDF({
    filename:
      companyData && companyData?.companyName
        ? companyData?.companyName + " Report.pdf"
        : "RegistryReport.pdf",
    page: { margin: Margin.MEDIUM },
  })

  useEffect(() => {
    const handleJurisdictionCode = (data: unknown) =>
      setJurisdictionCode(data as string)
    const handleCompanyNumber = (data: unknown) =>
      setCompanyNumber(data as string)

    const handleTimeStamp = (data: unknown) => setTimeStamp(data as number)

    emitter.on("jurisdictionCode", handleJurisdictionCode)
    emitter.on("companyNumber", handleCompanyNumber)
    emitter.on("timeStamp", handleTimeStamp)
    if (companyData) {
      setHasCompanyData(true)
    } else {
      setHasCompanyData(false)
    }

    return () => {
      emitter.off("jurisdictionCode", handleJurisdictionCode)
      emitter.off("companyNumber", handleCompanyNumber)
      emitter.off("timeStamp", handleTimeStamp)
    }
  }, [
    jurisdictionCode,
    companyNumber,
    refetchCompanyData,
    companyData,
    timeStamp,
  ])

  if (isLoadingData) {
    return (
      <div>
        <Dialog
          maxWidth="sm"
          open={openCompanyDataDialogs}
          onClose={onClose}
          PaperProps={{
            sx: {
              width: "180%",
              maxWidth: "1000px",
            },
          }}
        >
          <DialogTitle>Registry Lookup</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
          <DialogContent>
            <div>...Loading...</div>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={onClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  const renderNoCompanyDataCore = (
    <>
      <Card sx={{ width: "100%" }}>
        <CardHeader
          title={
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "8px",
              }}
            >
              <Typography variant="h6">Company Data</Typography>
            </Box>
          }
        />
        <Box
          sx={{
            color: "text.secondary",
            justifyContent: "center",
            display: "flex",
            height: "100%",
            flexDirection: "column",
            marginLeft: 3,
          }}
        >
          <span>No Data Found</span>
        </Box>
      </Card>
    </>
  )

  return (
    <div>
      <Dialog
        maxWidth="sm"
        open={openCompanyDataDialogs}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: "180%",
            maxWidth: "1000px",
          },
        }}
      >
        <DialogTitle>Registry Lookup</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <div>
            {hasCompanyData ? (
              <Stack ref={targetRef}>
                <OpenCorporateCompanyData
                  companyData={
                    companyData as OpenCorporateGetCompaniesInterface
                  }
                  jurisdictionCode={jurisdictionCode}
                  companyNumber={companyNumber}
                  perPage={3}
                  page={1}
                />
              </Stack>
            ) : (
              <>{renderNoCompanyDataCore}</>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          {hasCompanyData && (
            <Button variant="text" onClick={() => toPDF()}>
              Download
            </Button>
          )}
          <Button variant="outlined" onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default OpenCorporateCompaniesData
