import queryString from "query-string"
import { useQuery } from "react-query"
import {
  CreditRiskMonitorPDF,
  CreditRiskMonitorPurchasedReport,
  CreditRiskMonitorSearchFormInput,
  CreditRiskMonitorSearchResponse,
} from "src/types"

export function useCreditRiskMonitorSearch(
  data: CreditRiskMonitorSearchFormInput,
) {
  const request_data = {
    business_id: data.businessId,
    business_name: data.businessName,
  }
  const queryKey =
    "/credit_risk_monitor/search?" + queryString.stringify(request_data)
  return useQuery<CreditRiskMonitorSearchResponse[]>(queryKey, {
    retry: (failureCount, error) => {
      if (failureCount < 1) {
        return true
      }
      // do not retry on 404 and 400
      return ![404, 400].includes((error as any)?.response?.status)
    },
    meta: { timeout: 61000 },
  })
}

export function useCreditRiskMonitorPDF(
  application_id: string,
  business_id: string,
  credit_risk_monitor_business_id: string,
  businessName: string,
  address: string,
  cityName: string,
  provinceName: string,
  countryName: string,
) {
  const request_data = {
    business_id: business_id,
    application_id: application_id,
    credit_risk_monitor_business_id: credit_risk_monitor_business_id,
    business_name: businessName,
    address: address,
    city_name: cityName,
    province_name: provinceName,
    country_name: countryName,
  }
  const queryKey =
    "/credit_risk_monitor/pdf?" + queryString.stringify(request_data)
  return useQuery<CreditRiskMonitorPDF>(queryKey, {
    retry: (failureCount, error) => {
      if (failureCount < 1) {
        return true
      }
      // do not retry on 404
      return (error as any)?.response?.status !== 404
    },
    meta: { timeout: 140000 },
  })
}

export function useCreditRiskMonitorPurchasedReport(applicationId: string) {
  const queryKey = "/document/crm_reports?application_id=" + applicationId
  return useQuery<CreditRiskMonitorPurchasedReport[]>(queryKey, {
    enabled: !!applicationId,
    retry: (failureCount, error) => {
      if (failureCount < 1) {
        return true
      }
      // do not retry on 404
      return (error as any)?.response?.status !== 404
    },
  })
}
