import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Divider,
  Typography,
} from "@mui/material"
import { Stack } from "@mui/system"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"
import { useCreateInternalCreditApplicationTemplate } from "src/queries/internal/useCreateInternalCreditApplicationTemplate"
import { enqueueSnackbar } from "notistack"
import { confirm } from "src/components/confirm/"
import { CreateApplicationTemplate } from "src/types"
import EditCreditEmailNotes from "./components/EditCreditEmailNotes"
import EditCreditApplicationTemplate from "./components/EditCreditApplicationTemplate"
import CreditUrlPopulation from "./components/ApplicationTemplate/CreditUrlPopulation"
import CreditApplicationCard from "./components/ApplicationTemplate/CreditApplicationCard"
import { ExpandMore } from "@mui/icons-material"
import { useState } from "react"
import CreditApplicationBulkUploadDialog from "./components/CreditApplicationBulkUploadDialog"

export default function ClientCreditDetails({ id }: { id: string }) {
  const {
    execute: executeCreateCreditApplication,
    isLoading: isLoadingCreateTemplate,
  } = useCreateInternalCreditApplicationTemplate(() => {
    enqueueSnackbar("Credit Application Template Created", {
      variant: "success",
    })
  })
  const {
    data,
    refetch,
    isLoading: isFetchingTemplate,
  } = useApplicationTemplate(id)

  const [
    openCreditApplicationBulkImportDialog,
    setCreditApplicationBulkImportDialog,
  ] = useState(false)

  return (
    <Container sx={{ p: 2 }}>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          justifyContent: "flex-end",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            setCreditApplicationBulkImportDialog(true)
          }}
        >
          Credit Application Bulk Import
        </Button>
      </Stack>
      {isFetchingTemplate && "Loading..."}
      {!isFetchingTemplate && !data && (
        <>
          <Stack sx={{ p: 1 }}>
            <Button
              variant="contained"
              disabled={isLoadingCreateTemplate}
              onClick={() => {
                confirm(
                  "Are you Sure you want to Activate Credit Applications For This Account?",
                )
                  .then(
                    () => {
                      executeCreateCreditApplication(
                        { seller: id } as CreateApplicationTemplate,
                        () => {
                          refetch()
                        },
                      )
                      return
                    },
                    () => {
                      console.log("error")
                    },
                  )
                  .catch(() => {
                    console.log("error")
                  })
              }}
            >
              Activate Credit Application
            </Button>
          </Stack>
        </>
      )}
      {data && (
        <>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="view-credit-applications-content"
              id="view-credit-applications-header"
            >
              <Typography variant="subtitle2">
                View Credit Applications
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CreditApplicationCard id={id as string} />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="credit-url-content"
              id="credit-url-header"
            >
              <Typography variant="subtitle2">
                URL shortneer for Credit Applications
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CreditUrlPopulation id={id as string} />
            </AccordionDetails>
          </Accordion>
          <EditCreditApplicationTemplate
            applicationTemplate={data}
            refetch={refetch}
          />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="credit-url-content"
              id="credit-url-header"
            >
              <Typography variant="subtitle2">
                Notes and Approval Terms
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <EditCreditEmailNotes
                applicationTemplate={data}
                refetch={refetch}
              />
            </AccordionDetails>
          </Accordion>
        </>
      )}
      <Divider />
      {openCreditApplicationBulkImportDialog && (
        <CreditApplicationBulkUploadDialog
          open={openCreditApplicationBulkImportDialog}
          onClose={() => setCreditApplicationBulkImportDialog(false)}
          businessId={id}
          refetch={refetch}
        />
      )}
    </Container>
  )
}
