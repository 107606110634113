import * as React from "react"
import { FormikProps } from "formik"

import { confirm } from "src/components/confirm"
import { useLocation, useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"

import { CreditApplication } from "src/types"
import { usePatchApplication } from "src/queries/credit/usePatchApplication"
import { useCreditApplication } from "src/queries/credit/useCreditApplication"
import { usePatchBuyerApplication } from "src/queries/credit/usePatchBuyerApplication"
import { useSnackbar } from "notistack"
import { useQueryClient } from "react-query"
import { error } from "src/utils/logger"
import { DropzoneDialog } from "mui-file-dropzone"
import { usePostUploadedFile } from "src/queries/credit/usePostUploadedFile"
import ApplicationFormikForm from "../ApplicationFormikForm"
import ManualEntryFormComponent from "./forms/ManualEntryFormComponent"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"

import * as yup from "yup"
import { usePostAnalytics } from "src/queries/analytics/usePostAnalytics"

export default () => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const [additionalFilesNeeded, setAdditionalFilesNeeded] =
    React.useState(false)

  const params = useParams()
  const { id } = params

  const { data: application, refetch: refetchApplication } =
    useCreditApplication(id)
  const { user: auth0User, isAuthenticated, logout } = useAuth0()

  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const businessId = queryParams.get("business_id") || application?.seller?.id

  const { data: template, steps } = useApplicationTemplate(
    businessId,
    true,
    id ? !!application?.seller?.id : false,
    id,
  )

  const { execute: uploadFiles } = usePostUploadedFile()
  const { execute: saveApplicationProgress } = usePatchBuyerApplication(() => {
    queryClient.removeQueries()
    enqueueSnackbar("Application Saved.", {
      variant: "info",
    })
  })
  const { execute: patchApplication } = usePatchApplication(() => {
    enqueueSnackbar("Application Complete", { variant: "success" })
    navigate("/applications/" + id)
  })

  const { execute: saveAnalytics } = usePostAnalytics()

  const [activeStep, setActiveStep] = React.useState(0)

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const onSubmit = React.useCallback(() => {
    if (id) {
      patchApplication(id, {
        stage: 60,
      })
    }
  }, [id, patchApplication])

  const onNextStep = React.useCallback(
    (stepsRemaining?: boolean) => {
      if (id && formRef.current) {
        const values = formRef.current.values
        if (activeStep !== steps.length - 1) {
          saveApplicationProgress(id, formRef.current.values, () => {
            saveAnalytics("save", "manual", id, steps[activeStep].label, {
              seller: values.seller?.name || "",
              buyer: `${values.data.firstName} ${values.data.lastName}`,
              buyerEmail: auth0User?.email || "",
              buyerPhoneNumber: values.data.userPhoneNumber,
            })
            refetchApplication()
              // eslint-disable-next-line promise/no-nesting
              .then(() => {
                return setActiveStep((prevActiveStep) => prevActiveStep + 1)
              })
              // eslint-disable-next-line promise/no-nesting
              .catch((err) => {
                error(err)
              })
          })
        } else {
          // no more steps left
          formRef.current?.validateForm().then(() => {
            return saveApplicationProgress(
              id,
              formRef.current?.values as CreditApplication,
              () => {
                refetchApplication()
                  // eslint-disable-next-line promise/no-nesting
                  .then(() => {
                    return confirm(
                      `You are about to mark this application as complete. Please note that based on your selection, trade references for this application ${
                        application?.requestTradeRefs ? "WILL" : "WILL NOT"
                      } be automatically contacted. If this is incorrect, please archive this application and start a new one. Would you like to attach any additional files to this application?`,
                      {
                        title: "One Last Check",
                        okLabel: "Attach Files",
                        cancelLabel: "Application is Complete",
                      },
                    )
                  })
                  // eslint-disable-next-line promise/no-nesting
                  .then(
                    () => {
                      saveAnalytics(
                        "submit",
                        "manual",
                        id,
                        steps[activeStep].label,
                        {
                          seller: values.seller?.name || "",
                          buyer: `${values.data.firstName} ${values.data.lastName}`,
                          buyerEmail: auth0User?.email || "",
                          buyerPhoneNumber: values.data.userPhoneNumber,
                        },
                      )
                      setAdditionalFilesNeeded(true)
                      return
                    },
                    () => {
                      onSubmit()
                    },
                  )
                  // eslint-disable-next-line promise/no-nesting
                  .catch((err) => {
                    error("error asking for additional files", err)
                  })
              },
            )
          })
        }
      }
    },
    [
      application?.requestTradeRefs,
      id,
      onSubmit,
      refetchApplication,
      saveApplicationProgress,
      activeStep,
      steps.length,
    ],
  )

  const formRef = React.useRef<FormikProps<CreditApplication>>(null)

  if ((id && !application) || !template) return <>Loading application...</>

  return (
    <>
      <ApplicationFormikForm
        template={template}
        steps={steps}
        application={application as CreditApplication}
        activeStep={activeStep}
        initialValues={application as CreditApplication}
        handleNext={onNextStep}
        handleBack={handleBack}
        formRef={formRef}
        Component={ManualEntryFormComponent}
      />

      <DropzoneDialog
        open={additionalFilesNeeded}
        onSave={(newFiles) => {
          if (newFiles.length > 0) {
            const formData = new FormData()
            newFiles.forEach((file, index) => {
              formData.append("file" + index, file)
            })
            formData.append("applicationId", id || "")
            uploadFiles(formData, () => {
              onSubmit()
            })
          }
        }}
        acceptedFiles={["application/pdf", "image/png", "image/jpeg"]}
        showPreviews={true}
        maxFileSize={32000000}
        onClose={() => {
          setAdditionalFilesNeeded(false)
        }}
        filesLimit={10}
        fileObjects={null}
        dropzoneText={
          "Drag and drop a PDF, PNG or JPEG file here or click to select a file"
        }
      />
    </>
  )
}
