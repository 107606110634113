import { Paper, Typography, Box, useTheme } from "@mui/material"
import ReactApexChart from "react-apexcharts"
import { format } from "date-fns"
import { CreditSafeDBTHistory } from "src/types"

const CreditDBTHistory = ({ data }: { data: CreditSafeDBTHistory }) => {
  const theme = useTheme()
  const formattedData = data.dbtHistory.map((point) => ({
    x: format(new Date(point.date), "MMM yyyy"),
    y:
      point.industryDBT === undefined || Number.isNaN(point.industryDBT)
        ? 0
        : point.industryDBT,
  }))

  const chartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      background: "transparent",
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      type: "category",
      labels: {
        rotate: -45,
        style: {
          fontSize: "12px",
        },
      },
      title: {
        text: "Date",
      },
    },
    yaxis: {
      title: {
        text: "Days Beyond Terms",
      },
      labels: {
        formatter: (value: number) => {
          if (typeof value === "number") {
            return `${value.toFixed(2)}`
          }
          return `${value}`
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value: number) => {
          if (typeof value === "number") {
            return `${value.toFixed(2)} days`
          }
          return `${value} days`
        },
      },
    },
    markers: {
      size: 4,
      hover: {
        size: 6,
      },
    },
    grid: {
      borderColor: theme.palette.grey[300],
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    theme: {
      mode: "light" as const,
    },
  }

  const series = [
    {
      name: "Industry DBT",
      data: formattedData,
    },
  ]

  return (
    <Paper
      elevation={3}
      sx={{
        p: 3,
        mt: 4,
        backgroundColor: "background.paper",
        borderRadius: 2,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 3 }}>
        <Typography variant="h6" component="h2">
          Industry DBT History
        </Typography>
      </Box>

      <Box sx={{ height: 400, width: "100%", mb: 2 }}>
        <ReactApexChart
          options={chartOptions}
          series={series}
          type="line"
          height="100%"
        />
      </Box>

      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        sx={{ mt: 2 }}
      >
        Historical trend of Days Beyond Terms (DBT) in the industry
      </Typography>
    </Paper>
  )
}

export default CreditDBTHistory
