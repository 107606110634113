import { Stack, Typography } from "@mui/material"
import Chart from "react-apexcharts"
import { format } from "date-fns"

const RatingHistoryChart = ({
  data,
}: {
  data: { date: string; companyValue: string }[]
}) => {
  const formattedData = data.map((item) => ({
    x: format(new Date(item.date), "MMM dd, yyyy"),
    y: !Number.isNaN(item.companyValue) ? parseInt(item.companyValue) : 0,
  }))

  const chartOptions = {
    chart: {
      type: "line" as const,
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      type: "category" as const,
      labels: {
        rotate: -45,
        rotateAlways: false,
      },
    },
    yaxis: {
      title: {
        text: "Rating Value",
      },
      min:
        Math.min(
          ...data.map((item) =>
            Number.isNaN(item.companyValue) || item.companyValue === undefined
              ? 0
              : parseInt(item.companyValue),
          ),
        ) - 5,
      max:
        Math.max(
          ...data.map((item) =>
            Number.isNaN(item.companyValue) || item.companyValue === undefined
              ? 0
              : parseInt(item.companyValue),
          ),
        ) + 5,
    },
    title: {
      text: "Company Rating History",
      align: "left" as const,
    },
    stroke: {
      curve: "smooth" as const,
      width: 3,
    },
    markers: {
      size: 5,
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy",
      },
    },
  }

  const series = [
    {
      name: "Rating",
      data: formattedData,
    },
  ]

  return (
    <Stack sx={{ p: 3, m: 1 }}>
      <Typography variant="h6">Credit Rating History Over Time</Typography>
      <Chart options={chartOptions} series={series} type="line" height={350} />
    </Stack>
  )
}

export default RatingHistoryChart
