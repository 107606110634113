import { useQuery } from "react-query"
import { useTypedMutation } from "../useTypedMutation"
import { CreditData } from "src/types"

export function useTransUnionSearch(onSuccess: (data: CreditData) => void) {
  const ENDPOINT = "/transunion/report"

  const searchReport = useTypedMutation("post/" + ENDPOINT, onSuccess)

  const execute = (body: {
    applicationId: string
    firstName: string
    lastName: string
    ssn: string
    dob: string
    streetNumber: string
    streetName: string
    city: string
    state: string
    zipCode: string
  }) => {
    searchReport.mutate({
      method: "post",
      endpoint: ENDPOINT,
      body,
    })
  }
  return { ...searchReport, execute }
}

export function useTransUnionPurchasedReport(applicationId: string) {
  const queryKey =
    "/transunion/purchased_reports?application_id=" + applicationId
  return useQuery<{ json: CreditData; createdAt: string }[]>(queryKey, {
    enabled: !!applicationId,
    retry: (failureCount, error) => {
      if (failureCount < 1) {
        return true
      }
      // do not retry on 404
      return (error as any)?.response?.status !== 404
    },
  })
}
