import React from "react"
import {
  Paper,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Box,
} from "@mui/material"
import Chart from "react-apexcharts"
import { format } from "date-fns"
import { CreditSafeFinancialStatement } from "src/types"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`financial-tabpanel-${index}`}
      aria-labelledby={`financial-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

const FinancialStatementsCard = ({
  data,
  title,
}: {
  data: CreditSafeFinancialStatement
  title: string
}) => {
  const [tabValue, setTabValue] = React.useState(0)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const statement = data

  const profitLossChartOptions = {
    chart: {
      type: "bar" as const,
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [
        "Revenue",
        "Operating Profit",
        "Profit Before Tax",
        "Profit After Tax",
      ],
    },
    yaxis: {
      title: {
        text: `Amount (${statement.currency})`,
      },
    },
    theme: {
      mode: "light" as const,
    },
  }

  const profitLossSeries = [
    {
      name: "Amount",
      data: [
        statement.profitAndLoss.revenue,
        statement.profitAndLoss.operatingProfit,
        statement.profitAndLoss.profitBeforeTax,
        statement.profitAndLoss.profitAfterTax,
      ],
    },
  ]

  const balanceSheetChartOptions = {
    chart: {
      type: "pie" as const,
      height: 350,
    },
    labels: ["Total Assets", "Total Liabilities", "Total Shareholders Equity"],
    theme: {
      mode: "light" as const,
    },
  }

  const balanceSheetSeries = [
    statement.balanceSheet.totalAssets,
    statement.balanceSheet.totalLiabilities,
    statement.balanceSheet.totalShareholdersEquity,
  ]

  const ratiosChartOptions = {
    chart: {
      type: "radar" as const,
      height: 350,
    },
    xaxis: {
      categories: [
        "Current Ratio",
        "Gearing",
        "Equity %",
        "Debt Ratio",
        "Return on Capital",
      ],
    },
    theme: {
      mode: "light" as const,
    },
  }

  const ratiosSeries = [
    {
      name: "Ratios",
      data: [
        statement.ratios.currentRatio,
        statement.ratios.gearing,
        statement.ratios.equityInPercentage,
        statement.ratios.totalDebtRatio,
        statement.ratios.returnOnCapitalEmployed,
      ],
    },
  ]

  return (
    <Paper className="p-6 mt-6">
      <Typography variant="h6">
        {title}
        <Typography variant="subtitle2" className="ml-2">
          (Year End: {format(new Date(statement.yearEndDate), "dd MMM yyyy")})
        </Typography>
      </Typography>

      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="financial statements tabs"
      >
        <Tab label="Profit & Loss" />
        <Tab label="Balance Sheet" />
        <Tab label="Ratios" />
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Chart
              options={profitLossChartOptions}
              series={profitLossSeries}
              type="bar"
              height={350}
            />
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption">Metric</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="caption">
                        Amount ({statement.currency})
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(statement.profitAndLoss).map(
                    ([key, value]) => (
                      <TableRow key={key}>
                        <TableCell>
                          <Typography variant="caption">
                            {key.replace(/([A-Z])/g, " $1").trim()}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="caption">
                            {value.toLocaleString()}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ),
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Chart
              options={balanceSheetChartOptions}
              series={balanceSheetSeries}
              type="pie"
              height={350}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption">Metric</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="caption">
                        Amount ({statement.currency})
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(statement.balanceSheet).map(
                    ([key, value]) => (
                      <TableRow key={key}>
                        <TableCell>
                          <Typography variant="caption">
                            {key.replace(/([A-Z])/g, " $1").trim()}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="caption">
                            {value.toLocaleString()}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ),
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Chart
              options={ratiosChartOptions}
              series={ratiosSeries}
              type="radar"
              height={350}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption">Ratio</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="caption">Value</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(statement.ratios).map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell>
                        <Typography variant="caption">
                          {key.replace(/([A-Z])/g, " $1").trim()}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="caption">
                          {value.toFixed(2)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </TabPanel>
    </Paper>
  )
}

export default FinancialStatementsCard
