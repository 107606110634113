import { useQuery } from "react-query"
import { FinancialAnalysis } from "src/types"

export function useFinancialAnalysis(cache_key: string) {
  const queryKey = "/financial_analysis/" + cache_key

  return useQuery<FinancialAnalysis>(queryKey, {
    refetchInterval: (data) => (data?.response ? false : 1000),
    refetchOnMount: false,
    retry: (failureCount, error) => {
      if (failureCount < 1) {
        return true
      }
      // do not retry on 404
      return (error as any)?.response?.status !== 404
    },
  })
}
