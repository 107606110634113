import * as yup from "yup"
import { ApplicationTemplate, CreditApplication } from "src/types"
import { PAGE_LABEL_PERSONAL_GUARANTY } from "../constants"
import { isPageEnabled, isPageRequired } from "../template_helpers"
import { CUSTOM_WORD_MAPPINGS, customWordMapping } from "src/statics"

const PERSONAL_GUARANTY_YUP_SCHEMA = yup.object({
  guarantorName: yup.string().required("Name is required"),
  guarantorEmail: yup
    .string()
    .email("Email is required")
    .required("Email is required"),
  guarantorPhoneNumber: yup.string().required("Phone number is required"),
})

export const getNumberOfGuanators = (template: ApplicationTemplate): number => {
  return (
    Number(
      template?.formTemplate?.pages
        .find((tstep) => tstep.label === PAGE_LABEL_PERSONAL_GUARANTY)
        ?.config?.find((config) =>
          config.label.includes("Number of Guarantors"),
        )?.value,
    ) || 1
  )
}
const isFieldPopulated = (field: string, isPhoneNumber = false) => {
  if (isPhoneNumber && field === "+") {
    return false
  }

  if (field && field.length > 0) {
    return true
  }
  return false
}

export const getPersonalGuarantyEnabledSchema = (
  template: ApplicationTemplate,
  data?: CreditApplication["data"],
): yup.AnyObjectSchema => {
  if (
    isPageEnabled(PAGE_LABEL_PERSONAL_GUARANTY, template, data) &&
    isPageRequired(PAGE_LABEL_PERSONAL_GUARANTY, template, data)
  ) {
    if (getNumberOfGuanators(template) === 1) {
      return PERSONAL_GUARANTY_YUP_SCHEMA
    }

    let enabledSchema = yup.object({}).concat(PERSONAL_GUARANTY_YUP_SCHEMA)

    for (let i = 1; i < getNumberOfGuanators(template); i++) {
      const number = i + 1
      enabledSchema = enabledSchema.concat(
        yup.object().shape(
          {
            [`guarantorName${number}`]: yup
              .string()
              .when(
                [`guarantorEmail${number}`, `guarantorPhoneNumber${number}`],
                {
                  is: (fieldTwo: string, fieldThree: string) =>
                    isFieldPopulated(fieldTwo) ||
                    isFieldPopulated(fieldThree, true),
                  then: yup.string().required("Name is required"),
                },
              ),
            [`guarantorEmail${number}`]: yup
              .string()
              .when(
                [`guarantorName${number}`, `guarantorPhoneNumber${number}`],
                {
                  is: (fieldTwo: string, fieldThree: string) =>
                    isFieldPopulated(fieldTwo) ||
                    isFieldPopulated(fieldThree, true),
                  then: yup
                    .string()
                    .email("Email is required")
                    .required("Email is required")
                    .notOneOf(
                      new Array(getNumberOfGuanators(template))
                        .fill(0)
                        .map((_, j) => {
                          if (i !== j && j === 0) {
                            return yup.ref("guarantorEmail")
                          } else {
                            return i === j
                              ? undefined
                              : yup.ref("guarantorEmail" + (j + 1))
                          }
                        }),
                      customWordMapping(CUSTOM_WORD_MAPPINGS.GUARANTOR) +
                        " emails must be unique",
                    ),
                },
              ),
            [`guarantorPhoneNumber${number}`]: yup
              .string()
              .when([`guarantorName${number}`, `guarantorEmail${number}`], {
                is: (fieldTwo: string, fieldThree: string) =>
                  isFieldPopulated(fieldTwo) || isFieldPopulated(fieldThree),
                then: yup.string().required("Phone number is required"),
              }),
          },
          [
            [`guarantorEmail${number}`, `guarantorPhoneNumber${number}`],
            [`guarantorName${number}`, `guarantorPhoneNumber${number}`],
            [`guarantorName${number}`, `guarantorEmail${number}`],
          ],
        ),
      )
    }

    return enabledSchema
  }

  return yup.object({})
}
