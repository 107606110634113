import { useState } from "react"
import OpenCorporateSearchbar from "./components/OpenCorporateSearchbar"
import {
  CreditApplication,
  OpenCorporateGetCompaniesInterface,
} from "src/types"

import {
  Button,
  CardContent,
  CardHeader,
  Paper,
  Stack,
  Typography,
} from "@mui/material"
import { ScreenSearchDesktopOutlined } from "@mui/icons-material"

import emitter from "src/services/emitter-service"
import { useOpenCorporateGetReports } from "src/queries/vendors/useOpenCorporate"
import OpenCorporateDialogView from "./components/open-corporate/OpenCorporateDialogView"
import OpenCorporateCompaniesData from "./components/OpenCorporateCompaniesData"

export default function CreditApplicationBusinessRegistries({
  application,
}: {
  application: CreditApplication
}) {
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false)
  const [timeStamp, setTimeStamp] = useState(0)
  const [openCompanyDataDialogs, setOpenCompanyDataDialogs] = useState(false)
  const { data: openCorporateReports, refetch: refetchOpenCorporateGetReport } =
    useOpenCorporateGetReports(application.id as string)
  const [openCorporateCurrentReport, setOpenCorporateCurrentReport] = useState<
    OpenCorporateGetCompaniesInterface | undefined
  >(undefined)
  const handleSearchButtonClick = () => {
    setIsSearchDialogOpen(true)
    setTimeStamp(Date.now())
    emitter.emit(
      "applicationLegalBusinessName",
      application.data.legalBusinessName,
    )
  }

  const handleCloseSearchDialog = () => {
    refetchOpenCorporateGetReport()
    setIsSearchDialogOpen(false)
    setOpenCompanyDataDialogs(true)
  }
  return (
    <>
      <CardHeader
        title={"Business Registries"}
        subheader="Search global business registries for incorporation, officer and corporate events"
      />
      <CardContent style={{ display: "flex", justifyContent: "right" }}>
        <Button
          onClick={handleSearchButtonClick}
          variant="contained"
          style={{ margin: "1rem" }}
          startIcon={<ScreenSearchDesktopOutlined />}
        >
          Search
        </Button>
      </CardContent>
      <OpenCorporateSearchbar
        isSearchDialogOpen={isSearchDialogOpen}
        handleCloseSearchDialog={handleCloseSearchDialog}
      />

      {openCorporateReports && (
        <Stack spacing={1} sx={{ p: 2 }} direction="column">
          {openCorporateReports.map((report, index) => (
            <Paper elevation={3} sx={{ p: 2, m: 2 }} key={index}>
              <Stack
                spacing={2}
                direction="row"
                justifyContent={"space-between"}
              >
                <Stack direction="column" spacing={0.3}>
                  <Typography variant="subtitle2">
                    Registry Report {index + 1}
                  </Typography>
                  <Typography variant="caption">
                    {report?.companyName && report?.companyName}{" "}
                  </Typography>
                </Stack>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOpenCorporateCurrentReport(report)
                  }}
                >
                  View
                </Button>
              </Stack>
            </Paper>
          ))}
        </Stack>
      )}
      <OpenCorporateCompaniesData
        applicationId={application.id as string}
        openCompanyDataDialogs={openCompanyDataDialogs}
        onClose={() => {
          setOpenCompanyDataDialogs(false)
          refetchOpenCorporateGetReport()
        }}
      />
      {
        <OpenCorporateDialogView
          open={Boolean(openCorporateCurrentReport)}
          report={
            openCorporateCurrentReport as OpenCorporateGetCompaniesInterface
          }
          onClose={() => {
            setOpenCorporateCurrentReport(undefined)
            refetchOpenCorporateGetReport()
          }}
        />
      }
    </>
  )
}
