import { memo } from "react"
// @mui
import Box from "@mui/material/Box"

// ----------------------------------------------------------------------

function LoadingIcon({ ...other }) {
  return (
    <div
      style={{
        position: "relative",
        height: "72px",
        width: "72px",
      }}
    >
      <Box
        component="svg"
        viewBox="5 15 100 50"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "absolute",
          height: "100%",
          width: "90%",
          display: "block",
          zIndex: "999",
        }}
        {...other}
      >
        <g>
          <rect fill="#ffbf23" height="6" width="25" y="47" x="12.5">
            <animate
              begin="-0.20408163265306123s"
              keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
              values="45.8;47;47"
              keyTimes="0;0.5;1"
              calcMode="spline"
              dur="1.0204081632653061s"
              repeatCount="indefinite"
              attributeName="y"
            ></animate>
            <animate
              begin="-0.20408163265306123s"
              keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
              values="8.399999999999999;6;6"
              keyTimes="0;0.5;1"
              calcMode="spline"
              dur="1.0204081632653061s"
              repeatCount="indefinite"
              attributeName="height"
            ></animate>
          </rect>
          <rect fill="#ffbf23" height="6" width="25" y="47" x="37.5">
            <animate
              begin="-0.10204081632653061s"
              keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
              values="46.1;47;47"
              keyTimes="0;0.5;1"
              calcMode="spline"
              dur="1.0204081632653061s"
              repeatCount="indefinite"
              attributeName="y"
            ></animate>
            <animate
              begin="-0.10204081632653061s"
              keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
              values="7.799999999999999;6;6"
              keyTimes="0;0.5;1"
              calcMode="spline"
              dur="1.0204081632653061s"
              repeatCount="indefinite"
              attributeName="height"
            ></animate>
          </rect>
          <rect fill="#ffbf23" height="6" width="25" y="47" x="62.5">
            <animate
              keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
              values="46.1;47;47"
              keyTimes="0;0.5;1"
              calcMode="spline"
              dur="1.0204081632653061s"
              repeatCount="indefinite"
              attributeName="y"
            ></animate>
            <animate
              keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
              values="7.799999999999999;6;6"
              keyTimes="0;0.5;1"
              calcMode="spline"
              dur="1.0204081632653061s"
              repeatCount="indefinite"
              attributeName="height"
            ></animate>
          </rect>
          <g></g>
        </g>
      </Box>
      <Box
        component="svg"
        width="100%"
        height="100%"
        viewBox="0 0 60 80"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          // width: 350,
          // height: 50,
          // objectFit: "cover",
          // objectPosition: "0 30% 0 30%",
          position: "absolute",
          height: "60%",
          width: "60%",
          display: "block",
          zIndex: "999",
        }}
        {...other}
      >
        <g>
          <circle fill="#ffbf23" r="13" cy="23" cx="50">
            <animate
              values="32;72;32"
              keyTimes="0;0.5;1"
              keySplines="0.45 0 0.9 0.55;0 0.45 0.55 0.9"
              calcMode="spline"
              repeatCount="indefinite"
              dur="0.6s"
              attributeName="cy"
            ></animate>
          </circle>
          <g></g>
        </g>
      </Box>
    </div>
  )
}

export default memo(LoadingIcon)
