// @mui
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import CardHeader from "@mui/material/CardHeader"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  useTheme,
  Typography,
  CircularProgress,
} from "@mui/material"
// components
import { DropzoneDialog } from "mui-file-dropzone"
import { useEffect, useState } from "react"
import PDFViewerDialog from "src/components/pdf/PDFViewerDialog"
import { useUploadedCreditReport } from "src/queries/credit/useUploadedCreditReport"
import {
  CreditApplication,
  CreditData,
  CreditRiskMonitorPurchasedReport,
  CreditSafePurchasedReport,
  TransUnionPurchasedReport,
} from "src/types"
import { useUploadCreditReport } from "src/queries/credit/useUploadCreditReport"
import { useDeleteUploadedCreditReport } from "src/queries/credit/useDeleteUploadedCreditReport"
import { enqueueSnackbar } from "notistack"
import CreditViewWidget from "./components/credit-view/credit-view-widget"
import Iconify from "src/components/iconify"
import CreditSafeSearchDialog from "./components/CreditSafeSearchDialog"
import {
  useCreditCredentials,
  useCreditsafePurchasedReport,
  useCreditSafeReport,
} from "src/queries/vendors/useCreditSafeSearch"
import { fShortenNumber } from "src/utils/formatNumber"
import creditSafe from "./images/creditsafe.png"
import experian from "src/sections/@dashboard/credit/images/experian.png"
import creditRiskMonitor from "src/sections/@dashboard/credit/images/creditRiskMonitor.png"
import transUnionLogo from "src/sections/@dashboard/credit/images/tu.png"

import FileItem from "../components/file/FileItem"
import { confirm } from "src/components/confirm"
import { info } from "src/utils/logger"
import ExperianSearchDialog from "./components/ExperianSearchDialog"
import {
  useExperianPurchasedReport,
  useExperianReport,
} from "src/queries/vendors/useExperianSearch"
import PurchasedReportItem from "../components/PurchasedReportItem"
import CreditRiskMonitorSearchDialog from "./components/CreditRiskMonitorSearchDialog"
import { useCreditRiskMonitorPurchasedReport } from "src/queries/vendors/useCreditRiskMonitorSearch"
import CreditRiskMonitorPurchasedItem from "../components/CreditRiskMonitorPurchasedItem"
import TransUnionPurchasedItem from "../components/TransUnionPurchasedItem"
import { useTransUnionPurchasedReport } from "src/queries/vendors/useTransUnionSearch"
import { TransUnionSearchDialog } from "./components/transunion/TransUnionSearchDialog"
import TransUnionReportDialog from "./TransUnionReportDialog"
import { CREDIT_REPORT_VENDOR } from "src/statics"
import CreditSafeReportDialog from "./components/credit-safe/CreditSafeReportDialog"

// ----------------------------------------------------------------------
export default ({
  application,
  refetchApplicationToolbar,
}: {
  application: CreditApplication
  refetchApplicationToolbar: () => void
}) => {
  const [showUploadDialog, setShowUploadDialog] = useState(false)
  const [displayCreditSafeReport, setDisplayCreditSafeReport] = useState<
    CreditSafePurchasedReport | undefined
  >(undefined)
  const [pdfToView, setPdfToView] = useState<string | undefined>(undefined)
  const [showTransUnionReport, setShowTransUnionReport] =
    useState<CreditData | null>(null)
  const theme = useTheme()
  const { data: creditReport, refetch } = useUploadedCreditReport(
    application.id || "",
  )
  const [bin, setBin] = useState<string | undefined>(undefined)
  const [connectId, setConnectId] = useState("")
  const [showInsights, setShowInsights] = useState(false)
  const {
    data: report,
    refetch: fetchReport,
    isLoading,
  } = useCreditSafeReport(application.id || "", connectId, false)

  const { refetch: fetchExperianReport, isLoading: isLoadingExperianReport } =
    useExperianReport(application.id || "", bin || "", false)

  const { data: creditRiskMonitorData, refetch: creditRiskMonitorRefetch } =
    useCreditRiskMonitorPurchasedReport(application.id as string)

  const { data: transUnionData } = useTransUnionPurchasedReport(
    application.id as string,
  )

  const { data: credentials } = useCreditCredentials(
    application.seller?.id || "",
  )

  useEffect(() => {
    if (connectId) {
      fetchReport()
    }
  }, [connectId, fetchReport])

  useEffect(() => {
    if (bin) {
      fetchExperianReport()
        .then((res) => {
          return window.open(res.data)
        })
        .catch((err) => console.log(err))
    }
  }, [bin, fetchExperianReport])

  const [searchDialogOpen, setSearchDialogOpen] = useState<
    | typeof CREDIT_REPORT_VENDOR.EXPERIAN
    | typeof CREDIT_REPORT_VENDOR.CREDIT_SAFE
    | typeof CREDIT_REPORT_VENDOR.CREDIT_RISK_MONITOR
    | typeof CREDIT_REPORT_VENDOR.TRANSUNION
    | undefined
  >(undefined)

  const { data: purchasedExperianReports } = useExperianPurchasedReport(
    application.id || "",
  )

  const {
    data: purchasedCreditsafeReports,
    refetch: refetchCreditsafePurchasedReport,
  } = useCreditsafePurchasedReport(application.id || "")

  const { execute } = useUploadCreditReport(application.id || "", () => {
    refetch()
    enqueueSnackbar("Credit report uploaded successfully.", {
      variant: "success",
    })
  })
  const { execute: deleteCreditReport } = useDeleteUploadedCreditReport(() => {
    refetch()
    enqueueSnackbar("Credit report deleted successfully.", {
      variant: "success",
    })
  })
  const renderCredit = (
    <>
      <Stack
        sx={{ typography: "body2" }}
        style={{ margin: "1.5rem 1.5rem 1.5rem 1.5rem" }}
      >
        <Box
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "end",
            gap: "2px",
            marginBottom: "1.5rem",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              setShowUploadDialog(true)
            }}
          >
            Upload Existing Report
          </Button>
        </Box>
        {creditReport && creditReport.length > 0 && (
          <Stack
            direction="column"
            justifyContent="space-evenly"
            alignItems="stretch"
            spacing={2}
          >
            {creditReport?.map((file, index) => (
              <FileItem
                key={index}
                file={file.file}
                fileSize={file.fileSize}
                onDelete={() => {
                  confirm("You are about to delete this report.", {
                    okColor: "error",
                    okLabel: "Delete",
                  })
                    .then(
                      () => {
                        return deleteCreditReport(file.id)
                      },
                      () => {
                        info("cancelled")
                      },
                    )
                    .catch(() => {
                      info("cancelled")
                    })
                }}
                onDownload={() => {
                  window.open(file.file, "_blank")
                }}
                onView={() => {
                  setPdfToView(file.file)
                }}
              />
            ))}
          </Stack>
        )}
      </Stack>
    </>
  )
  return (
    <>
      <CardHeader
        title="Credit Report"
        subheader="Upload or view the credit report of the business."
      />
      <Stack
        sx={{
          px: 3,
          gap: "2rem",
          marginTop: "2rem",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            Creditsafe
          </Typography>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <img src={creditSafe} style={{ height: "24px" }} />
          </Box>
        </Box>
        {credentials?.creditSafe?.connected && (
          <>
            Your Creditsafe account is connected.
            <Box
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "end",
                gap: "8px",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setSearchDialogOpen(CREDIT_REPORT_VENDOR.CREDIT_SAFE)
                }}
              >
                Begin Search
              </Button>
            </Box>
            {credentials?.creditSafe?.connected &&
              purchasedCreditsafeReports && (
                <Stack
                  direction="column"
                  justifyContent="space-evenly"
                  alignItems="stretch"
                  spacing={2}
                >
                  {purchasedCreditsafeReports.map(
                    (report: any, index: number) => (
                      <PurchasedReportItem
                        key={index}
                        fileName={`Creditsafe Report ${index + 1}`}
                        createdAt={report.createdAt}
                        onView={() => {
                          if (
                            report &&
                            report.data &&
                            Object.keys(report.data).length > 0
                          ) {
                            setDisplayCreditSafeReport(report)
                          } else {
                            setPdfToView(report.url)
                          }
                        }}
                      />
                    ),
                  )}
                </Stack>
              )}
          </>
        )}
        {!credentials?.creditSafe?.connected && (
          <>
            You have a monthly quota of 5 free summary reports on us. If you
            would like to obtain full credit reports, please contact us.
            <Typography variant="subtitle1">
              You have {credentials?.creditSafe?.freeReports} out of 5 summary
              reports available for this month.
            </Typography>
            <Box
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "end",
                gap: "8px",
              }}
            >
              {credentials?.creditSafe?.freeReports &&
                credentials.creditSafe?.freeReports > 0 && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      enqueueSnackbar(
                        "This service is temporarily unavailable. We are working on bringing you a new version. Please excuse the inconvenience.",
                        {
                          variant: "error",
                        },
                      )
                    }}
                  >
                    Credit Score & Insights
                  </Button>
                )}
            </Box>
          </>
        )}
        <Divider />
        {credentials?.creditRiskMonitor.connected && (
          <>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="subtitle1" sx={{ mb: 2 }}>
                Credit Risk Monitor
              </Typography>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                <img src={creditRiskMonitor} style={{ height: "24px" }} />
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "end",
                gap: "8px",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setSearchDialogOpen(CREDIT_REPORT_VENDOR.CREDIT_RISK_MONITOR)
                }}
              >
                Search
              </Button>
            </Box>
          </>
        )}
        {creditRiskMonitorData && (
          <Stack
            direction="column"
            justifyContent="space-evenly"
            alignItems="stretch"
            spacing={2}
          >
            {creditRiskMonitorData.map(
              (report: CreditRiskMonitorPurchasedReport, index: number) => (
                <CreditRiskMonitorPurchasedItem
                  key={index}
                  index={index}
                  item={report}
                  onView={() => {
                    setPdfToView(report.url)
                  }}
                />
              ),
            )}
          </Stack>
        )}
        {(credentials?.creditRiskMonitor?.connected ||
          (creditRiskMonitorData && creditRiskMonitorData.length > 0)) && (
          <Divider />
        )}
        {credentials?.transUnion.connected && (
          <>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="subtitle1" sx={{ mb: 2 }}>
                TransUnion
              </Typography>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                <img src={transUnionLogo} style={{ height: "24px" }} />
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "end",
                gap: "8px",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setSearchDialogOpen(CREDIT_REPORT_VENDOR.TRANSUNION)
                }}
              >
                Obtain Credit Report
              </Button>
            </Box>
          </>
        )}
        {transUnionData && (
          <Stack
            direction="column"
            justifyContent="space-evenly"
            alignItems="stretch"
            spacing={2}
          >
            {transUnionData.map(
              (report: TransUnionPurchasedReport, index: number) => (
                <TransUnionPurchasedItem
                  key={index}
                  item={report}
                  onView={() => {
                    setShowTransUnionReport(report.json)
                  }}
                />
              ),
            )}
          </Stack>
        )}
        {(credentials?.transUnion?.connected ||
          (transUnionData && transUnionData.length > 0)) && <Divider />}
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            Experian
          </Typography>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <img src={experian} style={{ height: "24px" }} />
          </Box>
        </Box>
        Connect your Experian account to view credit reports instantly.
        <Box
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "end",
            gap: "8px",
          }}
        >
          {!credentials?.experian?.connected && (
            <Button variant="contained" disabled>
              Not Connected
            </Button>
          )}
          {credentials?.experian?.connected && (
            <Button
              variant="contained"
              onClick={() => {
                setSearchDialogOpen(CREDIT_REPORT_VENDOR.EXPERIAN)
              }}
            >
              Begin Search
            </Button>
          )}
        </Box>
        {purchasedExperianReports && (
          <Stack
            direction="column"
            justifyContent="space-evenly"
            alignItems="stretch"
            spacing={2}
          >
            {purchasedExperianReports.map((report: any, index: number) => (
              <PurchasedReportItem
                key={index}
                fileName={`Experian Report ${index + 1}`}
                createdAt={report.createdAt}
                onView={() => {
                  setPdfToView(report.url)
                }}
              />
            ))}
          </Stack>
        )}
        <Divider />
        <Typography variant="subtitle1" sx={{ mb: 2 }}>
          Upload your own copy
        </Typography>
        Use this option if you have a credit report from another source.
        {renderCredit}
      </Stack>
      {showUploadDialog && (
        <DropzoneDialog
          open={showUploadDialog}
          onSave={(newFiles) => {
            execute(newFiles)
            setShowUploadDialog(false)
          }}
          acceptedFiles={["application/pdf"]}
          showPreviews={true}
          maxFileSize={32000000}
          onClose={() => {
            setShowUploadDialog(false)
          }}
          filesLimit={10}
          fileObjects={[]}
        />
      )}
      {pdfToView && (
        <PDFViewerDialog
          title="Credit Report"
          fileUrl={pdfToView}
          onClose={() => setPdfToView(undefined)}
        />
      )}
      {searchDialogOpen === CREDIT_REPORT_VENDOR.CREDIT_SAFE && (
        <CreditSafeSearchDialog
          application={application}
          isSearchDialogOpen={!!searchDialogOpen}
          handleCloseSearchDialog={(connectId) => {
            setSearchDialogOpen(undefined)
            setConnectId(connectId || "")
            setShowInsights(connectId ? true : false)
            refetchCreditsafePurchasedReport()
          }}
        />
      )}

      {searchDialogOpen === CREDIT_REPORT_VENDOR.EXPERIAN && (
        <ExperianSearchDialog
          application={application}
          isSearchDialogOpen={!!searchDialogOpen}
          handleCloseSearchDialog={(bin) => {
            setBin(bin)
            setSearchDialogOpen(undefined)
          }}
        />
      )}
      {searchDialogOpen === CREDIT_REPORT_VENDOR.CREDIT_RISK_MONITOR && (
        <CreditRiskMonitorSearchDialog
          application={application}
          isSearchDialogOpen={!!searchDialogOpen}
          handleCloseSearchDialog={() => {
            creditRiskMonitorRefetch()
            setSearchDialogOpen(undefined)
          }}
        />
      )}

      {searchDialogOpen === CREDIT_REPORT_VENDOR.TRANSUNION && (
        <TransUnionSearchDialog
          applicationId={application.id || ""}
          data={application.data}
          onClose={() => {
            setSearchDialogOpen(undefined)
          }}
          onResult={(data) => {
            setShowTransUnionReport(data)
            setSearchDialogOpen(undefined)
          }}
        />
      )}

      {displayCreditSafeReport && (
        <CreditSafeReportDialog
          application={application}
          report={displayCreditSafeReport}
          refetchApplicationToolbar={refetchApplicationToolbar}
          open={Boolean(displayCreditSafeReport)}
          onClose={() => {
            setDisplayCreditSafeReport(undefined)
            refetchCreditsafePurchasedReport()
          }}
        />
      )}

      {showTransUnionReport && (
        <TransUnionReportDialog
          report={showTransUnionReport}
          onClose={() => {
            setShowTransUnionReport(null)
          }}
        />
      )}

      {isLoading ||
        (isLoadingExperianReport && (
          <Dialog open={true}>
            <DialogTitle sx={{ pb: 0 }}>Please wait</DialogTitle>
            <DialogContent sx={{ p: 0 }}>
              <CircularProgress />
            </DialogContent>
          </Dialog>
        ))}

      {showInsights && report && (
        <CreditSafeReportDialog
          application={application}
          report={report}
          refetchApplicationToolbar={refetchApplicationToolbar}
          open={showInsights}
          onClose={() => {
            setShowInsights(false)
          }}
        />
      )}
    </>
  )
}
