import { Button, Dialog, DialogActions, SxProps, Theme } from "@mui/material"
import TagSelect from "./TagSelect"
import { CreditApplication } from "src/types"

export default ({
  sx,
  application,
  onClose,
}: {
  sx: SxProps<Theme>
  application: CreditApplication
  onClose: () => void
}) => {
  return (
    <Dialog
      open
      onClose={() => {
        onClose()
      }}
      fullWidth
      maxWidth={"md"}
    >
      <TagSelect
        application={application}
        onTagsChanged={() => undefined}
        sx={{
          p: (theme) => theme.spacing(1.5, 2, 1.5, 1.5),
          "&:not(:last-of-type)": {
            borderBottom: (theme) =>
              `solid 2px ${theme.palette.background.paper}`,
          },
        }}
      />
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
