import { BankReferenceChart } from "src/types"
import { balancesBarChart } from "./BankReferenceChartDialog"
import { Stack } from "@mui/material"
import { INVISIBLE_CHART_ID } from "src/statics"

export default function InvisibleBankReferenceChart({
  bankReferenceChart,
}: {
  bankReferenceChart: BankReferenceChart | undefined
}) {
  return (
    <Stack sx={{ position: "fixed", left: "200%", width: 700 }}>
      {balancesBarChart(bankReferenceChart, INVISIBLE_CHART_ID)}
    </Stack>
  )
}
