import { Tooltip, IconButton } from "@mui/material"
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined"
import CloudSyncOutlinedIcon from "@mui/icons-material/CloudSyncOutlined"
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined"
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined"
import { VerificationStatus, TinData } from "src/types"
import useSignzy from "src/queries/vendors/useSignzy"
import { useSeller } from "src/queries/base/useSeller"

const EinVerificationIcon = ({ name, tin }: TinData) => {
  const { data: business } = useSeller(name || "")
  const tinRequest: TinData = {
    name: business?.name || "",
    tin: tin || "",
  }
  const {
    execute: checkTin,
    isLoading,
    verificationState,
  } = useSignzy(tinRequest)

  if (isLoading || verificationState === VerificationStatus.PROCESSING) {
    return (
      <Tooltip title={"Verifying EIN"} placement="top">
        <CloudSyncOutlinedIcon fontSize="small" style={{ marginLeft: 8 }} />
      </Tooltip>
    )
  }

  if (verificationState === VerificationStatus.NONE) {
    return (
      <Tooltip title={"Click to verify EIN"} placement="top">
        <IconButton
          aria-label="verify"
          onClick={() => checkTin(tinRequest)}
          sx={{
            border: "1px solid hsl(210, 14%, 87%)",
            borderRadius: "8px",
            boxShadow: 1,
            marginLeft: 1,
          }}
        >
          <SearchOutlinedIcon fontSize="small" color="disabled" />
        </IconButton>
      </Tooltip>
    )
  }

  if (verificationState === VerificationStatus.SUCCESS) {
    return (
      <Tooltip title={"EIN Verified"} placement="top">
        <VerifiedOutlinedIcon
          fontSize="small"
          color="primary"
          style={{ marginLeft: 8 }}
        />
      </Tooltip>
    )
  }

  if (verificationState === VerificationStatus.FAILURE) {
    return (
      <Tooltip title={"EIN verification failed"} placement="top">
        <ErrorOutlineOutlinedIcon
          fontSize="small"
          color="error"
          style={{ marginLeft: 8 }}
        />
      </Tooltip>
    )
  }
}

export default EinVerificationIcon
