import { QueryClient } from "react-query"
import { useTypedMutation } from "../useTypedMutation"
import { TradeReferenceContactReassign } from "../../types"

export function usePatchTradeReferenceAlternativeContacts(
  onSuccess?: (data: string) => void,
) {
  const ENDPOINT = "/application/trade_reference_update_alternative_contacts/"
  const patch = useTypedMutation<TradeReferenceContactReassign>(
    "patch/application/trade_reference_update_alternative_contacts",
    onSuccess,
  )

  const execute = (
    body: Partial<TradeReferenceContactReassign>,
    onSuccess?: () => void,
  ) => {
    patch.mutate(
      {
        endpoint: ENDPOINT + body.application_id + "/",
        body,
        method: "patch",
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...patch, execute }
}
