import { useQuery } from "react-query"
import { TemplateCustomField } from "src/types"

export function useCODTemplateCustomFields(businessId?: string) {
  let endpoint = "/internal/cod-custom-fields"
  if (businessId) {
    endpoint = `/internal/cod-custom-fields?business_id=${businessId}`
  }
  return useQuery<Array<TemplateCustomField>>(endpoint, {
    retry: (failureCount, error) => {
      if (failureCount < 1) {
        return true
      }
      // do not retry on 404
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (error as any)?.response?.status !== 404
    },
  })
}
