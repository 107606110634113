import { Button, InputAdornment, Stack, TextField } from "@mui/material"
import { enqueueSnackbar } from "notistack"
import { useCreateInternalUrlShorterner } from "src/queries/internal/useCreateInternalUrlShorterner"
import { useInternalBusiness } from "src/queries/internal/useInternalBusiness"
import { confirm } from "src/components/confirm"
import { ContentCopyOutlined } from "@mui/icons-material"

export default function CashUrlPopulation({ id }: { id: string }) {
  const { data: businessData, refetch: businessRefetch } = useInternalBusiness(
    id as string,
  )
  const { execute } = useCreateInternalUrlShorterner(() => {
    enqueueSnackbar("Url Shorterner updated", { variant: "success" })
    businessRefetch()
  })
  const shortcut = () => {
    if (businessData) {
      return businessData.shortenedUrl
        ? businessData.shortenedUrl + "-cod"
        : (businessData.name.replaceAll(" ", "").toLowerCase() as string) +
            "-cod"
    }
  }
  const applicationUrl = location.host.includes("my.netnow.io")
    ? "https://app.netnow.io/" + shortcut()
    : location.protocol +
      "//" +
      location.host +
      "/ua-trade-credit/base?business_id=" +
      id
  const FONT_SIZE = 9
  const DEFAULT_INPUT_WIDTH = 300
  let inputWidth = DEFAULT_INPUT_WIDTH
  if (
    applicationUrl &&
    applicationUrl.length * FONT_SIZE > DEFAULT_INPUT_WIDTH
  ) {
    inputWidth = (applicationUrl.length + 1) * FONT_SIZE
  }
  return (
    <>
      {businessData && (
        <Stack direction={"row"} spacing={2}>
          <Button
            variant="contained"
            onClick={() => {
              confirm(
                `Are you sure you want to add ${shortcut()} as the shortened url for COD applications`,
              )
                .then(() => {
                  execute(
                    {
                      url: `https://my.netnow.io/ua-cod/base?business_id=${businessData.id}`,
                      shortcut: shortcut() as string,
                    },
                    () => {
                      businessRefetch()
                    },
                  )
                  return
                })
                .catch(() => {
                  return
                })
            }}
          >
            Set url to 
          </Button>
          <TextField
            value={applicationUrl}
            sx={{ input: { cursor: "pointer" } }}
            InputProps={{
              style: { width: `${inputWidth}px` },
              endAdornment: (
                <InputAdornment position="end">
                  <ContentCopyOutlined />
                </InputAdornment>
              ),
            }}
            onClick={() => {
              enqueueSnackbar("Link copied to clipboard.", {
                variant: "info",
              })
              navigator.clipboard.writeText(applicationUrl)
            }}
          />
        </Stack>
      )}
    </>
  )
}
