import queryString from "query-string"
import { useQuery } from "react-query"

type WebsiteScreenShotData = {
  base64File: string
}

export function useWebsiteScreenShot(url: string) {
  const query = useQuery<WebsiteScreenShotData>(
    "/domain/screenshot?" +
      queryString.stringify({
        url,
      }),
    {
      enabled: !!url,
      retry: (failureCount, error) => {
        if (failureCount < 1) {
          return true
        }
        // do not retry on 404
        return (error as any)?.response?.status !== 404
      },
      meta: { timeout: 61000 },
    },
  )

  return query
}
