import ReactApexChart from "react-apexcharts"
import { useTheme } from "@mui/material/styles"
import useChart from "src/components/chart/useChart"
import { fShortenNumber } from "src/utils/formatNumber"

type ChartProps = {
  chart_id: string
  series_name: string
  series: Array<number>
  categories: Array<string>
}

export default function VerticalBarChart({
  chart_id,
  series_name,
  series,
  categories,
}: ChartProps) {
  const theme = useTheme()
  const chartOptions = useChart({
    //"basic-bar"
    chart: {
      id: chart_id,
      type: "bar",
    },
    colors: [theme.palette.success.dark],
    xaxis: {
      categories: categories,
    },
    yaxis: {
      labels: {
        formatter: (value: number) => `${"$" + fShortenNumber(value)}`,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
      },
    },
    tooltip: {
      y: {
        formatter: (value: number) => `${"$" + fShortenNumber(value)}`,
      },
    },
  })

  const chartSeries = [
    {
      name: series_name,
      data: series,
    },
  ]

  return (
    <ReactApexChart
      type="bar"
      series={chartSeries}
      options={chartOptions}
      height={364}
      width={"100%"}
    />
  )
}
