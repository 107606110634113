import { BrowserRouter } from "react-router-dom"
import Router from "./routes"
import ThemeProvider from "./theme"
import ScrollToTop from "./components/scroll-to-top"

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { isIE } from "react-device-detect"

import { CustomReactQueryProvider } from "./queries"
import { Auth0ProviderWithRedirectCallback } from "./auth"
import { SnackbarProvider } from "notistack"

import { hotjar } from "react-hotjar"
import { useEffect } from "react"

import * as Sentry from "@sentry/react"
import {
  CacheLocation,
  FingerprintJSPro,
  FpjsProvider,
} from "@fingerprintjs/fingerprintjs-pro-react"

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#FFBF23',
//     },
//     secondary: {
//       main: '#000000',
//     },
//   },
// });

function App() {
  useEffect(() => {
    if (isIE) {
      window.alert(
        "Internet Explorer is not supported. Please use Edge, Chrome or Firefox",
      )
    }
    if (process.env.REACT_APP_HOTJAR === "1") {
      hotjar.initialize(3397672, 6)

      Sentry.init({
        dsn: "https://2d4bebc0d7814aadb9ebaf37a94e39f1@o4504476867624960.ingest.sentry.io/4504476868608001",
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,

        integrations: [
          Sentry.captureConsoleIntegration(),
          Sentry.replayIntegration(),
          Sentry.browserTracingIntegration(),
        ],

        environment: process.env.REACT_APP_ENVIRONMENT,
      })
    }
  }, [])

  return (
    <FpjsProvider
      loadOptions={{
        apiKey: process.env.REACT_APP_FINGERPRINTJS_API_KEY || "",
        endpoint: [
          "https://metrics.netnow.io",
          FingerprintJSPro.defaultEndpoint,
        ],
        scriptUrlPattern: [
          "https://metrics.netnow.io/web/v<version>/<apiKey>/loader_v<loaderVersion>.js",
          FingerprintJSPro.defaultScriptUrlPattern,
        ],
      }}
      cacheLocation={CacheLocation.Memory}
    >
      <BrowserRouter>
        <ThemeProvider>
          <SnackbarProvider maxSnack={3} autoHideDuration={5000}>
            <Auth0ProviderWithRedirectCallback>
              <CustomReactQueryProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <ScrollToTop />
                  {/* #TODO add condition to use internal router 
                in internal enviroment*/}
                  <Router />
                </LocalizationProvider>
              </CustomReactQueryProvider>
            </Auth0ProviderWithRedirectCallback>
          </SnackbarProvider>
        </ThemeProvider>
      </BrowserRouter>
    </FpjsProvider>
  )
}

export default App
