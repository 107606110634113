import { Button, Stack } from "@mui/material"
import { useState } from "react"
import ViewCreditApplicationDialog from "../ViewCreditApplicationDialog"

export default function CreditApplicationsCard({ id }: { id: string }) {
  const [openCreditDialog, setOpenCreditDialog] = useState(false)
  return (
    <>
      <Stack sx={{ p: 0.3 }}>
        <Button
          variant="contained"
          onClick={() => {
            setOpenCreditDialog(true)
          }}
        >
          View Credit Applications
        </Button>
      </Stack>
      {openCreditDialog && (
        <ViewCreditApplicationDialog
          open={openCreditDialog}
          onClose={() => {
            setOpenCreditDialog(false)
          }}
          businessId={id}
        />
      )}
    </>
  )
}
