import { Box, CircularProgress, Typography } from "@mui/material"

export default function StagePieChart({
  activeStep,
  steps,
}: {
  activeStep: number
  steps: string[]
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress
          style={{
            width: "55px",
            height: "55px",
            color: "#E4E7EC",
          }}
          value={100}
          variant="determinate"
        />
        <Box style={{ position: "absolute" }}>
          <Typography>
            {activeStep + 1} of {steps.length}
          </Typography>
        </Box>
      </Box>
      <CircularProgress
        style={{
          width: "55px",
          height: "55px",
          color: "#475467",
          position: "absolute",
        }}
        value={((activeStep + 1) * 100) / steps.length}
        variant="determinate"
      />
    </Box>
  )
}
