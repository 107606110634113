import { useQuery } from "react-query"
import { AdditionalDataRequest } from "src/types"

export function useListAdditionalDataRequest(applicationId: string) {
  const query = useQuery<AdditionalDataRequest[]>(
    `/application/base/data_request/?application_id=${applicationId}`,
    {
      enabled: !!applicationId,
      retry: (failureCount, error) => {
        if (failureCount < 1) {
          return true
        }
        // do not retry on 404
        return (error as any)?.response?.status !== 404
      },
    },
  )

  const pendingRequests = query?.data?.filter((r) => !r.fulfilled)

  const pendingPageLabels = pendingRequests
    ?.map((r) => r.pages)
    ?.flat()
    //filter distict
    ?.filter((value, index, array) => array.indexOf(value) === index)
    ?.map((p) => p?.label)

  return { ...query, pendingRequests, pendingPageLabels }
}
