import {
  Box,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Divider,
  Button,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
  InputLabel,
} from "@mui/material"
import ReactPhoneInput from "react-phone-input-material-ui"

import { ApplicationTemplate, Application } from "src/types"
import { useState, useEffect } from "react"
import PDFViewerDialog from "src/components/pdf/PDFViewerDialog"
import { useLocation, useParams } from "react-router-dom"
import { APPLICANT_TYPES, APPLICANT_TYPES_OPTIONS, ROLES } from "src/statics"
import { PDFDocument } from "pdf-lib"
import { useAnonymousBusiness } from "src/queries/credit/useAnonymousBusiness"
import MobileNavButtonsBlock from "src/components/mobile-nav-buttons-block/MobileNavButtonsBlock"
import FormLabel from "src/components/label/FormLabel"
import {
  FORM_INPUT_NAMES,
  PAGE_LABEL_USER_DETAILS,
  USER_CONFIG_APPLICANT_TYPE,
  USER_CONFIG_BUSINESS_TYPE,
} from "../../intake_sections/constants"
import {
  getAdditionalApplicantTypes,
  getApplicantTypes,
  getBusinessTypes,
  hasCustomAddressQuestions,
  isProjectApplication,
} from "../../utils"
import { getIndividualConsumerHomeownerEnabled } from "../../intake_sections/schemas/UserRegistrationSectionSchema"
import { isSubSectionEnabled } from "../../intake_sections/template_helpers"
import { usePreference, BUSINESS_PREFERENCES } from "src/hooks/use-preference"

type Props = {
  application?: Application
  data: Application["data"]
  errors: Application["data"] | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (key: string, value: any) => void
  onCustomChange: (key: string, value: any) => void
  onContinue: (onFormValidationError: () => void) => void
  setFilesInMemory: (files: Map<string, File[]>) => void
  filesInMemory: Map<string, File[]>
  template: ApplicationTemplate
  handleBack: () => void
  activeStep: number
}

// create a new component based on TextField that has a set height
export const FixedHeightTextField = ({ ...props }) => {
  return (
    <TextField
      {...props}
      inputProps={{
        style: hasCustomAddressQuestions(
          props.template?.customFields,
          PAGE_LABEL_USER_DETAILS,
          props.data,
        )
          ? { height: "56px" }
          : {},
      }}
    />
  )
}

export default ({
  application,
  data,
  errors,
  onChange,
  onCustomChange,
  onContinue,
  template,
  filesInMemory,
  setFilesInMemory,
  handleBack,
  activeStep,
}: Props) => {
  const [openTerms, setOpenTerms] = useState<boolean | string>(false)

  const [loading, setLoading] = useState(false)

  const [showApplicantType, setShowApplicantType] = useState(true)

  const params = useParams()
  const { id } = params

  const { search } = useLocation()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const queryParams = new URLSearchParams(search)
  const businessId = queryParams.get("business_id") || ""
  const [termsAccepted, setTermsAccepted] = useState(false)

  const { data: business } = useAnonymousBusiness(businessId || "")
  const { preference: mergeTermsPreference } = usePreference(
    BUSINESS_PREFERENCES.MERGE_TERMS_IN_FIRST_PAGE,
    business,
  )

  const matches = useMediaQuery("(max-width: 899px)")
  const matchesXL = useMediaQuery("(max-width: 1199px)")

  const termsConditions = async () => {
    const mergedPdf = await PDFDocument.create()
    let documents = [
      "https://storage.googleapis.com/netnow-web-assets-production/Terms%20of%20Service%20-%20NetNow.pdf",
    ]

    if (business?.terms && mergeTermsPreference !== false) {
      // order is important
      documents = [
        business.terms as string,
        "https://storage.googleapis.com/netnow-web-assets-production/Terms%20of%20Service%20-%20NetNow.pdf",
      ]

      for (const document of documents) {
        const existingPdfBytes = await fetch(document).then((res) =>
          res.arrayBuffer(),
        )

        const pdfDoc = await PDFDocument.load(existingPdfBytes)

        const copiedPages = await mergedPdf.copyPages(
          pdfDoc,
          pdfDoc.getPageIndices(),
        )
        copiedPages.forEach((page) => mergedPdf.addPage(page))
      }

      const pdfBytes = await mergedPdf.save()

      const docUrl = URL.createObjectURL(
        new Blob([pdfBytes], { type: "application/pdf" }),
      )
      if (matches) {
        window.open(docUrl, "_blank")
      } else {
        setOpenTerms(docUrl)
      }
    } else {
      if (matches) {
        window.open(documents[0], "_blank")
      } else {
        setOpenTerms(documents[0])
      }
    }
  }
  useEffect(() => {
    if (template) {
      if (
        template.formTemplate.pages
          .find((step) => step.label === PAGE_LABEL_USER_DETAILS)
          ?.config?.find(
            (config) => config.label === USER_CONFIG_APPLICANT_TYPE,
          )?.value === false
      ) {
        onChange(FORM_INPUT_NAMES.APPLICANT_TYPE, APPLICANT_TYPES.CORPORATION)
        onChange(FORM_INPUT_NAMES.BUSINESS_TYPE, "Other")
        setShowApplicantType(false)
      }
    }
  }, [template, onChange])

  return (
    <>
      {!matchesXL && (
        <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
          User Details
        </Typography>
      )}
      <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
        Information of the person filling out this form. In case we have any
        questions, this will be the main point of contact.
      </Typography>
      {!matches && <Divider style={{ margin: "16px 0 16px 0" }} />}
      <form>
        <Box
          sx={{
            display: "flex",
            flexDirection: matches ? "column" : "row",
            width: "100%",
            gap: matches ? "0" : "32px",
            alignItems: "center",
            marginBottom: matches ? "0" : "16px",
          }}
        >
          <FormControl
            sx={{ width: "100%" }}
            margin={matches ? "normal" : "none"}
          >
            <FormLabel labelName="First Name" required idFor="first-name" />
            <TextField
              id="first-name"
              fullWidth
              placeholder={matches ? "Enter First Name" : ""}
              label={!matches ? "First Name" : ""}
              type="text"
              required
              value={data.firstName}
              onChange={(event) => {
                onChange(FORM_INPUT_NAMES.FIRST_NAME, event.target.value)
              }}
              error={Boolean(errors?.firstName)}
              inputProps={
                hasCustomAddressQuestions(
                  template?.customFields,
                  PAGE_LABEL_USER_DETAILS,
                  data,
                )
                  ? {
                      style: { height: "56px" },
                    }
                  : {}
              }
            />
          </FormControl>
          <FormControl
            sx={{ width: "100%" }}
            margin={matches ? "normal" : "none"}
          >
            <FormLabel labelName="Last Name" required idFor="last-name" />
            <TextField
              id="last-name"
              required
              fullWidth
              label={!matches ? "Last Name" : ""}
              placeholder={matches ? "Enter Last Name" : ""}
              type="text"
              value={data.lastName}
              onChange={(event) => {
                onChange(FORM_INPUT_NAMES.LAST_NAME, event.target.value)
              }}
              error={Boolean(errors?.lastName)}
              inputProps={
                hasCustomAddressQuestions(
                  template?.customFields,
                  PAGE_LABEL_USER_DETAILS,
                  data,
                )
                  ? {
                      style: { height: "56px" },
                    }
                  : {}
              }
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: matches ? "column" : "row",
            width: "100%",
            gap: matches ? "0" : "32px",
            alignItems: "center",
          }}
        >
          <FormControl
            sx={{ width: "100%" }}
            margin={matches ? "normal" : "none"}
          >
            <FormLabel labelName="Contact Email" required idFor="email" />
            <TextField
              disabled={!!id}
              fullWidth
              required
              placeholder={matches ? "Enter Email" : ""}
              label={!matches ? "Email" : ""}
              type="email"
              id="email"
              value={data.email}
              onChange={(event) => {
                onChange(
                  FORM_INPUT_NAMES.EMAIL,
                  event.target.value?.toLowerCase() || "",
                )
              }}
              error={Boolean(errors?.email)}
              inputProps={
                hasCustomAddressQuestions(
                  template?.customFields,
                  PAGE_LABEL_USER_DETAILS,
                  data,
                )
                  ? {
                      style: { height: "56px" },
                    }
                  : {}
              }
            />
          </FormControl>

          <Box
            style={{
              display: "flex",
              flexDirection: matches ? "column" : "row",
              width: "100%",
              alignItems: "center",
              gap: matches ? "0" : "32px",
            }}
          >
            <FormControl
              sx={{ width: "100%" }}
              margin={matches ? "normal" : "none"}
            >
              <FormLabel labelName="Phone Number" required />
              <ReactPhoneInput
                value={data.userPhoneNumber}
                label={matches ? "" : "Phone Number"}
                country={"us"}
                onChange={(event) => {
                  onChange(FORM_INPUT_NAMES.USER_PHONE_NUMBER, "+" + event)
                }}
                countryCodeEditable={false}
                component={FixedHeightTextField}
                containerStyle={{
                  width: "100%",
                }}
                inputProps={{
                  id: "phone-number",
                  required: true,
                  error: Boolean(errors?.userPhoneNumber),
                  template: template,
                  data: data,
                }}
              />
            </FormControl>
            <FormControl
              sx={{ width: "100%" }}
              margin={matches ? "normal" : "none"}
            >
              <FormLabel labelName="Extension" idFor="phone-number-ext" />
              <TextField
                id="phone-number-ext"
                fullWidth
                placeholder={matches ? "Extension" : ""}
                label={!matches ? "Extension" : ""}
                type="text"
                value={data.userPhoneNumberExt}
                onChange={(event) => {
                  onChange(
                    FORM_INPUT_NAMES.USER_PHONE_NUMBER_EXT,
                    event.target.value,
                  )
                }}
                inputProps={
                  hasCustomAddressQuestions(
                    template?.customFields,
                    PAGE_LABEL_USER_DETAILS,
                    data,
                  )
                    ? {
                        style: { height: "56px" },
                      }
                    : {}
                }
              />
            </FormControl>
          </Box>
        </Box>
        {showApplicantType && (
          <Box
            sx={{
              display: "flex",
              flexDirection: matches ? "column" : "row",
              width: "100%",
              gap: matches ? "0" : "32px",
              alignItems: "center",
            }}
          >
            <FormControl
              fullWidth
              margin="normal"
              style={{ flex: "1 1 0px" }}
              required
            >
              <FormLabel labelName="Applicant Type" required />
              {!matches && (
                <InputLabel id="applicant-type-select">
                  Applicant Type
                </InputLabel>
              )}
              <Select
                id="applicant-type-select"
                value={data.applicantType}
                label={matches ? "" : "Applicant Type"}
                onChange={(event) => {
                  onChange(FORM_INPUT_NAMES.APPLICANT_TYPE, event.target.value)
                  onChange("businessType", "")
                  onChange("role", "")
                }}
                error={Boolean(errors?.applicantType)}
                displayEmpty
                renderValue={(selected) => {
                  if (selected === undefined || selected === "") {
                    if (matches) {
                      return (
                        <Typography sx={{ color: "text.disabled" }}>
                          Select
                        </Typography>
                      )
                    }
                  }

                  return selected
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {getApplicantTypes(template)
                  .filter((option) =>
                    getIndividualConsumerHomeownerEnabled(template)
                      ? true
                      : option !==
                        APPLICANT_TYPES.INDIVIDUAL_CONSUMER_HOMEOWNER,
                  )
                  .map((type) => (
                    <MenuItem value={type} key={"applicant" + type}>
                      {type}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {!isProjectApplication(data) &&
              getAdditionalApplicantTypes(template)
                .concat([
                  APPLICANT_TYPES.LLC,
                  APPLICANT_TYPES.CORPORATION,
                  APPLICANT_TYPES.LIMITED_PARTNERSHIP,
                  APPLICANT_TYPES.PARTNERSHIP,
                  APPLICANT_TYPES.NON_PROFIT,
                  APPLICANT_TYPES.SOLE_PROPRIETORSHIP,
                  APPLICANT_TYPES.OTHER,
                ])
                .includes(data.applicantType) &&
              isSubSectionEnabled(
                template,
                PAGE_LABEL_USER_DETAILS,
                USER_CONFIG_BUSINESS_TYPE,
              ) && (
                <>
                  <FormControl
                    fullWidth
                    margin="normal"
                    style={{ flex: "1 1 0px" }}
                    required
                  >
                    <FormLabel labelName="Business Type" required />
                    {!matches && (
                      <InputLabel id="business-type-select">
                        Business Type
                      </InputLabel>
                    )}
                    <Select
                      id="business-type-select"
                      value={data.businessType}
                      label={matches ? "" : "Business Type"}
                      onChange={(event) => {
                        onChange(
                          FORM_INPUT_NAMES.BUSINESS_TYPE,
                          event.target.value,
                        )
                      }}
                      error={Boolean(errors?.businessType)}
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected === undefined || selected === "") {
                          if (matches) {
                            return (
                              <Typography sx={{ color: "text.disabled" }}>
                                Select
                              </Typography>
                            )
                          }
                        }

                        return selected
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {getBusinessTypes(template).map((type) => (
                        <MenuItem value={type} key={"business" + type}>
                          {type}
                        </MenuItem>
                      ))}

                      <MenuItem value={"Other"} key={"businessOther"}>
                        Other
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {data.businessType === "Other" && (
                    <TextField
                      id="specify"
                      margin="normal"
                      label="Specify Business Type"
                      type="text"
                      value={data.businessTypeOther}
                      onChange={(event) => {
                        onChange(
                          FORM_INPUT_NAMES.BUSINESS_TYPE_OTHER,
                          event.target.value,
                        )
                      }}
                      inputProps={
                        hasCustomAddressQuestions(
                          template?.customFields,
                          PAGE_LABEL_USER_DETAILS,
                          data,
                        )
                          ? {
                              style: { height: "56px" },
                            }
                          : {}
                      }
                    />
                  )}
                </>
              )}
          </Box>
        )}

        {getAdditionalApplicantTypes(template)
          .concat([
            APPLICANT_TYPES.CORPORATION,
            APPLICANT_TYPES.LLC,
            APPLICANT_TYPES.PARTNERSHIP,
            APPLICANT_TYPES.LIMITED_PARTNERSHIP,
            APPLICANT_TYPES.SOLE_PROPRIETORSHIP,
            APPLICANT_TYPES.NON_PROFIT,
            APPLICANT_TYPES.OTHER,
          ])
          .includes(data.applicantType) &&
          showApplicantType && (
            <Box
              sx={{
                display: "flex",
                flexDirection: matches ? "column" : "row",
                width: "100%",
                gap: matches ? "0" : "32px",
                alignItems: "center",
              }}
            >
              <FormControl fullWidth margin="normal">
                <FormLabel labelName="Role in the business" />
                {!matches && <InputLabel id="role-select">Role</InputLabel>}
                <Select
                  labelId="role-label"
                  id="role-select"
                  value={data.role}
                  label={matches ? "" : "Role"}
                  onChange={(event) => {
                    onChange(FORM_INPUT_NAMES.ROLE, event.target.value)
                  }}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected === undefined || selected === "") {
                      if (matches) {
                        return (
                          <Typography sx={{ color: "text.disabled" }}>
                            Select
                          </Typography>
                        )
                      }
                    }

                    return selected
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {ROLES.map((role) => (
                    <MenuItem value={role} key={"role" + role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {data.role === "Other" && (
                <TextField
                  id="specify"
                  margin="normal"
                  label="Specify Role"
                  type="text"
                  value={data.roleOther}
                  onChange={(event) => {
                    onChange(FORM_INPUT_NAMES.ROLE_OTHER, event.target.value)
                  }}
                  inputProps={
                    hasCustomAddressQuestions(
                      template?.customFields,
                      PAGE_LABEL_USER_DETAILS,
                      data,
                    )
                      ? {
                          style: { height: "56px" },
                        }
                      : {}
                  }
                />
              )}
            </Box>
          )}
        {/* {template?.customFields && (
          <ReusableCustomQuestions
            application={application}
            associatedPage="User Details"
            data={data["customFields"]}
            dataKeyResponses={data}
            errors={errors}
            onChange={onCustomChange}
            filesInMemory={filesInMemory}
            setFilesInMemory={setFilesInMemory}
          />
        )} */}

        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "left",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                id="terms-checkbox"
                value="remember"
                sx={{ color: "#D0D5DD" }}
                onChange={(event) => {
                  setTermsAccepted(event.target.checked)
                }}
                checked={termsAccepted}
              />
            }
            label={
              <div>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ fontSize: "14px" }}
                >
                  I accept the
                </Typography>

                <Button
                  style={{ marginBottom: "3px", textTransform: "none" }}
                  onClick={termsConditions}
                >
                  {mergeTermsPreference !== false
                    ? "Terms and Conditions"
                    : "NetNow User Terms and Conditions"}
                </Button>
              </div>
            }
          />
        </Box>

        {openTerms && (
          <PDFViewerDialog
            fileUrl={openTerms as string}
            onClose={() => setOpenTerms(false)}
          />
        )}
        <MobileNavButtonsBlock
          click={() => {
            setLoading(true)
            onContinue(() => {
              setLoading(false)
            })
          }}
          disable={!termsAccepted}
          loading={loading}
          setLoading={setLoading}
          handleBack={handleBack}
          activeStep={activeStep}
        />
      </form>
    </>
  )
}
