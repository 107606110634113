import { ApplicationTemplate } from "./types"
import ClientAccounts from "./pages/internal/ClientAccount"
import ClientAccountDetails from "./pages/internal/ClientAccountDetails"
import { Navigate } from "react-router-dom"
import * as Sentry from "@sentry/react"

// onboarding states
export const NEW = "NEW"
export const PENDING_APPROVAL = "PENDING_APPROVAL"
export const ONBOARDED = "ONBOARDED"

export const CLIENTS = {
  QUECAN: "Quecan",
}

export const APPLICATION_STAGE = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  MANUAL_INCOMPLETE: 50,
  MANUAL_COMPLETE: 60,
  AWAITING_SIGNATURE: 100,
  COMPLETED_BY_CLIENT: 200,
  APPROVED: 800,
  REJECTED: 900,
  ARCHIVE: 1000000,
} as const

export const TRADE_REFERENCE_ALTERNATE_STATUS = {
  ORIGINAL: "original",
  ALTERNATE: "alternate",
  ALTERNATE_SELECTED: "alternate_selected",
  ALTERNATE_PREVIOUSLY_SELECTED: "alternate_previously_selected",
}

export const PERSONAL_GUARANTY = {
  REQUIRED: "required",
  OPTIONAL: "optional",
  OFF: "off",
} as const

export const KEY_TYPES = {
  DATA_KEY: "dataKey",
  ADDITIONAL_QUESTIONS: "additionalQuestions",
}

export const BUSINESS_TYPE_OPTIONS = [
  "Building Maintenance",
  "Cabinet Manufacturer",
  "Commercial",
  "Deck Building",
  "Developer",
  "Fabricator",
  "General Contracting",
  "Industrial",
  "Remodeling",
  "Residential Construction",
  "Retailer",
  "Roofing",
]

export const ROLES = [
  "Owner/Executive",
  "Sales",
  "Finance",
  "Procurement",
  "Administration",
  "Other",
]

// applicantType
export const APPLICANT_TYPES = {
  CORPORATION: "Corporation",
  LLC: "Limited Liability Company (LLC)",
  LLC_LEGACY: "Limited Liability Corporation (LLC)", // legacy support
  PARTNERSHIP: "Partnership",
  LIMITED_PARTNERSHIP: "Limited Partnership",
  SOLE_PROPRIETORSHIP: "Sole Proprietorship",
  INDIVIDUAL_CONSUMER_HOMEOWNER: "Individual/Consumer/Homeowner",
  NON_PROFIT: "Non-Profit",
  GOVERNMENT: "Government", // not used in all instances
  GOVERNMENT_ENTITY: "Government Entity", // not used in all instances
  OTHER: "Other",
}

export const APPLICANT_TYPES_OPTIONS = [
  APPLICANT_TYPES.CORPORATION,
  APPLICANT_TYPES.LLC,
  APPLICANT_TYPES.PARTNERSHIP,
  APPLICANT_TYPES.LIMITED_PARTNERSHIP,
  APPLICANT_TYPES.SOLE_PROPRIETORSHIP,
  APPLICANT_TYPES.INDIVIDUAL_CONSUMER_HOMEOWNER,
  APPLICANT_TYPES.NON_PROFIT,
  APPLICANT_TYPES.OTHER,
]

export const APPLICANT_TYPES_OPTIONS_REQUIRES_BUSINESS_NAME = [
  APPLICANT_TYPES.SOLE_PROPRIETORSHIP,
  APPLICANT_TYPES.CORPORATION,
  APPLICANT_TYPES.PARTNERSHIP,
  APPLICANT_TYPES.LIMITED_PARTNERSHIP,
  APPLICANT_TYPES.NON_PROFIT,
  APPLICANT_TYPES.LLC,
  APPLICANT_TYPES.LLC_LEGACY,
  APPLICANT_TYPES.OTHER,
]

export const FIELD_TYPES = {
  TEXT: "text",
  DROPDOWN: "dropdown",
  MULTISELECT: "multiselect",
  AMOUNT: "amount",
  FILE: "file",
  DATE: "date",
  TIME: "time",
  PHONE: "phone",
  EMAIL: "email",
  ADDRESS: "address",
}
export const NET_TERMS = [
  { value: "pay-on-due", label: "On Due Date" },
  { value: "due-on-15th", label: "Due the 15th of the Month" },
  { value: "net-10", label: "Net 10" },
  { value: "net-15", label: "Net 15" },
  { value: "net-30", label: "Net 30" },
  { value: "net-45", label: "Net 45" },
  { value: "net-60", label: "Net 60" },
  { value: "net-75", label: "Net 75" },
  { value: "net-90", label: "Net 90" },
  { value: "net-120", label: "Net 120" },
  { value: "other", label: "Other" },
]

export const convertNetTerms = (value: string, termsArray = NET_TERMS) => {
  if (!value) return value
  try {
    const array = termsArray || NET_TERMS
    const term = array?.find((term) => term.value === value)
    return term?.label || value
  } catch (e) {
    Sentry.captureException(e)
    return value
  }
}

export const customWordMapping = (
  word: string,
  template?: Pick<ApplicationTemplate, "customWordMapping">,
) => {
  if (!template?.customWordMapping) {
    return word
  }
  return template.customWordMapping[word] || word
}

export const INTERNAL_EMAIL_ENDING = "@netnow.io"

export const internalRoutesEnabledForUser = (user: any) => {
  return (
    user &&
    user.isNetNowAdmin &&
    user.data &&
    user.data.email &&
    user.data.email.endsWith(INTERNAL_EMAIL_ENDING)
  )
}

export const getInternalChildrenRoutes = (ProtectedRoute: any) => {
  return [
    { element: <Navigate to="client-accounts" />, index: true },
    {
      path: "client-accounts",
      element: <ProtectedRoute component={ClientAccounts} />,
    },
    {
      path: "client-accounts/:id",
      element: <ProtectedRoute component={ClientAccountDetails} />,
    },
  ]
}

export const INVISIBLE_CHART_ID = "invisible-bank-reference-chart"
export const CREDIT_REPORT_VENDOR = {
  CREDIT_SAFE: "CreditSafe",
  EXPERIAN: "Experian",
  CREDIT_RISK_MONITOR: "CreditRiskMonitor",
  TRANSUNION: "TransUnion",
}

export const CUSTOM_WORD_MAPPINGS = {
  SHIPPING_ADDRESS: "Shipping Address",
  SHIPPING_ADDRESS_ALT: "Shipping address",
  PERSONAL_GUARANTY: "Personal Guaranty",
  PERSONAL_GUARANTOR: "Personal Guarantor",
  MAILING_ADDRESS: "Mailing Address",
  CUSTOMER_NUMBER: "Customer Number",
  OPTIONAL: "Optional",
  STORE: "Store",
  STORE_LOCATION: "Select the store/location you are applying for",
  BILLING_ADDRESS: "Billing address",
  CREDIT_MANAGER: "Who is the Credit Manager associated with this account?*",
  SALES_REPRESENTATIVE: "Who is the Sales Representative associated with this account?",
  SALES_REPRESENTATIVE_NAME: "What is the name of your Sales Representative?",
  OWNERS_CONTACTS: "Owners & Contacts",
  OWNER_PRINCIPAL: "Owner/Principal",
  GUARANTOR: "Guarantor",
  FILL_INFOMATION_BELOW: "Please fill out the information below.",
}
