import { Document, Text } from "@react-pdf/renderer"
import { PDFItem, PDFTemplate, QuestionItem } from "src/types/credit/pdf"

import { CreditApplication, TemplateCustomField } from "src/types"

import { PageBase, Cell, Input, Block, Title } from "./Elements"
import { fDateTime } from "src/utils/formatTime"
import { usePreference, BUSINESS_PREFERENCES } from "src/hooks/use-preference"
import { FIELD_TYPES } from "src/statics"
import { sortTemplateCustomFieldsForInternalDisplay } from "src/sections/@dashboard/utils"

export default ({
  data,
  businessData,
  pdfTemplate,
  customQuestionsTemplate,
}: {
  data: CreditApplication
  businessData: {
    name?: string
    logo?: string
    color?: string
  }
  pdfTemplate: PDFTemplate
  customQuestionsTemplate: TemplateCustomField[] | undefined
}) => {
  const { data: fieldsData } = data

  const pageAdditionalQuestions = getPagesQuestionsItems(
    customQuestionsTemplate,
    fieldsData.customFields,
  )

  const { preference: sensitiveDataPreference } = usePreference(
    BUSINESS_PREFERENCES.HIDE_SENSITIVE_FIELDS_IN_PDF,
    data.seller,
  )

  const BusinessDetailsPage = () => {
    return (
      <PageBase
        title={pdfTemplate["Business Details"].title}
        logoSrc={businessData.logo}
      >
        <Text
          style={{
            fontSize: "11px",
            margin: "10px",
            paddingTop: "10px",
            textAlign: "left",
          }}
        >
          Terms and conditions of NetNow and Supplier ({data.seller?.name})
          agreed to by clicking on checkbox and 2-Factor Authorization on{" "}
          {fDateTime(data.createdAt)}
        </Text>
        {renderPageItems(
          pdfTemplate["Business Details"].items,
          fieldsData,
          sensitiveDataPreference,
        )}

        {pageAdditionalQuestions &&
          renderQuestionsSection(pageAdditionalQuestions["User Details"])}

        {pageAdditionalQuestions &&
          renderQuestionsSection(pageAdditionalQuestions["Business Details"])}
      </PageBase>
    )
  }

  const OwnersAndContactsPage = () => {
    return (
      <PageBase
        title={pdfTemplate["Owners and Contacts"].title}
        logoSrc={businessData.logo}
      >
        {renderPageItems(
          pdfTemplate["Owners and Contacts"].items,
          fieldsData,
          sensitiveDataPreference,
        )}

        {pageAdditionalQuestions &&
          renderQuestionsSection(
            pageAdditionalQuestions["Owners and Contacts"],
          )}
      </PageBase>
    )
  }

  const ReferencePage = () => {
    const getCountryBasedFields = (
      templateFields: PDFItem[],
      country: string,
    ) => {
      if (country === "Canada") {
        return templateFields
      }

      const newTemplateArr = [...templateFields]

      const fieldsForUSA: PDFItem[] = [
        {
          label: "Account Number",
          type: "input",
          spacing: "48",
          dataField: "bankAccountNumber",
        },
        {
          label: "Routing Number",
          type: "input",
          spacing: "48",
          dataField: "bankRoutingNumber",
        },
      ]

      const mutualTemplateFieldIndex = templateFields.findIndex(
        (item) => item.dataField === "bankAccountNumber",
      )

      newTemplateArr.splice(mutualTemplateFieldIndex, 3, ...fieldsForUSA)

      return newTemplateArr
    }

    return (
      <PageBase
        title={pdfTemplate["Trade References"].title}
        logoSrc={businessData.logo}
      >
        {renderPageItems(
          pdfTemplate["Trade References"].items,
          fieldsData,
          sensitiveDataPreference,
        )}

        {pageAdditionalQuestions &&
          renderQuestionsSection(pageAdditionalQuestions["Trade References"])}

        <Title
          label={pdfTemplate["Bank Reference"].title}
          extraStyles={{ marginTop: "20px" }}
        />

        {fieldsData.plaidData ? (
          <Text
            style={{
              fontWeight: 600,
              fontSize: "14px",
              textAlign: "left",
              marginTop: "6px",
            }}
          >
            Instant Bank Reference
          </Text>
        ) : (
          renderPageItems(
            getCountryBasedFields(
              pdfTemplate["Bank Reference"].items,
              fieldsData.businessCountry,
            ),
            fieldsData,
            sensitiveDataPreference,
          )
        )}

        {pageAdditionalQuestions &&
          renderQuestionsSection(pageAdditionalQuestions["Bank Reference"])}
      </PageBase>
    )
  }

  const AdditionalQuestionAndGuarantyPage = () => {
    return (
      <>
        {pdfTemplate["Personal Guaranty"].title && (
          <PageBase
            title={pdfTemplate["Personal Guaranty"].title}
            logoSrc={businessData.logo}
          >
            {renderPageItems(
              pdfTemplate["Personal Guaranty"].items,
              fieldsData,
              sensitiveDataPreference,
            )}

            {pageAdditionalQuestions &&
              renderQuestionsSection(
                pageAdditionalQuestions["Personal Guaranty"],
              )}
          </PageBase>
        )}

        {pageAdditionalQuestions &&
          pageAdditionalQuestions["Additional Questions"] && (
            <PageBase
              title={pdfTemplate["Additional Questions"].title}
              logoSrc={businessData.logo}
            >
              {renderPageItems(
                pdfTemplate["Additional Questions"].items,
                fieldsData,
                sensitiveDataPreference,
              )}

              {renderQuestionsSection(
                pageAdditionalQuestions["Additional Questions"],
              )}
            </PageBase>
          )}
      </>
    )
  }

  return (
    <Document>
      <BusinessDetailsPage />
      <OwnersAndContactsPage />
      <ReferencePage />
      <AdditionalQuestionAndGuarantyPage />
    </Document>
  )
}

function renderPageItems(
  items: PDFItem[],
  fieldsData: {
    [key: string]: string | number
  },
  preference: boolean | string | string[] | undefined = undefined,
) {
  const getValue = (
    item: PDFItem,
    fieldsData: { [key: string]: string | number },
    preference: boolean | string | string[] | undefined = undefined,
  ) => {
    if (item.label.includes("SSN") || item.label.includes("Driver License")) {
      if (item.dataField && fieldsData[item.dataField]) {
        if (preference === undefined || preference === true) {
          return "*********" + fieldsData[item.dataField].toString().slice(-2)
        } else {
          return fieldsData[item.dataField].toString()
        }
      }
      return ""
    }

    if (item.label.includes("Owner Percentage")) {
      if (item.dataField && fieldsData[item.dataField] !== undefined) {
        return fieldsData[item.dataField] + "%"
      }
      return ""
    }

    return item.dataField ? fieldsData[item.dataField] : ""
  }

  return (
    <Block>
      {items.map((item) => {
        if (item.type === "input") {
          return (
            <Cell key={item.dataField} spacing={item.spacing}>
              <Input
                label={item.label}
                value={getValue(item, fieldsData, preference)}
              />
            </Cell>
          )
        }

        if (item.type === "text") {
          return (
            <Cell key={item.label} spacing={item.spacing}>
              <Text
                style={{
                  fontSize: "11px",
                  marginTop: "10px",
                  paddingTop: "4px",
                  textAlign: "left",
                }}
              >
                {item.label}
              </Text>
            </Cell>
          )
        }
      })}
    </Block>
  )
}

function renderQuestionsSection(items: QuestionItem[]) {
  if (!items) {
    return
  }

  return (
    <>
      {items.map((item) => (
        <Block key={item.id}>
          <Cell
            key={item.label}
            spacing={item.type === FIELD_TYPES.ADDRESS ? "30" : "48"}
          >
            <Text
              style={{
                fontSize: "11px",
                marginTop: "10px",
                paddingTop: "10px",
                textAlign: "left",
              }}
            >
              {item.label}
            </Text>
          </Cell>

          <Cell
            key={item.id}
            spacing={item.type === FIELD_TYPES.ADDRESS ? "66" : "48"}
          >
            <Input label="" value={String(item.value) ?? ""} />
          </Cell>
        </Block>
      ))}
    </>
  )
}

const getFieldType = (fieldType: string) => {
  if (fieldType === FIELD_TYPES.TEXT) {
    return FIELD_TYPES.TEXT
  }

  if (fieldType === FIELD_TYPES.ADDRESS) {
    return FIELD_TYPES.ADDRESS
  }

  return "input"
}

function getPagesQuestionsItems(
  template: TemplateCustomField[] | undefined,
  dataCustomFields: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  },
) {
  if (!template || !dataCustomFields) return

  const pagesQuestions: {
    [key: string]: QuestionItem[]
  } = {}
  sortTemplateCustomFieldsForInternalDisplay(
    template.filter(
      (q) => q.id !== undefined && q.fieldType !== FIELD_TYPES.FILE,
    ),
  )?.forEach((question) => {
    const dataField = question.id as string
    let value = dataCustomFields[dataField]?.response ?? ""

    if (question.fieldType === FIELD_TYPES.ADDRESS) {
      if (
        dataCustomFields[dataField]?.responseJson &&
        dataCustomFields[dataField]?.responseJson.address
      ) {
        value = `${dataCustomFields[dataField]?.responseJson.address}`

        if (dataCustomFields[dataField]?.responseJson.address2) {
          value += `, ${dataCustomFields[dataField]?.responseJson.address2}`
        }

        if (dataCustomFields[dataField]?.responseJson.city) {
          value += `, ${dataCustomFields[dataField]?.responseJson.city}`
        }

        if (dataCustomFields[dataField]?.responseJson.region) {
          value += `, ${dataCustomFields[dataField]?.responseJson.region}`
        }

        if (dataCustomFields[dataField]?.responseJson.postCode) {
          value += `, ${dataCustomFields[dataField]?.responseJson.postCode}`
        }

        if (dataCustomFields[dataField]?.responseJson.country) {
          value += `, ${dataCustomFields[dataField]?.responseJson.country}`
        }
      }
    }

    if (!question?.associatedPage) return

    if (question?.associatedPage && !pagesQuestions[question.associatedPage]) {
      pagesQuestions[question.associatedPage] = []
    }

    pagesQuestions[question.associatedPage].push({
      id: question.id,
      label: question.text,
      type: getFieldType(question.fieldType),
      value,
    })
  })

  return pagesQuestions
}
