import { Paper, Typography, Grid, useTheme, Stack } from "@mui/material"
import Chart from "react-apexcharts"
import { CreditSafeCreditScore } from "src/types"
import { fDateTime } from "src/utils/formatTime"

const CreditRatingComparison = ({ data }: { data: CreditSafeCreditScore }) => {
  const theme = useTheme()
  const creditRatingOptions = {
    chart: {
      type: "bar" as const,
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 4,
      },
    },
    xaxis: {
      categories: ["Credit Rating Score"],
    },
    yaxis: {
      title: {
        text: "Score",
      },
    },
    colors: [theme.palette.primary.main, theme.palette.secondary.main],
    dataLabels: {
      enabled: true,
    },
  }

  const creditLimitOptions = {
    chart: {
      type: "bar" as const,
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 4,
      },
    },
    xaxis: {
      categories: ["Credit Limit"],
    },
    yaxis: {
      title: {
        text: "Credit Limit (USD)",
      },
    },
    colors: [theme.palette.primary.main, theme.palette.secondary.main],
    dataLabels: {
      enabled: true,
    },
  }

  const creditRatingSeries = [
    {
      name: "Current",
      data: [
        Number.isNaN(parseInt(data?.currentCreditRating?.providerValue?.value))
          ? 0
          : parseInt(data?.currentCreditRating?.providerValue?.value),
      ],
    },
    {
      name: "Previous",
      data: [
        Number.isNaN(parseInt(data?.previousCreditRating?.providerValue?.value))
          ? 0
          : parseInt(data?.previousCreditRating?.providerValue?.value),
      ],
    },
  ]

  const creditLimitSeries = [
    {
      name: "Current",
      data: [
        Number.isNaN(parseInt(data?.currentCreditRating?.creditLimit?.value))
          ? 0
          : parseInt(data?.currentCreditRating?.creditLimit?.value),
      ],
    },
    {
      name: "Previous",
      data: [
        Number.isNaN(parseInt(data?.previousCreditRating?.creditLimit?.value))
          ? 0
          : parseInt(data?.previousCreditRating?.creditLimit?.value),
      ],
    },
  ]
  return (
    <Paper sx={{ p: 2 }} elevation={3}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Credit Rating Comparison
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle2">
            Credit Rating Score Comparison
          </Typography>
          <Chart
            options={creditRatingOptions}
            series={creditRatingSeries}
            type="bar"
            height={350}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle2">Credit Limit Comparison</Typography>
          <Chart
            options={creditLimitOptions}
            series={creditLimitSeries}
            type="bar"
            height={350}
          />
        </Grid>
      </Grid>
      <Stack direction={"row"} spacing={1} justifyContent={"center"}>
        <Typography variant="caption" color="text.secondary">
          Last updated: {fDateTime(data?.latestRatingChangeDate)}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          Current Rating: {data?.currentCreditRating?.commonValue} -{" "}
          {data?.currentCreditRating?.commonDescription}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          Previous Rating: {data?.previousCreditRating?.commonValue} -{" "}
          {data?.previousCreditRating?.commonDescription}
        </Typography>
      </Stack>
    </Paper>
  )
}

export default CreditRatingComparison
