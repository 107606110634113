import { AddOutlined, DeleteOutline } from "@mui/icons-material"
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  IconButton,
} from "@mui/material"
import { Form, Formik, FormikProps } from "formik"
import { enqueueSnackbar } from "notistack"
import { TemplateCustomField } from "src/types"
import CustomFieldConfigView from "./CustomFieldConfigView"
import { useTemplateCustomFields } from "src/queries/credit/useTemplateCustomFields"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"
import { MutableRefObject, useEffect, useState } from "react"
import { confirm } from "src/components/confirm"
import { useDeleteTemplateCustomFields } from "src/queries/credit/useDeleteTemplateCustomFields"
import { useCreateTemplateCustomFields } from "src/queries/credit/useCreateTemplateCustomFields"
import { usePatchTemplateCustomFields } from "src/queries/credit/usePatchTemplateCustomFields"
import { info, error } from "src/utils/logger"
import {
  PAGE_LABEL_ADDITIONAL_QUESTIONS,
  PAGE_LABEL_BANK_REFERENCE,
  PAGE_LABEL_BUSINESS_DETAILS,
  PAGE_LABEL_OWNERS,
  PAGE_LABEL_USER_DETAILS,
} from "../intake_sections/constants"
import { sortByFieldType } from "../components/ReusableCustomQuestions"
import { capitalizeFirstLetter } from "src/utils/utils"
import Label from "src/components/label"

export default ({
  bagRef,
  businessId,
}: {
  bagRef: MutableRefObject<FormikProps<TemplateCustomField[]> | null>
  businessId: string
}) => {
  const { data, refetch } = useApplicationTemplate(businessId)

  const { data: customfields, refetch: refetchCustomFields } =
    useTemplateCustomFields(businessId)

  const [showDetails, setShowDetails] = useState<boolean | number>(false)

  const { executeAsync: deleteCustomField } = useDeleteTemplateCustomFields()
  const {
    executeAsync: patchCustomField,
    isLoading: isLoadingPatchCustomField,
  } = usePatchTemplateCustomFields()
  const {
    executeAsync: createCustomField,
    isLoading: isLoadingCreateCustomField,
  } = useCreateTemplateCustomFields()

  const [selectedCustomField, setSelectedCustomField] = useState<
    TemplateCustomField | undefined
  >(undefined)

  let unmatchedConditionalIds = [] as string[]

  const getFirstSectionIndex = (
    fields: TemplateCustomField[],
    section: string,
  ) => {
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].associatedPage === section) {
        return i
      }
    }
    return -1
  }
  const getConditionalCustomQuestion = (
    conditional_custom_questions: {
      [key: string]: TemplateCustomField[]
    },
    question_id: string,
  ) => {
    const result = [] as TemplateCustomField[]
    if (
      conditional_custom_questions[question_id] &&
      conditional_custom_questions[question_id].length > 0
    ) {
      for (const conditional_question of conditional_custom_questions[
        question_id
      ].sort(sortByFieldType)) {
        if (
          unmatchedConditionalIds.includes(conditional_question.id as string)
        ) {
          continue
        }
        result.push(conditional_question)
        const childrenConditionalQuestions = getConditionalCustomQuestion(
          conditional_custom_questions,
          conditional_question.id as string,
        ) // recursively fetch more conditional questions
        if (childrenConditionalQuestions.length > 0) {
          result.push(...childrenConditionalQuestions)
        }
      }
    }
    return result
  }

  const sortFields = (fields: TemplateCustomField[]) => {
    const data_key_questions = {} as { [key: string]: TemplateCustomField[] }
    const required_questions = {} as { [key: string]: TemplateCustomField[] }
    const optional_questions = {} as { [key: string]: TemplateCustomField[] }
    const conditional_custom_questions = {} as {
      [key: string]: TemplateCustomField[]
    }
    unmatchedConditionalIds = [] as string[]

    const section_order = [
      PAGE_LABEL_USER_DETAILS,
      PAGE_LABEL_BUSINESS_DETAILS,
      PAGE_LABEL_OWNERS,
      PAGE_LABEL_BANK_REFERENCE,
      PAGE_LABEL_ADDITIONAL_QUESTIONS,
    ]

    for (const field of fields) {
      if (
        field.conditions &&
        field.conditions.length > 0 &&
        field.conditions[0].conditionCustomField
      ) {
        if (
          !conditional_custom_questions[
            field.conditions[0].conditionCustomField as string
          ]
        ) {
          conditional_custom_questions[
            field.conditions[0].conditionCustomField as string
          ] = []
        }
        conditional_custom_questions[
          field.conditions[0].conditionCustomField as string
        ].push(field)
      }
    }

    for (const field of fields) {
      if (!required_questions[field.associatedPage as string]) {
        required_questions[field.associatedPage as string] = []
      }
      if (!optional_questions[field.associatedPage as string]) {
        optional_questions[field.associatedPage as string] = []
      }
      if (!data_key_questions[field.associatedPage as string]) {
        data_key_questions[field.associatedPage as string] = []
      }
      if (!field.conditions || field.conditions.length === 0) {
        if (field.required) {
          required_questions[field.associatedPage as string].push(field)
        }

        if (!field.required) {
          if (!field.conditions || field.conditions.length === 0) {
            optional_questions[field.associatedPage as string].push(field)
          }
        }
      }

      if (
        field.conditions &&
        field.conditions.length > 0 &&
        field.conditions[0].dataKey
      ) {
        data_key_questions[field.associatedPage as string].push(field)
      }
    }
    const result = []
    for (const section of section_order) {
      const sectionResult = [] as TemplateCustomField[]
      if (data_key_questions[section]) {
        data_key_questions[section].sort(sortByFieldType)
        for (const data_key_question of data_key_questions[section]) {
          sectionResult.push(data_key_question)
          sectionResult.push(
            ...getConditionalCustomQuestion(
              conditional_custom_questions,
              data_key_question.id as string,
            ),
          )
        }
      }
      if (required_questions[section]) {
        required_questions[section].sort(sortByFieldType)
        for (const required_question of required_questions[section]) {
          sectionResult.push(required_question)
          sectionResult.push(
            ...getConditionalCustomQuestion(
              conditional_custom_questions,
              required_question.id as string,
            ),
          )
        }
      }
      if (optional_questions[section]) {
        optional_questions[section].sort(sortByFieldType)
        for (const optional_question of optional_questions[section]) {
          sectionResult.push(optional_question)
          sectionResult.push(
            ...getConditionalCustomQuestion(
              conditional_custom_questions,
              optional_question.id as string,
            ),
          )
        }
      }

      // add conditional questions without a current solid match
      const getAllConditionalQuestions = fields.filter(
        (field) =>
          field.associatedPage === section &&
          field.conditions &&
          field.conditions.length > 0,
      )

      for (const conditionalQuestion of getAllConditionalQuestions.sort(
        sortByFieldType,
      )) {
        if (
          !sectionResult.find((field) => field.id === conditionalQuestion.id)
        ) {
          sectionResult.push(conditionalQuestion)
          // add to unmatched conditional ids to show warning
          unmatchedConditionalIds.push(conditionalQuestion.id as string)
        }
      }

      result.push(...sectionResult)
    }
    return result
  }
  const fields = sortFields(customfields || [])

  const handleSuccess = () => {
    refetch()
    refetchCustomFields()
    enqueueSnackbar("Settings saved.", {
      variant: "success",
    })
    setShowDetails(false)
  }

  useEffect(() => {
    if (selectedCustomField) {
      if (selectedCustomField.id) {
        patchCustomField(selectedCustomField)
          .then(() => {
            handleSuccess()
            return
          })
          .catch((err) => {
            error(err)
          })
      } else {
        createCustomField(selectedCustomField)
          .then(() => {
            handleSuccess()
            return
          })
          .catch((err) => {
            error(err)
          })
      }
    }
  }, [selectedCustomField])

  if (!fields || !data) return <></>
  return (
    <Card
      style={{
        margin: "1rem 0 1rem 0",
      }}
    >
      <CardHeader title="Credit Additional Questions" />

      <CardContent>
        <Typography sx={{ marginBottom: "1rem" }}>
          You can customize your application! Add questions here and they will
          appear on your application under the assigned section.
        </Typography>
        <Formik
          innerRef={bagRef}
          initialValues={fields ? fields : []}
          enableReinitialize
          onSubmit={(values: Array<TemplateCustomField>) => {
            return
          }}
        >
          {(props) => (
            <>
              <Form>
                {props.values.map((field, index) => (
                  <Box key={index}>
                    {getFirstSectionIndex(
                      props.values,
                      field.associatedPage as string,
                    ) === index && (
                      <Grid
                        container
                        columnSpacing={3}
                        style={{
                          marginTop: "0.5rem",
                          marginBottom: "0.5rem",
                          marginLeft: "1rem",
                          marginRight: "1rem",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <Grid item sm={12} md={9}>
                          <Typography variant="h6">
                            {field.associatedPage}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}

                    <Grid
                      container
                      columnSpacing={3}
                      rowSpacing={1}
                      style={{
                        marginBottom: "1rem",
                        marginLeft: "1rem",
                        marginRight: "1rem",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "start",
                      }}
                    >
                      <Grid item sm={12} md={9}>
                        <Typography variant="body1">
                          {field.text}{" "}
                          {field.conditions && field.conditions.length > 0 && (
                            <>
                              {unmatchedConditionalIds.includes(
                                field.id as string,
                              ) ? (
                                <Label color={"error"}>
                                  Conditional With Matching Error
                                </Label>
                              ) : (
                                <Label color={"warning"}>Conditional</Label>
                              )}
                            </>
                          )}
                        </Typography>
                        <Typography variant="caption">
                          {capitalizeFirstLetter(field.fieldType)} -{" "}
                          {field.required ? "Required" : "Optional"}
                        </Typography>
                      </Grid>
                      <Grid item sm={12} md={1}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            setShowDetails(Number(index))
                          }}
                        >
                          Edit
                        </Button>
                      </Grid>

                      <Grid item sm={12} md={2}>
                        <IconButton
                          color="error"
                          onClick={() => {
                            confirm(
                              "You are about to remove this question from your application",
                            )
                              .then(
                                () => {
                                  return deleteCustomField(field)
                                },
                                () => {
                                  info("cancelled")
                                },
                              )
                              .then(() => {
                                refetch()
                                refetchCustomFields()
                                return enqueueSnackbar("Settings saved.", {
                                  variant: "success",
                                })
                              })
                              .catch(() => {
                                error("cancelled")
                              })
                          }}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Box>
                ))}

                {showDetails !== false && (
                  <CustomFieldConfigView
                    data={data}
                    isLoading={
                      isLoadingPatchCustomField || isLoadingCreateCustomField
                    }
                    value={
                      showDetails === true
                        ? undefined
                        : { ...props.values[showDetails] }
                    }
                    onClose={() => {
                      setShowDetails(false)
                    }}
                    onSave={(field) => {
                      setSelectedCustomField(field)
                    }}
                  />
                )}

                <Box
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "end",
                    margin: "1rem 0 0 0",
                  }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    startIcon={<AddOutlined />}
                    onClick={() => {
                      // props.setValues(
                      //   [
                      //     {
                      //       text: "",
                      //       fieldType: "text",
                      //       template: data.id,
                      //       required: true,
                      //     },
                      //   ].concat(props.values || []),
                      // )
                      setShowDetails(true)
                    }}
                  >
                    Add Question
                  </Button>
                </Box>
              </Form>
            </>
          )}
        </Formik>
      </CardContent>
    </Card>
  )
}
