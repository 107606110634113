import { useQuery } from "react-query"
import { CashTag } from "src/types"

export function useCashTags() {
  return useQuery<Array<CashTag>>("/cod-tags", {
    enabled: true,
    refetchOnMount: false,
    retry: (failureCount, error) => {
      if (failureCount < 1) {
        return true
      }
      // do not retry on 404
      return (error as any)?.response?.status !== 404
    },
  })
}
