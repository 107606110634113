import { QueryClient } from "react-query"
import { TinData, VerificationStatus } from "../../types"
import { useTypedMutation } from "../useTypedMutation"
import { useQuery } from "react-query"
import queryString from "query-string"
import { useEffect, useState } from "react"

const useSignzy = (data?: TinData) => {
  const ENDPOINT = "/signzy/tin_verification"
  const post = useTypedMutation<TinData>("post/signzy/tin_verification")

  const { isLoading: fetchingVerification, data: tinData } = useQuery<TinData>(
    queryString.stringifyUrl({
      url: ENDPOINT,
      query: { name: data?.name, tin: data?.tin },
    }),
    {
      enabled: !!data?.name && !!data?.tin,
      retry: (failureCount, error) => {
        if (failureCount < 1) {
          return true
        }
        // do not retry on 404
        return (error as any)?.response?.status !== 404
      },
    },
  )

  const [verificationState, setVerificationState] = useState(
    tinData?.status || VerificationStatus.NONE,
  )

  useEffect(() => {
    setVerificationState(tinData?.status || VerificationStatus.NONE)
  }, [tinData])
  const execute = (body: Partial<TinData>) => {
    post.mutate(
      {
        endpoint: ENDPOINT,
        body,
      },
      {
        onSuccess: async () => {
          setVerificationState(VerificationStatus.PROCESSING)
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
        },
        onError: async () => {
          setVerificationState(VerificationStatus.FAILURE)
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
        },
      },
    )
  }
  return {
    ...post,
    execute,
    tinData,
    fetchingVerification,
    isLoading: post.isLoading,
    verificationState,
  }
}

export default useSignzy
