import { useState, useEffect } from "react"
import {
  Box,
  Card,
  CardHeader,
  Avatar,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Typography,
} from "@mui/material"
import { stringAvatar } from "src/utils/avatar"
import { fDate } from "src/utils/formatTime"
import { SendOutlined } from "@mui/icons-material"
import { useApplicationComments } from "src/queries/credit/useApplicationComments"
import { CreditApplication, Group } from "src/types"
import { usePostApplicationComment } from "src/queries/credit/usePostApplicationComment"
import { useUser } from "src/queries"
import { useUsers } from "src/queries/base/useUsers"
import { MentionsTextField } from "@jackstenglein/mui-mentions"

export default ({
  application,
  refreshCommentTrigger,
}: {
  application: CreditApplication
  refreshCommentTrigger: { current: number }
}) => {
  const { data: userData } = useUser()

  const { data: users } = useUsers()

  const taggableUsers = users?.filter(
    (user) => user.groups?.includes(Group.CreditManager),
  )

  const [draft, setDraft] = useState<string | undefined>(undefined)

  const { data, refetch, isLoading } = useApplicationComments(application.id)

  const { execute, isLoading: isPosting } = usePostApplicationComment(() => {
    refetch()
  })

  useEffect(() => {
    if (refreshCommentTrigger.current) {
      refetch()
    }
  }, [refetch, refreshCommentTrigger.current])

  if (!userData || !data) return <></>

  return (
    <Card>
      <CardHeader title="Comments" />
      <Stack spacing={1.5} sx={{ px: 3, pb: 2 }}>
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          style={{ marginTop: "1rem" }}
        >
          {/* @ts-expect-error this is a 3rd party lib and it works */}
          <MentionsTextField
            fullWidth
            value={draft}
            placeholder="Use @ to mention someone."
            onChange={(event) => {
              setDraft(event)
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    disabled={isLoading || isPosting}
                    onClick={() => {
                      if (draft) {
                        execute({ application: application.id, comment: draft })
                        setDraft("")
                      }
                    }}
                  >
                    <SendOutlined />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            dataSources={[
              {
                data:
                  taggableUsers?.map((user) => {
                    return {
                      id: user.id,
                      display: `${user.firstName} ${user.lastName}`,
                    }
                  }) || [],
              },
            ]}
          />
        </Stack>
        {data?.map((comment) => {
          return (
            <Stack direction="row" spacing={2} key={comment.id}>
              <Avatar
                {...stringAvatar(
                  comment.user.firstName
                    ? `${comment.user.firstName} ${comment.user.lastName}`
                    : comment.user.email,
                )}
              />

              <Paper
                sx={{
                  p: 1.5,
                  flexGrow: 1,
                  bgcolor: "background.neutral",
                }}
              >
                <Stack
                  sx={{ mb: 0.5 }}
                  alignItems={{ sm: "center" }}
                  justifyContent="space-between"
                  direction={{ xs: "column", sm: "row" }}
                >
                  <Box
                    sx={{ typography: "subtitle2" }}
                    style={{ wordBreak: "break-word" }}
                  >
                    {comment.user.firstName
                      ? `${comment.user.firstName} ${
                          comment.user.lastName ? comment.user.lastName[0] : ""
                        }`
                      : comment.user.email}
                  </Box>

                  <Box sx={{ typography: "caption", color: "text.disabled" }}>
                    {fDate(comment.createdAt)}
                  </Box>
                </Stack>
                {comment.title && (
                  <Typography variant="caption">
                    <b>{comment.title}</b>
                  </Typography>
                )}
                {/* @ts-expect-error this is a 3rd party lib and it works */}
                <MentionsTextField
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  fullWidth
                  value={comment.comment}
                  disabled
                  multiline
                  dataSources={[
                    {
                      data:
                        taggableUsers?.map((user) => {
                          return {
                            id: user.id,
                            display: `${user.firstName} ${user.lastName}`,
                          }
                        }) || [],
                    },
                  ]}
                />
              </Paper>
            </Stack>
          )
        })}
      </Stack>
    </Card>
  )
}
