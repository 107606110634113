import { useTypedMutation } from "../../useTypedMutation"

export function useRemindGuarantorsV3() {
  const ENDPOINT = "/application/v3/remind_guarantors"
  const post = useTypedMutation<{ id: string; notes: string }>(
    "post/application/v3/remind_guarantors",
  )

  const execute = (
    applicationId: string,
    notes: string,
    onSuccess?: () => void,
  ) => {
    post.mutate(
      {
        endpoint: ENDPOINT,
        body: {
          id: applicationId,
          notes,
        },
      },
      {
        onSuccess: async () => {
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...post, execute }
}
