import { SellOutlined } from "@mui/icons-material"
import {
  Autocomplete,
  Chip,
  Stack,
  SxProps,
  TextField,
  Theme,
  Typography,
} from "@mui/material"
import { useEffect } from "react"
import { usePatchTags } from "src/queries/credit/tags/usePatchTags"
import { useTags } from "src/queries/credit/tags/useTags"
import { useCreditApplication } from "src/queries/credit/useCreditApplication"
import { CreditApplication, Tag } from "src/types"

export default ({
  sx,
  application,
  onTagsChanged,
}: {
  sx: SxProps<Theme>
  application: CreditApplication
  onTagsChanged: (tags: Tag[]) => void
}) => {
  const { data: orgTags } = useTags()
  const { data, refetch } = useCreditApplication(application?.id, false)
  const { execute: patchTags, isLoading } = usePatchTags()

  useEffect(() => {
    if (data?.tags) {
      onTagsChanged(data?.tags)
    }
  }, [data, onTagsChanged])

  const appTags = data?.tags || application?.tags || []

  return (
    <Stack direction="row" alignItems="center" sx={sx} spacing={1}>
      <SellOutlined />
      <Autocomplete
        fullWidth
        multiple
        options={
          orgTags?.filter((t) => !appTags.find((aT) => aT.id === t.id)) || []
        }
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        )}
        defaultValue={[]}
        value={appTags}
        onChange={(_, value) => {
          patchTags(
            {
              id: application.id,
              tags: value,
            },
            () => {
              refetch()
            },
          )
        }}
        renderTags={(selected, getTagProps) =>
          selected.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={option.id}
              variant="outlined"
              style={{
                color: option.backgroundColor,
                backgroundColor: "transparent !important",
                borderColor: option.backgroundColor,
                marginRight: "4px",
              }}
              label={
                <Typography
                  variant="caption"
                  sx={{
                    textTransform: "capitalize",
                    color: option.textColor,
                  }}
                >
                  {option.name}
                </Typography>
              }
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            placeholder={isLoading ? "Please wait..." : "+ Tag"}
            {...params}
          />
        )}
      />
    </Stack>
  )
}
