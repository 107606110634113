import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material"
import { Formik } from "formik"
import * as yup from "yup"
import { useCreateInternalBulkSelectableUserInvite } from "src/queries/internal/useCreateInternalBulkSelectableUserInvite"
import { Business, Group, SelectableUserBulkInvite } from "src/types"
import { enqueueSnackbar } from "notistack"
import { ExpandMore } from "@mui/icons-material"
import { useInternalStores } from "src/queries/internal/useInternalStores"

export default function ViewBulkUserInviteEmailDialog({
  open,
  onClose,
  business,
}: {
  open: boolean
  onClose: () => void
  business: Business
}) {
  const { execute, isLoading } = useCreateInternalBulkSelectableUserInvite()
  const { data: stores } = useInternalStores(business.id || "")
  const schema = yup.object({
    business: yup.string().required("Required"),
    users: yup.string().required("Required"),
    groups: yup.string().required("Required"),
    store: yup.string(),
  })
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth={"md"} fullWidth>
        <Formik
          initialValues={
            {
              business: business.id,
            } as SelectableUserBulkInvite
          }
          onSubmit={(values: SelectableUserBulkInvite) => {
            execute(values, () => {
              enqueueSnackbar("Invites Sent", {
                variant: "success",
              })
              onClose()
            })
          }}
          validationSchema={schema}
        >
          {(props) => (
            <>
              <DialogTitle id="alert-dialog-title">
                Invite Users in Bulk
              </DialogTitle>
              <DialogContent sx={{ p: 2 }}>
                <Stack spacing={4}>
                  <Stack spacing={0}>
                    <Paper variant="outlined">
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          File Example
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="caption">
                            firstname |required| ,lastname |required| ,email
                            |required|
                            <br />
                            John,Doe,jd@test.com
                            <br />
                            John1,Doe1,jd1@test.com
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Paper>
                  </Stack>
                  <Input
                    type="file"
                    id="users"
                    name="users"
                    placeholder="users"
                    onChange={(event) => {
                      if (event.nativeEvent?.target) {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const target = event.nativeEvent.target as any
                        props.setFieldValue("users", target.files[0])
                      }
                    }}
                    error={Boolean(props.errors?.users)}
                  />
                  <Stack spacing={1}>
                    <Typography variant="caption">Group</Typography>
                    <Select
                      fullWidth
                      id="groups"
                      label="Group"
                      name="groups"
                      value={props.values.groups}
                      onChange={props.handleChange}
                      error={Boolean(props.errors?.groups)}
                    >
                      <MenuItem disabled value={undefined}>
                        Select one
                      </MenuItem>
                      <MenuItem value={Group.CreditManager}>
                        Credit Manager
                      </MenuItem>
                      <MenuItem value={Group.Buyer}>Buyer</MenuItem>
                      <MenuItem value={Group.NetNowAdmin}>NetNowAdmin</MenuItem>
                      <MenuItem value={Group.Sales}>Sales</MenuItem>
                      <MenuItem value={Group.SalesManager}>
                        SalesManager
                      </MenuItem>
                      <MenuItem value={Group.NeedsSelfOnboarding}>
                        NeedsSelfOnboarding
                      </MenuItem>
                    </Select>
                  </Stack>
                  {props.values.groups == Group.Sales.toString() && (
                    <Stack spacing={1}>
                      <Typography variant="caption">Store</Typography>
                      <Select
                        fullWidth
                        id="store"
                        label="Store"
                        name="store"
                        value={props.values.store}
                        onChange={props.handleChange}
                      >
                        <MenuItem disabled value={undefined}>
                          Select one if applicable
                        </MenuItem>
                        {stores?.map((store) => (
                          <MenuItem key={store.id} value={store.id}>
                            {store.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Stack>
                  )}
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose}>Close</Button>
                <Button
                  variant="contained"
                  disabled={isLoading}
                  onClick={() => {
                    props.handleSubmit()
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </Dialog>
    </>
  )
}
