import PropTypes from "prop-types"
import { useMemo } from "react"
// @mui
import { CssBaseline, useMediaQuery } from "@mui/material"
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles"
//
import palette, { getPalette } from "./palette"
import shadows from "./shadows"
import typography from "./typography"
import GlobalStyles from "./globalStyles"
import customShadows from "./customShadows"
import componentsOverride from "./overrides"
import { useAnonymousBusiness } from "../queries/credit/useAnonymousBusiness"
import { useLocation } from "react-router-dom"

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
}
export default function ThemeProvider({ children }) {
  const themeOptions = useMemo(
    () => ({
      palette,
      shape: { borderRadius: 6 },
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
    }),
    [],
  )

  const theme = createTheme(themeOptions)
  theme.components = componentsOverride(theme)

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  )
}

DynamicThemeProvider.propTypes = {
  children: PropTypes.node,
}

export function DynamicThemeProvider({ children }) {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const businessId = queryParams.get("business_id") || ""
  const { data } = useAnonymousBusiness(businessId)

  const matches = useMediaQuery("(max-width: 899px)")

  const themeOptions = useMemo(
    () => ({
      palette: getPalette(data?.color ? "#" + data?.color : undefined),
      shape: { borderRadius: 6 },
      typography: matches
        ? {
            fontFamily: "Inter",
            h5: { fontSize: "1.5rem", color: "#101828", fontWeight: "500" },
            body2: { fontSize: "1rem", color: "#667085", fontWeight: "400" },
            body1: {
              fontSize: "0.875rem",
              color: "#344054",
              fontWeight: "500",
            },
            h6: { fontSize: "1.25rem", fontWeight: "600" },
            h4: { fontSize: "0.875rem", fontWeight: "600" },
          }
        : typography,
      shadows: shadows(),
      customShadows: customShadows(),
    }),
    [data?.color, matches],
  )

  const theme = createTheme(themeOptions)
  theme.components = componentsOverride(theme)

  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
}
