import { isNumber } from "lodash"
import numeral from "numeral"
import { useLocales as getLocales } from "src/locales"

// ----------------------------------------------------------------------
type InputValue = string | number | null

function getLocaleCode() {
  const {
    currentLang: {
      numberFormat: { code, currency },
    },
  } = getLocales()

  return {
    code: code ?? "en-US",
    currency: currency ?? "USD",
  }
}

export function convertCommaSeparatedToNumber(value: string | number): number {
  if (typeof value === "number" || value === undefined || value === null) {
    return value
  }
  value = value.replaceAll(",", "")
  return Number(value)
}

export function formatCurrency(amount: any, currency = "USD") {
  if (Number.isNaN(Number(amount))) {
    return ""
  }
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  })

  return formatter.format(amount)
}

export function fNumber(number: any) {
  return numeral(number).format()
}

export function fCurrency(number: any) {
  const format = number ? numeral(number).format("$0,0.00") : ""

  return result(format, ".00")
}

export function fPercent(number: any) {
  const format = number ? numeral(Number(number) / 100).format("0.0%") : ""

  return result(format, ".0")
}

export function fData(number: any) {
  const format = number ? numeral(number).format("0.0 b") : ""

  return result(format, ".0")
}

function result(format: string, key = ".00") {
  const isInteger = format.includes(key)

  return isInteger ? format.replace(key, "") : format
}

export function fShortenNumber(inputValue: InputValue) {
  const { code } = getLocaleCode()

  if (inputValue !== 0 && !inputValue) return ""

  const number = Number(inputValue)

  const fm = new Intl.NumberFormat(code, {
    notation: "compact",
    maximumFractionDigits: 2,
  }).format(number)

  return fm.replace(/[A-Z]/g, (match) => match.toLowerCase())
}
