import React from "react"
import { AnonymousBusiness, CreditApplication } from "src/types"
import { pdf, Document, Text } from "@react-pdf/renderer"
import {
  PageBase,
  Cell,
  Input,
  Block,
} from "src/components/pdf/output-template/Elements"
import { useTradeReferences } from "src/queries/credit/useTradeReferences"
import { TradeReference, TradeReferenceTemplate } from "../types/credit.types"
import { useAnonymousBusiness } from "src/queries/credit/useAnonymousBusiness"
import { useTradeReferenceTemplate } from "src/queries/credit/useTradeReferenceTemplate"

/**
 *
 * @param application
 * @returns A promise that resolves to a File containing the PDF of the application
 */

const TradeReferenceData = ({
  tradeRefs,
  businessData,
  template,
}: {
  tradeRefs: TradeReference[]
  businessData: AnonymousBusiness
  template: TradeReferenceTemplate
}) => {
  return (
    <Document>
      <PageBase title="Trade References" logoSrc={businessData.logo}>
        {tradeRefs &&
          tradeRefs.map((tradeRef, index) => (
            <Block key={tradeRef.id + index}>
              <Cell spacing="100">
                <Text
                  style={{
                    fontSize: "11px",
                    marginTop: "10px",
                    paddingTop: "10px",
                    textAlign: "left",
                  }}
                >
                  References {index + 1}
                </Text>
              </Cell>
              <Cell spacing="48">
                <Input label="Account Number" value={tradeRef.accountNumber} />
              </Cell>
              <Cell spacing="48">
                <Input label="Vendor Name" value={tradeRef.name} />
              </Cell>
              <Cell spacing="48">
                <Input label="Phone Number" value={tradeRef.phoneNumber} />
              </Cell>

              <Cell spacing="48">
                <Input label="Email" value={tradeRef.email} />
              </Cell>
              <Cell spacing="48">
                <Input label="Fax Number" value={tradeRef.faxNumber} />
              </Cell>

              {template?.fields
                ?.filter((field) => field.key != "comments")
                ?.map((field, index) => {
                  return (
                    <Cell key={index} spacing="48">
                      <Input
                        label={field.label}
                        value={
                          field.key == "reviewItems"
                            ? tradeRef.reviewItems?.join(", ")
                            : tradeRef[
                                field.key as keyof TradeReference
                              ]?.toString() || ""
                        }
                      />
                    </Cell>
                  )
                })}

              <Cell spacing="98">
                <Input label="Comments" value={tradeRef.comments} />
              </Cell>
            </Block>
          ))}
        {tradeRefs?.length === 0 && (
          <Block>
            <Cell spacing="100">
              <Text
                style={{
                  fontSize: "11px",
                  marginTop: "10px",
                  paddingTop: "10px",
                  textAlign: "left",
                }}
              >
                No Trade References
              </Text>
            </Cell>
          </Block>
        )}
      </PageBase>
    </Document>
  )
}
export default function useGenerateTradeReferencePdf(
  application: CreditApplication,
) {
  const { data: tradeRefs } = useTradeReferences(application.id)
  const { data: businessData } = useAnonymousBusiness(
    application.seller?.id || "",
  )

  const { data: template } = useTradeReferenceTemplate(application?.seller?.id)
  const execute = React.useMemo(() => {
    return async () => {
      if (application.id) {
        return await pdf(
          <TradeReferenceData
            tradeRefs={tradeRefs as TradeReference[]}
            businessData={businessData as AnonymousBusiness}
            template={template as TradeReferenceTemplate}
          />,
        ).toBlob()
      }
      throw new Error("Missing application data")
    }
  }, [application, tradeRefs, businessData])

  return { execute }
}
