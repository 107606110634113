import { Formik, FormikProps } from "formik"
import {
  Button,
  FormControl,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
} from "@mui/material"
import {
  BUSINESS_CONFIG_APPLICANT_TYPES_TO_REQUIRE_BUSINESS_NAME,
  BUSINESS_CONFIG_BILLING_ADDRESS,
  BUSINESS_CONFIG_BUSINESS_ADDRESS,
  BUSINESS_CONFIG_CREDIT_CARD_BILLING_ADDRESS,
  BUSINESS_CONFIG_CREDIT_MANGER_SELECTOR,
  BUSINESS_CONFIG_EIN,
  BUSINESS_CONFIG_REVENUE,
  BUSINESS_CONFIG_SERVICE_ADDRESS,
  BUSINESS_CONFIG_SHIPPING_ADDRESS,
  PAGE_LABEL_BUSINESS_DETAILS,
} from "src/sections/@dashboard/intake_sections/constants"
import { ApplicationTemplate, Config, Page } from "src/types"
import {
  getConfigFromPage,
  getPageFromTemplate,
  setUndefinedAsTrue,
} from "src/utils/utils"
import { usePatchCreditApplicationTemplatePage } from "src/queries/internal/usePatchCreditApplicationTemplatePage"
import { enqueueSnackbar } from "notistack"

export default function CreditSectionBusinessDetails({
  applicationTemplate,
  refetch,
}: {
  applicationTemplate: ApplicationTemplate
  refetch: () => void
}) {
  const businessDetailsPage = getPageFromTemplate(
    applicationTemplate,
    PAGE_LABEL_BUSINESS_DETAILS,
  )

  const { execute, isLoading } = usePatchCreditApplicationTemplatePage(
    applicationTemplate.id || "",
    () => {
      enqueueSnackbar("Application Template Updated", { variant: "success" })
    },
  )

  const getConfigFormControlValueField = (
    value: boolean,
    name: string,
    label: string,
    props: FormikProps<Page>,
  ) => {
    return (
      <FormControlLabel
        control={
          <Switch
            size="small"
            name={name}
            checked={value}
            onChange={(event) => {
              const config = getConfigFromPage(props.values, label) as Config
              if (config) {
                props.setFieldValue(
                  "config",
                  props.values.config.map((c) => {
                    if (c.label === label) {
                      c.value = event.target.checked
                    }
                    return c
                  }),
                )
              } else {
                props.values.config.push({
                  label: label,
                  description: label,
                  value: event.target.checked,
                })
              }
            }}
          />
        }
        label={"Enable " + label}
      />
    )
  }

  const getConfigFormControlListField = (
    value: string[],
    name: string,
    label: string,
    props: FormikProps<Page>,
  ) => {
    return (
      <TextField
        name={name}
        label={label}
        value={value ? (value.length > 0 ? value.join(",") : "") : ""}
        onChange={(event) => {
          const config = getConfigFromPage(props.values, label) as Config
          let value: string[] = []
          if (event.target.value) {
            if (event.target.value.split(",").length > 0) {
              value = event.target.value.split(",")
            } else {
              value = [event.target.value]
            }
          }

          if (config) {
            props.setFieldValue(
              "config",
              props.values.config.map((c) => {
                if (c.label === label) {
                  c.value = value
                }
                return c
              }),
            )
          } else {
            props.values.config.push({
              label: label,
              value: value,
            })
          }
        }}
      />
    )
  }

  return (
    <>
      <Stack sx={{ p: 0.3 }}>
        <Formik
          initialValues={
            businessDetailsPage ? businessDetailsPage : ({} as Page)
          }
          onSubmit={(values) => {
            execute({ ...values }, () => {
              refetch()
            })
          }}
        >
          {(props) => (
            <FormControl component="fieldset" variant="standard">
              <Stack spacing={3}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="enabled"
                      checked={props.values.enabled}
                      onChange={props.handleChange}
                    />
                  }
                  label="Enable Section"
                />
                {getConfigFormControlValueField(
                  setUndefinedAsTrue(
                    getConfigFromPage(props.values, BUSINESS_CONFIG_EIN)
                      ?.value as boolean,
                  ),
                  "EinValue",
                  BUSINESS_CONFIG_EIN,
                  props,
                )}
                {getConfigFormControlValueField(
                  setUndefinedAsTrue(
                    getConfigFromPage(props.values, BUSINESS_CONFIG_REVENUE)
                      ?.value as boolean,
                  ),
                  "RevenueValue",
                  BUSINESS_CONFIG_REVENUE,
                  props,
                )}
                {getConfigFormControlValueField(
                  getConfigFromPage(
                    props.values,
                    BUSINESS_CONFIG_BUSINESS_ADDRESS,
                  )?.value as boolean,
                  "businessAddressValue",
                  BUSINESS_CONFIG_BUSINESS_ADDRESS,
                  props,
                )}
                {getConfigFormControlValueField(
                  getConfigFromPage(
                    props.values,
                    BUSINESS_CONFIG_SHIPPING_ADDRESS,
                  )?.value as boolean,
                  "shippingAddressValue",
                  BUSINESS_CONFIG_SHIPPING_ADDRESS,
                  props,
                )}
                {getConfigFormControlValueField(
                  getConfigFromPage(
                    props.values,
                    BUSINESS_CONFIG_BILLING_ADDRESS,
                  )?.value as boolean,
                  "billingAddressValue",
                  BUSINESS_CONFIG_BILLING_ADDRESS,
                  props,
                )}
                {getConfigFormControlValueField(
                  getConfigFromPage(
                    props.values,
                    BUSINESS_CONFIG_CREDIT_CARD_BILLING_ADDRESS,
                  )?.value as boolean,
                  "creditCardBillingAddressValue",
                  BUSINESS_CONFIG_CREDIT_CARD_BILLING_ADDRESS,
                  props,
                )}
                {getConfigFormControlValueField(
                  getConfigFromPage(
                    props.values,
                    BUSINESS_CONFIG_SERVICE_ADDRESS,
                  )?.value as boolean,
                  "serviceAddressValue",
                  BUSINESS_CONFIG_SERVICE_ADDRESS,
                  props,
                )}
                {getConfigFormControlValueField(
                  getConfigFromPage(
                    props.values,
                    BUSINESS_CONFIG_CREDIT_MANGER_SELECTOR,
                  )?.value as boolean,
                  "creditManagerSelectorValue",
                  BUSINESS_CONFIG_CREDIT_MANGER_SELECTOR,
                  props,
                )}
                {getConfigFormControlListField(
                  getConfigFromPage(
                    props.values,
                    BUSINESS_CONFIG_APPLICANT_TYPES_TO_REQUIRE_BUSINESS_NAME,
                  )?.value as string[],
                  "addApplicantTypeToRequireBusinessName",
                  BUSINESS_CONFIG_APPLICANT_TYPES_TO_REQUIRE_BUSINESS_NAME,
                  props,
                )}
              </Stack>
              <Stack sx={{ marginY: 2 }}>
                <Button
                  variant="contained"
                  disabled={isLoading}
                  onClick={() => {
                    props.handleSubmit()
                  }}
                >
                  Save
                </Button>
              </Stack>
            </FormControl>
          )}
        </Formik>
      </Stack>
    </>
  )
}
