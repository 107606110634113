import { Paper, Typography, useTheme, Stack } from "@mui/material"
import Chart from "react-apexcharts"
import { CreditSafeReportData } from "src/types"
import { formatCurrency } from "src/utils/formatNumber"

const CreditRatingChart = ({ data }: { data: CreditSafeReportData }) => {
  const theme = useTheme()
  const chartOptions = {
    chart: {
      type: "radialBar" as const,
      //   height: 250,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        hollow: {
          margin: 0,
          size: "70%",
          background: theme.palette.common.white,
        },
        track: {
          background: theme.palette.grey[100],
          strokeWidth: "67%",
          margin: 0,
        },
        dataLabels: {
          show: true,
          name: {
            show: true,
            fontSize: "16px",
            fontWeight: 600,
            offsetY: -10,
          },
          value: {
            formatter: function (val: number) {
              if (typeof val === "number") {
                return val.toFixed(0) + "%"
              }
              return val + "%"
            },
            fontSize: "22px",
            fontWeight: 400,
            offsetY: 5,
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: [theme.palette.success.light],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: "round" as const,
    },
    labels: ["Credit Score"],
    colors: [theme.palette.primary.dark],
  }

  const chartSeries = [
    Number.isNaN(data.creditScore.currentCreditRating.providerValue.value) ||
    data.creditScore.currentCreditRating.providerValue.value === undefined
      ? 0
      : parseFloat(data.creditScore.currentCreditRating.providerValue.value),
  ]

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Typography variant="h5">Credit Rating</Typography>
      <Chart options={chartOptions} series={chartSeries} type="radialBar" />
      <Stack spacing={1} sx={{ mt: 2 }}>
        <Typography variant="caption" className="mb-2">
          <strong>Rating:</strong>{" "}
          {data.creditScore.currentCreditRating.commonValue}
        </Typography>
        <Typography variant="caption" color="text.secondary" className="mb-2">
          {data.creditScore.currentCreditRating.commonDescription}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          Credit Limit:{" "}
          {formatCurrency(
            data.creditScore.currentCreditRating.creditLimit.value,
            data.creditScore.currentCreditRating.creditLimit.currency,
          )}
        </Typography>
      </Stack>
    </Paper>
  )
}

export default CreditRatingChart
