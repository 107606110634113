import { Box, Typography, useMediaQuery } from "@mui/material"

export default function FormLabel({
  labelName,
  idFor,
  showInDesktop,
  required,
}: {
  labelName: string
  idFor?: string
  showInDesktop?: boolean
  required?: boolean
}) {
  const refId = idFor ? idFor : ""

  const matches = useMediaQuery("(max-width: 899px)")
  return (
    <>
      {(matches || showInDesktop) && (
        <Box
          sx={{
            width: "100%",
            marginBottom: "5px",
          }}
        >
          <Typography component="label" variant="body1" htmlFor={refId}>
            {labelName} {required && "*"}
          </Typography>
        </Box>
      )}
    </>
  )
}
