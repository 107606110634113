import { Box } from "@mui/material"

export default ({ ...others }) => {
  return (
    <Box
      component="svg"
      width="16px"
      height="16px"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <g>
        <g transform="translate(50,50)">
          <g transform="rotate(0)">
            <circle fillOpacity="1" fill="#ffbf23" r="5" cy="0" cx="0">
              <animateTransform
                repeatCount="indefinite"
                dur="2.5s"
                keyTimes="0;1"
                values="6.75 6.75;1 1"
                begin="-1.25s"
                type="scale"
                attributeName="transform"
              ></animateTransform>
              <animate
                begin="-1.25s"
                values="1;0"
                repeatCount="indefinite"
                dur="2.5s"
                keyTimes="0;1"
                attributeName="fill-opacity"
              ></animate>
            </circle>
          </g>
        </g>
        <g transform="translate(50,50)">
          <g transform="rotate(180)">
            <circle fillOpacity="0.5" fill="#ffbf23" r="5" cy="0" cx="0">
              <animateTransform
                repeatCount="indefinite"
                dur="2.5s"
                keyTimes="0;1"
                values="6.75 6.75;1 1"
                begin="0s"
                type="scale"
                attributeName="transform"
              ></animateTransform>
              <animate
                begin="0s"
                values="1;0"
                repeatCount="indefinite"
                dur="2.5s"
                keyTimes="0;1"
                attributeName="fill-opacity"
              ></animate>
            </circle>
          </g>
        </g>
        <g></g>
      </g>
    </Box>
  )
}
