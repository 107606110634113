import { QueryClient } from "react-query"
import { CashUploadedFile } from "../../types"
import { useTypedMutation } from "../useTypedMutation"

export function useDeleteCashUploadedFile(onSuccess?: () => void) {
  const ENDPOINT = "/cod/uploads"
  const d = useTypedMutation<Pick<CashUploadedFile, "id">>(
    "/cod/uploads",
    onSuccess,
  )

  const execute = (id: string) => {
    d.mutate(
      {
        method: "delete",
        endpoint: ENDPOINT,
        body: {
          id,
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
        },
      },
    )
  }
  return { ...d, execute }
}
