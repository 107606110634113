import { useQuery } from "react-query"

export function useSignatureRequestDetailsV3(req_id?: string) {
  return useQuery<{ complete: boolean }>(
    `/application/v3/request_details?request_id=${req_id}`,
    {
      enabled: !!req_id,
      retry: (failureCount, error) => {
        if (failureCount < 1) {
          return true
        }
        // do not retry on 404
        return (error as any)?.response?.status !== 404
      },
    },
  )
}
