import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  Alert,
  AlertColor,
} from "@mui/material"
import creditSafe from "src/sections/@dashboard/credit/images/creditsafe.png"
import { CreditSafeAddress, CreditSafePurchasedReport } from "src/types"
import CreditRatingChart from "./CreditRatingChart"
import { fDateTime, fToNow } from "src/utils/formatTime"
import CreditRatingComparison from "src/sections/@dashboard/credit/components/credit-safe/CreditRatingComparision"
import { ExpandMore } from "@mui/icons-material"
import CreditDBTHistory from "./CreditDBTHistory"
import { formatCurrency } from "src/utils/formatNumber"
import FinancialStatements from "./FinancialStatements"
import CreditRatingHistory from "./CreditRatingHistory"
import CreditSafePaidTpdIndustriesChart from "./CreditSafePaidTpdIndustriesChart"
import CreditSafeTPDIndustryBreakdown from "./CreditSafeTPDIndustryBreakdown"
import CreditSafeTPDMonthlyBalanceChart from "./CreditSafeTPDMonthlyBalanceChart"
import CreditSafePaidMonthlyBalanceHistoriesChart from "./CreditSafePaidMonthlyBalanceHistoriesChart"
import CreditDBTTrend from "./CreditDBTTrend"

export default function CreditSafeReport({
  report,
}: {
  report: CreditSafePurchasedReport
}) {
  const theme = useTheme()
  const data = report.data.report
  const displayExistingValue = (value1: string, value2: string) => {
    if (value1) {
      return value1
    }

    if (value2) {
      return value2
    }

    return ""
  }

  const displayAddress = (address: CreditSafeAddress) => {
    if (!address) {
      return ""
    }
    return (
      <>
        {address.type && address.type + "- "}
        {address.houseNumber && address.houseNumber + " "}
        {address.street && address.street + " "}
        {address.city && address.city + " "}
        {address.province && address.province + " "}
        {address.region && address.region + " "}
        {address.country && address.country + " "}
      </>
    )
  }
  const toTitleCase = (str: any) => {
    if (!str) {
      return ""
    }
    return str
      .toLowerCase()
      .split(" ")
      .map((word: any) => {
        return word.charAt(0).toUpperCase() + word.slice(1)
      })
      .join(" ")
  }

  const toSpaceCaseWithNumbers = (word: string) => {
    // Convert title case to space case by adding a space before uppercase letters
    const result = word
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/([A-Z])([A-Z](?![a-z]))/g, "$1 $2") // handle acronyms/initials
      .replace(/(\d+)/g, " $1 ") // Add spaces around numbers
      .trim() // Remove leading/trailing spaces

    return toTitleCase(result)
  }

  return (
    <Stack
      sx={{ backgroundColor: theme.palette.grey[100] }}
      id="credit-safe-report"
    >
      <Stack direction="row" justifyContent="space-between">
        <img src={creditSafe} style={{ height: "48px" }} />
        {report.createdAt && (
          <Typography variant="caption">
            Updated {fToNow(report.createdAt)}
          </Typography>
        )}
      </Stack>
      <Paper sx={{ p: 4, backgroundColor: theme.palette.grey[100] }}>
        <Typography variant="h4" align="center" sx={{ mb: 2 }}>
          Credit Safe Report -{" "}
          {report?.data?.report?.companySummary?.businessName}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Stack
              direction="row"
              spacing={5}
              sx={{ m: 2 }}
              justifyContent="space-between"
            >
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        Company Information
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Business Name:
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {displayExistingValue(
                            data?.companySummary?.businessName,
                            data?.companyIdentification?.basicInformation
                              ?.businessName,
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Registered Company Name:
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.companyIdentification?.basicInformation
                              ?.registeredCompanyName
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Country:</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {displayExistingValue(
                            data?.companySummary?.country,
                            data?.companyIdentification?.basicInformation
                              ?.country,
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Company Number:
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {data?.companySummary?.companyNumber}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Registration Number:
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {displayExistingValue(
                            data?.companySummary?.companyRegistrationNumber,
                            data.companyIdentification.basicInformation
                              .companyRegistrationNumber,
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Registration Date:
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {fDateTime(
                            data?.companyIdentification?.basicInformation
                              ?.companyRegistrationDate,
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">GGS ID:</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {displayExistingValue(
                            data?.companySummary?.ggsID,
                            data?.companyIdentification.basicInformation.ggsId,
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Lei Number:</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {report?.data?.report?.companySummary?.leiNumber}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {data?.companyIdentification?.basicInformation
                      ?.vatRegistrationNumber && (
                      <TableRow>
                        <TableCell>
                          <Typography variant="caption">
                            Vat Registration Number
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {
                              data?.companyIdentification?.basicInformation
                                ?.vatRegistrationNumber
                            }
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {data?.companyIdentification?.basicInformation
                      ?.vatRegistrationDate && (
                      <TableRow>
                        <TableCell>
                          <Typography variant="caption">
                            Vat Registration Date
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {fDateTime(
                              data?.companyIdentification?.basicInformation
                                ?.vatRegistrationDate,
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {data?.companyIdentification?.basicInformation
                      ?.commercialCourt && (
                      <TableRow>
                        <TableCell>
                          <Typography variant="caption">
                            Commercial Count
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {
                              data?.companyIdentification?.basicInformation
                                ?.commercialCourt
                            }
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {data?.companyIdentification?.basicInformation
                      ?.ownershipType && (
                      <TableRow>
                        <TableCell>
                          <Typography variant="caption">
                            Ownership Type
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {
                              data?.companyIdentification?.basicInformation
                                ?.ownershipType
                            }
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Main / Principal Activity Industry Sector:
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {displayExistingValue(
                            data?.companySummary?.mainActivity.industrySector,
                            data?.companyIdentification?.basicInformation
                              ?.principalActivity?.industrySector,
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Main / Principal Activity Description:
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {displayExistingValue(
                            data?.companySummary?.mainActivity?.description,
                            data?.companyIdentification?.basicInformation
                              .principalActivity?.description,
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Main / Principal Activity Classification:
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {displayExistingValue(
                            data?.companySummary?.mainActivity?.classification,
                            data?.companyIdentification?.basicInformation
                              .principalActivity?.classification,
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Main / Principal Activity Code:
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {displayExistingValue(
                            data?.companySummary?.mainActivity?.code,
                            data?.companyIdentification?.basicInformation
                              .principalActivity?.code,
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {data?.companyIdentification?.basicInformation?.legalForm
                      ?.description && (
                      <TableRow>
                        <TableCell>
                          <Typography variant="caption">
                            Legal Form Description
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {
                              data?.companyIdentification?.basicInformation
                                ?.legalForm?.description
                            }
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {data?.companyIdentification?.basicInformation?.legalForm
                      ?.providerCode && (
                      <TableRow>
                        <TableCell>
                          <Typography variant="caption">
                            Legal Form Provider Code
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {
                              data?.companyIdentification?.basicInformation
                                ?.legalForm?.providerCode
                            }
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {data?.companyIdentification?.basicInformation?.legalForm
                      ?.commonCode && (
                      <TableRow>
                        <TableCell>
                          <Typography variant="caption">
                            Legal Form Common Code
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {
                              data?.companyIdentification?.basicInformation
                                ?.legalForm?.commonCode
                            }
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {data?.companyIdentification?.basicInformation
                      ?.officeType && (
                      <TableRow>
                        <TableCell>
                          <Typography variant="caption">Office Type</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {
                              data?.companyIdentification?.basicInformation
                                ?.officeType
                            }
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Contact Address
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {data?.companyIdentification?.basicInformation
                            ?.contactAddress?.type &&
                            data?.companyIdentification?.basicInformation
                              ?.contactAddress?.type + "- "}
                          {data?.companyIdentification?.basicInformation
                            ?.contactAddress?.houseNumber &&
                            data?.companyIdentification?.basicInformation
                              ?.contactAddress?.houseNumber + " "}
                          {data?.companyIdentification?.basicInformation
                            ?.contactAddress?.street &&
                            data?.companyIdentification?.basicInformation
                              ?.contactAddress?.street + " "}
                          {data?.companyIdentification?.basicInformation
                            ?.contactAddress?.city &&
                            data?.companyIdentification?.basicInformation
                              ?.contactAddress?.city + " "}
                          {data?.companyIdentification?.basicInformation
                            ?.contactAddress?.province &&
                            data?.companyIdentification?.basicInformation
                              ?.contactAddress?.province + " "}
                          {data?.companyIdentification?.basicInformation
                            ?.contactAddress?.region &&
                            data?.companyIdentification?.basicInformation
                              ?.contactAddress?.region + " "}
                          {data?.companyIdentification?.basicInformation
                            ?.contactAddress?.country &&
                            data?.companyIdentification?.basicInformation
                              ?.contactAddress?.country + " "}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {data?.companyIdentification?.basicInformation
                      ?.activityClassifications && (
                      <TableRow>
                        <TableCell>Activity Classifications</TableCell>
                        <TableCell>
                          {data?.companyIdentification?.basicInformation?.activityClassifications.map(
                            (item, index) => {
                              return (
                                <Typography variant="caption" key={index}>
                                  {item.classification}
                                </Typography>
                              )
                            },
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                    {data?.companyIdentification?.basicInformation
                      ?.previousNames && (
                      <TableRow>
                        <TableCell>Previous Company Names</TableCell>
                        <TableCell>
                          {data?.companyIdentification?.basicInformation?.previousNames.map(
                            (item, index) => {
                              return (
                                <Typography variant="caption" key={index}>
                                  {item?.name} updated{" "}
                                  {item?.dateChanged &&
                                    fDateTime(item?.dateChanged)}
                                </Typography>
                              )
                            },
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                    {data?.companyIdentification?.basicInformation
                      ?.previousLegalForms && (
                      <TableRow>
                        <TableCell>Previous Legal Forms</TableCell>
                        <TableCell>
                          {data?.companyIdentification?.basicInformation?.previousLegalForms.map(
                            (item) => {
                              return (
                                <>
                                  {item?.legalForm?.commonCode}{" "}
                                  {item?.legalForm?.providerCode}{" "}
                                  {item?.legalForm?.description} updated{" "}
                                  {item?.dateChanged &&
                                    fDateTime(item?.dateChanged)}
                                </>
                              )
                            },
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Grid>
          <Grid item xs={5}>
            <CreditRatingChart data={data} />
            <Paper elevation={3} sx={{ p: 2, mt: 3 }}>
              <Typography variant="h5">Company</Typography>
              <Stack direction="row" sx={{ mb: 1 }}>
                <Typography variant="body2">
                  Company Name:{" "}
                  {data?.companySummary?.businessName ||
                    data?.companyIdentification?.basicInformation?.businessName}
                </Typography>
              </Stack>
              <Stack sx={{ mb: 1 }}>
                <Typography variant="body2">
                  Company Id: {report.data.companyId}
                </Typography>
              </Stack>
            </Paper>
            <Paper elevation={3} sx={{ p: 2, mt: 3 }}>
              <Typography variant="h5">Company Status</Typography>
              <Stack direction="row" sx={{ mb: 1 }}>
                <Typography variant="body2">
                  Status:{" "}
                  {
                    data?.companyIdentification?.basicInformation?.companyStatus
                      ?.status
                  }
                </Typography>
              </Stack>
              <Stack sx={{ mb: 1 }}>
                <Typography variant="body2">
                  Status Description:{" "}
                  {
                    data?.companyIdentification?.basicInformation?.companyStatus
                      ?.description
                  }
                </Typography>
              </Stack>
            </Paper>
            <Paper elevation={3} sx={{ p: 2, mt: 3 }}>
              <Typography variant="h5">Financial Overview</Typography>
              <Stack sx={{ mb: 1 }}>
                <Typography variant="body2">
                  Latest Turnover:{" "}
                  {formatCurrency(
                    data?.companySummary?.latestTurnoverFigure?.value,
                    data?.companySummary?.latestTurnoverFigure?.currency,
                  )}
                </Typography>
              </Stack>
              <Stack sx={{ mb: 1 }}>
                <Typography variant="body2">
                  Shareholders Equity:{" "}
                  {formatCurrency(
                    data?.companySummary?.latestTurnoverFigure?.value,
                    data?.companySummary?.latestShareholdersEquityFigure
                      ?.currency,
                  )}
                </Typography>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
        {data?.creditScore?.previousCreditRating &&
          data?.creditScore?.currentCreditRating &&
          data?.creditScore?.latestRatingChangeDate && (
            <Stack>
              <Divider sx={{ m: 1, mb: 4 }} />
              <CreditRatingComparison data={data?.creditScore} />
            </Stack>
          )}
      </Paper>
      {data?.contactInformation && (
        <Stack>
          <Divider sx={{ m: 4 }} />
          <Paper sx={{ p: 2 }} elevation={3}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      Contact Information
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption">Main Address</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">
                        {displayAddress(
                          data?.contactInformation
                            ?.mainAddress as CreditSafeAddress,
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">
                        {data?.contactInformation?.mainAddress?.telephone &&
                          "+1" +
                            data?.contactInformation?.mainAddress?.telephone}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {data?.contactInformation?.otherAddresses &&
                    data?.contactInformation?.otherAddresses.map(
                      (address, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography variant="caption">
                              Other Address {index + 1}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {displayAddress(address as CreditSafeAddress)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {address?.telephone && "+1" + address?.telephone}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                  {data?.contactInformation?.previousAddresses &&
                    data?.contactInformation?.previousAddresses.map(
                      (address, index) => (
                        <TableRow>
                          <TableCell>
                            <Typography variant="caption">
                              Previous Address {index + 1}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {displayAddress(address as CreditSafeAddress)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {address?.telephone && "+1" + address?.telephone}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                  {data?.contactInformation?.emailAddresses &&
                    data?.contactInformation?.emailAddresses.length > 0 && (
                      <TableRow>
                        <TableCell>
                          <Typography variant="caption">
                            Email Addresses
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {data?.contactInformation?.emailAddresses.map(
                              (email) => email + " ",
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  {data?.contactInformation?.websites &&
                    data?.contactInformation?.websites.length > 0 && (
                      <TableRow>
                        <TableCell>
                          <Typography variant="caption">Websites</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {data?.contactInformation?.websites.map(
                              (email) => email + " ",
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Stack>
      )}
      {data?.indicators && (
        <Stack>
          <Divider sx={{ m: 4 }} />
          <Paper sx={{ p: 2 }} elevation={3}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      Indicators
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell>Changed Date</TableCell>
                    <TableCell>Industry Comparison Sector Code</TableCell>
                    <TableCell>Industry Comparison Sector Average</TableCell>
                    <TableCell>Industry Comparison Sector Count</TableCell>
                    <TableCell>Industry Comparison Total Average</TableCell>
                    <TableCell>Industry Comparison Total Count</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.indicators &&
                    data?.indicators.map((indicator, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography variant="caption">
                            {indicator.type}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {indicator.value}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {fDateTime(indicator.changeDate)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {indicator?.industryComparison?.sectorCode}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {indicator?.industryComparison?.sectorAverage}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {indicator?.industryComparison?.sectorCount}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {indicator?.industryComparison?.totalAverage}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {indicator?.industryComparison?.totalCount}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Stack>
      )}
      {data.shareCapitalStructure && (
        <Stack>
          <Divider sx={{ m: 4 }} />
          <Paper sx={{ p: 3 }} elevation={3}>
            <Stack spacing={4} direction="column">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        Share Capital Structure
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Nominal Share Capital
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {data?.shareCapitalStructure?.nominalShareCapital
                            ?.value &&
                            formatCurrency(
                              data?.shareCapitalStructure?.nominalShareCapital
                                ?.value,
                              data?.shareCapitalStructure?.nominalShareCapital
                                ?.currency,
                            )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Issued Share Capital
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {data?.shareCapitalStructure?.issuedShareCapital
                            ?.value &&
                            formatCurrency(
                              data?.shareCapitalStructure?.issuedShareCapital
                                ?.value,
                              data?.shareCapitalStructure?.issuedShareCapital
                                ?.currency,
                            )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Share Capital Currency
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {data?.shareCapitalStructure?.shareCapitalCurrency}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Number Of Shared Issued
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {data?.shareCapitalStructure?.numberOfSharesIssued}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer sx={{ m: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={13} align="center">
                        ShareHolders
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Address</TableCell>
                      <TableCell>Shareholder Type</TableCell>
                      <TableCell>Share Type</TableCell>
                      <TableCell>Currency</TableCell>
                      <TableCell>Total Value Of Shares Owned</TableCell>
                      <TableCell>Total Number Of Shares Owned</TableCell>
                      <TableCell>Percent Shares Held</TableCell>
                      <TableCell>Start Date</TableCell>
                      <TableCell>End Date</TableCell>
                      <TableCell>Has Negative Info</TableCell>
                      <TableCell>Share Classes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(!data?.shareCapitalStructure?.shareHolders ||
                      data?.shareCapitalStructure?.shareHolders.length ===
                        0) && (
                      <TableRow>
                        <TableCell colSpan={13}>
                          <Typography variant="caption">
                            No Information Found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {data?.shareCapitalStructure?.shareHolders &&
                      data?.shareCapitalStructure?.shareHolders.map(
                        (shareholder, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Typography variant="caption">
                                {shareholder?.name}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {shareholder?.title}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {displayAddress(shareholder?.address)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {shareholder?.shareholderType}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {shareholder?.shareType}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {shareholder?.currency}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {shareholder?.totalValueOfSharesOwned}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {shareholder?.totalNumberOfSharesOwned}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {shareholder?.percentSharesHeld &&
                                  shareholder?.percentSharesHeld + "%"}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {shareholder?.startDate}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {shareholder?.endDate}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {shareholder?.hasNegativeInfo}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {shareholder?.shareClasses &&
                                  shareholder?.shareClasses.length > 0 &&
                                  shareholder?.shareClasses.map(
                                    (shareClass) => (
                                      <>
                                        Share Type: {shareClass?.shareType},
                                        Value Per Share:{" "}
                                        {formatCurrency(
                                          shareClass?.valuePerShare,
                                          shareClass?.currency,
                                        )}
                                        , Share Number:{" "}
                                        {shareClass?.numberOfSharesOwned}, Value
                                        Of Share Owned
                                        {formatCurrency(
                                          shareClass?.valueOfSharesOwned,
                                          shareClass?.currency,
                                        )}
                                      </>
                                    ),
                                  )}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ),
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Paper>
        </Stack>
      )}
      <Stack>
        <Divider sx={{ m: 1, mb: 4 }} />
        <Paper sx={{ p: 2 }} elevation={3}>
          <Accordion
            defaultExpanded={Boolean(
              data?.directors?.currentDirectors &&
                data?.directors?.currentDirectors.length > 0,
            )}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
            >
              <Typography variant="h6">Current Directors</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Positions</TableCell>
                      <TableCell>Date of Birth</TableCell>
                      <TableCell>Nationality</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Tags</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(!data?.directors?.currentDirectors ||
                      data?.directors?.currentDirectors.length === 0) && (
                      <TableRow>
                        <TableCell colSpan={8}>
                          <Typography variant="caption">
                            No Information Found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {data?.directors?.currentDirectors?.map((director) => (
                      <TableRow key={director?.id}>
                        <TableCell>
                          <Typography variant="caption">
                            {director?.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {director?.directorType}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {director?.title}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {director?.positions.map(
                              (position) => position?.positionName + " ",
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {fDateTime(director?.dateOfBirth)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {director?.nationality}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {" "}
                            {director?.address?.type &&
                              director?.address?.type + "- "}
                            {director?.address?.houseNumber &&
                              director?.address?.houseNumber + " "}
                            {director?.address?.street &&
                              director?.address?.street + " "}
                            {director?.address?.city &&
                              director?.address?.city + " "}
                            {director?.address?.province &&
                              director?.address?.province + " "}
                            {director?.address?.region &&
                              director?.address?.region + " "}
                            {director?.address?.country &&
                              director?.address?.country + " "}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {director?.hasNegativeInfo === true && (
                            <Chip
                              label="Negative Info"
                              sx={{
                                m: 1,
                                backgroundColor: theme.palette.error.main,
                                color: theme.palette.common.white,
                              }}
                            />
                          )}
                          {director?.signingAuthority === true && (
                            <Chip
                              label="Signing Authority"
                              sx={{
                                m: 1,
                                backgroundColor: theme.palette.success.dark,
                                color: theme.palette.common.white,
                              }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
          <Accordion
            defaultExpanded={Boolean(
              data?.directors?.previousDirectors &&
                data?.directors?.previousDirectors.length > 1,
            )}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography variant="h6">Previous Directors</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Positions</TableCell>
                      <TableCell>Date of Birth</TableCell>
                      <TableCell>Nationality</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Tags</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(!data?.directors?.previousDirectors ||
                      data?.directors?.previousDirectors.length === 0) && (
                      <TableRow>
                        <TableCell colSpan={8} align="center">
                          <Typography variant="caption">
                            No Information Found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {data?.directors?.previousDirectors?.map((director) => (
                      <TableRow key={director.id}>
                        <TableCell>
                          <Typography variant="caption">
                            {director?.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {director?.directorType}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {director?.title}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {director?.positions.map(
                              (position) => position?.positionName + " ",
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {fDateTime(director?.dateOfBirth)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {director?.nationality}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {" "}
                            {director?.address?.type &&
                              director?.address?.type + "- "}
                            {director?.address?.houseNumber &&
                              director?.address?.houseNumber + " "}
                            {director?.address?.street &&
                              director?.address?.street + " "}
                            {director?.address?.city &&
                              director?.address?.city + " "}
                            {director?.address?.province &&
                              director?.address?.province + " "}
                            {director?.address?.region &&
                              director?.address?.region + " "}
                            {director?.address?.country &&
                              director?.address?.country + " "}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {director.hasNegativeInfo === true && (
                            <Chip
                              label="Negative Info"
                              sx={{
                                m: 1,
                                backgroundColor: theme.palette.error.main,
                                color: theme.palette.common.white,
                              }}
                            />
                          )}
                          {director?.signingAuthority === true && (
                            <Chip
                              label="Signing Authority"
                              sx={{
                                m: 1,
                                backgroundColor: theme.palette.success.dark,
                                color: theme.palette.common.white,
                              }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </Paper>
      </Stack>
      <Stack>
        <Divider sx={{ m: 1, mb: 4 }} />
        <Paper sx={{ p: 2 }} elevation={3}>
          <Accordion
            defaultExpanded={Boolean(
              data?.directorships?.currentDirectorships &&
                data?.directorships?.currentDirectorships.length > 0,
            )}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
            >
              <Typography variant="h6">Current Directorship</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Position</TableCell>
                      <TableCell>Registration Number</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell>Date Appointment</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(!data?.directorships?.currentDirectorships ||
                      data?.directorships?.currentDirectorships.length ===
                        0) && (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          <Typography variant="caption">
                            No Information Found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {data?.directorships?.currentDirectorships?.map(
                      (directorship) => (
                        <TableRow key={directorship?.id}>
                          <TableCell>
                            <Typography variant="caption">
                              {directorship?.name}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {directorship?.position?.positionName}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {directorship?.registrationNumber}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {directorship?.status.status}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {directorship?.companyName}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {fDateTime(directorship?.position?.dateAppointed)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {directorship?.status?.status}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
          <Accordion
            defaultExpanded={Boolean(
              data?.directorships?.previousDirectorships &&
                data?.directorships?.previousDirectorships.length > 1,
            )}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography variant="h6">Previous Directors</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Position</TableCell>
                      <TableCell>Registration Number</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell>Date Appointment</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(!data?.directorships?.previousDirectorships ||
                      data?.directorships?.previousDirectorships.length ===
                        0) && (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          <Typography variant="caption">
                            No Information Found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {data?.directorships?.previousDirectorships?.map(
                      (directorship) => (
                        <TableRow key={directorship.id}>
                          <TableCell>
                            <Typography variant="caption">
                              {directorship?.name}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {directorship?.position?.positionName}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {directorship?.registrationNumber}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {directorship?.status?.status}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {directorship?.companyName}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {fDateTime(directorship?.position?.dateAppointed)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {directorship?.status?.status}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </Paper>
      </Stack>
      {data?.groupStructure && (
        <Stack>
          <Divider sx={{ m: 4 }} />
          <Paper sx={{ p: 3 }} elevation={3}>
            <Stack spacing={4} direction="column">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        Company Group Structure Ultimate Parent
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">ID</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {data?.groupStructure?.ultimateParent?.id}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Id Type</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {data?.groupStructure?.ultimateParent?.idType}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Type</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {data?.groupStructure?.ultimateParent?.type}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Office Type</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {data?.groupStructure?.ultimateParent?.officeType}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Name</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {data?.groupStructure?.ultimateParent?.name}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Country</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {data?.groupStructure?.ultimateParent?.country}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Registration Number
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.groupStructure?.ultimateParent
                              ?.registrationNumber
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Safe No</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {data?.groupStructure?.ultimateParent?.safeNo}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Vat Number</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {data?.groupStructure?.ultimateParent?.vatNo}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Address</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {displayAddress(
                            data?.groupStructure?.ultimateParent?.address,
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">is PLC</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.groupStructure?.ultimateParent
                              ?.additionalInformation?.isPLC
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Coeffient Linear
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.groupStructure?.ultimateParent
                              ?.additionalInformation?.coefficientLinear
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Legal Form</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {data?.groupStructure?.ultimateParent?.legalForm}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Activity Code</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {data?.groupStructure?.ultimateParent?.activity.code}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Activity Industry Sector
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.groupStructure?.ultimateParent?.activity
                              ?.industrySector
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Activity Description
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.groupStructure?.ultimateParent?.activity
                              ?.description
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Activity Classification
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.groupStructure?.ultimateParent?.activity
                              ?.classification
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Status</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {data?.groupStructure?.ultimateParent?.status}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        Company Group Structure immediate Parent
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">ID</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {data?.groupStructure?.immediateParent?.id}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Id Type</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {data?.groupStructure?.immediateParent?.idType}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Type</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {data?.groupStructure?.immediateParent?.type}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Office Type</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {data?.groupStructure?.immediateParent?.officeType}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Name</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {data?.groupStructure?.immediateParent?.name}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Country</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {data?.groupStructure?.immediateParent?.country}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Registration Number
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.groupStructure?.immediateParent
                              ?.registrationNumber
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Safe No</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {data?.groupStructure?.immediateParent?.safeNo}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Vat Number</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {data?.groupStructure?.immediateParent?.vatNo}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Address</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {displayAddress(
                            data?.groupStructure?.immediateParent?.address,
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">is PLC</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.groupStructure?.immediateParent
                              ?.additionalInformation?.isPLC
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Coeffient Linear
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.groupStructure?.immediateParent
                              ?.additionalInformation?.coefficientLinear
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Legal Form</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {data?.groupStructure?.immediateParent?.legalForm}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Activity Code</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.groupStructure?.immediateParent?.activity
                              ?.code
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Activity Industry Sector
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.groupStructure?.immediateParent?.activity
                              ?.industrySector
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Activity Description
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.groupStructure?.immediateParent?.activity
                              ?.description
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Activity Classification
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.groupStructure?.immediateParent?.activity
                              ?.classification
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Status</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {data?.groupStructure?.immediateParent?.status}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
            <Stack spacing={4} direction="column">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={18} align="center">
                        Subsidiary Companies
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>ID Type</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Country</TableCell>
                      <TableCell>Registration Number</TableCell>
                      <TableCell>Safe No</TableCell>
                      <TableCell>Office Type</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Vat No</TableCell>
                      <TableCell>Is PLC</TableCell>
                      <TableCell>Coefficient Linear</TableCell>
                      <TableCell>Address</TableCell>
                      <TableCell>Code</TableCell>
                      <TableCell>Industry Sector</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Classification</TableCell>
                      <TableCell>Legal Form</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(!data?.groupStructure?.subsidiaryCompanies ||
                      data?.groupStructure?.subsidiaryCompanies.length ===
                        0) && (
                      <TableRow>
                        <TableCell colSpan={18} align="center">
                          <Typography variant="caption">
                            No Information Found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {data?.groupStructure?.subsidiaryCompanies &&
                      data?.groupStructure?.subsidiaryCompanies.map(
                        (subsidiary) => (
                          <TableRow>
                            <TableCell>
                              <Typography variant="caption">
                                {subsidiary?.id}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {subsidiary?.idType}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {subsidiary?.name}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {subsidiary?.type}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {subsidiary?.country}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {subsidiary?.registrationNumber}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {subsidiary?.safeNo}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {subsidiary?.officeType}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {subsidiary?.status}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {subsidiary?.vatNo}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {subsidiary.additionalInformation?.isPLC}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {
                                  subsidiary.additionalInformation
                                    ?.coefficientLinear
                                }
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {displayAddress(subsidiary?.address)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {subsidiary?.activity?.code}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {subsidiary?.activity?.industrySector}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {subsidiary?.activity?.description}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {subsidiary?.activity?.classification}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {subsidiary?.legalForm}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ),
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={18} align="center">
                        Subsidiary Companies
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>ID Type</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Country</TableCell>
                      <TableCell>Registration Number</TableCell>
                      <TableCell>Safe No</TableCell>
                      <TableCell>Office Type</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Vat No</TableCell>
                      <TableCell>Is PLC</TableCell>
                      <TableCell>Coefficient Linear</TableCell>
                      <TableCell>Address</TableCell>
                      <TableCell>Code</TableCell>
                      <TableCell>Industry Sector</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Classification</TableCell>
                      <TableCell>Legal Form</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(!data?.groupStructure?.subsidiaryCompanies ||
                      data?.groupStructure?.subsidiaryCompanies.length ===
                        0) && (
                      <TableRow>
                        <TableCell colSpan={18} align="center">
                          <Typography variant="caption">
                            No Information Found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {data?.groupStructure?.affiliatedCompanies &&
                      data?.groupStructure?.affiliatedCompanies.map(
                        (affiliatedCompany, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Typography variant="caption">
                                {affiliatedCompany?.id}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {affiliatedCompany?.idType}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {affiliatedCompany?.name}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {affiliatedCompany?.type}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {affiliatedCompany?.country}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {affiliatedCompany?.registrationNumber}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {affiliatedCompany?.safeNo}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {affiliatedCompany?.officeType}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {affiliatedCompany?.status}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {affiliatedCompany?.vatNo}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {
                                  affiliatedCompany?.additionalInformation
                                    ?.isPLC
                                }
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {
                                  affiliatedCompany?.additionalInformation
                                    ?.coefficientLinear
                                }
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {displayAddress(affiliatedCompany?.address)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {affiliatedCompany?.activity?.code}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {affiliatedCompany?.activity?.industrySector}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {affiliatedCompany?.activity?.description}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {affiliatedCompany?.activity?.classification}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {affiliatedCompany?.legalForm}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ),
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Paper>
        </Stack>
      )}

      {data?.extendedGroupStructure &&
        data?.extendedGroupStructure?.length > 0 && (
          <Stack>
            <Divider sx={{ m: 4 }} />
            <Paper sx={{ p: 3 }} elevation={3}>
              <Stack spacing={4} direction="column">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={11} align="center">
                          Extended Group Structure
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Company Name</TableCell>
                        <TableCell>Id Type</TableCell>
                        <TableCell>Registered Number</TableCell>
                        <TableCell>Lastest Annual Account</TableCell>
                        <TableCell>Level</TableCell>
                        <TableCell>Percentage Of Ownership</TableCell>
                        <TableCell>Safe Number</TableCell>
                        <TableCell>Common Rating Band</TableCell>
                        <TableCell>Latest Annual Accounts</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.extendedGroupStructure &&
                        data?.extendedGroupStructure?.length > 0 &&
                        data?.extendedGroupStructure.map((structure, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Typography variant="caption">
                                {structure.id}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {" "}
                              <Typography variant="caption">
                                {structure?.companyName}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {" "}
                              <Typography variant="caption">
                                {structure?.idType}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {structure?.registeredNumber}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {fDateTime(structure?.latestAnnualAccounts)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {structure?.level}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {structure?.percentOfOwnership}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {structure?.safeNumber}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {structure?.commonRatingBand}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {structure?.status}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </Paper>
          </Stack>
        )}
      <Stack spacing={4} direction="column">
        {data?.financialStatements &&
          data?.financialStatements.length > 0 &&
          data?.financialStatements.map((financialStatement, index) => (
            <FinancialStatements
              key={index}
              data={financialStatement}
              title={"Financial Statement" + (index + 1)}
            />
          ))}
      </Stack>
      <Stack spacing={4} direction="column">
        {data?.localFinancialStatements &&
          data?.localFinancialStatements.length > 0 &&
          data?.localFinancialStatements.map(
            (localFinancialStatement, index) => (
              <FinancialStatements
                key={index}
                data={localFinancialStatement}
                title={"Local Financial Statement" + (index + 1)}
              />
            ),
          )}
      </Stack>
      {data?.otherInformation && (
        <Stack>
          <Divider sx={{ m: 4 }} />
          <Paper sx={{ p: 3 }} elevation={3}>
            <Stack spacing={2} direction="row">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        Bankers
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Address</TableCell>
                      <TableCell>Bank Code</TableCell>
                      <TableCell>BIC</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(!data?.otherInformation?.bankers ||
                      data?.otherInformation?.bankers.length === 0) && (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          <Typography variant="caption">
                            No Information Found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {data?.otherInformation?.bankers &&
                      data?.otherInformation?.bankers.map((banker, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography variant="caption">
                              {banker?.name}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {displayAddress(banker?.address)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {banker?.bankCode}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {banker?.bic}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        Advisors
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Auditor Name</TableCell>
                      <TableCell>Solicitor Name</TableCell>
                      <TableCell>Account Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(!data?.otherInformation?.advisors ||
                      data?.otherInformation?.advisors.length === 0) && (
                      <TableRow>
                        <TableCell colSpan={3} align="center">
                          <Typography variant="caption">
                            No Information Found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {data?.otherInformation?.advisors &&
                      data?.otherInformation?.advisors.map((advisor, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography variant="caption">
                              {advisor?.auditorName}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {advisor?.solicitorName}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {advisor?.accountantName}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        Employees Information
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Number of Employees</TableCell>
                      <TableCell>Year</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(!data?.otherInformation?.employeesInformation ||
                      data?.otherInformation?.employeesInformation.length ===
                        0) && (
                      <TableRow>
                        <TableCell colSpan={2} align="center">
                          <Typography variant="caption">
                            No Information Found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {data?.otherInformation?.employeesInformation &&
                      data?.otherInformation?.employeesInformation.map(
                        (info, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Typography variant="caption">
                                {info?.numberOfEmployees}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {info?.year}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ),
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Paper>
        </Stack>
      )}
      {data?.paymentData?.dbtHistory &&
        data?.paymentData?.dbtHistory.length > 0 && (
          <Stack>
            <Divider sx={{ m: 4 }} />
            <CreditDBTHistory data={data.paymentData} />
          </Stack>
        )}
      {data?.paymentData?.dbtTrends &&
        data?.paymentData?.dbtTrends.length > 0 && (
          <Stack>
            <Divider sx={{ m: 4 }} />
            <CreditDBTTrend data={data.paymentData} />
          </Stack>
        )}
      <Divider sx={{ m: 4 }} />
      {data?.negativeInformation && (
        <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
          <Stack direction="row" spacing={2} sx={{ p: 2 }}>
            {data?.negativeInformation?.legalFilingBranchSummary && (
              <TableContainer sx={{ mt: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        Legal Filing Branch Summary
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Bankruptcy</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.negativeInformation?.legalFilingBranchSummary
                              ?.bankruptcy
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Number Of Cautionary UCC Filings
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.negativeInformation?.legalFilingBranchSummary
                              ?.cautionaryUCCFilings
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Number Of Judgement Filings
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.negativeInformation?.legalFilingBranchSummary
                              ?.judgmentFilings
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Number Of Suits
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.negativeInformation?.legalFilingBranchSummary
                              ?.suits
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Sum</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {formatCurrency(
                            data?.negativeInformation?.legalFilingBranchSummary
                              ?.sum.value,
                            data?.negativeInformation?.legalFilingBranchSummary
                              ?.sum.currency,
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Number Of Tax Lien Filings
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.negativeInformation?.legalFilingBranchSummary
                              ?.taxLienFilings
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Number Of UCC Filings
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.negativeInformation?.legalFilingBranchSummary
                              ?.uccFilings
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {data?.negativeInformation?.legalFilingGroupSummary && (
              <TableContainer sx={{ mt: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        Legal Filing Group Summary
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Bankruptcy</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.negativeInformation?.legalFilingGroupSummary
                              ?.bankruptcy
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Number Of Cautionary UCC Filings
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.negativeInformation?.legalFilingGroupSummary
                              ?.cautionaryUCCFilings
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Number Of Judgement Filings
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.negativeInformation?.legalFilingGroupSummary
                              ?.judgmentFilings
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Number Of Suits
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.negativeInformation?.legalFilingGroupSummary
                              ?.suits
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Sum</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {formatCurrency(
                            data?.negativeInformation?.legalFilingGroupSummary
                              ?.sum?.value,
                            data?.negativeInformation?.legalFilingGroupSummary
                              ?.sum?.currency,
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Number Of Tax Lien Filings
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.negativeInformation?.legalFilingGroupSummary
                              ?.taxLienFilings
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Number Of UCC Filings
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.negativeInformation?.legalFilingGroupSummary
                              ?.uccFilings
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {data?.negativeInformation?.legalFilingSummary && (
              <TableContainer sx={{ mt: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>Legal Filing Summary</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">Bankruptcy</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.negativeInformation?.legalFilingSummary
                              ?.bankruptcy
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Number Of Cautionary UCC Filings
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.negativeInformation?.legalFilingSummary
                              ?.cautionaryUCCFilings
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Number Of Judgement Filings
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.negativeInformation?.legalFilingSummary
                              ?.judgmentFilings
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Number Of Suits
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {data?.negativeInformation?.legalFilingSummary?.suits}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          <Typography variant="caption">Sum</Typography>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {formatCurrency(
                            data?.negativeInformation?.legalFilingSummary?.sum
                              ?.value,
                            data?.negativeInformation?.legalFilingSummary.sum
                              ?.currency,
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Number Of Tax Lien Filings
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.negativeInformation?.legalFilingSummary
                              ?.taxLienFilings
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          Number Of UCC Filings
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">
                          {
                            data?.negativeInformation?.legalFilingSummary
                              ?.uccFilings
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Stack>
        </Paper>
      )}
      <Divider sx={{ m: 4 }} />
      {data?.negativeInformation?.mostRecentLegalFilings &&
        data?.negativeInformation?.mostRecentLegalFilings.length > 0 && (
          <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
            <Stack direction="row" spacing={2} sx={{ p: 2 }}>
              <TableContainer sx={{ mt: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        Most Recent Legal Filings
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Case</TableCell>
                      <TableCell>Filed Date</TableCell>
                      <TableCell>Legal Filings Type</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.negativeInformation?.mostRecentLegalFilings.map(
                      (item, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography variant="caption">
                              {item?.case ? item?.case : item?.caseFilingNumber}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {item?.filedDate
                                ? fDateTime(item?.filedDate)
                                : fDateTime(item?.dateFiled)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {item?.legalFilingType
                                ? item?.legalFilingType
                                : item?.filingType}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {item?.status}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Paper>
        )}
      <Divider sx={{ m: 4 }} />
      {data?.negativeInformation?.uccDetails &&
        data?.negativeInformation?.uccDetails.length > 0 && (
          <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
            <Stack direction="row" spacing={2} sx={{ p: 2 }}>
              <TableContainer sx={{ mt: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={11} align="center">
                        UCC Details
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Debtor Name</TableCell>
                      <TableCell>Debtor Address</TableCell>
                      <TableCell>Filing Number</TableCell>
                      <TableCell>Filing Office</TableCell>
                      <TableCell>Filing Type</TableCell>
                      <TableCell>Filed Date</TableCell>
                      <TableCell>Jurisdiction</TableCell>
                      <TableCell>Related Document Number</TableCell>
                      <TableCell>Secured Party Name</TableCell>
                      <TableCell>Secured Address</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.negativeInformation?.uccDetails.map(
                      (item, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography variant="caption">
                              {item?.debtorName}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {displayAddress(
                                item?.debtorAddress as CreditSafeAddress,
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {item?.filingNumber}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {item?.filingOffice}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {item?.filingType}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {fDateTime(item?.filedDate)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {item?.jurisdiction}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {item?.relatedDocumentNumber}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {item?.securedPartyName}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {displayAddress(
                                item?.securedPartyAddress as CreditSafeAddress,
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {item?.status}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Paper>
        )}
      <Divider sx={{ m: 4 }} />
      {data?.negativeInformation?.derogatorySummaries &&
        data?.negativeInformation?.derogatorySummaries.length > 0 && (
          <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
            <Stack direction="row" spacing={2} sx={{ p: 2 }}>
              <TableContainer sx={{ mt: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        Derogatory Summaries
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Number On File</TableCell>
                      <TableCell>Comments On File</TableCell>
                      <TableCell>Date Of Last Item</TableCell>
                      <TableCell>Derogatory Type</TableCell>
                      <TableCell>Total Amount On File</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.negativeInformation?.derogatorySummaries.map(
                      (item, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography variant="caption">
                              {item?.numberOnFile}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {item?.commentsOnFile}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {fDateTime(item?.dateOfLastItem)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {item?.derogatoryType}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {formatCurrency(
                                item?.totalAmountOnFile,
                                item?.currency,
                              )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Paper>
        )}
      {data?.additionalInformation && (
        <Stack>
          <Divider sx={{ m: 4 }} />
          <Paper sx={{ p: 3 }} elevation={3}>
            {(data?.additionalInformation.misc ||
              data?.additionalInformation.commentaries) && (
              <Stack spacing={4} direction="row" sx={{ mb: 2 }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2} align="center">
                          Additional Information MISC
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(
                        data?.additionalInformation?.misc || {},
                      ).map(([key, value]) => (
                        <TableRow key={key}>
                          <TableCell>
                            <Typography variant="caption">
                              {toSpaceCaseWithNumbers(key)}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography variant="caption">
                              {key === "establishmentDate"
                                ? fDateTime(value)
                                : value.toLocaleString()}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {data?.additionalInformation?.commentaries && (
                  <Stack spacing={4} direction="column" sx={{ p: 2 }}>
                    <Typography variant="h6" align="center">
                      Commentary
                    </Typography>
                    {data?.additionalInformation?.commentaries &&
                      data?.additionalInformation?.commentaries.map(
                        (commentary, index) => {
                          let severity = "info"
                          if (commentary?.positiveOrNegative === "Positive") {
                            severity = "success"
                          }
                          if (commentary?.positiveOrNegative === "Negative") {
                            severity = "success"
                          }
                          return (
                            <Alert
                              key={index}
                              severity={severity as AlertColor}
                              sx={{ minWidth: 400 }}
                            >
                              <Typography variant="caption">
                                {commentary?.commentaryText}
                              </Typography>
                            </Alert>
                          )
                        },
                      )}
                    {(!data?.additionalInformation ||
                      data?.additionalInformation?.commentaries?.length ===
                        0) && (
                      <Typography variant="caption">
                        No Information Found
                      </Typography>
                    )}
                  </Stack>
                )}
              </Stack>
            )}
            {data?.additionalInformation?.ratingHistory && (
              <Stack spacing={4} direction="column" sx={{ mb: 2 }}>
                <CreditRatingHistory
                  data={data?.additionalInformation?.ratingHistory}
                />
              </Stack>
            )}
            {(data?.additionalInformation?.enquiriesTrend ||
              data?.additionalInformation?.inquiriesTrend ||
              data?.additionalInformation?.ratingCommentary) && (
              <Stack spacing={4} direction="row" sx={{ mb: 2 }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2} align="center">
                          Enquires Trend
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.additionalInformation?.enquiriesTrend && (
                        <>
                          <TableRow>
                            <TableCell>
                              <Typography variant="caption">Month 1</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {
                                  data?.additionalInformation?.enquiriesTrend
                                    ?.month1
                                }
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="caption">Month 2</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {
                                  data?.additionalInformation?.enquiriesTrend
                                    ?.month2
                                }
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="caption">Month 3</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {
                                  data?.additionalInformation?.enquiriesTrend
                                    ?.month3
                                }
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="caption">Month 4</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {
                                  data?.additionalInformation?.enquiriesTrend
                                    ?.month4
                                }
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="caption">Month 5</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {
                                  data?.additionalInformation?.enquiriesTrend
                                    ?.month5
                                }
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="caption">Month 6</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {
                                  data?.additionalInformation?.enquiriesTrend
                                    ?.month6
                                }
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="caption">Month 7</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {
                                  data?.additionalInformation?.enquiriesTrend
                                    ?.month7
                                }
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="caption">Month 8</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {
                                  data?.additionalInformation?.enquiriesTrend
                                    ?.month8
                                }
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="caption">Month 9</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {
                                  data?.additionalInformation?.enquiriesTrend
                                    ?.month9
                                }
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="caption">
                                Month 10
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {
                                  data?.additionalInformation?.enquiriesTrend
                                    ?.month10
                                }
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="caption">
                                Month 11
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {
                                  data?.additionalInformation?.enquiriesTrend
                                    ?.month11
                                }
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="caption">
                                Month 1 to 3
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {
                                  data?.additionalInformation?.enquiriesTrend
                                    ?.months1to3
                                }
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="caption">
                                Month 4 to 6
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {
                                  data?.additionalInformation?.enquiriesTrend
                                    ?.months4to6
                                }
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="caption">
                                Month 7 to 9
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {
                                  data?.additionalInformation?.enquiriesTrend
                                    ?.months7to9
                                }
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="caption">
                                Month 10 to 12
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {
                                  data?.additionalInformation?.enquiriesTrend
                                    ?.months10to12
                                }
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2} align="center">
                          Inquiries Trend
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.additionalInformation?.inquiriesTrend &&
                        Object.entries(
                          data?.additionalInformation?.inquiriesTrend,
                        ).map(([key, value]) => (
                          <TableRow key={key}>
                            <TableCell>
                              <Typography variant="caption">
                                {toSpaceCaseWithNumbers(key)}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography variant="caption">
                                {key === "latestEnquiryDate"
                                  ? fDateTime(value)
                                  : value.toLocaleString()}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2} align="center">
                          Rating Commentary
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.additionalInformation?.ratingCommentary &&
                        Object.entries(
                          data?.additionalInformation?.ratingCommentary,
                        ).map(([key, value]) => (
                          <TableRow key={key}>
                            <TableCell>
                              <Typography variant="caption">
                                {toSpaceCaseWithNumbers(key)}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography variant="caption">
                                {value.toLocaleString()}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            )}
            {data?.additionalInformation?.ratingPercentiles && (
              <Stack spacing={4} direction="row" sx={{ m: 2, mt: 4 }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2} align="center">
                          Rating Percentiles US Percentile
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.additionalInformation?.ratingPercentiles
                        ?.industryPercentile &&
                        Object.entries(
                          data?.additionalInformation?.ratingPercentiles
                            ?.industryPercentile,
                        ).map(([key, value]) => (
                          <TableRow key={key}>
                            <TableCell>
                              <Typography variant="caption">
                                {toSpaceCaseWithNumbers(key)}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography variant="caption">
                                {key === "generatedDate"
                                  ? fDateTime(value)
                                  : value.toLocaleString()}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2} align="center">
                          Rating Percentiles industry Percentile
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.additionalInformation?.ratingPercentiles
                        ?.usPercentile &&
                        Object.entries(
                          data?.additionalInformation?.ratingPercentiles
                            ?.usPercentile,
                        ).map(([key, value]) => (
                          <TableRow key={key}>
                            <TableCell>
                              <Typography variant="caption">
                                {toSpaceCaseWithNumbers(key)}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography variant="caption">
                                {key === "generatedDate"
                                  ? fDateTime(value)
                                  : value.toLocaleString()}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            )}
          </Paper>
        </Stack>
      )}

      {data?.paymentData?.tradePaymentInformation && (
        <Stack spacing={4} direction="row" sx={{ mb: 2 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    Trade Payment By Credit Extended
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.paymentData?.tradePaymentInformation &&
                  Object.entries(
                    data?.paymentData?.tradePaymentInformation,
                  ).map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell>
                        <Typography variant="caption">
                          {toSpaceCaseWithNumbers(key)}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="caption">
                          {key === "generatedDate"
                            ? fDateTime(value)
                            : value.toLocaleString()}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      )}
      {data?.paymentData?.tradePaymentDashboard && (
        <Stack spacing={4} direction="row" sx={{ mb: 2 }}>
          <Divider sx={{ m: 4 }} />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    Trade Payment Dashboard
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.paymentData?.tradePaymentDashboard &&
                  Object.entries(data?.paymentData?.tradePaymentDashboard).map(
                    ([key, value]) => (
                      <TableRow key={key}>
                        <TableCell>
                          <Typography variant="caption">
                            {toSpaceCaseWithNumbers(key)}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="caption">
                            {key === "lastUpdated"
                              ? fDateTime(value)
                              : value.toLocaleString()}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ),
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      )}
      {data?.paymentData?.creditReferenceSummaries && (
        <Stack spacing={4} direction="row" sx={{ mb: 2 }}>
          <Divider sx={{ m: 4 }} />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    Credit Reference Summaries
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="caption">
                      Number of Credit References
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">Payment Index</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">Description</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">Period 1</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">Period 2</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">Period 3</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.paymentData?.creditReferenceSummaries &&
                  data?.paymentData?.creditReferenceSummaries.map(
                    (item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography variant="caption">
                            {item?.numberofCreditReferences}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="caption">
                            {item?.paymentIndex}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="caption">
                            {item?.description}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="caption">
                            {item?.period1}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="caption">
                            {item?.period2}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="caption">
                            {item?.period3}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ),
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      )}
      {data?.paymentData?.historicTradeInformation && (
        <Stack spacing={4} direction="row" sx={{ mb: 2 }}>
          <Divider sx={{ m: 4 }} />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    Historic Trade Information
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="caption">Past Due</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">Period 1</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">Period 2</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">Period 3</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">
                      Serverely Past Due
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">Month</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">Current</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">Total</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.paymentData?.historicTradeInformation &&
                  data?.paymentData?.historicTradeInformation.map(
                    (item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography variant="caption">
                            {item?.pastDue}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="caption">
                            {item?.period1}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="caption">
                            {item?.period2}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="caption">
                            {item?.period3}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="caption">
                            {item?.severelyPastDue}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="caption">
                            {item?.month}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="caption">
                            {formatCurrency(item?.current, item?.currency)}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="caption">
                            {formatCurrency(item?.total, item?.currency)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ),
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      )}

      {data?.paymentData?.tradePaymentsByCreditExtended && (
        <Stack spacing={4} direction="row" sx={{ mb: 2 }}>
          <Divider sx={{ m: 4 }} />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    Trade Payments By Credit Extended
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="caption">Past Due</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">Within Terms</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">Trade Lines</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">
                      Credit Extended Range
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">Total Amount</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.paymentData?.tradePaymentsByCreditExtended &&
                  data?.paymentData?.tradePaymentsByCreditExtended.map(
                    (item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography variant="caption">
                            {item?.pastDue}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="caption">
                            {item?.withinTerms}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="caption">
                            {item?.tradeLines}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="caption">
                            {item?.creditExtendedRange}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="caption">
                            {item?.totalAmount &&
                              formatCurrency(item?.totalAmount, item?.currency)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ),
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      )}
      {data?.paymentData?.outstandingByHighCreditUtilization && (
        <Stack spacing={4} direction="row" sx={{ mb: 2 }}>
          <Divider sx={{ m: 4 }} />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    Outstanding By High Credit Utilization
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="caption">
                      Credit Utilization Band
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">Balance</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">High Credit</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.paymentData?.outstandingByHighCreditUtilization &&
                  data?.paymentData?.outstandingByHighCreditUtilization.map(
                    (item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography variant="caption">
                            {item?.creditUtilizationBand}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="caption">
                            {item?.balance &&
                              formatCurrency(item?.balance, item?.currency)}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="caption">
                            {item?.highCredit &&
                              formatCurrency(item?.highCredit, item?.currency)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ),
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      )}

      {data?.additionalInformation?.extendedPaymentData && (
        <Stack>
          <Divider sx={{ m: 4 }} />
          <Paper sx={{ p: 3 }} elevation={3}>
            <Typography variant="h6" align="center" sx={{ mb: 2 }}>
              Extended Payments
            </Typography>
            {data?.additionalInformation?.extendedPaymentData?.paidInvoices && (
              <Stack spacing={4} direction="row" sx={{ mb: 2 }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2} align="center">
                          Paid Invoices History
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.additionalInformation?.extendedPaymentData
                        ?.paidInvoices?.paidInvoicesHistory &&
                        Object.entries(
                          data?.additionalInformation?.extendedPaymentData
                            ?.paidInvoices?.paidInvoicesHistory,
                        ).map(([key, value]) => (
                          <TableRow key={key}>
                            <TableCell>
                              <Typography variant="caption">
                                {toSpaceCaseWithNumbers(key)}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography variant="caption">
                                {value.toLocaleString()}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2} align="center">
                          Paid Invoices Summary
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.additionalInformation?.extendedPaymentData
                        ?.paidInvoices?.paidInvoicesSummary &&
                        Object.entries(
                          data?.additionalInformation?.extendedPaymentData
                            ?.paidInvoices?.paidInvoicesSummary,
                        ).map(([key, value]) => (
                          <TableRow key={key}>
                            <TableCell>
                              <Typography variant="caption">
                                {toSpaceCaseWithNumbers(key)}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography variant="caption">
                                {value.toLocaleString()}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            )}
            {(data?.additionalInformation?.extendedPaymentData?.miniDashBoard ||
              data?.additionalInformation?.extendedPaymentData
                ?.pastDueBalances) && (
              <Stack spacing={4} direction="row" sx={{ mb: 2 }}>
                {data?.additionalInformation?.extendedPaymentData
                  ?.miniDashBoard && (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            Mini Dashboard
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.additionalInformation?.extendedPaymentData
                          ?.miniDashBoard &&
                          Object.entries(
                            data?.additionalInformation?.extendedPaymentData
                              ?.miniDashBoard,
                          ).map(([key, value]) => (
                            <TableRow key={key}>
                              <TableCell>
                                <Typography variant="caption">
                                  {toSpaceCaseWithNumbers(key)}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="caption">
                                  {value.toLocaleString()}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {(data?.additionalInformation?.extendedPaymentData
                  ?.pastDueBalances?.pastDueHigh ||
                  data?.additionalInformation?.extendedPaymentData
                    ?.pastDueBalances?.pastDueTotal) && (
                  <Stack spacing={4} direction="column" sx={{ minWidth: 450 }}>
                    {data?.additionalInformation?.extendedPaymentData
                      ?.pastDueBalances?.pastDueHigh && (
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell colSpan={2} align="center">
                                Past Due High
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data?.additionalInformation?.extendedPaymentData
                              ?.pastDueBalances?.pastDueHigh &&
                              Object.entries(
                                data?.additionalInformation?.extendedPaymentData
                                  ?.pastDueBalances?.pastDueHigh,
                              ).map(([key, value]) => (
                                <TableRow key={key}>
                                  <TableCell>
                                    <Typography variant="caption">
                                      {toSpaceCaseWithNumbers(key)}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="right">
                                    <Typography variant="caption">
                                      {value.toLocaleString()}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                    {data?.additionalInformation?.extendedPaymentData
                      ?.pastDueBalances?.pastDueTotal && (
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell colSpan={2} align="center">
                                Past Due Total
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data?.additionalInformation?.extendedPaymentData
                              ?.pastDueBalances?.pastDueTotal &&
                              Object.entries(
                                data?.additionalInformation?.extendedPaymentData
                                  ?.pastDueBalances?.pastDueTotal,
                              ).map(([key, value]) => (
                                <TableRow key={key}>
                                  <TableCell>
                                    <Typography variant="caption">
                                      {toSpaceCaseWithNumbers(key)}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="right">
                                    <Typography variant="caption">
                                      {value.toLocaleString()}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </Stack>
                )}
                {data?.additionalInformation?.extendedPaymentData
                  ?.pastDueBalances?.pastDueBalance && (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            Past Due Balance
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.additionalInformation?.extendedPaymentData
                          ?.pastDueBalances?.pastDueBalance &&
                          Object.entries(
                            data?.additionalInformation?.extendedPaymentData
                              ?.pastDueBalances?.pastDueBalance,
                          ).map(([key, value]) => (
                            <TableRow key={key}>
                              <TableCell>
                                <Typography variant="caption">
                                  {toSpaceCaseWithNumbers(key)}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="caption">
                                  {value.toLocaleString()}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Stack>
            )}
            {(data?.additionalInformation?.extendedPaymentData
              ?.tradeReferences ||
              data?.additionalInformation?.extendedPaymentData
                ?.tradeReferenceHistory) && (
              <Stack spacing={4} direction="row" sx={{ mt: 2, mb: 2 }}>
                {data?.additionalInformation?.extendedPaymentData
                  ?.tradeReferences && (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            Trade References
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.additionalInformation?.extendedPaymentData
                          ?.tradeReferences?.tradeReferenceSummary &&
                          Object.entries(
                            data?.additionalInformation?.extendedPaymentData
                              ?.tradeReferences?.tradeReferenceSummary,
                          ).map(([key, value]) => (
                            <TableRow key={key}>
                              <TableCell>
                                <Typography variant="caption">
                                  {toSpaceCaseWithNumbers(key)}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="caption">
                                  {value.toLocaleString()}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {data?.additionalInformation?.extendedPaymentData
                  ?.tradeReferenceHistory && (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            Trade References History
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.additionalInformation?.extendedPaymentData
                          ?.tradeReferenceHistory &&
                          Object.entries(
                            data?.additionalInformation?.extendedPaymentData
                              ?.tradeReferenceHistory,
                          ).map(([key, value]) => (
                            <TableRow key={key}>
                              <TableCell>
                                <Typography variant="caption">
                                  {toSpaceCaseWithNumbers(key)}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="caption">
                                  {value.toLocaleString()}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Stack>
            )}
            {(data?.additionalInformation?.extendedPaymentData
              ?.highCreditBalances ||
              data?.additionalInformation?.extendedPaymentData
                ?.lifetimeHighBalanceChart) && (
              <Stack spacing={4} direction="row" sx={{ mt: 2, mb: 2 }}>
                {data?.additionalInformation?.extendedPaymentData
                  ?.highCreditBalances && (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            Hign Credit Balances Chart
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.additionalInformation?.extendedPaymentData
                          ?.highCreditBalances &&
                          Object.entries(
                            data?.additionalInformation?.extendedPaymentData
                              ?.highCreditBalances,
                          ).map(([key, value]) => (
                            <TableRow key={key}>
                              <TableCell>
                                <Typography variant="caption">
                                  {toSpaceCaseWithNumbers(key)}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="caption">
                                  {value.toLocaleString()}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {data?.additionalInformation?.extendedPaymentData
                  ?.lifetimeHighBalanceChart && (
                  <Stack spacing={4} direction="column" sx={{ minWidth: 450 }}>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2} align="center">
                              Life Time High Balance 12 Months
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data?.additionalInformation?.extendedPaymentData
                            ?.lifetimeHighBalanceChart.highLifetime &&
                            Object.entries(
                              data?.additionalInformation?.extendedPaymentData
                                ?.lifetimeHighBalanceChart.highLifetime,
                            ).map(([key, value]) => (
                              <TableRow key={key}>
                                <TableCell>
                                  <Typography variant="caption">
                                    {toSpaceCaseWithNumbers(key)}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <Typography variant="caption">
                                    {value.toLocaleString()}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2} align="center">
                              Life Time High Balance 12 Months
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data?.additionalInformation?.extendedPaymentData
                            ?.lifetimeHighBalanceChart?.high12Month &&
                            Object.entries(
                              data?.additionalInformation?.extendedPaymentData
                                ?.lifetimeHighBalanceChart.high12Month,
                            ).map(([key, value]) => (
                              <TableRow key={key}>
                                <TableCell>
                                  <Typography variant="caption">
                                    {toSpaceCaseWithNumbers(key)}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <Typography variant="caption">
                                    {value.toLocaleString()}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2} align="center">
                              Life Time High Balance 9 Months
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data?.additionalInformation?.extendedPaymentData
                            ?.lifetimeHighBalanceChart.high9Month &&
                            Object.entries(
                              data?.additionalInformation?.extendedPaymentData
                                ?.lifetimeHighBalanceChart.high9Month,
                            ).map(([key, value]) => (
                              <TableRow key={key}>
                                <TableCell>
                                  <Typography variant="caption">
                                    {toSpaceCaseWithNumbers(key)}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <Typography variant="caption">
                                    {value.toLocaleString()}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2} align="center">
                              Life Time High Balance 6 Months
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data?.additionalInformation?.extendedPaymentData
                            ?.lifetimeHighBalanceChart.high6Month &&
                            Object.entries(
                              data?.additionalInformation?.extendedPaymentData
                                ?.lifetimeHighBalanceChart?.high6Month,
                            ).map(([key, value]) => (
                              <TableRow key={key}>
                                <TableCell>
                                  <Typography variant="caption">
                                    {toSpaceCaseWithNumbers(key)}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <Typography variant="caption">
                                    {value.toLocaleString()}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2} align="center">
                              Life Time High Balance 3 Months
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data?.additionalInformation?.extendedPaymentData
                            ?.lifetimeHighBalanceChart?.high3Month &&
                            Object.entries(
                              data?.additionalInformation?.extendedPaymentData
                                ?.lifetimeHighBalanceChart?.high3Month,
                            ).map(([key, value]) => (
                              <TableRow key={key}>
                                <TableCell>
                                  <Typography variant="caption">
                                    {toSpaceCaseWithNumbers(key)}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <Typography variant="caption">
                                    {value.toLocaleString()}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Stack>
                )}
              </Stack>
            )}
            {(data?.additionalInformation?.extendedPaymentData
              ?.tpdTradeLinesInformation ||
              data?.additionalInformation?.extendedPaymentData
                ?.tradePaymentsByCreditExtended) && (
              <>
                <Stack spacing={4} direction="row" sx={{ mt: 2, mb: 2 }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            TDP TradeLines Information
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.additionalInformation?.extendedPaymentData
                          ?.tpdTradeLinesInformation &&
                          Object.entries(
                            data?.additionalInformation?.extendedPaymentData
                              ?.tpdTradeLinesInformation,
                          ).map(([key, value]) => (
                            <TableRow key={key}>
                              <TableCell>
                                <Typography variant="caption">
                                  {toSpaceCaseWithNumbers(key)}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="caption">
                                  {value.toLocaleString()}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            Trade Payments Paid By Credit Extended Under 1k
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.additionalInformation?.extendedPaymentData
                          ?.tradePaymentsPaidByCreditExtended?.creditUnder1k &&
                          Object.entries(
                            data?.additionalInformation?.extendedPaymentData
                              ?.tradePaymentsPaidByCreditExtended
                              ?.creditUnder1k,
                          ).map(([key, value]) => (
                            <TableRow key={key}>
                              <TableCell>
                                <Typography variant="caption">
                                  {toSpaceCaseWithNumbers(key)}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="caption">
                                  {value.toLocaleString()}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            Trade Payments Paid By Credit Extended Under 1k to
                            5k
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.additionalInformation?.extendedPaymentData
                          ?.tradePaymentsPaidByCreditExtended?.credit1kTo5k &&
                          Object.entries(
                            data?.additionalInformation?.extendedPaymentData
                              ?.tradePaymentsPaidByCreditExtended?.credit1kTo5k,
                          ).map(([key, value]) => (
                            <TableRow key={key}>
                              <TableCell>
                                <Typography variant="caption">
                                  {toSpaceCaseWithNumbers(key)}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="caption">
                                  {value.toLocaleString()}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            Trade Payments Paid By Credit Extended Under 5k to
                            25k
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.additionalInformation?.extendedPaymentData
                          ?.tradePaymentsPaidByCreditExtended?.credit5kTo25k &&
                          Object.entries(
                            data?.additionalInformation?.extendedPaymentData
                              ?.tradePaymentsPaidByCreditExtended
                              ?.credit5kTo25k,
                          ).map(([key, value]) => (
                            <TableRow key={key}>
                              <TableCell>
                                <Typography variant="caption">
                                  {toSpaceCaseWithNumbers(key)}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="caption">
                                  {value.toLocaleString()}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
                <Stack spacing={4} direction="row" sx={{ mt: 4, mb: 2 }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            Trade Payments Paid By Credit Extended Under 25k to
                            50k
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.additionalInformation?.extendedPaymentData
                          ?.tradePaymentsPaidByCreditExtended?.credit25kTo50k &&
                          Object.entries(
                            data?.additionalInformation?.extendedPaymentData
                              ?.tradePaymentsPaidByCreditExtended
                              ?.credit25kTo50k,
                          ).map(([key, value]) => (
                            <TableRow key={key}>
                              <TableCell>
                                <Typography variant="caption">
                                  {toSpaceCaseWithNumbers(key)}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="caption">
                                  {value.toLocaleString()}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            Trade Payments Paid By Credit Extended Under 50k to
                            100k
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.additionalInformation?.extendedPaymentData
                          ?.tradePaymentsPaidByCreditExtended
                          ?.credit50kTo100k &&
                          Object.entries(
                            data?.additionalInformation?.extendedPaymentData
                              ?.tradePaymentsPaidByCreditExtended
                              ?.credit50kTo100k,
                          ).map(([key, value]) => (
                            <TableRow key={key}>
                              <TableCell>
                                <Typography variant="caption">
                                  {toSpaceCaseWithNumbers(key)}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="caption">
                                  {value.toLocaleString()}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            Trade Payments Paid By Credit Extended Under 100k to
                            1m
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.additionalInformation?.extendedPaymentData
                          ?.tradePaymentsPaidByCreditExtended?.credit100kTo1m &&
                          Object.entries(
                            data?.additionalInformation?.extendedPaymentData
                              ?.tradePaymentsPaidByCreditExtended
                              ?.credit100kTo1m,
                          ).map(([key, value]) => (
                            <TableRow key={key}>
                              <TableCell>
                                <Typography variant="caption">
                                  {toSpaceCaseWithNumbers(key)}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="caption">
                                  {value.toLocaleString()}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            Trade Payments Paid By Credit Extended Over 1m
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.additionalInformation?.extendedPaymentData
                          ?.tradePaymentsPaidByCreditExtended?.creditOver1m &&
                          Object.entries(
                            data?.additionalInformation?.extendedPaymentData
                              ?.tradePaymentsPaidByCreditExtended?.creditOver1m,
                          ).map(([key, value]) => (
                            <TableRow key={key}>
                              <TableCell>
                                <Typography variant="caption">
                                  {toSpaceCaseWithNumbers(key)}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="caption">
                                  {value.toLocaleString()}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
                <Stack spacing={4} direction="row" sx={{ mt: 4, mb: 2 }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            Trade Payments Paid Credit Extended Under 1k
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.additionalInformation?.extendedPaymentData
                          ?.tradePaymentsByCreditExtended?.creditUnder1k &&
                          Object.entries(
                            data?.additionalInformation?.extendedPaymentData
                              ?.tradePaymentsByCreditExtended?.creditUnder1k,
                          ).map(([key, value]) => (
                            <TableRow key={key}>
                              <TableCell>
                                <Typography variant="caption">
                                  {toSpaceCaseWithNumbers(key)}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="caption">
                                  {value.toLocaleString()}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            Trade Payments By Credit Extended 1k to 5k
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.additionalInformation?.extendedPaymentData
                          ?.tradePaymentsByCreditExtended?.credit1kTo5k &&
                          Object.entries(
                            data?.additionalInformation?.extendedPaymentData
                              ?.tradePaymentsByCreditExtended?.credit1kTo5k,
                          ).map(([key, value]) => (
                            <TableRow key={key}>
                              <TableCell>
                                <Typography variant="caption">
                                  {toSpaceCaseWithNumbers(key)}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="caption">
                                  {value.toLocaleString()}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            Trade Payments By Credit Extended 5k to 25k
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.additionalInformation?.extendedPaymentData
                          ?.tradePaymentsByCreditExtended?.credit5kTo25k &&
                          Object.entries(
                            data?.additionalInformation?.extendedPaymentData
                              ?.tradePaymentsByCreditExtended?.credit5kTo25k,
                          ).map(([key, value]) => (
                            <TableRow key={key}>
                              <TableCell>
                                <Typography variant="caption">
                                  {toSpaceCaseWithNumbers(key)}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="caption">
                                  {value.toLocaleString()}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            Trade Payments By Credit Extended 25k to 50k
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.additionalInformation?.extendedPaymentData
                          ?.tradePaymentsByCreditExtended?.credit25kTo50k &&
                          Object.entries(
                            data?.additionalInformation?.extendedPaymentData
                              ?.tradePaymentsByCreditExtended?.credit25kTo50k,
                          ).map(([key, value]) => (
                            <TableRow key={key}>
                              <TableCell>
                                <Typography variant="caption">
                                  {toSpaceCaseWithNumbers(key)}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="caption">
                                  {value.toLocaleString()}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
                <Stack spacing={4} direction="row" sx={{ mt: 4, mb: 2 }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            Trade Payments Paid Credit Extended 50k to 100k
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.additionalInformation?.extendedPaymentData
                          ?.tradePaymentsByCreditExtended?.credit50kTo100k &&
                          Object.entries(
                            data?.additionalInformation?.extendedPaymentData
                              ?.tradePaymentsByCreditExtended?.credit50kTo100k,
                          ).map(([key, value]) => (
                            <TableRow key={key}>
                              <TableCell>
                                <Typography variant="caption">
                                  {toSpaceCaseWithNumbers(key)}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="caption">
                                  {value.toLocaleString()}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            Trade Payments By Credit Extended 100k to 1m
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.additionalInformation?.extendedPaymentData
                          ?.tradePaymentsByCreditExtended?.credit100kTo1m &&
                          Object.entries(
                            data?.additionalInformation?.extendedPaymentData
                              ?.tradePaymentsByCreditExtended?.credit100kTo1m,
                          ).map(([key, value]) => (
                            <TableRow key={key}>
                              <TableCell>
                                <Typography variant="caption">
                                  {toSpaceCaseWithNumbers(key)}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="caption">
                                  {value.toLocaleString()}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            Trade Payments By Credit Extended Over 1m
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.additionalInformation?.extendedPaymentData
                          ?.tradePaymentsByCreditExtended?.creditOver1m &&
                          Object.entries(
                            data?.additionalInformation?.extendedPaymentData
                              ?.tradePaymentsByCreditExtended?.creditOver1m,
                          ).map(([key, value]) => (
                            <TableRow key={key}>
                              <TableCell>
                                <Typography variant="caption">
                                  {toSpaceCaseWithNumbers(key)}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="caption">
                                  {value.toLocaleString()}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              </>
            )}
            {data?.additionalInformation?.extendedPaymentData
              ?.paidTradePaymentInformation && (
              <>
                <Stack spacing={4} direction="row" sx={{ mt: 2, mb: 2 }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            Paid Trade Payment Information
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.additionalInformation?.extendedPaymentData
                          ?.paidTradePaymentInformation &&
                          Object.entries(
                            data?.additionalInformation?.extendedPaymentData
                              ?.paidTradePaymentInformation,
                          ).map(([key, value]) => (
                            <TableRow key={key}>
                              <TableCell>
                                <Typography variant="caption">
                                  {toSpaceCaseWithNumbers(key)}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="caption">
                                  {value.toLocaleString()}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {(data?.additionalInformation?.extendedPaymentData
                    ?.paidTpdIndustries ||
                    data?.additionalInformation?.extendedPaymentData
                      ?.tpdIndustryBreakdown) && (
                    <Stack sx={{ minWidth: 700 }} spacing={4}>
                      {data?.additionalInformation?.extendedPaymentData
                        ?.paidTpdIndustries && (
                        <CreditSafePaidTpdIndustriesChart
                          data={
                            data?.additionalInformation?.extendedPaymentData
                              ?.paidTpdIndustries
                          }
                        />
                      )}
                      {data?.additionalInformation?.extendedPaymentData
                        ?.tpdIndustryBreakdown && (
                        <CreditSafeTPDIndustryBreakdown
                          data={
                            data?.additionalInformation?.extendedPaymentData
                              ?.tpdIndustryBreakdown
                          }
                        />
                      )}
                    </Stack>
                  )}
                </Stack>
              </>
            )}
            {(data?.additionalInformation?.extendedPaymentData
              ?.tpdTradeLinesInformation ||
              data?.additionalInformation?.extendedPaymentData
                ?.tpdMonthlyBalanceHistories) && (
              <>
                <Stack spacing={4} direction="row" sx={{ mt: 2, mb: 2 }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            TPD Trade Lines Information
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.additionalInformation?.extendedPaymentData
                          ?.tpdTradeLinesInformation &&
                          Object.entries(
                            data?.additionalInformation?.extendedPaymentData
                              ?.tpdTradeLinesInformation,
                          ).map(([key, value]) => (
                            <TableRow key={key}>
                              <TableCell>
                                <Typography variant="caption">
                                  {toSpaceCaseWithNumbers(key)}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="caption">
                                  {key === "lastUpdateDate"
                                    ? fDateTime(value)
                                    : value.toLocaleString()}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {data?.additionalInformation?.extendedPaymentData
                    ?.tpdMonthlyBalanceHistories && (
                    <Stack sx={{ minWidth: 700 }} spacing={4}>
                      {data?.additionalInformation?.extendedPaymentData
                        ?.tpdMonthlyBalanceHistories &&
                        data?.additionalInformation?.extendedPaymentData
                          ?.tpdMonthlyBalanceHistories.length > 0 && (
                          <CreditSafeTPDMonthlyBalanceChart
                            data={
                              data?.additionalInformation?.extendedPaymentData
                                ?.tpdMonthlyBalanceHistories
                            }
                          />
                        )}
                    </Stack>
                  )}
                </Stack>
              </>
            )}
            {data?.additionalInformation?.extendedPaymentData
              ?.paidMonthlyBalanceHistories && (
              <Stack sx={{ minWidth: 700 }} spacing={4}>
                {data?.additionalInformation?.extendedPaymentData
                  ?.paidMonthlyBalanceHistories &&
                  data?.additionalInformation?.extendedPaymentData
                    ?.paidMonthlyBalanceHistories.length > 0 && (
                    <CreditSafePaidMonthlyBalanceHistoriesChart
                      data={
                        data?.additionalInformation?.extendedPaymentData
                          ?.paidMonthlyBalanceHistories
                      }
                    />
                  )}
              </Stack>
            )}
          </Paper>
        </Stack>
      )}
    </Stack>
  )
}
