import { QueryClient } from "react-query"
import { AdditionalDataRequest, CreditApplication } from "src/types"
import { useTypedMutation } from "../../useTypedMutation"

export function usePatchAdditionalDataRequest(
  requestId?: string,
  onSuccess?: (data: CreditApplication) => void,
) {
  const ENDPOINT = "/application/base/data_request/"
  const patch = useTypedMutation<FormData>(
    "post/application/data_request",
    onSuccess,
  )

  const execute = (
    body: Partial<Pick<AdditionalDataRequest, "fulfilled">>,
    stepsComplete: boolean,
    pdf?: File,
    onSuccess?: () => void,
  ) => {
    if (!requestId) return
    const formData = new FormData()
    if (pdf) {
      formData.append("file_0", pdf)
    }
    if (body.fulfilled) {
      formData.append("fulfilled", "true")
    }
    patch.mutate(
      {
        method: "patch",
        endpoint:
          ENDPOINT +
          requestId +
          "/?" +
          (stepsComplete ? "steps_complete=true" : ""),
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }

  return { ...patch, execute }
}
