import {
  PendingActionsOutlined,
  SellOutlined,
  SupportAgentOutlined,
} from "@mui/icons-material"
import {
  TableRow,
  TableCell,
  ListItemText,
  Tooltip,
  Typography,
  IconButton,
  Stack,
  Collapse,
  Paper,
  Button,
  Badge,
} from "@mui/material"
import { Box } from "@mui/system"
import { formatDistance } from "date-fns"
import Iconify from "src/components/iconify"
import { CreditApplication, Tag } from "src/types"
import { formatCurrency } from "src/utils/formatNumber"
import { fDate } from "src/utils/formatTime"
import { getStatusLabel } from "../../utils"
import { useNavigate } from "react-router-dom"
import { useUser } from "src/queries"
import { useTradeReferences } from "src/queries/credit/useTradeReferences"
import Label from "src/components/label"
import { convertNetTerms } from "src/statics"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"
import { useListAdditionalDataRequest } from "src/queries/credit/additional/useListAdditionalDataRequests"
import usePersonalGuarantors, {
  ProgressState,
} from "src/queries/credit/usePersonalGuarantors"
import { BUSINESS_PREFERENCES, usePreference } from "src/hooks/use-preference"
import { useBoolean } from "src/hooks/use-boolean"
import TagSelect from "../components/TagSelect"
import { useState } from "react"

export default ({
  row,
  index,
  handleOpenMenu,
}: {
  row: CreditApplication
  index: number
  handleOpenMenu: (event: React.MouseEvent<HTMLButtonElement>) => void
}) => {
  const navigate = useNavigate()

  const { isCreditManager, isSales, data: user } = useUser()

  const { data: tradeReferences } = useTradeReferences(row.id)

  const { preference: personalGuarantorColumnEnabled } = usePreference(
    BUSINESS_PREFERENCES.DASHBOARD_PERSONAL_GUARANTOR_COLUMN,
    user?.userToBusiness?.business,
  )

  const {
    personalGuarantorsSignatureState,
    guarantorsToUse,
    signedCount,
    isFetchingGuarantors,
    isFetchingDetails,
  } = usePersonalGuarantors(personalGuarantorColumnEnabled ? row : undefined)

  const { data: template } = useApplicationTemplate(row.seller?.id || undefined)

  const { pendingPageLabels } = useListAdditionalDataRequest(row.id || "")

  const collapse = useBoolean()

  const [tags, setTags] = useState<Array<Tag>>(row?.tags || [])

  const renderSecondary = (
    <TableRow>
      <TableCell sx={{ p: 0, border: "none" }} colSpan={8}>
        <Collapse
          in={collapse.value}
          timeout="auto"
          unmountOnExit
          sx={{ bgcolor: "background.neutral" }}
        >
          <Stack component={Paper} sx={{ m: 1.5 }}>
            <TagSelect
              application={row}
              onTagsChanged={(newTags) => {
                setTags(newTags)
              }}
              sx={{
                p: (theme) => theme.spacing(1.5, 2, 1.5, 1.5),
                "&:not(:last-of-type)": {
                  borderBottom: (theme) =>
                    `solid 2px ${theme.palette.background.paper}`,
                },
              }}
            />
          </Stack>
        </Collapse>
      </TableCell>
    </TableRow>
  )

  return (
    <>
      <TableRow hover key={row.id} tabIndex={-1} role="checkbox">
        <TableCell align="left">
          <ListItemText
            style={{
              cursor: isCreditManager ? "pointer" : "default",
            }}
            primaryTypographyProps={{
              typography: "subtitle2",
              sx: { textTransform: "capitalize" },
            }}
            secondaryTypographyProps={{
              typography: "caption",
            }}
            primary={
              <Box
                key={index}
                component="span"
                sx={{
                  color: "text.primary",
                }}
              >
                {row.data.legalBusinessName ||
                  `${row.data.firstName} ${row.data.lastName}`}
              </Box>
            }
            secondary={
              <Box
                key={index}
                component="span"
                sx={{
                  color: "text.secondary",
                }}
              >
                {row.data.email}
              </Box>
            }
            onClick={() => {
              if (isCreditManager) {
                if (row.stage !== 50) {
                  navigate("/applications/" + row.id + "/")
                } else {
                  navigate("/manual-application/" + row.id + "/")
                }
              }
              if (isSales) {
                navigate("/applications/" + row.id + "/")
              }
            }}
          />
          {/* <Typography variant="subtitle2" noWrap></Typography> */}
        </TableCell>
        <TableCell align="left">{fDate(row.createdAt)}</TableCell>
        <TableCell align="left">
          <Stack gap="8px" direction="row">
            {getStatusLabel(row.stage)}
            {pendingPageLabels && pendingPageLabels?.length > 0 && (
              <Tooltip
                title={
                  <Stack gap="8px">
                    <Typography>
                      A credit manager requested the following additional data:
                    </Typography>
                    <Typography>{pendingPageLabels?.join(", ")}</Typography>
                  </Stack>
                }
              >
                <PendingActionsOutlined color="warning" />
              </Tooltip>
            )}
          </Stack>
        </TableCell>
        <TableCell align="left">
          <Stack>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              {row.assignee ? (
                <>
                  <Tooltip title="Credit Manager">
                    <Iconify
                      width={"24px"}
                      icon="fluent:person-money-24-regular"
                    />
                  </Tooltip>
                  {row.assignee.firstName
                    ? `${row.assignee.firstName} ${
                        row.assignee.lastName ? row.assignee.lastName[0] : ""
                      }`
                    : row.assignee.email}
                </>
              ) : (
                <>
                  <Tooltip title="Credit Manager">
                    <Iconify
                      width={"24px"}
                      icon="fluent:person-money-24-regular"
                    />
                  </Tooltip>
                  Unassigned
                </>
              )}
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              {row.salesRep ? (
                <>
                  <Tooltip title="Sales Rep">
                    <SupportAgentOutlined />
                  </Tooltip>
                  {row.salesRep.firstName
                    ? `${row.salesRep.firstName} ${
                        row.salesRep.lastName ? row.salesRep.lastName[0] : ""
                      }`
                    : row.salesRep.email}
                </>
              ) : (
                <>
                  <Tooltip title="Sales Rep">
                    {/* <Avatar
                    {...stringAvatar("?", {
                      width: 28,
                      height: 28,
                    })}
                  /> */}

                    <SupportAgentOutlined />
                  </Tooltip>
                  Unassigned
                </>
              )}
            </Box>
          </Stack>
        </TableCell>

        {isCreditManager && (
          <TableCell align="left">
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>
                {row.data.netTerms
                  ? convertNetTerms(row.data.netTerms, template?.approvalTerms)
                  : ""}
              </div>
              <div>
                {row.data.creditLimit
                  ? formatCurrency(
                      Number(row.data.creditLimit),
                      row.data.currency,
                    )
                  : ""}
              </div>
            </Box>
          </TableCell>
        )}
        <TableCell align="left">
          {tradeReferences && (
            <Label
              color={
                tradeReferences?.length === 0
                  ? "default"
                  : tradeReferences?.filter((t) => t.isFilled).length
                    ? "success"
                    : "error"
              }
            >
              {`${tradeReferences?.filter((t) => t.isFilled).length} / 
     ${tradeReferences?.length}`}
            </Label>
          )}
        </TableCell>
        {personalGuarantorColumnEnabled && (
          <TableCell align="left">
            {!isFetchingGuarantors && !isFetchingDetails && guarantorsToUse ? (
              <Label
                color={
                  personalGuarantorsSignatureState ===
                    ProgressState.ALL_SIGNED ||
                  personalGuarantorsSignatureState === ProgressState.EMPTY
                    ? "success"
                    : personalGuarantorsSignatureState ===
                        ProgressState.NONE_SIGNED
                      ? "error"
                      : "warning"
                }
              >
                {`${signedCount} / 
     ${guarantorsToUse?.length}`}
              </Label>
            ) : (
              <>
                <Label color={"success"}>{"0 / 0"}</Label>
              </>
            )}
          </TableCell>
        )}
        <TableCell align="left">
          <Tooltip title={fDate(row.lastViewed)} placement="top" arrow>
            <Typography>
              {row.lastViewed
                ? formatDistance(new Date(row.lastViewed), new Date(), {
                    addSuffix: true,
                  })
                : ""}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell align="right">
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            {isCreditManager && tags.length ? (
              <Stack direction="row" alignItems="center">
                <Box sx={{ position: "relative" }}>
                  <SellOutlined color="warning" />

                  <Badge
                    badgeContent={tags?.length}
                    overlap="circular"
                    color="warning"
                    sx={{
                      top: -6,
                      px: 0.5,
                      left: 15,
                      height: 15,
                      position: "absolute",
                      borderBottomLeftRadius: 2,
                    }}
                  />
                </Box>

                <IconButton
                  color={collapse.value ? "inherit" : "default"}
                  onClick={collapse.onToggle}
                  sx={{
                    ...(collapse.value && {
                      bgcolor: "action.hover",
                    }),
                  }}
                >
                  <Iconify
                    icon={
                      collapse.value
                        ? "eva:arrow-ios-upward-fill"
                        : "eva:arrow-ios-downward-fill"
                    }
                  />
                </IconButton>
              </Stack>
            ) : (
              <Button color="inherit" onClick={collapse.onToggle}>
                + <SellOutlined color="inherit" />
              </Button>
            )}

            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              <IconButton
                id={`menu-${index}`}
                row={row.id}
                size="large"
                color="inherit"
                onClick={handleOpenMenu}
              >
                <Iconify icon={"eva:more-vertical-fill"} />
              </IconButton>
            }
          </Stack>
        </TableCell>
      </TableRow>
      {isCreditManager && renderSecondary}
    </>
  )
}
