import { Paper, useTheme } from "@mui/material"
import Chart from "react-apexcharts"
import { format } from "date-fns"
import { CreditSafeTpdMonthlyBalanceHistories } from "src/types"

const CreditSafeTPDMonthlyBalanceChart = ({
  data,
}: {
  data: CreditSafeTpdMonthlyBalanceHistories
}) => {
  const theme = useTheme()
  const formattedData = data.map((item) => ({
    x: format(new Date(item.date), "MMM yyyy"),
  }))

  const options = {
    chart: {
      type: "bar" as const,
      height: 350,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    // stroke: {
    //   curve: "smooth" as const,
    //   width: 2,
    // },
    xaxis: {
      type: "category" as const,
      categories: formattedData.map((item) => item.x),
      labels: {
        rotate: -45,
        rotateAlways: false,
      },
    },
    yaxis: {
      title: {
        text: `Balance (${data[0].currency ?? "USD"})`,
      },
    },
    title: {
      text: "(TDP) Monthly Balance History",
      align: "left" as const,
    },
    colors: [
      theme.palette.success.dark,
      theme.palette.grey[900],
      theme.palette.error.main,
      theme.palette.info.main,
      theme.palette.warning.main,
    ],
    tooltip: {
      y: {
        formatter: (value: number) => {
          if (typeof value === "number") {
            return `$${value.toFixed(2)}`
          }
          return value
        },
      },
    },
  }

  const series = [
    {
      name: "Total Balance",
      data: data.map((item) =>
        Number.isNaN(item.totalBalance) || item.totalBalance === undefined
          ? 0
          : item.totalBalance,
      ),
    },
    {
      name: "Range 1 to 30",
      data: data.map((item) =>
        Number.isNaN(item.range1to30) || item.range1to30 === undefined
          ? 0
          : item.range1to30,
      ),
    },
    {
      name: "Range 31 to 60",
      data: data.map((item) =>
        Number.isNaN(item.range31to60) || item.range31to60 === undefined
          ? 0
          : item.range31to60,
      ),
    },
    {
      name: "Range 61 to 90",
      data: data.map((item) =>
        Number.isNaN(item.range61to90) || item.range61to90 === undefined
          ? 0
          : item.range61to90,
      ),
    },
    {
      name: "Range 91 Plus",
      data: data.map((item) =>
        Number.isNaN(item.range91plus) || item.range91plus === undefined
          ? 0
          : item.range91plus,
      ),
    },
  ]

  return (
    <Paper sx={{ p: 3, mt: 4 }}>
      <Chart options={options} series={series} type="bar" height={350} />
    </Paper>
  )
}

export default CreditSafeTPDMonthlyBalanceChart
