import { useState } from "react"
import { ArrowForward } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import * as yup from "yup"

import {
  Box,
  Checkbox,
  Typography,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material"
import { ApplicationTemplate, Application } from "src/types"

import Address, { PROVINCES, STATES } from "../../components/Address"
import { useSalesReps } from "src/queries/base/useSalesReps"
import { useCreditManagers } from "src/queries/base/useCreditManagers"
import {
  BUSINESS_CONFIG_BILLING_ADDRESS,
  BUSINESS_CONFIG_BUSINESS_ADDRESS,
  BUSINESS_CONFIG_CREDIT_CARD_BILLING_ADDRESS,
  BUSINESS_CONFIG_CREDIT_MANGER_SELECTOR,
  BUSINESS_CONFIG_DBA,
  BUSINESS_CONFIG_REVENUE,
  BUSINESS_CONFIG_SERVICE_ADDRESS,
  BUSINESS_CONFIG_SHIPPING_ADDRESS,
  FORM_INPUT_NAMES,
  PAGE_LABEL_BUSINESS_DETAILS,
  PAGE_LABEL_PROJECT_DETAILS,
} from "../../intake_sections/constants"
import {
  APPLICANT_TYPES,
  CUSTOM_WORD_MAPPINGS,
  customWordMapping,
  FIELD_TYPES,
} from "src/statics"
import {
  addConditionalValidationstoCustomSchema,
  hasConditions,
  isProjectApplication,
} from "../../utils"
import CashReusableCustomQuestions from "../components/CashReusableCustomQuestions"
import { addAddressToSchema } from "../../components/CustomAddress"

const baseYup = (template: ApplicationTemplate) =>
  yup.object({
    legalBusinessName: yup.string().required(),
    revenue: yup.string().when("applicantType", {
      is: (fieldTwo: string) => {
        return (
          ![
            APPLICANT_TYPES.INDIVIDUAL_CONSUMER_HOMEOWNER,
            APPLICANT_TYPES.GOVERNMENT,
            APPLICANT_TYPES.GOVERNMENT_ENTITY,
          ].includes(fieldTwo) && getRevenueEnabled(template)
        )
      },
      then: yup.string().required(),
    }),
    salesRep: yup.mixed(),
  })

export const getEnabledSchema = (
  template: ApplicationTemplate,
  data?: Application["data"],
): yup.AnyObjectSchema => {
  let enabledSchema = baseYup(template)
  template.formTemplate.pages
    .find((step) => step.label === "Business Details")
    ?.config?.filter((config) => !!config.value)
    .forEach(
      (config) =>
        (enabledSchema = enabledSchema.concat(
          getAdditionalSchema(config.label),
        )),
    )

  const addresses = getAddressTypes(template)

  if (addresses.includes(BUSINESS_CONFIG_SHIPPING_ADDRESS)) {
    enabledSchema = enabledSchema.concat(
      yup.object({
        shippingAddress: yup.string().required("Shipping Address is required"),
        shippingCity: yup.string().required("City is required"),
        shippingCountry: yup
          .string()
          .required("Country is required")
          .oneOf(["Canada", "United States"]),
        shippingRegion: yup
          .string()
          .required()
          .oneOf([...STATES, ...PROVINCES]),
        shippingPostCode: yup.string().required("Post code is required"),
      }),
    )
  }

  if (addresses.includes(BUSINESS_CONFIG_SERVICE_ADDRESS)) {
    enabledSchema = enabledSchema.concat(
      yup.object({
        serviceAddress: yup.string().required("Service Address is required"),
        serviceCity: yup.string().required("City is required"),
        serviceCountry: yup
          .string()
          .required("Country is required")
          .oneOf(["Canada", "United States"]),
        serviceRegion: yup
          .string()
          .required()
          .oneOf([...STATES, ...PROVINCES]),
        servicePostCode: yup.string().required("Post code is required"),
      }),
    )
  }

  if (addresses.includes(BUSINESS_CONFIG_CREDIT_CARD_BILLING_ADDRESS)) {
    enabledSchema = enabledSchema.concat(
      yup.object({
        creditCardBillingAddress: yup.string().required("Address is required"),
        creditCardBillingCity: yup.string().required("City is required"),
        creditCardBillingCountry: yup
          .string()
          .required("Country is required")
          .oneOf(["Canada", "United States"]),
        creditCardBillingRegion: yup
          .string()
          .required()
          .oneOf([...STATES, ...PROVINCES]),
        creditCardBillingPostCode: yup
          .string()
          .required("Post code is required"),
      }),
    )
  }

  if (addresses.includes(BUSINESS_CONFIG_BUSINESS_ADDRESS)) {
    enabledSchema = enabledSchema.concat(
      yup.object({
        businessAddress: yup.string().required("Address is required"),
        businessCity: yup.string().required("City is required"),
        businessCountry: yup
          .string()
          .required("Country is required")
          .oneOf(["Canada", "United States"]),
        businessRegion: yup
          .string()
          .required()
          .oneOf([...STATES, ...PROVINCES]),
        businessPostCode: yup.string().required("Post code is required"),
      }),
    )
  }

  let customSchema = yup.object({})
  template.customFields
    ?.filter((field) => field.associatedPage === PAGE_LABEL_BUSINESS_DETAILS)
    .filter((field) => field.required && !!field.id)
    .filter((field) => !hasConditions(field))
    .forEach((field) => {
      if (field.fieldType === "file") {
        customSchema = customSchema.concat(
          yup.object({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            [field.id!]: yup.mixed().required("Please upload a file"),
          }),
        )
      } else if (field.fieldType === FIELD_TYPES.ADDRESS) {
        customSchema = addAddressToSchema(customSchema, field)
      } else {
        customSchema = customSchema.concat(
          yup.object({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            [field.id!]: yup
              .object({
                response: yup.string().required("Field is required"),
              })
              .required("Field is required"),
          }),
        )
      }
    })

  if (data) {
    customSchema = addConditionalValidationstoCustomSchema(
      template,
      customSchema,
      data,
      PAGE_LABEL_BUSINESS_DETAILS,
    )
  }

  enabledSchema = enabledSchema.concat(
    yup.object({
      customFields: customSchema,
    }),
  )

  return enabledSchema
}

const getAdditionalSchema = (label: string) => {
  return yup.object({})
}

type Props = {
  application?: Application
  data: Application["data"]
  errors: Application["data"] | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (key: string, value: any) => void
  onCustomChange: (key: string, value: any) => void
  onContinue: () => void
  onApplicationFieldUpdated?: (key: string, value: any) => void
  topLevelValues?: Application
  template?: ApplicationTemplate
  setFilesInMemory: (files: Map<string, File[]>) => void
  filesInMemory: Map<string, File[]>
}

export const getAddressTypes = (template?: ApplicationTemplate) => {
  let fromTemplate = template?.formTemplate.pages
    ?.find((page) => page.label === PAGE_LABEL_BUSINESS_DETAILS)
    ?.config?.filter((config) => config.label.includes("Address"))
    ?.filter((c) => c.value === true)
    ?.map((c) => c.label)

  if (!fromTemplate || fromTemplate.length === 0) {
    fromTemplate = [
      BUSINESS_CONFIG_BUSINESS_ADDRESS,
      BUSINESS_CONFIG_SHIPPING_ADDRESS,
    ]
  }
  return fromTemplate
}

const getRevenueEnabled = (template?: ApplicationTemplate) => {
  const v = template?.formTemplate.pages
    .find((page) => page.label === PAGE_LABEL_BUSINESS_DETAILS)
    ?.config?.find((config) => config.label.includes(BUSINESS_CONFIG_REVENUE))
    ?.value

  // by default it is enabled if not specified
  return v === undefined || Boolean(v)
}

const getCreditManagerSelectorEnabled = (template?: ApplicationTemplate) => {
  const v = template?.formTemplate.pages
    .find((page) => page.label === PAGE_LABEL_BUSINESS_DETAILS)
    ?.config?.find((config) =>
      config.label.includes(BUSINESS_CONFIG_CREDIT_MANGER_SELECTOR),
    )?.value

  // by default it is enabled if not specified
  return Boolean(v)
}

const getBusinessDbaEnabled = (template?: ApplicationTemplate) => {
  const v = template?.formTemplate.pages
    .find((page) => page.label === PAGE_LABEL_BUSINESS_DETAILS)
    ?.config?.find((config) => config.label.includes(BUSINESS_CONFIG_DBA))
    ?.value

  // by default it is enabled if not specified
  return v === undefined || Boolean(v)
}

export default ({
  application,
  data,
  errors,
  onChange,
  onCustomChange,
  onContinue,
  onApplicationFieldUpdated,
  topLevelValues,
  filesInMemory,
  setFilesInMemory,
  template,
}: Props) => {
  const [hideShippingAddress, setHideShippingAddress] = useState(false)
  const [hideCcBillingAddress, setHideCcBillingAddress] = useState(false)

  const [hideDba, setHideDba] = useState(false)

  const { data: salesReps } = useSalesReps(application?.seller?.id || "")

  const { data: creditManagers } = useCreditManagers(
    application?.seller?.id || "",
  )

  return (
    <>
      <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
        Applicant/
        {isProjectApplication(data)
          ? PAGE_LABEL_PROJECT_DETAILS
          : PAGE_LABEL_BUSINESS_DETAILS}
      </Typography>
      <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
        {customWordMapping(
          CUSTOM_WORD_MAPPINGS.FILL_INFOMATION_BELOW,
          template,
        )}
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: "5%",
          alignItems: "center",
        }}
      >
        <TextField
          id="legal-business-name"
          margin="normal"
          fullWidth
          required
          label={
            [APPLICANT_TYPES.INDIVIDUAL_CONSUMER_HOMEOWNER].includes(
              data.applicantType,
            )
              ? "Full Name"
              : "Legal Business Name"
          }
          type="text"
          value={data.legalBusinessName}
          onChange={(event) => {
            onChange("legalBusinessName", event.target.value)
          }}
          error={Boolean(errors?.legalBusinessName)}
          inputProps={{
            style: { height: "56px" },
          }}
        />
        {getBusinessDbaEnabled(template) && !hideDba && (
          <TextField
            id="business-dba"
            fullWidth
            label={"Doing Business As / Trade Name"}
            type="text"
            value={data.businessDba}
            onChange={(event) => {
              onChange(FORM_INPUT_NAMES.BUSINESS_DBA, event.target.value)
            }}
            inputProps={{
              style: { height: "56px" },
            }}
            error={Boolean(errors?.businessDba)}
          />
        )}
      </Box>
      {getBusinessDbaEnabled(template) && (
        <FormGroup>
          <FormControlLabel
            defaultChecked={true}
            control={
              <Checkbox
                id="dba-same"
                onChange={(event) => {
                  setHideDba(event.target.checked)
                  if (event.target.checked) {
                    onChange(
                      FORM_INPUT_NAMES.BUSINESS_DBA,
                      data.legalBusinessName,
                    )
                  } else {
                    onChange(FORM_INPUT_NAMES.BUSINESS_DBA, undefined)
                  }
                }}
              />
            }
            label="DBA same as legal business name"
          />
        </FormGroup>
      )}

      {getAddressTypes(template).includes(BUSINESS_CONFIG_BUSINESS_ADDRESS) && (
        <>
          <Typography variant="subtitle1">
            {customWordMapping(CUSTOM_WORD_MAPPINGS.MAILING_ADDRESS, template)}
          </Typography>

          <Address
            keyPrefix="business"
            data={data}
            onChange={onChange}
            errors={errors}
            defaults={{ country: application?.seller?.country || "" }}
          />
        </>
      )}

      {getAddressTypes(template).includes(BUSINESS_CONFIG_SHIPPING_ADDRESS) && (
        <>
          <Typography variant="subtitle1">
            {customWordMapping(CUSTOM_WORD_MAPPINGS.SHIPPING_ADDRESS, template)}
          </Typography>

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id="shipping-same-as-mailing"
                  onChange={(event) => {
                    setHideShippingAddress(event.target.checked)
                    if (event.target.checked) {
                      onChange("shippingAddress", data.businessAddress)
                      onChange("shippingCity", data.businessCity)
                      onChange("shippingCountry", data.businessCountry)
                      onChange("shippingRegion", data.businessRegion)
                      onChange("shippingPostCode", data.businessPostCode)
                    } else {
                      onChange("shippingAddress", undefined)
                      onChange("shippingCity", undefined)
                      onChange("shippingCountry", undefined)
                      onChange("shippingRegion", undefined)
                      onChange("shippingPostCode", undefined)
                    }
                  }}
                />
              }
              label={`${customWordMapping(
                CUSTOM_WORD_MAPPINGS.SHIPPING_ADDRESS_ALT,
                template,
              )} same as ${customWordMapping(
                CUSTOM_WORD_MAPPINGS.MAILING_ADDRESS,
                template,
              )}`}
            />
          </FormGroup>

          {!hideShippingAddress && (
            <Address
              keyPrefix="shipping"
              data={data}
              onChange={onChange}
              errors={errors}
              defaults={{ country: application?.seller?.country || "" }}
            />
          )}
        </>
      )}

      {getAddressTypes(template).includes(BUSINESS_CONFIG_SERVICE_ADDRESS) && (
        <>
          <Typography variant="subtitle1">Service Address</Typography>

          <Address
            keyPrefix="service"
            data={data}
            onChange={onChange}
            errors={errors}
            defaults={{ country: application?.seller?.country || "" }}
          />
        </>
      )}

      {getAddressTypes(template).includes(BUSINESS_CONFIG_BILLING_ADDRESS) && (
        <>
          <Typography variant="subtitle1">Billing Address</Typography>

          <Address
            keyPrefix="billing"
            data={data}
            onChange={onChange}
            errors={errors}
            defaults={{ country: application?.seller?.country || "" }}
          />
        </>
      )}

      {getAddressTypes(template).includes(
        BUSINESS_CONFIG_CREDIT_CARD_BILLING_ADDRESS,
      ) && (
        <>
          <Typography variant="subtitle1">
            Credit Card Billing Address
          </Typography>

          {/* <FormGroup>
            <FormControlLabel
              required
              control={
                <Checkbox
                  id="cc-billing-same-as-mailing"
                  onChange={(event) => {
                    setHideCcBillingAddress(event.target.checked)
                    if (event.target.checked) {
                      onChange("creditCardBillingAddress", data.businessAddress)
                      onChange("creditCardBillingCity", data.businessCity)
                      onChange("creditCardBillingCountry", data.businessCountry)
                      onChange("creditCardBillingRegion", data.businessRegion)
                      onChange(
                        "creditCardBillingPostCode",
                        data.businessPostCode,
                      )
                    } else {
                      onChange("creditCardBillingAddress", undefined)
                      onChange("creditCardBillingCity", undefined)
                      onChange("creditCardBillingCountry", undefined)
                      onChange("creditCardBillingRegion", undefined)
                      onChange("creditCardBillingPostCode", undefined)
                    }
                  }}
                />
              }
              label="Credit Card billing address same as mailing address"
            />
          </FormGroup> */}

          {!hideCcBillingAddress && (
            <Address
              keyPrefix="creditCardBilling"
              data={data}
              onChange={onChange}
              errors={errors}
              defaults={{ country: application?.seller?.country || "" }}
            />
          )}
        </>
      )}

      {!isProjectApplication(data) && getRevenueEnabled(template) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "5%",
            alignItems: "center",
          }}
        >
          <Typography style={{ flex: "1 1 0px" }}>
            What is the annual revenue of your business?
          </Typography>
          <FormControl
            fullWidth
            margin="normal"
            style={{ flex: "1 1 0px" }}
            required
          >
            <InputLabel id="revenue-label">Revenue</InputLabel>
            <Select
              labelId="revenue-label"
              id="revenue-select"
              label="Revenue"
              value={data.revenue}
              onChange={(event) => {
                onChange("revenue", event.target.value)
              }}
              style={{ height: "56px" }}
              error={Boolean(errors?.revenue)}
            >
              <MenuItem disabled value={undefined}>
                Select one
              </MenuItem>
              {[
                "Less than $100,000 / year",
                "$100,000 - $500,000 / year",
                "$500,000 - $1M / year",
                "$1M - $2.5M / year",
                "$2.5M - $10M / year",
                "$10M - $50M / year",
                "More than $50M / year",
                "Not a business",
              ].map((revenue) => (
                <MenuItem value={revenue} key={revenue}>
                  {revenue}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
      {onApplicationFieldUpdated !== undefined &&
        topLevelValues !== undefined &&
        salesReps &&
        salesReps.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
              alignItems: "center",
            }}
          >
            <Typography style={{ flex: "1 1 0px" }}>
              {customWordMapping(
                CUSTOM_WORD_MAPPINGS.SALES_REPRESENTATIVE,
                template,
              )}
            </Typography>
            <FormControl fullWidth margin="normal" style={{ flex: "1 1 0px" }}>
              <InputLabel id="sales-rep-label">Sales Rep</InputLabel>
              <Select
                labelId="sales-rep-label"
                id="sales-rep-select"
                label="Sales Rep"
                value={topLevelValues?.salesRep?.id}
                onChange={(event) => {
                  onApplicationFieldUpdated(
                    "salesRep",
                    salesReps?.find(
                      (salesRep) => salesRep.id === event.target.value,
                    ) || { id: -1 },
                  )
                }}
                error={Boolean(errors?.salesRep)}
                style={{ height: "56px" }}
              >
                <MenuItem disabled value={undefined}>
                  Select One
                </MenuItem>
                {salesReps
                  ?.sort((a, b) => {
                    if (!a.firstName) {
                      return 1
                    }
                    if (!b.firstName) {
                      return -1
                    }
                    return a.firstName < b.firstName ? -1 : 1
                  })
                  ?.map((salesRep) => (
                    <MenuItem value={salesRep.id} key={salesRep.id}>
                      {salesRep.firstName} {salesRep.lastName}
                    </MenuItem>
                  ))}
                <MenuItem value={-1} key="sales_rep_0">
                  No Sales Rep / Other
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}
      {onApplicationFieldUpdated !== undefined &&
        topLevelValues !== undefined &&
        getCreditManagerSelectorEnabled(template) &&
        creditManagers && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
              alignItems: "center",
            }}
          >
            <Typography style={{ flex: "1 1 0px" }}>
              {customWordMapping(CUSTOM_WORD_MAPPINGS.CREDIT_MANAGER, template)}
            </Typography>
            <FormControl fullWidth margin="normal" style={{ flex: "1 1 0px" }}>
              <InputLabel id="credit-manager-label">Credit Manager</InputLabel>
              <Select
                labelId="credit-manager-label"
                id="credit-manager-select"
                label="Credit Manager"
                value={topLevelValues?.assignee?.id}
                onChange={(event) => {
                  onApplicationFieldUpdated(
                    "assignee",
                    creditManagers?.find(
                      (creditManager) =>
                        creditManager.id === event.target.value,
                    ) || { id: -1 },
                  )
                }}
                error={Boolean(errors?.creditManager)}
                style={{ height: "56px" }}
                renderValue={(selected) => {
                  if (
                    selected === undefined ||
                    selected === "" ||
                    String(selected) === "-1"
                  ) {
                    return (
                      <Typography sx={{ color: "text.disabled" }}>
                        Select
                      </Typography>
                    )
                  }
                  const cm = creditManagers?.find(
                    (creditManager) => creditManager.id === selected,
                  )
                  return `${cm?.firstName} ${cm?.lastName}`
                }}
              >
                {creditManagers
                  ?.sort((a, b) => {
                    return a.firstName < b.firstName ? -1 : 1
                  })
                  ?.map((creditManager) => (
                    <MenuItem value={creditManager.id} key={creditManager.id}>
                      {creditManager.firstName} {creditManager.lastName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        )}

      {template?.customFields && (
        <CashReusableCustomQuestions
          application={application}
          associatedPage={"Business Details"}
          data={data["customFields"]}
          dataKeyResponses={data}
          errors={errors}
          onChange={onCustomChange}
          overrideHeight
          filesInMemory={filesInMemory}
          setFilesInMemory={setFilesInMemory}
        />
      )}

      <LoadingButton
        id="continue-business"
        onClick={() => {
          if (hideShippingAddress) {
            // the user can change the address after checking the box.
            // in that case, the changes don't propagate. and we should sync here.
            onChange("shippingAddress", data.businessAddress)
            onChange("shippingCity", data.businessCity)
            onChange("shippingCountry", data.businessCountry)
            onChange("shippingRegion", data.businessRegion)
            onChange("shippingPostCode", data.businessPostCode)
          }
          onContinue()
        }}
        size="large"
        fullWidth
        endIcon={<ArrowForward />}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Save & Continue
      </LoadingButton>
    </>
  )
}
