import queryString from "query-string"
import { QueryClient, useQuery } from "react-query"
import {
  CreditSafePurchasedReport,
  CreditSafeSearchResult,
  CreditSafeUpdateReport,
} from "src/types/vendors.types"
import { useTypedMutation } from "../useTypedMutation"

export function useCreditSafeSearch(
  applicationId: string,
  searchQuery: string,
  perPage: number,
  page: number,
) {
  const queryKey =
    "/document/creditsafe_search?" +
    queryString.stringify({
      application_id: applicationId,
      name: searchQuery,
      per_page: perPage,
      page,
    })
  type Response = {
    result: CreditSafeSearchResult[]
    purchasedIds: string[]
  }
  return useQuery<Response>(queryKey, {
    enabled: !!applicationId && !!searchQuery,
    retry: (failureCount, error) => {
      if (failureCount < 1) {
        return true
      }
      // do not retry on 404
      return (error as any)?.response?.status !== 404
    },
  })
}

// disabled by default
export function useCreditSafeReport(
  applicationId: string,
  connectId: string,
  enabled = true,
) {
  const queryKey =
    "/document/credit_report_pdf?" +
    queryString.stringify({
      application_id: applicationId,
      connect_id: connectId,
    })
  return useQuery<CreditSafePurchasedReport>(queryKey, {
    enabled,
    retry: (failureCount, error) => {
      if (failureCount < 1) {
        return true
      }
      // do not retry on 404
      return (error as any)?.response?.status !== 404
    },
  })
}

export function useCreditCredentials(businessId: string) {
  const queryKey =
    "/credit_credentials_status?" +
    queryString.stringify({
      business_id: businessId,
    })
  type Status = {
    freeReports: number
    connected: boolean
  }
  return useQuery<{
    creditSafe: Status
    experian: Status
    creditRiskMonitor: Status
    transUnion: Status
  }>(queryKey, {
    enabled: !!businessId,
    retry: (failureCount, error) => {
      if (failureCount < 1) {
        return true
      }
      // do not retry on 404
      return (error as any)?.response?.status !== 404
    },
  })
}

export function useCreditsafePurchasedReport(applicationId: string) {
  const queryKey = "/document/cs_reports?application_id=" + applicationId
  return useQuery<CreditSafePurchasedReport[]>(queryKey, {
    enabled: !!applicationId,
    retry: (failureCount, error) => {
      if (failureCount < 1) {
        return true
      }
      // do not retry on 404
      return (error as any)?.response?.status !== 404
    },
  })
}

export function useUpdateCreditSafePurchaseReport(onSuccess?: () => void) {
  const ENDPOINT = "/document/cs_reports_update/"
  const patch = useTypedMutation<CreditSafeUpdateReport>(
    "patch/document/cs_reports_update/",
    onSuccess,
  )

  const execute = (
    body: Partial<CreditSafeUpdateReport>,
    onSuccess?: () => void,
  ) => {
    patch.mutate(
      {
        endpoint: ENDPOINT + body.credit_safe_report_id + "/",
        body,
        method: "patch",
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...patch, execute }
}
