import React, { useState, useEffect, memo } from "react"
// @mui
import { useTheme } from "@mui/material/styles"
import Box from "@mui/material/Box"
import InputBase from "@mui/material/InputBase"
import InputAdornment from "@mui/material/InputAdornment"
// hooks
import useDebounced from "src/hooks/useDebounced"
// components
import Label from "src/components/label"
import Iconify from "src/components/iconify"
import Scrollbar from "src/components/scrollbar"
import SearchNotFound from "src/components/search-not-found"
//
import { OpenCorporatesResultItem } from "src/sections/@dashboard/components/result-item"
import { useOpenCorporateSearch } from "src/queries/vendors/useOpenCorporate"
import emitter from "src/services/emitter-service"
import { OpenCorporateSearchInterface } from "src/types"
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material"
import { Close } from "@mui/icons-material"

// ----------------------------------------------------------------------

interface OpenCorporateSearchbarProps {
  isSearchDialogOpen: boolean
  handleCloseSearchDialog: () => void
}

function OpenCorporateSearchbar({
  isSearchDialogOpen,
  handleCloseSearchDialog,
}: OpenCorporateSearchbarProps) {
  const theme = useTheme()
  const [searchQuery, setSearchQuery] = useState("")
  const [jurisdictionCode, setJurisdictionCode] = useState("")
  const [companyNumber, setCompanyNumber] = useState("")
  const [timeStamp, setTimeStamp] = useState(0)

  const debouncedSearchTerm = useDebounced(searchQuery)

  const { data, isLoading } = useOpenCorporateSearch(
    debouncedSearchTerm,
    100,
    1,
  )

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleOnItemClick = (item: OpenCorporateSearchInterface) => {
    setJurisdictionCode(item.jurisdictionCode)
    setCompanyNumber(item.companyNumber)
    setTimeStamp(Date.now())
    handleCloseSearchDialog()
  }

  useEffect(() => {
    emitter.emit("jurisdictionCode", jurisdictionCode)
    emitter.emit("companyNumber", companyNumber)
    emitter.emit("timeStamp", timeStamp)

    const handleSearchTerm = (data: unknown) => setSearchQuery(data as string)

    emitter.on("applicationLegalBusinessName", handleSearchTerm)
    return () => {
      emitter.off("applicationLegalBusinessName", handleSearchTerm)
    }
  }, [companyNumber, jurisdictionCode, timeStamp])

  const notFound = data?.length == 0

  return (
    <Card>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isSearchDialogOpen}
        onClose={handleCloseSearchDialog}
      >
        <DialogTitle>Registry Search</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseSearchDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <Box sx={{ p: 3, borderBottom: `solid 1px ${theme.palette.divider}` }}>
          <InputBase
            fullWidth
            autoFocus
            placeholder="Search by company name..."
            value={searchQuery}
            onChange={handleSearch}
            startAdornment={
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  width={24}
                  sx={{ color: "text.disabled" }}
                />
              </InputAdornment>
            }
            endAdornment={
              <Label sx={{ letterSpacing: 1, color: "text.secondary" }}>
                esc
              </Label>
            }
            inputProps={{
              sx: { typography: "h6" },
            }}
          />
        </Box>
        <DialogContent>
          {isLoading && <CircularProgress />}
          {!isLoading && notFound && (
            <SearchNotFound query={debouncedSearchTerm} sx={{ py: 10 }} />
          )}
          {!isLoading && !notFound && (
            <Scrollbar sx={{}}>
              <OpenCorporatesResultItem
                data={data}
                onClick={handleOnItemClick}
              />
            </Scrollbar>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseSearchDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}

export default memo(OpenCorporateSearchbar)
