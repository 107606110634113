import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import { Formik } from "formik"
import { useCreateInternalSelectableUserInvite } from "src/queries/internal/useCreateInternalSelectableUserInvite"
import { Business, Group, SelectableUserInvite } from "src/types"
import * as yup from "yup"
import { enqueueSnackbar } from "notistack"
import { generateString } from "src/utils/utils"

export default function AddUserSelectableInviteDialog({
  open,
  onClose,
  business,
  refetch,
}: {
  open: boolean
  onClose: () => void
  business: Business
  refetch: () => void
}) {
  const schema = yup.object({
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    email: yup.string().required("Required"),
    business: yup.string().required("Required"),
    password: yup.string().required("Required"),
    groups: yup.string().required("Required"),
  })
  const { execute, isLoading } = useCreateInternalSelectableUserInvite()
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth={"md"} fullWidth>
        <DialogTitle id="alert-dialog-title">
          Invite New User Via Email
        </DialogTitle>
        <DialogContent sx={{ p: 2 }}>
          <Formik
            initialValues={
              {
                business: business.id,
                password: generateString(8),
              } as SelectableUserInvite
            }
            onSubmit={(values: SelectableUserInvite) => {
              values.username = values.email + String(Math.floor(Math.random()))
              values.groups = [values.groups as string]
              execute(values, () => {
                enqueueSnackbar("Invite Sent updated", {
                  variant: "success",
                })
                refetch()
                onClose()
              })
            }}
            validationSchema={schema}
          >
            {(props) => (
              <>
                <Grid direction={"row"} container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      id="firstName"
                      name="firstName"
                      margin="normal"
                      fullWidth
                      label="FirstName"
                      type="text"
                      value={props.values.firstName}
                      onChange={props.handleChange}
                      error={Boolean(props.errors?.firstName)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="lastName"
                      name="lastName"
                      margin="normal"
                      fullWidth
                      label="LastName"
                      type="text"
                      value={props.values.lastName}
                      onChange={props.handleChange}
                      error={Boolean(props.errors?.lastName)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="email"
                      name="email"
                      margin="normal"
                      fullWidth
                      label="Email"
                      type="text"
                      value={props.values.email}
                      onChange={props.handleChange}
                      error={Boolean(props.errors?.email)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption">Groups</Typography>
                    <Select
                      fullWidth
                      labelId="group-label"
                      id="groups"
                      label="groups"
                      name="groups"
                      value={props.values.groups}
                      onChange={props.handleChange}
                      error={Boolean(props.errors?.groups)}
                    >
                      <MenuItem disabled value={undefined}>
                        Select one
                      </MenuItem>
                      <MenuItem value={Group.CreditManager}>
                        Credit Manager
                      </MenuItem>
                      <MenuItem value={Group.Buyer}>Buyer</MenuItem>
                      <MenuItem value={Group.NetNowAdmin}>NetNowAdmin</MenuItem>
                      <MenuItem value={Group.Sales}>Sales</MenuItem>
                      <MenuItem value={Group.SalesManager}>
                        SalesManager
                      </MenuItem>
                      <MenuItem value={Group.NeedsSelfOnboarding}>
                        NeedsSelfOnboarding
                      </MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      size="small"
                      fullWidth
                      disabled={isLoading}
                      onClick={() => {
                        props.handleSubmit()
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
