// @mui
import React, { useState } from "react"
import { Application, TemplateCustomField, TinType } from "src/types"

import {
  Box,
  Button,
  CardHeader,
  CircularProgress,
  ButtonGroup,
  FormControl,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material"

import { confirm } from "src/components/confirm"

// ----------------------------------------------------------------------

import { CloseOutlined, Edit, SaveOutlined } from "@mui/icons-material"
import { useFormik } from "formik"
import Label from "src/components/label"
import EditableTypography from "src/components/editable-typography"
import parsePhoneNumberFromString, {
  isPossiblePhoneNumber,
} from "libphonenumber-js"
import { info, error } from "src/utils/logger"
import SsnVerificationIcon from "src/sections/@dashboard/credit/components/verification-icon/ssnVerificationIcon"
import EinVerificationIcon from "src/sections/@dashboard/credit/components/verification-icon/einVerificationIcon"

interface rowType {
  name: string
  key: string
  options?: string[]
  postFix?: string
  customField?: TemplateCustomField | undefined
}

export default ({
  title,
  subtitle,
  subheader,
  applicationId,
  data,
  rows,
  isPatching,
  patchApplication,
  actions,
}: {
  title: string | React.ReactNode
  subtitle?: string
  applicationId: string
  subheader?: React.ReactNode
  data: Application["data"]
  isPatching: boolean
  actions?: React.ReactNode
  patchApplication: (
    applicationId: string,
    body: { data: Application["data"] },
    cb: () => void,
  ) => unknown
  rows: Array<Array<rowType>>
}) => {
  const [editable, setEditable] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDataFieldUpdated = (key: string, value: any) => {
    formik.setFieldValue(key, value, false)
  }

  const onCustomFieldUpdated = (field: TemplateCustomField, value: any) => {
    const customFields = formik.values.customFields || {}
    customFields[field.id as string] = value
    customFields[field.id as string].field = field
    formik.setFieldValue("customFields", customFields, false)
  }

  const displayItemKey = (value: any, postFix: string | undefined) => {
    if (postFix && value) {
      return value + postFix
    }
    return value
  }

  const cleanData = (values: Application["data"]) => {
    for (const key in values) {
      if (key.includes("ownerPercentage")) {
        values[key] = values[key].replace("%", "")
      }
    }
    return values
  }

  const formik = useFormik({
    initialValues: {} as Application["data"],
    enableReinitialize: true,
    onSubmit: (values: Application["data"]) => {
      values = cleanData(values)
      patchApplication(applicationId, { data: values }, () => {
        setEditable(false)
      })
    },
  })
  return (
    <>
      <CardHeader
        subheader={subheader}
        title={
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "8px",
            }}
          >
            {typeof title === "string" ? (
              <Typography variant="h6">{title}</Typography>
            ) : (
              title
            )}

            {isPatching && <CircularProgress />}

            {!isPatching && !editable && (
              <ButtonGroup variant="outlined">
                {actions && actions}
                <Button
                  onClick={() => {
                    setEditable(true)
                  }}
                >
                  <Edit />
                </Button>
              </ButtonGroup>
            )}

            {!isPatching && editable && (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "1px",
                }}
              >
                <Label variant="outlined" color={"error"}>
                  Unsaved Changes
                </Label>
                <IconButton
                  onClick={() => {
                    confirm("You are about to discard your changes.", {
                      okLabel: "Discard",
                    })
                      .then(
                        () => {
                          formik.resetForm()
                          return setEditable(false)
                        },
                        () => {
                          info("cancelled")
                        },
                      )
                      .catch(() => {
                        error("error")
                      })
                  }}
                >
                  <CloseOutlined />
                </IconButton>
                <IconButton
                  onClick={() => {
                    confirm(
                      "You are about to make changes to this application.",
                      {
                        // okColor: "error",
                        okLabel: "Save",
                      },
                    )
                      .then(
                        () => {
                          formik
                            .validateForm()
                            .then((errors) => {
                              if (Object.keys(errors).length === 0) {
                                formik.submitForm()
                              }
                              return
                            })
                            .catch((err) => {
                              error(err)
                            })
                          return
                        },
                        () => {
                          info("cancelled")
                        },
                      )
                      .catch(() => {
                        setEditable(false)
                      })
                  }}
                >
                  <SaveOutlined />
                </IconButton>
              </Box>
            )}
          </Box>
        }
      />
      <Stack>
        {subtitle && (
          <Typography variant="subtitle2" sx={{ pl: 1.5, pt: 1.5 }}>
            {subtitle}
          </Typography>
        )}
      </Stack>
      <Stack direction="row" sx={{ p: subtitle ? 1.5 : 3, gap: 3 }}>
        {rows.map((columns, index) => {
          return (
            <Stack
              spacing={1.5}
              alignItems="flex-start"
              sx={{ typography: "body2", width: "50%" }}
              key={index}
            >
              {columns.map((item, index) => {
                if (!item) {
                  return <></>
                }
                return (
                  <Stack
                    direction="row"
                    alignItems="center"
                    key={index}
                    style={{ height: "100%", width: "auto" }}
                  >
                    <Box
                      sx={{
                        color: "text.secondary",
                        width: 120,
                        flexShrink: 0,
                        height: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          color: "text.secondary",
                          justifyContent: "center",
                          display: "flex",
                          height: "100%",
                          flexDirection: "column",
                        }}
                      >
                        <span
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        >
                          {item.name}
                          {item.name === TinType.SSN &&
                            SsnVerificationIcon({
                              name: data[
                                `ownerName${item.key.split("ownerSsn")[1]}`
                              ],
                              tin: data[
                                `ownerSsn${item.key.split("ownerSsn")[1]}`
                              ],
                            })}
                          {item.name === TinType.EIN &&
                            EinVerificationIcon({
                              name: data?.seller,
                              tin: data?.ein,
                            })}
                        </span>
                      </Box>
                    </Box>
                    {!item.customField &&
                      editable &&
                      item.options &&
                      item.options.length > 0 && (
                        <FormControl size="small" sx={{ minWidth: 120 }}>
                          {/* <InputLabel id="demo-simple-select-label">
                          {item.name}
                        </InputLabel> */}
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={
                              (formik.values as { [key: string]: string })?.[
                                item.key
                              ] || data[item.key]
                            }
                            // label={item.name}
                            onChange={(e) => {
                              onDataFieldUpdated(item.key, e.target.value)
                            }}
                            input={
                              <OutlinedInput
                                margin="dense"
                                style={{ fontSize: "0.8rem" }}
                              />
                            }
                          >
                            {item.options.map((type) => (
                              <MenuItem value={type} key={item.name + type}>
                                {type}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}

                    {!item.customField &&
                      (!item.options ||
                        item.options.length === 0 ||
                        !editable) && (
                        <>
                          <EditableTypography
                            variant="body2"
                            editable={editable}
                            onChange={(val) => {
                              onDataFieldUpdated(item.key, val as string)
                            }}
                          >
                            {data[item.key] &&
                            isPossiblePhoneNumber(data[item.key], "US")
                              ? `${parsePhoneNumberFromString(
                                  data[item.key] || "",
                                  "US",
                                )?.formatInternational()}`
                              : displayItemKey(data[item.key], item?.postFix)}
                          </EditableTypography>
                          {data[item.key] &&
                            isPossiblePhoneNumber(data[item.key], "US") && (
                              <EditableTypography
                                variant="body2"
                                editable={editable}
                                onChange={(val) => {
                                  onDataFieldUpdated(
                                    item.key + "Ext",
                                    (val as string).replace("ext. ", ""),
                                  )
                                }}
                              >
                                {`ext. ${data[item.key + "Ext"] || "--"}`}
                              </EditableTypography>
                            )}
                        </>
                      )}

                    {item.customField &&
                      (!item.options ||
                        item.options.length === 0 ||
                        !editable) && (
                        <>
                          <EditableTypography
                            variant="body2"
                            editable={editable}
                            onChange={(val) => {
                              const field =
                                data?.customFields[
                                  item.customField?.id as string
                                ]
                              if (field) {
                                field.responseJson[item.key] = val as string
                              }
                              onCustomFieldUpdated(
                                item.customField as TemplateCustomField,
                                field,
                              )
                            }}
                          >
                            {data?.customFields[
                              item.customField?.id as string
                            ] &&
                              data?.customFields[item.customField?.id as string]
                                .responseJson[item.key]}
                          </EditableTypography>
                        </>
                      )}

                    {item.customField &&
                      editable &&
                      item.options &&
                      item.options.length > 0 && (
                        <FormControl size="small" sx={{ minWidth: 120 }}>
                          {/* <InputLabel id="demo-simple-select-label">
                          {item.name}
                        </InputLabel> */}
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={
                              (formik.values?.customFields &&
                                formik.values?.customFields[
                                  item.customField?.id as string
                                ]?.responseJson[item.key]) ||
                              data?.customFields[item.customField?.id as string]
                                ?.responseJson[item.key]
                            }
                            // label={item.name}
                            onChange={(e) => {
                              const field =
                                data?.customFields[
                                  item.customField?.id as string
                                ]
                              if (field) {
                                field.responseJson[item.key] = e.target
                                  .value as string
                              }
                              onCustomFieldUpdated(
                                item.customField as TemplateCustomField,
                                field,
                              )
                            }}
                            input={
                              <OutlinedInput
                                margin="dense"
                                style={{ fontSize: "0.8rem" }}
                              />
                            }
                          >
                            {item?.options?.map((type) => (
                              <MenuItem value={type} key={item.name + type}>
                                {type}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                  </Stack>
                )
              })}
            </Stack>
          )
        })}
      </Stack>
    </>
  )
}
