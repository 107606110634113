import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Divider,
} from "@mui/material"
import AssessmentIcon from "@mui/icons-material/Assessment"
import { FraudAlertSection } from "./credit-profile/FraudAlertSection"
import { CreditScoreSection } from "./credit-profile/CreditScoreSection"
import { AccountSummarySection } from "./credit-profile/AccountSummarySection"
import { AccountDetailsSection } from "./credit-profile/AccountDetailsSection"

import { CVCreditSummary } from "src/types/vendors.types"
import { IDMismatchSection } from "./credit-profile/IDMismatchSection"

interface AddOnProductProps {
  data: Array<{
    code?: string
    status?: string
    // TruValidate ID Mismatch Alerts
    idMismatchAlert?: {
      type?: string
      condition?: string
      inquiriesInLast60Count?: string
    }
    // TruValidate Fraud Alerts
    highRiskFraudAlert?: {
      "@searchStatus": string
    }
    // FICO® Score 98
    scoreModel?: {
      score?: {
        results?: string
        derogatoryAlert?: string
        fileInquiriesImpactedScore?: string
        factors?: {
          factor?: Array<{
            code?: string
            rank?: string
          }>
        }
      }
    }
    //TruVision Trended Credit Summary
    CVCreditSummary?: CVCreditSummary
  }>
}

const AddOnProductCard = ({ data }: AddOnProductProps) => {
  const formatCurrency = (amount?: string) => {
    if (!amount) return ""
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(parseInt(amount))
  }

  const formatDate = (date?: string) => {
    if (!date) return ""
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    })
  }

  const fraudAlert = data.find((product) => product.highRiskFraudAlert)
  const idMismatchAlert = data.find((product) => product.idMismatchAlert)
  const scoreModel = data.find((product) => product.scoreModel)
  const creditSummary = data.find((product) => product.CVCreditSummary)

  return (
    <Card elevation={2}>
      <CardContent>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <AssessmentIcon sx={{ mr: 1 }} />
          <Typography variant="h6">Credit Profile Summary</Typography>
        </Box>

        <Grid container spacing={3}>
          {fraudAlert && fraudAlert.highRiskFraudAlert && (
            <FraudAlertSection fraudAlert={fraudAlert} />
          )}

          {idMismatchAlert && (
            <IDMismatchSection
              idMismatchAlert={idMismatchAlert.idMismatchAlert}
            />
          )}

          {scoreModel && scoreModel.scoreModel && (
            <CreditScoreSection
              scoreModel={scoreModel.scoreModel}
              code={scoreModel.code}
            />
          )}

          {creditSummary && creditSummary.CVCreditSummary && (
            <>
              <AccountSummarySection
                creditSummary={creditSummary.CVCreditSummary}
                formatDate={formatDate}
                formatCurrency={formatCurrency}
              />

              {creditSummary.CVCreditSummary.accountSummaryDetail && (
                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} />
                  <Typography color="text.secondary" gutterBottom>
                    Account Details
                  </Typography>
                  <AccountDetailsSection
                    accounts={
                      creditSummary.CVCreditSummary.accountSummaryDetail
                    }
                    formatCurrency={formatCurrency}
                    formatDate={formatDate}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}

export default AddOnProductCard
