import { useState, useRef } from "react"
// @mui
import Stack from "@mui/material/Stack"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Unstable_Grid2"

// components
import { useParams } from "../../../../routes/hook"
//
import CreditApplicationDetailsInfo from "../credit-application-details-info"
import CreditApplicationTabs from "../credit-application-tabs"
import CreditApplicationDetailsToolbar from "../CreditApplicationDetailsToolbar"
import { useCreditApplication } from "../../../../queries/credit/useCreditApplication"
import {
  TimelineResponse,
  useApplicationTimeline,
} from "src/queries/credit/useApplicationTimeline"

import CreditApplicationDetailsExtra from "../credit-application-details-extra"
import CreditApplicationBaseInfo from "../credit-application-base-info"
import CreditApplicationTimeline from "../credit-application-timeline"
import ApplicationComments from "../components/ApplicationComments"
import { CLIENTS } from "src/statics"
import { useUser } from "src/queries"
import { Paper, Typography } from "@mui/material"
// ----------------------------------------------------------------------

export default function CreditApplicationDetailsView() {
  const params = useParams()

  const { id } = params

  const { data, refetch } = useCreditApplication(id)
  const { isCreditManager } = useUser()

  const { data: timelineData, refetch: refetchApplicationTimeline } =
    useApplicationTimeline(data?.id)

  const [tab, setTab] = useState(0)

  const refreshCommentTrigger = useRef(0)
  const refreshApplicationTrigger = useRef(0)

  const setRefreshCommentTrigger = (value: number) => {
    refreshCommentTrigger.current = value
  }

  const triggerApplicationToolbarRefresh = () => {
    refreshApplicationTrigger.current = refreshApplicationTrigger.current + 1
  }

  if (!data) {
    return <></>
  }

  if (!isCreditManager) {
    return (
      <>
        <Paper sx={{ p: 4, textAlign: "center" }}>
          <Typography variant="h6">
            You do not have permission to view this page, Please refresh your
            browser or contact your administrator
          </Typography>
        </Paper>
      </>
    )
  }

  return (
    <Container maxWidth={false}>
      <CreditApplicationDetailsToolbar
        backLink={"/applications"}
        application={data}
        refreshCommentTrigger={refreshCommentTrigger}
        refreshApplicationTrigger={refreshApplicationTrigger}
        setRefreshCommentTrigger={setRefreshCommentTrigger}
        refetchTimeline={() => {
          refetch()
          refetchApplicationTimeline()
        }}
      />
      {!data?.seller?.name.includes(CLIENTS.QUECAN) && (
        <>
          <CreditApplicationTabs application={data} tab={tab} setTab={setTab} />
        </>
      )}
      <Grid container spacing={3}>
        <Grid xs={12} md={8}>
          {!data?.seller?.name.includes(CLIENTS.QUECAN) && (
            <CreditApplicationBaseInfo
              applicationId={data.id as string}
              triggerApplicationToolbarRefresh={
                triggerApplicationToolbarRefresh
              }
              tab={tab}
            />
          )}

          {data?.seller?.name.includes(CLIENTS.QUECAN) && (
            <CreditApplicationDetailsInfo application={data} />
          )}
        </Grid>
        <Grid xs={12} md={4}>
          <Stack spacing={3} direction={{ xs: "column-reverse", md: "column" }}>
            {!data?.seller?.name.includes(CLIENTS.QUECAN) && (
              <CreditApplicationTimeline
                timelineData={timelineData as TimelineResponse}
              />
            )}

            {[CLIENTS.QUECAN].includes(data?.seller?.name as string) && (
              <CreditApplicationDetailsExtra />
            )}

            <ApplicationComments
              application={data}
              refreshCommentTrigger={refreshCommentTrigger}
            />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  )
}
