import { CreditApplication } from "src/types"
import { Card, CardHeader, Tooltip, Typography } from "@mui/material"

import * as React from "react"
import Timeline from "@mui/lab/Timeline"
import TimelineItem from "@mui/lab/TimelineItem"
import TimelineSeparator from "@mui/lab/TimelineSeparator"
import TimelineConnector from "@mui/lab/TimelineConnector"
import TimelineContent from "@mui/lab/TimelineContent"
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent"
import TimelineDot from "@mui/lab/TimelineDot"

import { TimelineResponse } from "src/queries/credit/useApplicationTimeline"

import { getSVGIcon } from "../utils"

export default function ApplicationTimeline({
  timelineData,
}: {
  timelineData: TimelineResponse
}) {
  const { timeline, activeStep } = timelineData
    ? timelineData
    : { timeline: [], activeStep: 0 }

  if (!timelineData) return null

  return (
    <>
      <Card>
        <CardHeader title="Timeline of Events" />
        <Timeline position="alternate">
          {timeline?.map((v, i) => {
            const timelineItemContent = (
              <TimelineItem>
                <TimelineOppositeContent
                  sx={{
                    m: "auto 0",
                    alignSelf: "center",
                    paddingBottom: "11px",
                  }}
                  variant="subtitle2"
                >
                  {v.label}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  {v.label !== "Approved for Credit" &&
                  v.label !== "Application Rejected" &&
                  v.completed ? (
                    <TimelineDot>{getSVGIcon(v.label)}</TimelineDot>
                  ) : (
                    getSVGIcon(v.label)
                  )}
                  {i !== timeline.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent
                  sx={{
                    alignSelf: "center",
                    paddingBottom: "11px",
                  }}
                  color="text.secondary"
                  variant="body1"
                >
                  {v.date}
                </TimelineContent>
              </TimelineItem>
            )

            const tooltipText = v.metadata ? (
              <>
                <Typography variant="body2">
                  Reference Name: {v.metadata.referenceName}
                </Typography>
                <Typography variant="body2">
                  Reference Email: {v.metadata.referenceEmail}
                </Typography>
              </>
            ) : (
              ""
            )

            return (
              <React.Fragment key={i}>
                {v.metadata ? (
                  <Tooltip title={tooltipText} arrow>
                    {timelineItemContent}
                  </Tooltip>
                ) : (
                  timelineItemContent
                )}
              </React.Fragment>
            )
          })}
        </Timeline>
      </Card>
    </>
  )
}
