import { FormikProps, useFormik } from "formik"
import { enqueueSnackbar } from "notistack"
import { useRef } from "react"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"
import { ApplicationTemplate, TemplateCustomField } from "src/types"
import * as yup from "yup"
import { isEqual } from "lodash"
import { usePatchInternalBusiness } from "src/queries/internal/useInternalPatchBusiness"
import CreditApplicationBrandingInfo from "src/sections/@dashboard/credit/credit-application-branding-info"
import CreditApplicationConfigCustomFields from "src/sections/@dashboard/credit/credit-application-config-customfields"
import { Button, Stack } from "@mui/material"
import { Save } from "@mui/icons-material"
import CODApplicationConfigCustomFields from "src/sections/@dashboard/cod/CODApplicationConfigCustomFields"

export default function ClientApplicationSettings({ id }: { id: string }) {
  const { data, refetch, isLoading: isFetching } = useApplicationTemplate(id)
  const { execute: patchBusiness, isLoading: isPatching } =
    usePatchInternalBusiness()
  const validationSchema = yup.object({
    data: yup.object().shape({
      color: yup.string().max(6),
    }),
  })

  const formik = useFormik({
    initialValues: (data as Pick<ApplicationTemplate, "color">) || {
      color: "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values: Pick<ApplicationTemplate, "color">) => {
      if (id && !isEqual(values, data)) {
        patchBusiness(id, { color: values.color }, () => {
          refetch()
          enqueueSnackbar("Color successfully updated.", {
            variant: "success",
          })
        })
      }
    },
  })

  // ref for customfields formik
  const customFieldsBagRef = useRef<FormikProps<TemplateCustomField[]> | null>(
    null,
  )

  if (!data) {
    return (
      <>
        <Stack sx={{ p: 1 }}>
          Credit Template not loaded Please check if it has been created
        </Stack>
      </>
    )
  }

  return (
    <>
      <Stack sx={{ p: 1 }}>
        <CreditApplicationBrandingInfo
          template={formik.values}
          onColorChanged={(field, value) => {
            if (
              // i wrote this on a plane with no internet. cut me some slack.
              value.length < 7 &&
              Array.from(new Set(value)).every(
                (char) => char.toLowerCase() < "g",
              )
            ) {
              formik.setFieldValue(field, value)
            }
          }}
          onFileChanged={() => {
            refetch()
          }}
        />
      </Stack>
      <Button
        color="primary"
        fullWidth={false}
        size="medium"
        variant="contained"
        endIcon={<Save />}
        disabled={isFetching || isPatching}
        sx={{ textTransform: "capitalize" }}
        onClick={() => {
          formik.handleSubmit()
        }}
      >
        Save Changes
      </Button>
      <Stack sx={{ p: 1 }}>
        <CreditApplicationConfigCustomFields
          bagRef={customFieldsBagRef}
          businessId={id}
        />
        <CODApplicationConfigCustomFields businessId={id} />
      </Stack>
    </>
  )
}
