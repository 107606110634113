export type KYBStatus = {
  status: boolean
}

export type AccountingSyncStatus = {
  status: boolean
}

export type RailsToken = {
  token: string
}

export type PlaidLinkToken = {
  linkToken: string
}

export type PlaidExchangeTokenBody = {
  publicToken: string
  businessId: string
}

export type DocumentField = {
  fieldLabel: string
  fieldValue: string
  fieldName: string
}

export type DocumentFields = {
  fields: Array<DocumentField>
}

export type AccountInfo = {
  AccountNumber: string
  InstitutionNumber: string
  TransitNumber: string
}

export type DocumentDetails = {
  status: boolean
}

//
// CreditSafe
//
export type CreditSafeSearchResult = {
  name: string
  address: {
    simpleValue: string
  }
  id: string
  country: string
  status: string
  officeType: string
}

export type CreditSafeCompanySummary = {
  businessName: string
  country: string
  companyNumber: string
  companyRegistrationNumber: string
  ggsID: string
  leiNumber: string
  mainActivity: {
    code: string
    industrySector: string
    description: string
    classification: string
  }
  companyStatus: {
    status: string
    providerStatus: string
    providerCode: string
    isActive: boolean
    description: string
  }
  latestTurnoverFigure: {
    currency: string
    value: number
  }
  latestShareholdersEquityFigure: {
    currency: string
    value: number
  }
  creditRating: {
    commonValue: string
    commonDescription: string
    creditLimit: {
      currency: string
      value: string
    }
    providerValue: {
      maxValue: string
      minValue: string
      value: string
    }
    providerDescription: string
    pod: number
    assessment: string
  }
}

export type CreditSafeCreditScore = {
  currentCreditRating: {
    commonValue: string
    commonDescription: string
    creditLimit: {
      currency: string
      value: string
    }
    providerValue: {
      maxValue: string
      minValue: string
      value: string
    }
    providerDescription: string
    pod: number
    assessment: string
  }
  currentContractLimit: {
    currency: string
    value: number
  }
  previousCreditRating: {
    commonValue: string
    commonDescription: string
    creditLimit: {
      currency: string
      value: string
    }
    providerValue: {
      maxValue: string
      minValue: string
      value: string
    }
    providerDescription: string
    pod: number
    assessment: string
  }
  latestRatingChangeDate: string
}

export type CreditSafeDBTHistory = {
  creditReferenceSummaries: {
    currency: string
    current: number
    description: string
    highCredit: number
    numberofCreditReferences: number
    paymentIndex: string
    period1: string
    period2: string
    period3: string
    totalOwing: number
  }[]
  historicTradeInformation: {
    currency: string
    current: number
    month: string
    pastDue: number
    period1: number
    period2: number
    period3: number
    severelyPastDue: number
    total: number
  }[]
  dbtHistory: {
    date: string
    industryDBT: number
  }[]
  dbtTrends: {
    dbt: string
    year: number
    quarter: number
    currency: string
  }[]
  tradePaymentInformation: {
    currency: string
    current: number
    currentPercentage: number
    period1: number
    period1Percentage: number
    period2: number
    period2Percentage: number
    period3: number
    period3Percentage: number
    total: number
    totalTradeLines: number
  }
  tradePaymentDashboard: {
    activeTradeLines: number
    averageCreditAmount: number
    creditRatio: number
    currency: string
    daysBeyondTerms: string
    highestAmountPastDue: number
    lastUpdated: string
    pastDue: number
    percentPastDue: number
    recentHighCredit: number
    severelyPastDue: number
    totalBalance: number
    totalTradeLines: number
  }

  tradePaymentsByCreditExtended: {
    creditExtendedRange: string
    currency: string
    pastDue: string
    totalAmount: string
    tradeLines: string
    withinTerms: string
  }[]
  outstandingByHighCreditUtilization: {
    balance: number
    creditUtilizationBand: string
    currency: string
    highCredit: number
  }[]
}

export type CreditSafeFinancialStatement = {
  type: string
  yearEndDate: string
  numberOfWeeks: number
  currency: string
  consolidatedAccounts: true
  profitAndLoss: {
    revenue: number
    operatingCosts: number
    operatingProfit: number
    wagesAndSalaries: number
    pensionCosts: number
    depreciation: number
    amortisation: number
    financialIncome: number
    financialExpenses: number
    extraordinaryIncome: number
    extraordinaryCosts: number
    profitBeforeTax: number
    tax: number
    profitAfterTax: number
    dividends: number
    minorityInterests: number
    otherAppropriations: number
    retainedProfit: number
  }
  balanceSheet: {
    landAndBuildings: number
    plantAndMachinery: number
    otherTangibleAssets: number
    totalTangibleAssets: number
    goodwill: number
    otherIntangibleAssets: number
    totalIntangibleAssets: number
    investments: number
    loansToGroup: number
    otherLoans: number
    miscellaneousFixedAssets: number
    totalOtherFixedAssets: number
    totalFixedAssets: number
    rawMaterials: number
    workInProgress: number
    finishedGoods: number
    otherInventories: number
    totalInventories: number
    tradeReceivables: number
    groupReceivables: number
    receivablesDueAfter1Year: number
    miscellaneousReceivables: number
    totalReceivables: number
    cash: number
    otherCurrentAssets: number
    totalCurrentAssets: number
    totalAssets: number
    tradePayables: number
    bankLiabilities: number
    otherLoansOrFinance: number
    groupPayables: number
    miscellaneousLiabilities: number
    totalCurrentLiabilities: number
    tradePayablesDueAfter1Year: number
    bankLiabilitiesDueAfter1Year: number
    otherLoansOrFinanceDueAfter1Year: number
    groupPayablesDueAfter1Year: number
    miscellaneousLiabilitiesDueAfter1Year: number
    totalLongTermLiabilities: number
    totalLiabilities: number
    calledUpShareCapital: number
    sharePremium: number
    revenueReserves: number
    otherReserves: number
    totalShareholdersEquity: number
  }
  otherFinancials: {
    contingentLiabilities: string
    workingCapital: number
    netWorth: number
  }
  ratios: {
    preTaxProfitMargin: number
    returnOnCapitalEmployed: number
    returnOnTotalAssetsEmployed: number
    returnOnNetAssetsEmployed: number
    salesOrNetWorkingCapital: number
    stockTurnoverRatio: number
    debtorDays: number
    creditorDays: number
    currentRatio: number
    liquidityRatioOrAcidTest: number
    currentDebtRatio: number
    gearing: number
    equityInPercentage: number
    totalDebtRatio: number
  }
}

export type CreditSafeTpdMonthlyBalanceHistories = {
  date: string
  currency: string
  range1to30: number
  range31to60: number
  range61to90: number
  range91plus: number
  totalBalance: number
  balanceCurrent: number
}[]

export type CreditSafePaidMonthlyBalanceHistories = {
  date: string
  currency: string
  paid1To30: number
  paid31To60: number
  paid61To90: number
  paid91Plus: number
  paidCurrent: number
}[]

export type CreditSafeExtendedPaymentData = {
  paidInvoices: {
    paidInvoicesHistory: {
      last3MonthsCount: number
      last12MonthsCount: number
      medianInvoiceValue: number
      averageInvoiceValue: number
      singleLowestInvoice: number
      singleHighestInvoice: number
    }
    paidInvoicesSummary: {
      totalValue: number
      totalTradeLines: number
      uniqueCompanies: number
    }
  }
  miniDashBoard: {
    dbt: string
    paidLate: string
    paidOnTime: string
    creditRatio: string
    pastDueTotal: string
    totalBalance: string
    valuePaidLate: string
    highestPastDue: string
    pastDuePercent: string
    totalValuePaid: string
    totalTradelines: string
    valuePaidOnTime: string
    activeTradeLines: string
    paidSeverelyLate: string
    recentHighCredit: string
    activeCreditAmount: string
    pastDueSevereTotal: string
    industryPerformance: string
    valuePaidSeverelyLate: string
    highestSeverelyPastDue: string
  }
  pastDueBalances: {
    pastDueHigh: {
      currency: string
      highTradeLines12Month: number
      highTradeLinesLifetime: number
      highTradeLinesSevere12Month: number
      highTradeLinesSevereLifetime: number
    }
    pastDueTotal: {
      currency: string
      totalTradeLines12Month: number
      totalTradeLinesLifetime: number
      totalTradeLinesSevere12Month: number
      totalTradeLinesSevereLifetime: number
    }
    pastDueBalance: {
      currency: string
      highSingle12Month: number
      highBalance12Month: number
      highSingleLifetime: number
      highBalanceLifetime: number
      totalBalance12Month: number
      totalBalanceLifetime: number
      highSingleSevere12Month: number
      highBalanceSevere12Month: number
      highSingleSevereLifetime: number
      highBalanceSevereLifetime: number
      totalBalanceSevere12Month: number
      totalBalanceSevereLifetime: number
    }
  }
  tradeReferences: {
    tradeReferenceSummary: {
      paymentScore: number
      experienceScore: number
      combinedCreditLimit: number
      valueOfTotalTradeLines: number
      numberOfTotalTradeLines: number
      numberOfUniqueTradeLineProviders: number
    }
  }
  paidTpdIndustries: {
    currency: string
    industryCode: string
    invoicesPaid: number
    tradeLinesPaid: number
    valuePercent1To30: number
    invoicesPaidOnTime: number
    valuePercent31To60: number
    valuePercent61To90: number
    valuePercent91Plus: number
    invoicesPercent1To30: number
    invoicesPaid1To30Late: number
    invoicesPercent31To60: number
    invoicesPercent61To90: number
    invoicesPercent91Plus: number
    invoicesPaid31To60Late: number
    invoicesPaid61To90Late: number
    invoicesPaid91PlusLate: number
    valuePercentPaidOnTime: number
    invoicesValuePaidOnTime: number
    invoicesPercentPaidOnTime: number
    invoicesValuePaid1To30Late: number
    invoicesValuePaid31To60Late: number
    invoicesValuePaid61To90Late: number
    invoicesValuePaid91PlusLate: number
  }[]
  highCreditBalances: {
    currency: string
    singleDbt: number
    averageDbt: number
    tradeLinesDbt: number
    averageDbtDate: string
    singleBalance130: number
    averageBalance130: number
    singleBalance3160: number
    singleBalance6190: number
    tradeLinesDbtDate: string
    averageBalance3160: number
    averageBalance6190: number
    singleBalance91Plus: number
    averageBalance91Plus: number
    singleBalanceCurrent: number
    tradeLinesBalance130: number
    averageBalanceCurrent: number
    tradeLinesBalance3160: number
    tradeLinesBalance6190: number
    tradeLinesBalance91Plus: number
    singleBalanceCurrentDate: string
    tradeLinesBalanceCurrent: number
    averageBalanceCurrentDate: string
    tradeLinesBalanceCurrentDate: string
  }
  tpdIndustryBreakdown: {
    total: number
    currency: string
    range1to30: number
    tradeLines: number
    range31to60: number
    range61to90: number
    range91plus: number
    currentValue: number
    industryCode: string
    percent1to30: number
    totalPercent: number
    percent31to60: number
    percent61to90: number
    percent91plus: number
    currentPercent: number
    industryDescription: string
  }[]
  tradeReferenceHistory: {
    avgVal: number
    maxVal: number
    medianVal: number
    averageScore: number
    numberOfReferences: number
    estimatedTotalValue: number
  }
  lifetimeHighBalanceChart: {
    high3Month: {
      dbt: number
      currency: string
      singleHighBalance: number
      averageHighBalance: number
      tradeLinesHighBalance: number
    }
    high6Month: {
      dbt: number
      currency: string
      singleHighBalance: number
      averageHighBalance: number
      tradeLinesHighBalance: number
    }
    high9Month: {
      dbt: number
      currency: string
      singleHighBalance: number
      averageHighBalance: number
      tradeLinesHighBalance: number
    }
    high12Month: {
      dbt: number
      currency: string
      singleHighBalance: number
      averageHighBalance: number
      tradeLinesHighBalance: number
    }
    highLifetime: {
      dbt: number
      currency: string
      singleHighBalance: number
      averageHighBalance: number
      tradeLinesHighBalance: number
    }
  }
  tpdTradeLinesInformation: {
    currency: string
    creditUtilization: number
    averageCreditAmount: number
    industryPerformance: number
  }
  tpdMonthlyBalanceHistories: CreditSafeTpdMonthlyBalanceHistories
  paidMonthlyBalanceHistories: {
    date: string
    currency: string
    paid1To30: number
    paid31To60: number
    paid61To90: number
    paid91Plus: number
    paidCurrent: number
  }[]
  paidTradePaymentInformation: {
    currency: string
    tradeLinesPaid: number
    valuePercent1To30: number
    invoicesPaidOnTime: number
    valuePercent31To60: number
    valuePercent61To90: number
    valuePercent91Plus: number
    invoicesPercent1To30: number
    invoicesPaid1To30Late: number
    invoicesPercent31To60: number
    invoicesPercent61To90: number
    invoicesPercent91Plus: number
    invoicesPaid31To60Late: number
    invoicesPaid61To90Late: number
    invoicesPaid91PlusLate: number
    valuePercentPaidOnTime: number
    invoicesValuePaidOnTime: number
    invoicesPercentPaidOnTime: number
    invoicesValuePaid1To30Late: number
    invoicesValuePaid31To60Late: number
    invoicesValuePaid61To90Late: number
    invoicesValuePaid91PlusLate: number
  }
  tradePaymentsByCreditExtended: {
    credit1kTo5k: {
      pastDue: number
      currency: string
      tradeLines: number
      totalAmount: number
      percentPastDue: number
      percentWithinTerms: number
    }
    creditOver1m: {
      pastDue: number
      currency: string
      tradeLines: number
      totalAmount: number
      percentPastDue: number
      percentWithinTerms: number
    }
    credit5kTo25k: {
      pastDue: number
      currency: string
      tradeLines: number
      totalAmount: number
      percentPastDue: number
      percentWithinTerms: number
    }
    creditUnder1k: {
      pastDue: number
      currency: string
      tradeLines: 2
      totalAmount: number
      percentPastDue: number
      percentWithinTerms: number
    }
    credit100kTo1m: {
      pastDue: number
      currency: string
      tradeLines: number
      totalAmount: number
      percentPastDue: number
      percentWithinTerms: number
    }
    credit25kTo50k: {
      pastDue: number
      currency: string
      tradeLines: number
      totalAmount: number
      percentPastDue: number
      percentWithinTerms: number
    }
    credit50kTo100k: {
      pastDue: number
      currency: string
      tradeLines: number
      totalAmount: number
      percentPastDue: number
      percentWithinTerms: number
    }
  }
  tradePaymentsPaidByCreditExtended: {
    credit1kTo5k: {
      pastDue: number
      currency: string
      tradeLines: number
      totalAmount: number
      percentPastDue: number
      percentWithinTerms: number
    }
    creditOver1m: {
      pastDue: number
      currency: string
      tradeLines: number
      totalAmount: number
      percentPastDue: number
      percentWithinTerms: number
    }
    credit5kTo25k: {
      pastDue: number
      currency: string
      tradeLines: number
      totalAmount: number
      percentPastDue: number
      percentWithinTerms: number
    }
    creditUnder1k: {
      pastDue: number
      currency: string
      tradeLines: 1
      totalAmount: 210
      percentPastDue: number
      percentWithinTerms: 100
    }
    credit100kTo1m: {
      pastDue: number
      currency: string
      tradeLines: number
      totalAmount: number
      percentPastDue: number
      percentWithinTerms: number
    }
    credit25kTo50k: {
      pastDue: number
      currency: string
      tradeLines: number
      totalAmount: number
      percentPastDue: number
      percentWithinTerms: number
    }
    credit50kTo100k: {
      pastDue: number
      currency: string
      tradeLines: number
      totalAmount: number
      percentPastDue: number
      percentWithinTerms: number
    }
  }
}
export type CreditSafePaidTpdIndustries = {
  currency: string
  industryCode: string
  invoicesPaid: number
  tradeLinesPaid: number
  valuePercent1To30: number
  invoicesPaidOnTime: number
  valuePercent31To60: number
  valuePercent61To90: number
  valuePercent91Plus: number
  invoicesPercent1To30: number
  invoicesPaid1To30Late: number
  invoicesPercent31To60: number
  invoicesPercent61To90: number
  invoicesPercent91Plus: number
  invoicesPaid31To60Late: number
  invoicesPaid61To90Late: number
  invoicesPaid91PlusLate: number
  valuePercentPaidOnTime: number
  invoicesValuePaidOnTime: number
  invoicesPercentPaidOnTime: number
  invoicesValuePaid1To30Late: number
  invoicesValuePaid31To60Late: number
  invoicesValuePaid61To90Late: number
  invoicesValuePaid91PlusLate: number
}[]

export type CreditSafeTDPIndustryBreakdown = {
  total: number
  currency: string
  range1to30: number
  tradeLines: number
  range31to60: number
  range61to90: number
  range91plus: number
  currentValue: number
  industryCode: string
  percent1to30: number
  totalPercent: number
  percent31to60: number
  percent61to90: number
  percent91plus: number
  currentPercent: number
  industryDescription: string
}[]

export type CreditAdditionalInformation = {
  misc: {
    salesRange: string
    addressType: string
    locationType: string
    headQuarterName: string
    yearsInBusiness: string
    establishmentDate: string
    headQuarterSafeNumber: string
    corporatePrimaryAddress: string
    primarySicCodeIndustryClassification: string
    businessLegalStatus: string
    fileNumber: string
    naicsCode: string
    naicsCodeDescription: string
    numberOfInquiriesOnFile: number
    reportType: number
  }
  commentaries: { commentaryText: string; positiveOrNegative: string }[]
  ratingHistory: { date: string; companyValue: string }[]
  enquiriesTrend: {
    month1: number
    month2: number
    month3: number
    month4: number
    month5: number
    month6: number
    month7: number
    month8: number
    month9: number
    month10: number
    month11: number
    month12: number
    months1to3: number
    months4to6: number
    months7to9: number
    months10to12: number
  }
  inquiriesTrend: {
    month1: number
    month2: number
    month3: number
    month4: number
    month5: number
    month6: number
    month7: number
    month8: number
    month9: number
    month10: number
    month11: number
    month12: number
    months1to3: number
    months4to6: number
    months7to9: number
    months10to12: number
  }
  ratingCommentary: {
    groupSize: number
    activeFlag: string
    yearsInFile: number
    monthsInFile: number
    totalUccCount: number
    activeIndicator: number
    stateAverageDbt: number
    uccDataIndicator: number
    legalFilingsCount: number
    cautionaryUccCount: number
    creditLimitCurrent: number
    industryAverageDbt: number
    legalFilingsAmount: number
    creditLimitPrevious: number
    creditRatingCurrent: number
    lastActivityAgeCode: number
    yearBusinessStarted: number
    creditRatingPrevious: number
    legalFilingsCountLast12M: number
    legalFilingsAmountLast12M: number
    estimatedNumberOfEmployees: number
  }
  ratingPercentiles: {
    usPercentile: {
      percentile: number
      safeNumber: string
      generatedDate: string
    }
    industryPercentile: {
      sicCode: number
      percentile: number
      safeNumber: string
      generatedDate: string
    }
  }
  creditRatingHistory: {
    date: string
    companyValue: string
    industryValue: number
  }
  extendedPaymentData: {
    paidInvoices: {
      paidInvoicesHistory: {
        last3MonthsCount: number
        last12MonthsCount: number
        medianInvoiceValue: number
        averageInvoiceValue: number
        singleLowestInvoice: number
        singleHighestInvoice: number
      }
      paidInvoicesSummary: {
        totalValue: number
        totalTradeLines: number
        uniqueCompanies: number
      }
    }
    miniDashBoard: {
      dbt: string
      paidLate: string
      paidOnTime: string
      creditRatio: string
      pastDueTotal: string
      totalBalance: string
      valuePaidLate: string
      highestPastDue: string
      pastDuePercent: string
      totalValuePaid: string
      totalTradelines: string
      valuePaidOnTime: string
      activeTradeLines: string
      paidSeverelyLate: string
      recentHighCredit: string
      activeCreditAmount: string
      pastDueSevereTotal: string
      industryPerformance: string
      valuePaidSeverelyLate: string
      highestSeverelyPastDue: string
    }
    pastDueBalances: {
      pastDueHigh: {
        currency: string
        highTradeLines12Month: number
        highTradeLinesLifetime: number
        highTradeLinesSevere12Month: number
        highTradeLinesSevereLifetime: number
      }
      pastDueTotal: {
        currency: string
        totalTradeLines12Month: number
        totalTradeLinesLifetime: number
        totalTradeLinesSevere12Month: number
        totalTradeLinesSevereLifetime: number
      }
      pastDueBalance: {
        currency: string
        highSingle12Month: number
        highBalance12Month: number
        highSingleLifetime: number
        highBalanceLifetime: number
        totalBalance12Month: number
        totalBalanceLifetime: number
        highSingleSevere12Month: number
        highBalanceSevere12Month: number
        highSingleSevereLifetime: number
        highBalanceSevereLifetime: number
        totalBalanceSevere12Month: number
        totalBalanceSevereLifetime: number
      }
    }
    tradeReferences: {
      tradeReferenceSummary: {
        paymentScore: number
        experienceScore: number
        combinedCreditLimit: number
        valueOfTotalTradeLines: number
        numberOfTotalTradeLines: number
        numberOfUniqueTradeLineProviders: number
      }
    }
    paidTpdIndustries: CreditSafePaidTpdIndustries
    highCreditBalances: {
      currency: string
      singleDbt: number
      averageDbt: number
      tradeLinesDbt: number
      averageDbtDate: string
      singleBalance130: number
      averageBalance130: number
      singleBalance3160: number
      singleBalance6190: number
      tradeLinesDbtDate: string
      averageBalance3160: number
      averageBalance6190: number
      singleBalance91Plus: number
      averageBalance91Plus: number
      singleBalanceCurrent: number
      tradeLinesBalance130: number
      averageBalanceCurrent: number
      tradeLinesBalance3160: number
      tradeLinesBalance6190: number
      tradeLinesBalance91Plus: number
      singleBalanceCurrentDate: string
      tradeLinesBalanceCurrent: number
      averageBalanceCurrentDate: string
      tradeLinesBalanceCurrentDate: string
    }
    tpdIndustryBreakdown: CreditSafeTDPIndustryBreakdown
    tradeReferenceHistory: {
      avgVal: number
      maxVal: number
      medianVal: number
      averageScore: number
      numberOfReferences: number
      estimatedTotalValue: number
    }
    lifetimeHighBalanceChart: {
      high3Month: {
        dbt: number
        currency: string
        singleHighBalance: number
        averageHighBalance: number
        tradeLinesHighBalance: number
      }
      high6Month: {
        dbt: number
        currency: string
        singleHighBalance: number
        averageHighBalance: number
        tradeLinesHighBalance: number
      }
      high9Month: {
        dbt: number
        currency: string
        singleHighBalance: number
        averageHighBalance: number
        tradeLinesHighBalance: number
      }
      high12Month: {
        dbt: number
        currency: string
        singleHighBalance: number
        averageHighBalance: number
        tradeLinesHighBalance: number
      }
      highLifetime: {
        dbt: number
        currency: string
        singleHighBalance: number
        averageHighBalance: number
        tradeLinesHighBalance: number
      }
    }
    tpdTradeLinesInformation: {
      currency: string
      creditUtilization: number
      averageCreditAmount: number
      industryPerformance: number
      lastUpdateDate: string
    }
    tpdMonthlyBalanceHistories: {
      date: string
      currency: string
      range1to30: number
      range31to60: number
      range61to90: number
      range91plus: number
      totalBalance: number
      balanceCurrent: number
    }[]
    paidMonthlyBalanceHistories: {
      date: string
      currency: string
      paid1To30: number
      paid31To60: number
      paid61To90: number
      paid91Plus: number
      paidCurrent: number
    }[]
    paidTradePaymentInformation: CreditSafePaidTpdIndustries
    tradePaymentsByCreditExtended: {
      credit1kTo5k: {
        pastDue: number
        currency: string
        tradeLines: number
        totalAmount: number
        percentPastDue: number
        percentWithinTerms: number
      }
      creditOver1m: {
        pastDue: number
        currency: string
        tradeLines: number
        totalAmount: number
        percentPastDue: number
        percentWithinTerms: number
      }
      credit5kTo25k: {
        pastDue: number
        currency: string
        tradeLines: number
        totalAmount: number
        percentPastDue: number
        percentWithinTerms: number
      }
      creditUnder1k: {
        pastDue: number
        currency: string
        tradeLines: number
        totalAmount: number
        percentPastDue: number
        percentWithinTerms: number
      }
      credit100kTo1m: {
        pastDue: number
        currency: string
        tradeLines: number
        totalAmount: number
        percentPastDue: number
        percentWithinTerms: number
      }
      credit25kTo50k: {
        pastDue: number
        currency: string
        tradeLines: number
        totalAmount: number
        percentPastDue: number
        percentWithinTerms: number
      }
      credit50kTo100k: {
        pastDue: number
        currency: string
        tradeLines: number
        totalAmount: number
        percentPastDue: number
        percentWithinTerms: number
      }
    }
    tradePaymentsPaidByCreditExtended: {
      credit1kTo5k: {
        pastDue: number
        currency: string
        tradeLines: number
        totalAmount: number
        percentPastDue: number
        percentWithinTerms: number
      }
      creditOver1m: {
        pastDue: number
        currency: string
        tradeLines: number
        totalAmount: number
        percentPastDue: number
        percentWithinTerms: number
      }
      credit5kTo25k: {
        pastDue: number
        currency: string
        tradeLines: number
        totalAmount: number
        percentPastDue: number
        percentWithinTerms: number
      }
      creditUnder1k: {
        pastDue: number
        currency: string
        tradeLines: 1
        totalAmount: 210
        percentPastDue: number
        percentWithinTerms: 100
      }
      credit100kTo1m: {
        pastDue: number
        currency: string
        tradeLines: number
        totalAmount: number
        percentPastDue: number
        percentWithinTerms: number
      }
      credit25kTo50k: {
        pastDue: number
        currency: string
        tradeLines: number
        totalAmount: number
        percentPastDue: number
        percentWithinTerms: number
      }
      credit50kTo100k: {
        pastDue: number
        currency: string
        tradeLines: number
        totalAmount: number
        percentPastDue: number
        percentWithinTerms: number
      }
    }
  }
  companyRecommendation: {
    dbt: string
    name: string
    address: {
      city: string
      street: string
      province: string
      postalCode: string
      simpleValue: string
    }
    safeNumber: string
    tradeLines: string
    creditLimit: string
    creditRating: string
    totalBalance: string
  }
  financialTradePresent: boolean
  otherCorporateRecords: {
    status: string
    filingDate: string
    otherLegalName: string
    primaryAddress: {
      city: string
      street: string
      province: string
      postalCode: string
      simpleValue: string
    }
    principalState: string
    sosCharterNumber: string
    businessLegalName: string
    foreignOrDomestic: string
    incorporatedState: string
    incorporationDate: string
    profitOrNonProfit: string
  }[]
  primaryCorporateRecord: {
    status: string
    filingDate: string
    otherLegalName: string
    primaryAddress: {
      city: string
      street: string
      province: string
      postalCode: string
      simpleValue: string
    }
    principalState: string
    sosCharterNumber: string
    businessLegalName: string
    foreignOrDomestic: string
    incorporatedState: string
    incorporationDate: string
    profitOrNonProfit: string
  }
  corporateRecordAdditionalInformation: {
    address: {
      city: string
      province: string
      postalCode: string
      simpleValue: string
    }
  }[]
}

export type CreditSafeReportData = {
  companyId: string
  language: string
  companySummary: CreditSafeCompanySummary
  companyIdentification: {
    basicInformation: {
      businessName: string
      registeredCompanyName: string
      companyRegistrationNumber: string
      ggsId: string
      leiNumber: string
      country: string
      vatRegistrationNumber: string
      vatRegistrationDate: string
      companyRegistrationDate: string
      operationsStartDate: string
      commercialCourt: string
      legalForm: {
        commonCode: string
        providerCode: string
        description: string
      }
      officeType: string
      ownershipType: string
      companyStatus: {
        status: string
        providerStatus: string
        providerCode: string
        isActive: boolean
        description: string
      }
      principalActivity: {
        code: string
        industrySector: string
        description: string
        classification: string
      }
      contactAddress: {
        type: string
        simpleValue: string
        street: string
        houseNumber: string
        additionToAddress: string
        city: string
        postalCode: string
        province: string
        region: string
        telephone: string
        directMarketingOptOut: boolean
        directMarketingOptIn: boolean
        country: string
      }
      activityClassifications: {
        classification: string
        activities: {
          code: string
          description: string
        }[]
      }[]
      previousNames: {
        dateChanged: string
        name: string
      }[]
      previousLegalForms: {
        dateChanged: string
        legalForm: {
          commonCode: string
          providerCode: string
          description: string
        }
      }[]
    }
  }
  creditScore: CreditSafeCreditScore
  contactInformation: {
    mainAddress: {
      type: string
      simpleValue: string
      street: string
      houseNumber: string
      additionToAddress: string
      city: string
      postalCode: string
      province: string
      region: string
      telephone: string
      directMarketingOptOut: boolean
      directMarketingOptIn: boolean
      country: string
    }
    otherAddresses: {
      type: string
      simpleValue: string
      street: string
      houseNumber: string
      additionToAddress: string
      city: string
      postalCode: string
      province: string
      region: string
      telephone: string
      directMarketingOptOut: boolean
      directMarketingOptIn: boolean
      country: string
    }[]
    previousAddresses: {
      type: string
      simpleValue: string
      street: string
      houseNumber: string
      additionToAddress: string
      city: string
      postalCode: string
      province: string
      region: string
      telephone: string
      directMarketingOptOut: boolean
      directMarketingOptIn: boolean
      country: string
    }[]
    emailAddresses: string[]
    websites: string[]
  }
  indicators: {
    type: string
    value: string
    changeDate: string
    industryComparison: {
      sectorCode: string
      sectorAverage: string
      sectorCount: number
      totalAverage: string
      totalCount: number
    }
  }[]
  shareCapitalStructure: {
    nominalShareCapital: {
      currency: string
      value: number
    }
    issuedShareCapital: {
      currency: string
      value: number
    }
    shareCapitalCurrency: string
    numberOfSharesIssued: number
    shareHolders: {
      id: string
      idType: string
      name: string
      title: string
      firstNames: string
      firstName: string
      middleName: string
      surname: string
      address: {
        type: string
        simpleValue: string
        street: string
        houseNumber: string
        additionToAddress: string
        city: string
        postalCode: string
        province: string
        region: string
        telephone: string
        directMarketingOptOut: boolean
        directMarketingOptIn: boolean
        country: string
      }
      shareholderType: string
      shareType: string
      currency: string
      totalValueOfSharesOwned: number
      totalNumberOfSharesOwned: number
      percentSharesHeld: number
      startDate: string
      endDate: string
      hasNegativeInfo: boolean
      shareClasses: {
        shareType: string
        currency: string
        valuePerShare: number
        jointlyOwned: boolean
        numberOfSharesOwned: number
        valueOfSharesOwned: number
        additionalData: { [key: string]: any }
      }[]
    }[]
  }

  directors: {
    currentDirectors: {
      id: string
      idType: string
      name: string
      title: string
      firstNames: string
      firstName: string
      middleName: string
      surname: string
      address: {
        type: string
        simpleValue: string
        street: string
        houseNumber: string
        additionToAddress: string
        city: string
        postalCode: string
        province: string
        region: string
        telephone: string
        directMarketingOptOut: boolean
        directMarketingOptIn: boolean
        country: string
      }
      gender: string
      birthName: string
      dateOfBirth: string
      placeOfBirth: string
      nationality: string
      countryOfResidence: string
      country: string
      directorType: string
      hasNegativeInfo: boolean
      signingAuthority: boolean
      positions: {
        dateAppointed: string
        commonCode: string
        providerCode: string
        positionName: string
        authority: string
        apptDurationType: string
        additionalData: { [key: string]: any }
      }[]
      additionalData: { [key: string]: any }
    }[]
    previousDirectors: {
      id: string
      idType: string
      name: string
      title: string
      firstNames: string
      firstName: string
      middleName: string
      surname: string
      address: {
        type: string
        simpleValue: string
        street: string
        houseNumber: string
        additionToAddress: string
        city: string
        postalCode: string
        province: string
        region: string
        telephone: string
        directMarketingOptOut: boolean
        directMarketingOptIn: boolean
        country: string
      }
      gender: string
      birthName: string
      dateOfBirth: string
      placeOfBirth: string
      nationality: string
      countryOfResidence: string
      country: string
      directorType: string
      hasNegativeInfo: boolean
      signingAuthority: boolean
      positions: {
        dateAppointed: string
        commonCode: string
        providerCode: string
        positionName: string
        authority: string
        apptDurationType: string
        additionalData: { [key: string]: any }
      }[]
      additionalData: { [key: string]: any }
      resignationDate: string
    }[]
  }
  directorships: {
    currentDirectorships: {
      id: string
      title: string
      initials: string
      name: string
      position: {
        dateAppointed: string
        commonCode: string
        providerCode: string
        positionName: string
        authority: string
        apptDurationType: string
        additionalData: { [key: string]: any }
        resignationDate: string
      }
      registrationNumber: string
      companyName: string
      status: {
        status: string
        providerStatus: string
        providerCode: string
        isActive: boolean
        description: string
      }
    }[]
    previousDirectorships: {
      id: string
      title: string
      initials: string
      name: string
      position: {
        dateAppointed: string
        commonCode: string
        providerCode: string
        positionName: string
        authority: string
        apptDurationType: string
        additionalData: { [key: string]: any }
        resignationDate: string
      }
      registrationNumber: string
      companyName: string
      status: {
        status: string
        providerStatus: string
        providerCode: string
        isActive: boolean
        description: string
      }
    }[]
  }
  otherInformation: {
    bankers: {
      name: string
      address: {
        type: string
        simpleValue: string
        street: string
        houseNumber: string
        additionToAddress: string
        city: string
        postalCode: string
        province: string
        region: string
        telephone: string
        directMarketingOptOut: boolean
        directMarketingOptIn: boolean
        country: string
      }
      bankCode: string
      bic: string
    }[]
    advisors: {
      auditorName: string
      solicitorName: string
      accountantName: string
    }[]
    employeesInformation: {
      year: number
      numberOfEmployees: string
    }[]
  }
  groupStructure: {
    ultimateParent: {
      id: string
      country: string
      registrationNumber: string
      safeNo: string
      idType: string
      name: string
      type: string
      officeType: string
      status: string
      vatNo: string
      additionalInformation: {
        isPLC: string
        coefficientLinear: number
      }
      address: {
        type: string
        simpleValue: string
        street: string
        houseNumber: string
        additionToAddress: string
        city: string
        postalCode: string
        province: string
        region: string
        telephone: string
        directMarketingOptOut: boolean
        directMarketingOptIn: boolean
        country: string
      }
      activity: {
        code: string
        industrySector: string
        description: string
        classification: string
      }
      legalForm: string
      additionalData: { [key: string]: any }
    }
    immediateParent: {
      id: string
      country: string
      registrationNumber: string
      safeNo: string
      idType: string
      name: string
      type: string
      officeType: string
      status: string
      vatNo: string
      additionalInformation: {
        isPLC: string
        coefficientLinear: number
      }
      address: {
        type: string
        simpleValue: string
        street: string
        houseNumber: string
        additionToAddress: string
        city: string
        postalCode: string
        province: string
        region: string
        telephone: string
        directMarketingOptOut: boolean
        directMarketingOptIn: boolean
        country: string
      }
      activity: {
        code: string
        industrySector: string
        description: string
        classification: string
      }
      legalForm: string
      additionalData: { [key: string]: any }
    }
    subsidiaryCompanies: {
      id: string
      country: string
      registrationNumber: string
      safeNo: string
      idType: string
      name: string
      type: string
      officeType: string
      status: string
      vatNo: string
      additionalInformation: {
        isPLC: string
        coefficientLinear: number
      }
      address: {
        type: string
        simpleValue: string
        street: string
        houseNumber: string
        additionToAddress: string
        city: string
        postalCode: string
        province: string
        region: string
        telephone: string
        directMarketingOptOut: boolean
        directMarketingOptIn: boolean
        country: string
      }
      activity: {
        code: string
        industrySector: string
        description: string
        classification: string
      }
      legalForm: string
      additionalData: { [key: string]: any }
    }[]
    affiliatedCompanies: {
      id: string
      country: string
      registrationNumber: string
      safeNo: string
      idType: string
      name: string
      type: string
      officeType: string
      status: string
      vatNo: string
      additionalInformation: {
        isPLC: string
        coefficientLinear: number
      }
      address: {
        type: string
        simpleValue: string
        street: string
        houseNumber: string
        additionToAddress: string
        city: string
        postalCode: string
        province: string
        region: string
        telephone: string
        directMarketingOptOut: boolean
        directMarketingOptIn: boolean
        country: string
      }
      activity: {
        code: string
        industrySector: string
        description: string
        classification: string
      }
      legalForm: string
      additionalData: { [key: string]: any }
    }[]
  }
  extendedGroupStructure: {
    id: string
    country: string
    safeNumber: string
    idType: string
    companyName: string
    registeredNumber: string
    latestAnnualAccounts: string
    level: number
    percentOfOwnership: number
    status: string
    commonRatingBand: string
    additionalData: { [key: string]: any }
  }[]
  financialStatements: CreditSafeFinancialStatement[]
  localFinancialStatements: CreditSafeFinancialStatement[]
  negativeInformation: {
    derogatorySummaries: {
      commentsOnFile: string
      currency: string
      derogatoryType: string
      numberOnFile: string
      totalAmountOnFile: string
      dateOfLastItem: string
    }[]
    legalFilingBranchSummary: {
      bankruptcy: boolean
      cautionaryUCCFilings: number
      judgmentFilings: number
      suits: number
      sum: { value: number; currency: string }
      taxLienFilings: number
      uccFilings: number
    }
    legalFilingGroupSummary: {
      bankruptcy: boolean
      cautionaryUCCFilings: number
      judgmentFilings: number
      suits: number
      sum: { value: number; currency: string }
      taxLienFilings: number
      uccFilings: number
    }
    legalFilingSummary: {
      bankruptcy: boolean
      cautionaryUCCFilings: number
      judgmentFilings: number
      suits: number
      sum: { value: number; currency: string }
      taxLienFilings: number
      uccFilings: number
    }
    mostRecentLegalFilings: {
      case: string
      caseFilingNumber: string
      status: string
      filedDate: string
      dateFiled: string
      legalFilingType: string
      legalFiling: string
      filingType: string
    }[]
    possibleOfac: boolean
    uccDetails: {
      status: string
      filedDate: string
      debtorName: string
      filingType: string
      filingNumber: string
      filingOffice: string
      jurisdiction: string
      relatedDocumentNumber: string
      securedPartyName: string
      securedPartyAddress: {
        city: string
        postalCode: string
        province: string
        simpleValue: string
        street: string
      }
      debtorAddress: {
        city: string
        postalCode: string
        province: string
        simpleValue: string
        street: string
      }
    }[]
  }
  additionalInformation: CreditAdditionalInformation
  directorsExtra: { [key: string]: any }
  extendedGroupStructureExtra: { [key: string]: any }
  paymentData: CreditSafeDBTHistory
  paymentDataExtra: { [key: string]: any }
  alternateSummary: { [key: string]: any }
  negativeInformationExtra: { [key: string]: any }
  exceptions: {
    eventType: {
      code: string
      description: string
    }
    eventLevel: {
      code: string
      description: string
    }
    eventDate: string
  }[]
}
export type CreditSafeAddress = {
  type: string
  simpleValue: string
  street: string
  houseNumber: string
  additionToAddress: string
  city: string
  postalCode: string
  province: string
  region: string
  telephone: string
  directMarketingOptOut: boolean
  directMarketingOptIn: boolean
  country: string
}

export type CreditSafeData = {
  correlationId: string
  report: CreditSafeReportData
  companyId: string
  dateOfOrder: string
  language: string
  userId: string
}
export type CreditSafePurchasedReport = {
  id: string
  createdAt: string
  url: string
  data: CreditSafeData
}

export type CreditSafeUpdateReport = {
  application_id: string
  credit_safe_report_id: string
  file: string
}
//
// Experian
//
export type ExperianSearchResult = {
  businessName: string
  address: {
    street: string
    city: string
    state: string
    zip: string
  }
  bin: string
  phone: string
}

//
// OpenCorporate
//
export interface OpenCorporateSearchInterface {
  name: string
  jurisdictionCode: string
  companyNumber: string
  registeredAddressInFull: string
}

export interface Officers {
  name: string | undefined
  position: string | undefined
  startDate: string | undefined
  endDate: string | undefined
}

export interface Filings {
  title: string | undefined
  date: string | undefined
}

export interface CompanyPreviousNames {
  companyName: string | undefined
}

export interface OpenCorporateGetCompaniesInterface {
  companyName: string | undefined
  companyPreviousNames: CompanyPreviousNames[] | undefined
  companyType: string | undefined
  incorporationDate: string | undefined
  currentStatus: string | undefined
  companyAddress: string | undefined
  website: string | undefined
  linkToRegistryData: string | undefined
  officers: Officers[] | undefined
  filings: Filings[] | undefined
}
export interface OpenCorporateGetCompaniesEventsInterface {
  createdAt: string | undefined
  type: string | undefined
  date: string | undefined
}

// Credit Risk Monitor

export type CreditRiskMonitorSearchResponse = {
  BusinessId: string
  PrimaryBusinessName: string
  Address1: string
  Address2: string
  CityName: string
  StateCode: string
  PostalCode: string
  CountryName: string
  CountryCode: string
  PhoneNumber: string
  TradingStatus: string
  IncorporatedDate: string
  IncoporateIn: string
  IncorporatedInState: string
  TickerSymbol: string
  StockExchange: string
  FederalTaxId: string
  BankruptcyCount: number
  LienCount: number
  JudgmentCount: number
  FriskScore: number
  FriskScoreDate: string
  PayceScore: number
  PayceScoreDate: string
}

export type CreditRiskMonitorSearchFormInput = {
  businessId: string
  businessName: string
  streetAddress: string
  cityName: string
  provinceName: string
  postalCode: string
  countryCode: string
  phoneNumber: string
  federalTaxID: string
}

export interface CreditRiskMonitorPDF {
  url: string
}

export interface CreditRiskMonitorBusiness {
  businessId: string
  businessName: string
  address: string
  cityName: string
  provinceName: string
  countryName: string
}

export interface CreditRiskMonitorPurchasedReport {
  businessId: string
  businessName: string
  address: string
  city: string
  state: string
  country: string
  url: string
  createdAt: string
}

export interface TransUnionPurchasedReport {
  json: CreditData
  createdAt: string
}

interface Person {
  first: string
  middle: string
  last: string
}

interface DateText {
  "#text": string
}

interface CreditDataStatus {
  minor: string
  freeze: {
    indicator: string
  }
  disputed: string
  suppressed: string
  doNotPromote: {
    indicator: string
  }
}

interface FileSummary {
  market: string
  submarket: string
  inFileSinceDate: DateText
  creditDataStatus: CreditDataStatus
  fileHitIndicator: string
  ssnMatchIndicator: string
  consumerStatementIndicator: string
}

interface Indicative {
  name: {
    person: Person
  }
  dateOfBirth: DateText
  socialSecurity: {
    number: string
  }
  address: any[] // Keep as any[] since it's empty in the mock
}

interface Factor {
  code: string
  rank: string
}

interface ScoreModel {
  score: {
    factors: {
      factor: Factor[]
    }
    results: string
    derogatoryAlert: string
    fileInquiriesImpactedScore: string
  }
}

interface TotalAmount {
  creditLimit?: string
  currentBalance?: string
  pastDue?: string
  estimatedSpend?: string
  priorPaymentDue?: string
  currentPaymentDue?: string
  mostRecentPayment?: MostRecentPayment
}

interface RecordCounts {
  openTradeCount?: string
  collectionCount?: string
  negativeTradeCount?: string
  totalTradeCount?: string
  revolvingTradeCount?: string
  installmentTradeCount?: string
  otherTradeCount?: string
  publicRecordCount?: string
  totalInquiryCount?: string
  mortgageTradeCount?: string
  openOtherTradeCount?: string
  openMortgageTradeCount?: string
  openRevolvingTradeCount?: string
  openInstallmentTradeCount?: string
  historicalNegativeTradeCount?: string
  historicalNegativeOccurrencesCount?: string
  "@reportingPeriod"?: string
}

interface AccountSummaryDetail {
  terms?: {
    scheduledMonthlyPayment?: string
  }
  subscriber?: Subscriber
  portfolioType?: string
  currentBalance?: string
  creditLimit?: string
  accountNumber?: string
  dateOpened?: DateText
}

export interface MostRecentPayment {
  amount?: string
}

export interface AmountDetail {
  pastDue?: string
  currentBalance?: string
  priorPaymentDue?: string
  currentPaymentDue?: string
  mostRecentPayment?: MostRecentPayment
  creditLimit?: string
  estimatedSpend?: string
}

export interface CVCreditSummary {
  totalAmount?: TotalAmount
  recordCounts?: RecordCounts
  accountSummaryDetail?: Array<AccountSummaryDetail>
  revolvingAmount?: AmountDetail
  installmentAmount?: AmountDetail
  mostRecentDelinquency?: MostRecentDelinquency
  accountDelinquencyDetail?: AccountDelinquencyDetail[]
  accountDelinquencySummary?: AccountDelinquencySummary
  totalPaymentRatioInstallment?: TotalPaymentRatioInstallment
}

interface AddOnProduct {
  code?: string
  status?: string
  highRiskFraudAlert?: {
    "@searchStatus": string
  }
  scoreModel?: ScoreModel
  CVCreditSummary?: CVCreditSummary
}

interface Custom {
  credit: {
    trade: any[] // Keep as any[] since it's empty in the mock
    creditSummary: {
      accountRating: any[] // Keep as any[] since it's empty in the mock
    }
  }
}

interface SubjectRecord {
  indicative: Indicative
  fileSummary: FileSummary
  addOnProduct: AddOnProduct[]
  custom: Custom
}

export interface CreditData {
  creditBureau: {
    product: {
      subject: {
        subjectRecord: SubjectRecord
      }
    }
  }
}

export interface TotalPaymentRatioInstallment {
  TPR1Month?: string
  TPR6Months?: string
  TPR12Months?: string
  payments1MonthAgo?: string
  TPR12MonthsAverage?: string
  payments6MonthsAgo?: string
  payments12MonthsAgo?: string
}

export interface MostRecentDelinquency {
  date: DateText
  accountRatingCode?: string
  paymentDue?: string
}

export interface SubscriberName {
  unparsed: string
}

export interface Subscriber {
  name: SubscriberName
  memberCode?: string
}

export interface AccountDelinquencyDetail {
  subscriber?: Subscriber
  accountNumber?: string
  portfolioType?: string
  mostRecentDelinquency?: MostRecentDelinquency
  totalDelinquencyCount?: string
}

export interface DelinquencyCount {
  late30DaysTotal: string
  late60DaysTotal: string
  late90DaysTotal: string
}

export interface AccountDelinquencySummary {
  revolvingCount: DelinquencyCount
  installmentCount: DelinquencyCount
}

export enum VerificationStatus {
  SUCCESS = "success",
  FAILURE = "failure",
  PROCESSING = "processing",
  NONE = "none",
}

export type TinData = {
  name: string
  tin: string
  status?: VerificationStatus
}

export enum TinType {
  EIN = "EIN",
  SSN = "SSN / SIN",
  NONE = "NONE",
}

export interface OpenCorporateCompanyDataTable {
  companyData: OpenCorporateGetCompaniesInterface | undefined
}

export interface OpenCorporateOfficersTable {
  officers: Officers[] | undefined
}

export interface OpenCorporateFilingsTable {
  filings: Filings[] | undefined
}
