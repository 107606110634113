import { Box, Chip, Grid, Typography } from "@mui/material"
import SecurityIcon from "@mui/icons-material/Security"

interface IDMismatchSectionProps {
  idMismatchAlert?: {
    type?: string
    condition?: string
    inquiriesInLast60Count?: string
  }
}

export const IDMismatchSection = ({
  idMismatchAlert,
}: IDMismatchSectionProps) => {
  return (
    <Grid item xs={12}>
      <Box>
        <Typography color="text.secondary" gutterBottom>
          TruValidate ID Mismatch Alerts
        </Typography>
        <Chip
          icon={<SecurityIcon />}
          label={`Type: ${idMismatchAlert?.type}`}
          color="info"
          size="small"
        />
        <Chip
          icon={<SecurityIcon />}
          label={`Condition: ${idMismatchAlert?.condition}`}
          color="info"
          size="small"
        />
        <Chip
          icon={<SecurityIcon />}
          label={`Inquiries in Last 60 days: ${idMismatchAlert?.inquiriesInLast60Count}`}
          color="info"
          size="small"
        />
      </Box>
    </Grid>
  )
}
