import { useQuery } from "react-query"
import { User } from "src/types"

export function useSalesReps(businessId: string, storeId?: string) {
  const djangoResponse = useQuery<User[]>(
    "/application/ar_team?team=Sales&business_id=" +
      businessId +
      "&store_id=" +
      (storeId ? storeId : ""),
    {
      enabled: !!businessId,
      retry: (failureCount, error) => {
        if (failureCount < 1) {
          return true
        }
        // do not retry on 404
        return (error as any)?.response?.status !== 404
      },
    },
  )

  return { ...djangoResponse }
}
