import {
  WarningOutlined,
  CheckCircleOutline,
  EmailOutlined,
} from "@mui/icons-material"
import { Tooltip, TextField } from "@mui/material"
import { useCallback } from "react"
import AnimatedDot from "src/assets/icons/animated-dot"
import useDebounced from "src/hooks/useDebounced"
import { useValidateEmail } from "src/queries/vendors/useValidateEmail"

//create a new TextField to use a set InputProps
export const ValidatedEmailTextField = ({ ...props }) => {
  const emailToValidate = useDebounced(props.value, 1000)

  const { data: emailValidationResult, isLoading: isValidatingEmail } =
    useValidateEmail(emailToValidate || "", "INVITE")

  const getValidationIcon = useCallback(() => {
    if (!emailValidationResult) {
      return <></>
    }

    if (["disposable"].includes(emailValidationResult?.bounceVerify?.result)) {
      return (
        <Tooltip
          title={
            "This is likely a disposable email address. A disposable email address is often considered risky. Please verify the address with buyer. Please note this is only a warning and not a definitive indicator of fraud."
          }
          arrow
        >
          <WarningOutlined color="error" />
        </Tooltip>
      )
    }

    if (emailValidationResult.checks.additional.hasKnownBounces) {
      return (
        <Tooltip
          title={
            "Our system shows that emails previously sent to this address have been rejected by their email servers. Please verify the address with buyer. Note this is only a warning and not definitive. You can still continue to send the invite to this email."
          }
          arrow
        >
          <WarningOutlined color="warning" />
        </Tooltip>
      )
    }

    if (emailValidationResult.verdict == "Invalid") {
      return (
        <Tooltip
          title={
            "Our system shows that this email domain is invalid. Please verify the address with buyer."
          }
          arrow
        >
          <WarningOutlined color="error" />
        </Tooltip>
      )
    }

    if (["invalid"].includes(emailValidationResult?.bounceVerify?.result)) {
      return (
        <Tooltip
          title={
            "Our system shows there is a high probability that this email address is invalid. This could be due to the person leaving the company or a typo. Please verify the address with buyer. Note: This is only a warning and not definitive. You can still continue to send the invite to this email."
          }
          arrow
        >
          <WarningOutlined color="warning" />
        </Tooltip>
      )
    }

    if (
      emailValidationResult.verdict == "Valid" &&
      emailValidationResult?.bounceVerify?.result == "valid"
    ) {
      return (
        <Tooltip title={"No issues detected with email address."} arrow>
          <CheckCircleOutline color="success" />
        </Tooltip>
      )
    }

    return <></>
  }, [emailValidationResult])

  return (
    <TextField
      {...props}
      InputProps={{
        ...props.InputProps,
        startAdornment: <EmailOutlined style={{ marginRight: "8px" }} />,
        endAdornment: isValidatingEmail ? <AnimatedDot /> : getValidationIcon(),
      }}
    />
  )
}
