import { LoadingButton } from "@mui/lab"
import { CreditApplication } from "../../../types"
import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import { useDocumentPDF } from "src/queries/vendors/useDocumentPDF"
import Label from "src/components/label"
import { useRequestPersonalGuaranty } from "src/queries/credit/useRequestPersonalGuaranty" // this hook?
import { enqueueSnackbar } from "notistack"
import { info, error } from "src/utils/logger"
import { TableHeadCustom } from "src/components/table"
import { startCase } from "lodash"
import { useState } from "react"
import { useDeletePersonalGuarantor } from "src/queries/credit/useDeletePersonalGuarantor"
import AddNewPersonalGuarantorDialog from "./components/AddNewPersonalGuarantorDialog"
import { confirm } from "src/components/confirm"
import {
  PAGE_LABEL_PERSONAL_GUARANTY,
  PERSONAL_GUARANTY_CONFIG_NUMBER_OF_GUARANTORS,
} from "../intake_sections/constants"
import {
  REQUEST_TYPES,
  useDocumentV3,
} from "src/queries/credit/signing_v3/useDocumentV3"
import { useRemindGuarantorsV3 } from "src/queries/credit/signing_v3/useRemindGuarantorsV3"
import usePersonalGuarantors, {
  ProgressState,
} from "src/queries/credit/usePersonalGuarantors"

export default function ({
  application,
  refreshApplication,
}: {
  application: CreditApplication
  refreshApplication: () => void
}) {
  const [showAddPersonalGuarantorDialog, setAddPersonalGuarantor] =
    useState(false)
  const { refetch: fetchPdf, isLoading } = useDocumentPDF(
    application.personalGuaranty
      ? application.personalGuaranty[0]?.documentRequestId
      : "",
    "guaranty",
  )

  const { fetch: fetchDocumentV3, isLoading: isDownloadingV3 } = useDocumentV3()

  const {
    findAction,
    isFetchingDetails,
    personalGuarantorsSignatureState,
    hasSignatureV3,
    documentDetailsV3,
  } = usePersonalGuarantors(application, application.personalGuaranty)

  const [notes, setNotes] = useState("")
  const [reminderDialogOpen, setReminderDialogOpen] = useState(false)

  const { execute: remindV3, isLoading: isRemindingV3 } =
    useRemindGuarantorsV3()

  const { execute: requestSecureDocument, isLoading: isRequesting } =
    useRequestPersonalGuaranty(() => {
      enqueueSnackbar("Request sent successfully.", { variant: "success" })
      setReminderDialogOpen(false)
    })

  const { execute: deletePersonalGuarantor } = useDeletePersonalGuarantor(
    () => {
      refreshApplication()
      enqueueSnackbar("Personal Guarantor deleted successfully.", {
        variant: "success",
      })
    },
  )
  const applicationId = application.id || ""

  const getLabel = (signed: boolean) => {
    return (
      <Label color={signed ? "success" : "warning"}>
        {signed ? "SIGNED" : "NOT SIGNED"}
      </Label>
    )
  }

  const canAddPersonalGuaranty = (application: CreditApplication) => {
    let numberExpectedOfGuarantors = 0
    if (application.personalGuaranty) {
      numberExpectedOfGuarantors = application.personalGuaranty.length
    }
    if (numberExpectedOfGuarantors === 0) {
      return true
    }
    if (application && application.template && application.template.pages) {
      const page = application.template.pages.find(
        (p) => p.label === PAGE_LABEL_PERSONAL_GUARANTY,
      )
      if (page && page.enabled) {
        let numberPossibleOfGuarantors = 1
        page.config.forEach((config) => {
          if (config.label === PERSONAL_GUARANTY_CONFIG_NUMBER_OF_GUARANTORS) {
            numberPossibleOfGuarantors = Number(config.value)
          }
        })

        if (numberExpectedOfGuarantors < numberPossibleOfGuarantors) {
          return true
        }
      }
    }
    return false
  }

  return (
    <Card>
      <CardHeader
        title={
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                alignItems: "center",
              }}
            >
              Personal Guarantor
              {isFetchingDetails && <CircularProgress size={24} />}
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
              }}
            >
              <LoadingButton
                variant="outlined"
                onClick={() => {
                  setReminderDialogOpen(true)
                }}
                // disabled if there are no personal guarantors or if all personal guarantors have signed
                disabled={
                  personalGuarantorsSignatureState ===
                    ProgressState.ALL_SIGNED ||
                  personalGuarantorsSignatureState === ProgressState.EMPTY
                }
                loading={isRequesting}
              >
                Send Reminder
              </LoadingButton>
              <LoadingButton
                loading={isLoading || isDownloadingV3}
                disabled={
                  !application.personalGuaranty ||
                  application.personalGuaranty.length === 0 ||
                  !application.personalGuaranty[0]?.documentRequestId
                }
                variant="contained"
                onClick={() => {
                  function saveByteArray(fileName: string, b64: string) {
                    const link = document.createElement("a")
                    link.href = "data:application/pdf;base64," + b64
                    link.download = fileName
                    link.click()
                  }
                  if (hasSignatureV3) {
                    fetchDocumentV3(
                      REQUEST_TYPES.PERSONAL_GUARANTY,
                      application?.personalGuaranty?.[0]?.documentRequestId,
                    )
                      .then((res) => {
                        if (!res) throw new Error("No data")
                        return saveByteArray(
                          `${startCase(
                            application.data.legalBusinessName ||
                              `${application.data.firstName} ${application.data.lastName}`,
                          )} Personal Guarantor Agreement.pdf`,
                          res as string,
                        )
                      })
                      .catch((err) => error(err))
                  } else {
                    fetchPdf()
                      .then((res) => {
                        if (!res.data) throw new Error("No data")
                        return saveByteArray(
                          `${startCase(
                            application.data.legalBusinessName ||
                              `${application.data.firstName} ${application.data.lastName}`,
                          )} Personal Guarantor Agreement.pdf`,
                          res.data,
                        )
                      })
                      .catch((err) => error(err))
                  }
                }}
              >
                Download PDF
              </LoadingButton>
              <Button
                variant="outlined"
                onClick={() => setAddPersonalGuarantor(true)}
                disabled={!canAddPersonalGuaranty(application)}
              >
                Add Guarantor
              </Button>
            </Box>
          </Box>
        }
      />

      <Dialog
        open={reminderDialogOpen}
        onClose={() => setReminderDialogOpen(false)}
        maxWidth={"sm"}
      >
        <DialogTitle>Send Reminder</DialogTitle>
        <Divider style={{}} />
        <DialogContent>
          <Stack
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Typography align="justify">
              You are about to send a reminder to pending signers.
            </Typography>

            <Grid container>
              <Grid item md={12}>
                <Typography>Notes</Typography>
                <TextField
                  type="text"
                  fullWidth
                  multiline
                  placeholder="Enter any additional instructions here. This will be included in the email."
                  value={notes}
                  onChange={(e) => {
                    setNotes(e.target.value)
                  }}
                />
              </Grid>
            </Grid>
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={() => setReminderDialogOpen(false)}>Close</Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isRequesting || isRemindingV3}
            onClick={() => {
              if (hasSignatureV3) {
                remindV3(application.id || "", notes, () => {
                  enqueueSnackbar("Request sent successfully.", {
                    variant: "success",
                  })
                  setReminderDialogOpen(false)
                })
              } else {
                requestSecureDocument(application.id || "", notes)
              }
            }}
          >
            Send
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <TableContainer sx={{ overflow: "unset", marginTop: "24px" }}>
        <Table sx={{ minWidth: 680 }}>
          <TableHeadCustom
            headLabel={[
              { id: "name", label: "Name" },
              { id: "email", label: "Email" },
              { id: "phone", label: "Phone Number" },
              { id: "status", label: "Status" },
              { id: "action", label: "Action" },
            ]}
          />

          <TableBody>
            {application.personalGuaranty &&
              application.personalGuaranty.map((row) => (
                <>
                  <TableRow>
                    <TableCell>{row.name}</TableCell>

                    <TableCell>{row.email}</TableCell>

                    <TableCell>{row.phoneNumber}</TableCell>

                    <TableCell>
                      {!isFetchingDetails &&
                        !hasSignatureV3 &&
                        getLabel(
                          findAction(row.actionId)?.actionStatus === "SIGNED",
                        )}
                      {!isFetchingDetails &&
                        hasSignatureV3 &&
                        getLabel(Boolean(documentDetailsV3?.complete))}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        disabled={
                          hasSignatureV3
                            ? Boolean(documentDetailsV3?.complete)
                            : findAction(row.actionId)?.actionStatus ===
                              "SIGNED"
                        }
                        onClick={() => {
                          confirm(
                            "Are you sure you want to delete the personal guarantor request to " +
                              row.name +
                              "?",
                          )
                            .then(
                              () => {
                                return deletePersonalGuarantor(
                                  applicationId,
                                  row.id,
                                )
                              },
                              () => {
                                info("cancelled")
                              },
                            )
                            .catch(() => {
                              error("cancelled")
                            })
                        }}
                      >
                        delete
                      </Button>
                    </TableCell>
                  </TableRow>
                </>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddNewPersonalGuarantorDialog
        application={application}
        onClose={() => setAddPersonalGuarantor(false)}
        open={showAddPersonalGuarantorDialog}
        refreshApplication={refreshApplication}
      />
    </Card>
  )
}
