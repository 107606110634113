import { LoadingButton } from "@mui/lab"
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material"
import { Stack } from "@mui/system"
import { usePatchApplication } from "src/queries/credit/usePatchApplication"
import { CreditApplication } from "src/types"
import { useState } from "react"
import { BUSINESS_PREFERENCES, usePreference } from "src/hooks/use-preference"
import { useSnackbar } from "notistack"

export default ({
  application,
  onClose,
  open,
}: {
  application: CreditApplication
  onClose: () => void
  open: boolean
}) => {
  const { preference } = usePreference(
    BUSINESS_PREFERENCES.SEND_APPROVAL_EMAIL_DEFAULT,
    application.seller,
  )

  const [sendRejection, setSendRejection] = useState(
    preference !== undefined ? Number(preference) : 1,
  )

  const { enqueueSnackbar } = useSnackbar()

  const { isLoading, execute: patchApplication } = usePatchApplication()

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"sm"}>
      <DialogTitle>Reject Application</DialogTitle>
      <Divider style={{}} />
      <DialogContent>
        <Stack
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Grid container>
            <FormControl>
              <FormLabel
                id="rejection-email-label"
                style={{ margin: "8px 0 8px 0" }}
              >
                Choose one of the options below:
              </FormLabel>
              <RadioGroup
                aria-labelledby="rejection-email-label"
                defaultValue={1}
                name="radio-buttons-group"
                value={sendRejection}
                onChange={(e) => setSendRejection(Number(e.target.value))}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="Send a rejection email to the buyer"
                />
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label="Do not send a rejection email to the buyer"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Stack>
      </DialogContent>
      <Divider style={{}} />
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={() => {
            patchApplication(
              application.id as string,
              { stage: 900 },
              () => {
                enqueueSnackbar("Application updated", { variant: "success" })
                onClose()
              },
              // error will be handled by the global ReactQuery error handler
              () => undefined,
              { notifyBuyer: `${sendRejection}` },
            )
          }}
          loading={isLoading}
        >
          Reject
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
