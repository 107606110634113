import { Card, CardContent, CardHeader, useTheme } from "@mui/material"
import Chart from "react-apexcharts"
import { CreditSafePaidTpdIndustries } from "src/types"

const PaidTpdIndustriesChart = ({
  data,
}: {
  data: CreditSafePaidTpdIndustries
}) => {
  const theme = useTheme()
  const chartOptions = {
    chart: {
      type: "bar" as const,
      stacked: true,
      stackType: "100%" as const,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: data.map((industry) =>
        industry.industryCode ? industry.industryCode : "",
      ),
      labels: {
        rotate: -45,
        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      title: {
        text: "Percentage (%)",
      },
      labels: {
        formatter: function (value: number) {
          if (typeof value === "number") {
            return value.toFixed(0) + "%"
          }
          return value + "%"
        },
      },
    },
    legend: {
      position: "right" as const,
      offsetY: 40,
    },
    colors: [
      theme.palette.success.dark,
      theme.palette.grey[900],
      theme.palette.error.main,
      theme.palette.info.main,
      theme.palette.warning.main,
    ],
    tooltip: {
      y: {
        formatter: function (value: number) {
          if (typeof value === "number") {
            return value.toFixed(1) + "%"
          }
          return value + "%"
        },
      },
    },
  }

  const series = [
    {
      name: "Paid On Time",
      data: data.map((industry) =>
        Number.isNaN(industry.valuePercentPaidOnTime) ||
        industry.valuePercentPaidOnTime === undefined
          ? 0
          : industry.valuePercentPaidOnTime,
      ),
    },
    {
      name: "1-30 Days Late",
      data: data.map((industry) =>
        Number.isNaN(industry.valuePercent1To30) ||
        industry.valuePercent1To30 === undefined
          ? 0
          : industry.valuePercent1To30,
      ),
    },
    {
      name: "31-60 Days Late",
      data: data.map((industry) =>
        Number.isNaN(industry.valuePercent31To60) ||
        industry.valuePercent31To60 === undefined
          ? 0
          : industry.valuePercent31To60,
      ),
    },
    {
      name: "61-90 Days Late",
      data: data.map((industry) =>
        Number.isNaN(industry.valuePercent61To90) ||
        industry.valuePercent61To90 === undefined
          ? 0
          : industry.valuePercent61To90,
      ),
    },
    {
      name: "90+ Days Late",
      data: data.map((industry) =>
        Number.isNaN(industry.valuePercent91Plus) ||
        industry.valuePercent91Plus === undefined
          ? 0
          : industry.valuePercent91Plus,
      ),
    },
  ]

  return (
    <Card>
      <CardHeader title="Paid (TPD) Industries" />
      <CardContent>
        <Chart options={chartOptions} series={series} type="bar" height={400} />
      </CardContent>
    </Card>
  )
}

export default PaidTpdIndustriesChart
