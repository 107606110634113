import {
  Grid,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material"
import LoadingButton from "@mui/lab/LoadingButton"
import { startCase } from "lodash"
import { ApplicationTemplate, Condition, TemplateCustomField } from "src/types"
import { useState, useEffect } from "react"
import {
  ADDITIONAL_QUESTIONS_DATA_KEY,
  BANK_REFERENCE_DATA_KEY,
  BUSINESS_DETAILS_DATA_KEY,
  FORM_INPUT_NAMES,
  OWNERS_DATA_KEY,
  PAGE_LABEL_ADDITIONAL_QUESTIONS,
  PAGE_LABEL_BANK_REFERENCE,
  PAGE_LABEL_BUSINESS_DETAILS,
  PAGE_LABEL_OWNERS,
  PAGE_LABEL_USER_DETAILS,
  USER_DETAILS_DATA_KEY,
} from "../intake_sections/constants"
import { FIELD_TYPES, KEY_TYPES } from "src/statics"
import { getMultiSelectField, getTemplateCustomField } from "../utils"
import { DropzoneDialog } from "mui-file-dropzone"

type Props = {
  data: ApplicationTemplate
  isLoading: boolean
  onClose: () => void
  onSave: (field: TemplateCustomField) => void
  value?: TemplateCustomField
}

export default ({ data, isLoading, value, onClose, onSave }: Props) => {
  const getDefaultIsConditionalQuestion = (
    field: TemplateCustomField | undefined,
  ) => {
    if (
      field &&
      field?.conditions &&
      field?.conditions.length > 0 &&
      Object.keys(field?.conditions[0]).length > 0
    ) {
      return true
    }
    return false
  }

  const getDefaultKeyType = (field: TemplateCustomField | undefined) => {
    if (
      field &&
      field?.conditions &&
      field?.conditions.length > 0 &&
      Object.keys(field?.conditions[0]).length > 0
    ) {
      if (field.conditions[0].dataKey) {
        return KEY_TYPES.DATA_KEY
      }
      if (field.conditions[0].conditionCustomField) {
        return KEY_TYPES.ADDITIONAL_QUESTIONS
      }
    }
    return KEY_TYPES.ADDITIONAL_QUESTIONS
  }

  const getValue = (value: TemplateCustomField | undefined) => {
    if (value && value?.conditions) {
      if (
        value.conditions.length === 0 ||
        Object.keys(value?.conditions[0]).length === 0
      ) {
        value.conditions = [{} as Condition]
      }
    }
    return value
  }

  const [field, setField] = useState<TemplateCustomField>(
    getValue(value) || {
      text: "",
      fieldType: "text",
      associatedPage: PAGE_LABEL_ADDITIONAL_QUESTIONS,
      template: data?.id || "",
      required: true,
      conditions: [{} as Condition],
      order: 1000,
    },
  )

  const [keyType, setKeyType] = useState(getDefaultKeyType(value))

  const [isConditionalQuestion, setIsConditionalQuestion] = useState(
    getDefaultIsConditionalQuestion(value),
  )

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false)

  const handleTemplateFileUpload = (newFiles: File[]) => {
    const file = newFiles[0]
    if (file) {
      setField({
        ...field,
        templateFile: file,
      })
    }
    setUploadDialogOpen(false)
  }

  const populateConditionalQuestionsForm = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getDefaultValue = (value: any) => {
      if (value) {
        if (typeof value === "string") {
          if (value.split(",").length > 1) {
            return value.split(",")
          }
          return value
        }
        return value
      }
      return value
    }
    const additionalQuestionMenu = () => {
      const result = []
      if (data?.customFields) {
        for (const customField of data.customFields) {
          if (
            customField.associatedPage === field.associatedPage &&
            customField.id !== field.id &&
            (customField.fieldType === FIELD_TYPES.DROPDOWN ||
              customField.fieldType === FIELD_TYPES.MULTISELECT) &&
            !customField.archived
          ) {
            result.push(
              <MenuItem key={customField.id} value={customField.id}>
                {customField.text}
              </MenuItem>,
            )
          }
        }
      }
      return result
    }
    const dataKeysMenu = () => {
      if (field.associatedPage === PAGE_LABEL_USER_DETAILS) {
        return Object.entries(USER_DETAILS_DATA_KEY).map(([key, value]) => (
          <MenuItem key={key} value={key}>
            {startCase(value)}
          </MenuItem>
        ))
      }
      if (field.associatedPage === PAGE_LABEL_BUSINESS_DETAILS) {
        return Object.entries({
          ...USER_DETAILS_DATA_KEY,
          ...BUSINESS_DETAILS_DATA_KEY,
        }).map(([key, value]) => (
          <MenuItem key={key} value={key}>
            {startCase(value)}
          </MenuItem>
        ))
      }
      if (field.associatedPage === PAGE_LABEL_OWNERS) {
        return Object.entries({
          ...USER_DETAILS_DATA_KEY,
          ...BUSINESS_DETAILS_DATA_KEY,
          ...OWNERS_DATA_KEY,
        }).map(([key, value]) => (
          <MenuItem key={key} value={key}>
            {startCase(value)}
          </MenuItem>
        ))
      }
      if (field.associatedPage === PAGE_LABEL_BANK_REFERENCE) {
        return Object.entries({
          ...USER_DETAILS_DATA_KEY,
          ...BUSINESS_DETAILS_DATA_KEY,
          ...OWNERS_DATA_KEY,
          ...BANK_REFERENCE_DATA_KEY,
        }).map(([key, value]) => (
          <MenuItem key={key} value={key}>
            {startCase(value)}
          </MenuItem>
        ))
      }

      if (field.associatedPage === PAGE_LABEL_ADDITIONAL_QUESTIONS) {
        return Object.entries({
          ...USER_DETAILS_DATA_KEY,
          ...BUSINESS_DETAILS_DATA_KEY,
          ...OWNERS_DATA_KEY,
          ...BANK_REFERENCE_DATA_KEY,
          ...ADDITIONAL_QUESTIONS_DATA_KEY,
        }).map(([key, value]) => (
          <MenuItem key={key} value={key}>
            {startCase(value)}
          </MenuItem>
        ))
      }

      return <></>
    }
    return (
      <>
        <>
          <Grid container>
            <Grid item md={8}>
              Condition Question Type
            </Grid>
            <Grid item md={4}>
              <FormControl fullWidth>
                <InputLabel
                  id="required-label"
                  sx={{
                    zIndex: 100,
                    backgroundColor: "#FFF",
                    paddingRight: "0.3rem",
                  }}
                >
                  Conditional Questions
                </InputLabel>
                <Select
                  labelId="required-label"
                  label="Required"
                  value={keyType}
                  onChange={(event) => {
                    setKeyType(event.target.value)
                  }}
                >
                  <MenuItem disabled value={undefined}>
                    Select
                  </MenuItem>
                  <MenuItem value={KEY_TYPES.DATA_KEY}>Base Questions</MenuItem>
                  <MenuItem value={KEY_TYPES.ADDITIONAL_QUESTIONS}>
                    Additional Questions
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </>

        {keyType === KEY_TYPES.DATA_KEY && (
          <>
            <Grid container>
              <Grid item md={8}>
                <Typography>
                  Which data key should this question be dependent on?
                </Typography>
              </Grid>
              <Grid item md={4}>
                <FormControl fullWidth>
                  <InputLabel
                    id="required-label"
                    sx={{
                      zIndex: 100,
                      backgroundColor: "#FFF",
                      paddingRight: "0.3rem",
                    }}
                  >
                    Data Key
                  </InputLabel>
                  <Select
                    labelId="required-label"
                    label="Required"
                    value={
                      field.conditions.length > 0
                        ? field.conditions[0]?.dataKey
                        : ""
                    }
                    onChange={(event) => {
                      const conditions = field.conditions
                      conditions[0]["conditionCustomField"] = undefined
                      conditions[0]["dataKey"] = event.target.value
                      setField({
                        ...field,
                        conditions: conditions,
                      })
                    }}
                  >
                    <MenuItem disabled value={undefined}>
                      Select
                    </MenuItem>
                    <MenuItem value={0}>
                      {startCase(FORM_INPUT_NAMES.APPLICANT_TYPE)}
                    </MenuItem>
                    {dataKeysMenu()}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </>
        )}
        {keyType === KEY_TYPES.ADDITIONAL_QUESTIONS && (
          <>
            <Grid container>
              <Grid item md={8}>
                <Typography>
                  Which additional question should this question be dependent
                  on?
                </Typography>
              </Grid>
              <Grid item md={4}>
                <FormControl fullWidth>
                  <InputLabel
                    id="required-label"
                    sx={{
                      zIndex: 100,
                      backgroundColor: "#FFF",
                      paddingRight: "0.3rem",
                    }}
                  >
                    Additional Questions
                  </InputLabel>
                  <Select
                    labelId="required-label"
                    label="Required"
                    value={
                      field.conditions.length > 0
                        ? field.conditions[0]?.conditionCustomField
                        : ""
                    }
                    onChange={(event) => {
                      const conditions = field.conditions
                      conditions[0]["dataKey"] = undefined
                      conditions[0]["conditionCustomField"] = event.target.value

                      setField({
                        ...field,
                        conditions: conditions,
                      })
                    }}
                  >
                    <MenuItem disabled value={""}>
                      Select
                    </MenuItem>
                    {additionalQuestionMenu()}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </>
        )}
        {keyType === KEY_TYPES.DATA_KEY && (
          <>
            <Grid container>
              <Grid item md={8}>
                <Typography>
                  What is the value that triggers the appearance of this
                  question?
                </Typography>
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Value"
                  fullWidth
                  onChange={(event) => {
                    const conditions = field.conditions
                    conditions[0]["value"] = event.target.value
                    setField({
                      ...field,
                      conditions: conditions,
                    })
                  }}
                  value={
                    field.conditions.length > 0
                      ? field.conditions[0]["value"]
                      : undefined
                  }
                />
              </Grid>
            </Grid>
          </>
        )}
        {keyType === KEY_TYPES.ADDITIONAL_QUESTIONS &&
          field.conditions &&
          field.conditions.length > 0 &&
          field.conditions[0].conditionCustomField && (
            <>
              <Grid container>
                <Grid item md={8}>
                  <Typography>
                    What is the value that triggers the appearance of this
                    question?
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  {getMultiSelectField(
                    getTemplateCustomField(
                      field.conditions[0].conditionCustomField,
                      data,
                    ),
                    (value) => {
                      const conditions = field.conditions
                      conditions[0]["value"] = value
                      setField({
                        ...field,
                        conditions: conditions,
                      })
                    },
                    getDefaultValue(field.conditions[0].value),
                  )}
                </Grid>
              </Grid>
            </>
          )}
      </>
    )
  }

  useEffect(() => {
    if (!isConditionalQuestion) {
      setField({
        ...field,
        conditions: [{} as Condition],
      })
    }
  }, [field, isConditionalQuestion])

  return (
    <Dialog
      open={true}
      onClose={() => {
        onClose()
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>Edit Additional Question</DialogTitle>
      <DialogContent>
        <Stack
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            margin: "1rem",
          }}
        >
          <Grid container>
            <Grid container>
              <Grid item md={12}>
                <Typography>What is the title of your question?</Typography>
                <Typography variant="caption">
                  Example: How many employees do you have?
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={12}>
                <TextField
                  label="Question"
                  fullWidth
                  onChange={(event) => {
                    setField({
                      ...field,
                      text: event.target.value,
                    })
                  }}
                  value={field.text}
                  style={{ margin: "1rem 0 1rem 0" }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item md={8}>
              <Typography>
                Which section of the application should this question be
                displayed on?
              </Typography>
            </Grid>
            <Grid item md={4}>
              <FormControl fullWidth>
                <InputLabel id="section-label">Section</InputLabel>
                <Select
                  labelId="section-label"
                  label="Section"
                  defaultValue={field.associatedPage}
                  value={field.associatedPage}
                  onChange={(event) => {
                    if (
                      isConditionalQuestion &&
                      field.conditions &&
                      field.conditions.length > 0 &&
                      field.associatedPage !== event.target.value
                    ) {
                      const conditions = field.conditions
                      conditions[0]["conditionCustomField"] = undefined
                      setField({
                        ...field,
                        conditions: conditions,
                        associatedPage: event.target.value as string,
                      })
                    } else {
                      setField({
                        ...field,
                        associatedPage: event.target.value as string,
                      })
                    }
                  }}
                >
                  <MenuItem disabled value={undefined}>
                    Select
                  </MenuItem>
                  {data?.formTemplate.pages
                    .filter((page) =>
                      [PAGE_LABEL_BUSINESS_DETAILS].includes(page.label),
                    )
                    .map((page) => {
                      return (
                        <MenuItem key={page.label} value={page.label}>
                          {page.label}
                        </MenuItem>
                      )
                    })}
                  {/* <MenuItem
                    key={PAGE_LABEL_ADDITIONAL_QUESTIONS}
                    value={PAGE_LABEL_ADDITIONAL_QUESTIONS}
                  >
                    {PAGE_LABEL_ADDITIONAL_QUESTIONS}
                  </MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={8}>
              <Typography>Is a response required?</Typography>
            </Grid>
            <Grid item md={4}>
              <FormControl fullWidth>
                <InputLabel id="required-label">Required</InputLabel>
                <Select
                  labelId="required-label"
                  label="Required"
                  value={field.required ? 1 : 0}
                  onChange={(event) => {
                    setField({
                      ...field,
                      required: Boolean(event.target.value),
                    })
                  }}
                >
                  <MenuItem disabled value={undefined}>
                    Select
                  </MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={8}>
              <Typography>
                Is this a conditional question? (Only show if another question
                contains a specific response)
              </Typography>
            </Grid>
            <Grid item md={4}>
              <FormControl fullWidth>
                <InputLabel
                  id="required-label"
                  sx={{
                    zIndex: 100,
                    backgroundColor: "#FFF",
                    paddingRight: "0.3rem",
                  }}
                >
                  Conditional Questions
                </InputLabel>
                <Select
                  labelId="required-label"
                  label="Required"
                  value={isConditionalQuestion ? 1 : 0}
                  onChange={(event) => {
                    setIsConditionalQuestion(Boolean(event.target.value))
                  }}
                >
                  <MenuItem disabled value={undefined}>
                    Select
                  </MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {isConditionalQuestion && populateConditionalQuestionsForm()}
          <Grid container>
            <Grid item md={8}>
              <Typography>
                What type of a response does this question accept?
              </Typography>
            </Grid>
            <Grid item md={4}>
              <FormControl fullWidth>
                <InputLabel id="type-label">Type</InputLabel>
                <Select
                  labelId="type-label"
                  label="Type"
                  value={field.fieldType}
                  onChange={(event) => {
                    setField({
                      ...field,
                      fieldType: event.target.value as string,
                    })
                  }}
                >
                  <MenuItem disabled value={undefined}>
                    Select one
                  </MenuItem>
                  {/* <MenuItem value={FIELD_TYPES.ADDRESS}>Address</MenuItem> */}
                  <MenuItem value={FIELD_TYPES.TEXT}>Text</MenuItem>
                  <MenuItem value={FIELD_TYPES.EMAIL}>Email</MenuItem>
                  <MenuItem value={FIELD_TYPES.PHONE}>Phone Number</MenuItem>
                  <MenuItem value={FIELD_TYPES.AMOUNT}>Dollar Amount</MenuItem>
                  <MenuItem value={FIELD_TYPES.DATE}>Date</MenuItem>
                  <MenuItem value={FIELD_TYPES.TIME}>Time</MenuItem>
                  <MenuItem value={FIELD_TYPES.FILE}>File</MenuItem>
                  <MenuItem value={FIELD_TYPES.DROPDOWN}>Dropdown</MenuItem>
                  <MenuItem value={FIELD_TYPES.MULTISELECT}>
                    Multiselect
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {field.fieldType === FIELD_TYPES.FILE && (
            <Grid container>
              <Grid item md={8}>
                <Typography>
                  Upload a fillable template pdf (optional)
                </Typography>
              </Grid>
              <Grid item md={4}>
                <FormControl fullWidth>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setUploadDialogOpen(true)
                    }}
                    size="large"
                    style={{ width: "40%" }}
                  >
                    Select File
                  </Button>
                  {field.templateFile &&
                    typeof field.templateFile === "string" && (
                      <a href={field.templateFile as string} download>
                        View
                      </a>
                    )}
                  {field.templateFile && field.templateFile instanceof File && (
                    <a href={URL.createObjectURL(field.templateFile)} download>
                      View (unsaved change)
                    </a>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          )}
          {(field.fieldType === FIELD_TYPES.DROPDOWN ||
            field.fieldType === FIELD_TYPES.MULTISELECT) && (
            <>
              <Grid container>
                <Grid item md={12}>
                  <Stack>
                    <Typography>Options (Comma Separated)</Typography>
                    <Typography variant="caption">
                      Example: Option 1,Option 2,Option 3
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12}>
                  <TextField
                    label="Options (comma separated)"
                    fullWidth
                    onChange={(event) => {
                      setField({
                        ...field,
                        options: event.target.value,
                      })
                    }}
                    value={field.options}
                  />
                </Grid>
              </Grid>
            </>
          )}
          <Grid container>
            <Grid item md={8}>
              <Typography>
                What is the question order number between 1 to 1000 (Lower
                numbers appear first)?
              </Typography>
            </Grid>
            <Grid item md={4}>
              <FormControl fullWidth>
                <TextField
                  id="order"
                  fullWidth
                  type="number"
                  label="order"
                  value={field.order}
                  defaultValue={1000}
                  onChange={(event) => {
                    setField({
                      ...field,
                      order: Number(event.target.value),
                    })
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose()
          }}
          disabled={isLoading}
        >
          Close
        </Button>
        <LoadingButton
          onClick={() => {
            onSave(field)
          }}
          variant="contained"
          loading={isLoading}
        >
          Save
        </LoadingButton>
      </DialogActions>
      {uploadDialogOpen && (
        <DropzoneDialog
          open={uploadDialogOpen}
          onSave={(newFiles) => {
            handleTemplateFileUpload(newFiles)
          }}
          acceptedFiles={[
            "application/pdf",
            "application/doc",
            "application/ms-doc",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/rtf",
          ]}
          showPreviews={true}
          maxFileSize={5000000}
          onClose={() => {
            setUploadDialogOpen(false)
          }}
          filesLimit={1}
          fileObjects={null}
          dropzoneText={"Upload a fillable template pdf (optional)"}
        />
      )}
    </Dialog>
  )
}
