import React, { useState } from "react"
import { AnonymousBusiness, CreditApplication } from "src/types"
import { pdf, Document, Text, Image, Page } from "@react-pdf/renderer"
import {
  PageBase,
  Cell,
  Input,
  Block,
} from "src/components/pdf/output-template/Elements"
import { BankReferenceChart } from "../types/credit.types"
import { useAnonymousBusiness } from "src/queries/credit/useAnonymousBusiness"
import { fShortenNumber } from "src/utils/formatNumber"

/**
 *
 * @param application
 * @returns A promise that resolves to a File containing the PDF of the application
 */

const DigitalBankReferenceData = ({
  chartData,
  businessData,
  bankReferenceDataUrl,
}: {
  chartData: BankReferenceChart
  businessData: AnonymousBusiness
  bankReferenceDataUrl?: string
}) => {
  const key: string =
    chartData.balances && Object.keys(chartData.balances).length > 0
      ? Object.keys(chartData.balances)[0]
      : ""
  if (
    !chartData ||
    !chartData?.balances ||
    Object.keys(chartData?.balances).length === 0
  ) {
    return (
      <Document>
        <PageBase title="Digital Reference Chart" logoSrc={businessData.logo}>
          <Block>
            <Cell spacing="100">
              <Text
                style={{
                  fontSize: "11px",
                  marginTop: "10px",
                  paddingTop: "10px",
                  textAlign: "left",
                }}
              >
                <Block>
                  <Cell spacing="25">
                    <Text style={{ fontSize: "11px" }}>No data available</Text>
                  </Cell>
                </Block>
              </Text>
            </Cell>
          </Block>
        </PageBase>
      </Document>
    )
  }
  if (
    !chartData ||
    !chartData?.assets ||
    Object.keys(chartData?.assets).length === 0
  ) {
    return (
      <Document>
        <PageBase title="Digital Bank Reference" logoSrc={businessData.logo}>
          <Block>
            <Cell spacing="100">
              <Text
                style={{
                  fontSize: "11px",
                  marginTop: "10px",
                  paddingTop: "10px",
                  textAlign: "left",
                }}
              >
                <Block>
                  <Cell spacing="25">
                    <Text style={{ fontSize: "11px" }}>No data available</Text>
                  </Cell>
                </Block>
              </Text>
            </Cell>
          </Block>
        </PageBase>
      </Document>
    )
  }
  const currentBalance = fShortenNumber(chartData.balances[key].currentBalance)
  const nsflastMonthCount =
    chartData.transactions[key].nsfTransactions.lastMonthCount
  const averageBalanceLastYear = fShortenNumber(
    chartData.balances[key].averageBalanceLastYear,
  )
  const nsflast12MonthsCount =
    chartData.transactions[key].nsfTransactions.last12MonthsCount

  return (
    <Document>
      <PageBase title="Digital Reference Chart" logoSrc={businessData.logo}>
        <Block>
          <Cell spacing="48">
            <Input label="Current Balance" value={`${"$" + currentBalance}`} />
          </Cell>
          <Cell spacing="48">
            <Input label="NSF Last Month Count" value={nsflastMonthCount} />
          </Cell>
          <Cell spacing="48">
            <Input
              label="Average Balance Last Year"
              value={`${"$" + averageBalanceLastYear}`}
            />
          </Cell>
          <Cell spacing="48">
            <Input
              label="NSF Last 12 Month Count"
              value={nsflast12MonthsCount}
            />
          </Cell>
        </Block>
        {bankReferenceDataUrl && (
          <Block extraStyles={{ marginTop: 15 }}>
            <Text>Average Cash Balance By Month</Text>
            <Image
              src={bankReferenceDataUrl}
              style={{
                width: "90%",
                height: 250,
                alignSelf: "center",
                margin: "auto",
              }}
            />
          </Block>
        )}
        {chartData.assets && Object.keys(chartData.assets).length > 0 && (
          <Block extraStyles={{ marginTop: 5 }}>
            <Cell spacing="100">
              <Text>Assets</Text>
            </Cell>
            <Cell spacing="48">
              <Input
                label="Institution"
                value={chartData.assets[key].institutionName}
              />
            </Cell>
            <Cell spacing="48">
              <Input
                label="Account Type"
                value={chartData.assets[key].accountType}
              />
            </Cell>
            <Cell spacing="98">
              <Input label="Account Name" value={chartData.assets[key].name} />
            </Cell>
            <Cell spacing="48">
              <Input
                label="Avaliable"
                value={`${
                  "$" + fShortenNumber(chartData.assets[key].available)
                }`}
              />
            </Cell>
            <Cell spacing="48">
              <Input
                label="Current Balance"
                value={`${"$" + fShortenNumber(chartData.assets[key].current)}`}
              />
            </Cell>
          </Block>
        )}
      </PageBase>
      {chartData.assets && Object.keys(chartData.liabilities).length > 0 && (
        <PageBase title="Digital Bank Reference" logoSrc={businessData.logo}>
          <Block extraStyles={{ marginTop: 10 }}>
            <Cell spacing="100">
              <Text>Liabilities</Text>
            </Cell>
            <Cell spacing="48">
              <Input
                label="Institution"
                value={chartData.liabilities[key].institutionName}
              />
            </Cell>
            <Cell spacing="48">
              <Input
                label="Account Type"
                value={chartData.liabilities[key].accountType}
              />
            </Cell>
            <Cell spacing="98">
              <Input
                label="Account Name"
                value={chartData.liabilities[key].name}
              />
            </Cell>
            <Cell spacing="48">
              <Input
                label="Avaliable"
                value={`${
                  "$" + fShortenNumber(chartData.liabilities[key].available)}`}
              />
            </Cell>
            <Cell spacing="48">
              <Input
                label="Current Balance"
                value={`${
                  "$" + fShortenNumber(chartData.liabilities[key].current)
                }`}
              />
            </Cell>
          </Block>
        </PageBase>
      )}
    </Document>
  )
}
export default function useGenerateDigitalBankReferencePdf(
  application: CreditApplication,
  bankReferenceCharts: BankReferenceChart,
  bankReferenceDataUrl: string | undefined,
) {
  const { data: businessData } = useAnonymousBusiness(
    application.seller?.id || "",
  )
  const execute = React.useMemo(() => {
    return async () => {
      if (application.id && bankReferenceCharts) {
        return await pdf(
          <DigitalBankReferenceData
            chartData={bankReferenceCharts}
            businessData={businessData as AnonymousBusiness}
            bankReferenceDataUrl={bankReferenceDataUrl}
          />,
        ).toBlob()
      }
      throw new Error("Missing application data")
    }
  }, [application.id, bankReferenceCharts, bankReferenceDataUrl, businessData])

  return { execute }
}
