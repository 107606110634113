import { LoadingButton } from "@mui/lab"
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  DialogActions,
  Button,
  Stack,
} from "@mui/material"
import { useFormik } from "formik"
import { usePatchTradeReference } from "src/queries/credit/usePatchTradeReference"
import { TradeReference } from "src/types"
import SingleTradeReference from "./SingleTradeReference"
import { useState } from "react"
import { confirm } from "src/components/confirm"
import { convertCommaSeparatedToNumber } from "src/utils/formatNumber"
import TradeReferenceFields from "./TradeReferenceFields"

export default ({
  reference,
  onClose,
  open,
}: {
  reference: TradeReference
  onClose: () => void
  open: boolean
}) => {
  const { execute, isLoading } = usePatchTradeReference(() => {
    onClose()
  }, false)

  const [emailChanged, setEmailChanged] = useState(false)

  const formik = useFormik({
    initialValues: reference,
    onSubmit: (values: TradeReference) => {
      values.creditLimit = convertCommaSeparatedToNumber(values.creditLimit)
      values.balance = convertCommaSeparatedToNumber(values.balance)
      if (emailChanged) {
        confirm(
          "You have changed the contact email for this reference. A new request for a reference will be sent to this address. Would you like to proceed?",
        )
          .then(() => {
            execute(values)
            return
          })
          .catch((err) => console.log(err))
      } else {
        execute(values)
      }
    },
  })

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"md"}>
      <DialogTitle>Enter Trade Reference</DialogTitle>
      <Divider style={{}} />
      <DialogContent>
        <Stack
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <SingleTradeReference
            data={formik.values}
            onChange={(key, value) => {
              formik.setFieldValue(key, value, false)
              if (key === "email") {
                setEmailChanged(true)
              }
            }}
            errors={formik.errors}
          />

          <TradeReferenceFields
            businessId={reference?.sellerId || undefined}
            values={formik.values}
            setFieldValue={formik.setFieldValue}
          />
        </Stack>
      </DialogContent>
      <Divider style={{}} />
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={() => formik.submitForm()}
          loading={isLoading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
