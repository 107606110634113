import { Box, Chip, Typography, Divider } from "@mui/material"
import PaymentIcon from "@mui/icons-material/Payment"
import { TotalPaymentRatioInstallment } from "src/types/vendors.types"

interface PaymentRatioSectionProps {
  paymentRatio: TotalPaymentRatioInstallment
  formatCurrency: (amount?: string) => string
}

export const PaymentRatioSection = ({
  paymentRatio,
  formatCurrency,
}: PaymentRatioSectionProps) => {
  return (
    <>
      <Divider sx={{ my: 2 }} />
      <Typography color="text.secondary" gutterBottom>
        Payment Ratio Details
      </Typography>
      <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
        <Chip
          icon={<PaymentIcon />}
          label={`1 Month TPR: ${paymentRatio.TPR1Month}`}
          variant="outlined"
          size="small"
        />
        <Chip
          icon={<PaymentIcon />}
          label={`6 Month TPR: ${paymentRatio.TPR6Months}`}
          variant="outlined"
          size="small"
        />
        <Chip
          icon={<PaymentIcon />}
          label={`12 Month TPR: ${paymentRatio.TPR12Months}`}
          variant="outlined"
          size="small"
        />
        <Chip
          icon={<PaymentIcon />}
          label={`12 Month Average: ${paymentRatio.TPR12MonthsAverage}`}
          variant="outlined"
          size="small"
        />
        {paymentRatio.payments1MonthAgo !== "NA" && (
          <Chip
            icon={<PaymentIcon />}
            label={`1 Month Ago Payment: ${formatCurrency(
              paymentRatio.payments1MonthAgo,
            )}`}
            variant="outlined"
            size="small"
          />
        )}
        {paymentRatio.payments6MonthsAgo !== "NA" && (
          <Chip
            icon={<PaymentIcon />}
            label={`6 Months Ago Payment: ${formatCurrency(
              paymentRatio.payments6MonthsAgo,
            )}`}
            variant="outlined"
            size="small"
          />
        )}
        {paymentRatio.payments12MonthsAgo !== "NA" && (
          <Chip
            icon={<PaymentIcon />}
            label={`12 Months Ago Payment: ${formatCurrency(
              paymentRatio.payments12MonthsAgo,
            )}`}
            variant="outlined"
            size="small"
          />
        )}
      </Box>
    </>
  )
}
