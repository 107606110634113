import { Formik, FormikConfig, FormikErrors, FormikProps } from "formik"
import React, { RefObject, useCallback } from "react"
import { useParams } from "react-router-dom"
import {
  ApplicationTemplate,
  Application,
  TemplateCustomField,
} from "src/types"

import * as yup from "yup"

import { error } from "src/utils/logger"
import { FormComponent } from "src/types/credit/FormComponent"
import { useSnackbar } from "notistack"
import { getDataKeyFromName, hasPassedConditions } from "./utils"
import { FIELD_TYPES } from "src/statics"
import { FORM_INPUT_NAMES } from "./intake_sections/constants"

export const validateErrorsAgainstSchema = (
  formRef: RefObject<FormikProps<Application>>,
  handleNext: (stepsRemaining?: boolean) => void,
  schema?: yup.AnyObjectSchema,
  onError = (err: any) => {
    error("validation error", err)
  },
) => {
  const conditionalCustomFieldErrors: {
    [key: string]: any
  } = {}
  if (schema) {
    schema
      .validate(formRef?.current?.values.data, { abortEarly: false })
      .catch((err) => {
        for (const error of err.inner) {
          // This if condition is to handle custom questions that return objects like the address field type
          if (
            error.path &&
            error.path.split(".").length > 3 &&
            error.path.split(".")[0] === "customFields" &&
            error.path.split(".")[2] === "responseJson"
          ) {
            const key = error.path.split(".")[1]
            if (conditionalCustomFieldErrors[key]?.responseJson) {
              const value = conditionalCustomFieldErrors[key].responseJson
              const fieldName = error.path.split(".")[3] as string
              value[fieldName] = error.message
              Object.assign(conditionalCustomFieldErrors, {
                [key]: { responseJson: value },
              })
            } else {
              const value: { [key: string]: any } = {}
              const fieldName = error.path.split(".")[3] as string
              value[fieldName] = error.message
              Object.assign(conditionalCustomFieldErrors, {
                [key]: { responseJson: value },
              })
            }
            continue
          }
          if (
            error.path &&
            error.path.split(".").length > 1 &&
            error.path.split(".")[0] === "customFields"
          ) {
            const key = error.path.split(".")[1]
            Object.assign(conditionalCustomFieldErrors, {
              [key]: { response: error.message },
            })
          }
        }
      })
  }

  const addConditionalErrorsToErrors = (
    conditionalCustomFieldErrors: { [key: string]: any },
    errors: FormikErrors<Application>,
    formRef: RefObject<FormikProps<Application>>,
  ) => {
    if (conditionalCustomFieldErrors) {
      if (!errors.data) {
        errors.data = {}
      }
      if (!errors.data.customFields) {
        formRef.current?.setErrors({
          data: {
            ...errors.data,
            customFields: conditionalCustomFieldErrors,
          },
        })
        Object.assign(errors.data, {
          customFields: conditionalCustomFieldErrors,
        })
      } else {
        for (const key in conditionalCustomFieldErrors) {
          Object.assign(errors.data.customFields, {
            [key]: conditionalCustomFieldErrors[key],
          })
        }

        formRef.current?.setErrors({
          data: {
            ...errors.data,
            customFields: errors.data.customFields,
          },
        })
      }
    }
  }

  formRef?.current
    ?.validateForm()
    .then((errors) => {
      if (
        Object.keys(errors).length === 0 &&
        Object.keys(conditionalCustomFieldErrors).length === 0
      ) {
        handleNext()
      } else {
        // only check store & salesRep on the company details page
        if (
          errors.store &&
          schema &&
          Object.keys(schema.fields).find((k) => k === FORM_INPUT_NAMES.STORE)
        ) {
          onError(error)
          return
        }
        if (
          errors.salesRep &&
          schema &&
          Object.keys(schema.fields).find(
            (k) => k === FORM_INPUT_NAMES.SALES_REP,
          )
        ) {
          onError(error)
          return
        }
        if (
          errors.assignee &&
          schema &&
          Object.keys(schema.fields).find(
            (k) => k === FORM_INPUT_NAMES.ASSIGNEE,
          )
        ) {
          onError(error)
          return
        }
        if (errors.data || conditionalCustomFieldErrors) {
          if (schema) {
            addConditionalErrorsToErrors(
              conditionalCustomFieldErrors,
              errors,
              formRef,
            )
            const { customFields: customFieldsErrors, ...restOfErrors } =
              errors.data || { customFields: {} }

            const { customFields, ...restOfFields } = schema.fields

            if (
              Object.keys(customFieldsErrors || {}).every(
                (key) => !Object.keys(customFields?.fields || {}).includes(key),
              ) &&
              Object.keys(restOfErrors).every(
                (key) => !Object.keys(restOfFields).includes(key),
              )
            ) {
              formRef.current?.setErrors({})
              handleNext(true)
            } else {
              const errorsCopy = { ...errors }
              if (
                formRef.current?.values.data?.skippedFields &&
                errorsCopy.data
              ) {
                for (const skippedField of formRef.current?.values.data
                  ?.skippedFields) {
                  const skippedKeys = skippedField.skippedKeys
                  for (const key of skippedKeys) {
                    delete errorsCopy.data[key]
                  }
                }
                if (Object.keys(errors).length === 0) {
                  handleNext()
                  return
                }
              }
              onError(errorsCopy)
            }
          } else {
            const errorsCopy = { ...errors }
            if (
              formRef.current?.values.data?.skippedFields &&
              errorsCopy.data
            ) {
              for (const skippedField of formRef.current?.values.data
                ?.skippedFields) {
                const skippedKeys = Object.keys(skippedField.schema.fields)
                for (const key of skippedKeys) {
                  delete errorsCopy.data[key]
                }
                if (Object.keys(errors).length === 0) {
                  handleNext()
                  return
                }
              }
            }
            onError(errorsCopy)
          }
        }
      }
      return
    })
    .catch((err) => {
      error(err)
    })
}

export default ({
  activeStep,
  handleNext,
  handleBack,
  initialValues,
  application,
  formRef,
  Component,
  completionError,
  template,
  dataSchema,
  steps,
}: Omit<FormikConfig<Application>, "onSubmit"> & {
  activeStep: number
  handleNext: (stepsRemaining?: boolean, onFailed?: () => void) => void
  handleBack: () => void
  application: Application
  formRef: RefObject<FormikProps<Application>>
  completionError?: FormComponent["completionError"]
  Component: React.ComponentType<FormComponent>
  template: ApplicationTemplate
  dataSchema?: yup.AnyObjectSchema
  steps: FormComponent["steps"]
}) => {
  const params = useParams()
  const { id } = params

  // salesRep and store have to be added to the validation schema in two places
  // 1. here so that formik checks for them (this doesn't by default block the page)
  // 2. in the onContinue call they should be added to the schema of the company details page
  // so the page gets blocked if they are not filled out
  // in the future we should replace .validateForm() with specific schema validation
  // and that should remove this redundancy
  const validationSchema = yup.object({
    data: dataSchema ? dataSchema : yup.object({}),
    // although these are added, they won't be blocking validation
    // since an error will be thrown only if we are checking them against the
    // company details page schema
    store: dataSchema
      ? yup.string().required("Please select an opton")
      : yup.string().nullable(),
    salesRep: dataSchema
      ? yup.object().required("Please select an option")
      : yup.object().nullable(),
    assignee: dataSchema
      ? yup.object().required("Please select an option")
      : yup.object().nullable(),
  })

  const { enqueueSnackbar } = useSnackbar()

  const getFieldChildren = useCallback(
    (
      parentFieldId: string,
      customFields: TemplateCustomField[],
      existingIDs: string[],
    ) => {
      // Recursively gets all the dependent fields of a parent field
      const result: TemplateCustomField[] = []
      const children = customFields.filter(
        (field) =>
          field.conditions &&
          field.conditions.length > 0 &&
          field.conditions[0].conditionCustomField === parentFieldId,
      )

      for (const field of children) {
        if (existingIDs.includes(field.id as string)) {
          continue
        }
        result.push(field)
        existingIDs.push(field.id as string)
        result.push(
          ...getFieldChildren(field.id as string, customFields, existingIDs),
        )
      }

      return result
    },
    [],
  )

  const removeInvalidChildrenValues = (
    customFieldResponseId: string | undefined,
    dataKey: string | undefined,
    data: Application["data"],
  ) => {
    const children: TemplateCustomField[] = []

    if (customFieldResponseId && template.customFields) {
      const fields = template.customFields.filter(
        (field) =>
          field.conditions &&
          field.conditions.length > 0 &&
          field.conditions[0].conditionCustomField &&
          field.conditions[0].conditionCustomField === customFieldResponseId &&
          field.associatedPage &&
          !hasPassedConditions(
            field,
            data["customFields"],
            data,
            field.associatedPage,
          ),
      )
      for (const field of fields) {
        children.push(field)
        children.push(
          ...getFieldChildren(field.id as string, template.customFields, []),
        )
      }
    } else if (dataKey && template.customFields) {
      const fields = template.customFields.filter(
        (field) =>
          field.conditions &&
          field.conditions.length > 0 &&
          field.conditions[0].dataKey &&
          field.conditions[0].dataKey === dataKey &&
          field.associatedPage &&
          !hasPassedConditions(
            field,
            data["customFields"],
            data,
            field.associatedPage,
          ),
      )
      for (const field of fields) {
        children.push(field)
        children.push(
          ...getFieldChildren(field.id as string, template.customFields, []),
        )
      }
    }

    if (children.length > 0) {
      for (const child of children) {
        if (
          data &&
          data["customFields"] &&
          data["customFields"][child.id as string]
        ) {
          delete data["customFields"][child.id as string]
          if (!data["customResponsesToBeDeleted"]) {
            data["customResponsesToBeDeleted"] = {}
          }
          data["customResponsesToBeDeleted"][child.id as string] = 1
          const existed = pageFilesToUpload.delete(child.id as string)
          if (existed) {
            setPageFilesToUpload(pageFilesToUpload)
          }
        }
      }
      formRef.current?.setFieldValue("data", data, false)
    }
  }

  const [pageFilesToUpload, setPageFilesToUpload] = React.useState<
    // map of question uuid to files that user wants to upload
    Map<string, Array<File>>
  >(new Map())

  // // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDataFieldUpdated = (key: string, value: any, label?: string) => {
    let data = formRef.current?.values.data
    if (!data) {
      data = {}
    }
    data[key] = value
    // if user is typing on a page, remove it from skipped pages
    // if they skip again, it will be added back via onSkipPage
    if (label) {
      if (!data["skippedFields"]) {
        data["skippedFields"] = []
      } else {
        data["skippedFields"] = data["skippedFields"].filter(
          (f: any) => f.label !== label,
        )
      }
    }
    if (!value) {
      data[key] = ""
    }
    formRef.current?.setFieldValue("data", data, false)
    const dataKey = getDataKeyFromName(key)
    removeInvalidChildrenValues(undefined, dataKey, data)
  }

  const onSkipPage = (
    label: string,
    reason: string,
    schema: yup.AnyObjectSchema,
  ) => {
    let data = formRef.current?.values.data
    if (!data) {
      data = {}
    }
    if (!data["skippedFields"]) {
      data["skippedFields"] = []
    }
    // remove any existing reasons
    data["skippedFields"] = data["skippedFields"].filter(
      (f: any) => f.label !== label,
    )
    // add it to the skipped fields if not already there
    data["skippedFields"].push({
      label,
      reason,
      skippedKeys: Object.keys(schema.fields),
    })

    formRef.current?.setFieldValue("data", data, false)
    handleNext()
  }

  // // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onApplicationFieldUpdated = (key: string, value: any) => {
    formRef.current?.setFieldValue(key, value, false)
  }

  const onCustomFieldUpdated = (
    key: string,
    value: File | string | undefined | Array<File>,
    label?: string,
  ) => {
    let data = formRef.current?.values.data

    // create properties if they don't exist
    if (!data) {
      data = {}
    }
    if (!data["customFields"]) {
      data["customFields"] = {}
    }
    if (!data["customResponsesToBeDeleted"]) {
      data["customResponsesToBeDeleted"] = {}
    }

    if (value && !(value instanceof Array) && !(value instanceof File)) {
      data["customFields"][key] = value
      // if we had previously marked the fieldId to be deleted
      // revert that.
      if (data["customResponsesToBeDeleted"][key]) {
        delete data["customResponsesToBeDeleted"][key]
      }

      // user removed all files associated with this question
      pageFilesToUpload.delete(key)
      setPageFilesToUpload(pageFilesToUpload)
    } else if (value instanceof Array) {
      // if we had previously marked the fieldId to be deleted
      // revert that.
      if (data["customResponsesToBeDeleted"][key]) {
        delete data["customResponsesToBeDeleted"][key]
      }

      // in memory files for a field changed
      pageFilesToUpload.set(key, value)
      setPageFilesToUpload(pageFilesToUpload)

      if (!data["filesToUpload"]) {
        data["filesToUpload"] = {}
      }

      // this will be iterated over and uploaded
      // is `usePatchBuyerApplication`
      // it is not read anywhere else.
      data["filesToUpload"] = pageFilesToUpload

      // mark the field with some data so validation passes
      // this will be rewritten by correct files when we submit the page data
      data["customFields"][key] = {
        field: { fieldType: FIELD_TYPES.FILE },
        file: value[0],
      }
    } else if (data["customFields"][key]) {
      delete data["customFields"][key]
      data["customResponsesToBeDeleted"][key] = 1
    }

    // unskip the page because the user entered some data in the page
    if (label) {
      if (!data["skippedFields"]) {
        data["skippedFields"] = []
      } else {
        data["skippedFields"] = data["skippedFields"].filter(
          (f: any) => f.label !== label,
        )
      }
    }
    formRef.current?.setFieldValue("data", data, false)
    removeInvalidChildrenValues(key, undefined, data)
  }

  const onContinue = React.useCallback(
    async (
      schema?: yup.AnyObjectSchema,
      onFormValidationError = () => {
        enqueueSnackbar(
          "Data validation failed. Please correct any errors and try again.",
          {
            variant: "warning",
          },
        )
        return undefined
      },
      ref: RefObject<FormikProps<Application>> = formRef,
      label?: string,
    ) => {
      if (label) {
        if (ref.current?.values.data["skippedFields"]) {
          // unskip this page if skipped before because the user pressed Continue this time
          const newData = ref.current?.values.data
          newData["skippedFields"] = newData["skippedFields"].filter(
            (f: any) => f.label !== label,
          )
          await ref.current?.setFieldValue("data", newData, false)
        }
      }
      if (schema) {
        validateErrorsAgainstSchema(
          ref,
          () => {
            handleNext(activeStep === steps.length - 1, onFormValidationError)
          },
          schema,
          onFormValidationError,
        )
      } else {
        handleNext(activeStep === steps.length - 1, onFormValidationError)
      }
    },
    [activeStep, enqueueSnackbar, formRef, handleNext, steps.length],
  )

  if (!template || steps.length === 0)
    return (
      <>
        Loading business template...If this screen persists, please contact
        info@netnow.io
      </>
    )
  if (id && !application) return <>Loading application...</>

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {
        console.log("no-op")
      }}
      validationSchema={validationSchema}
      innerRef={formRef}
    >
      {(props) => (
        <Component
          filesInMemory={pageFilesToUpload}
          template={template}
          activeStep={activeStep}
          steps={steps}
          handleBack={handleBack}
          props={props}
          onContinue={onContinue}
          onSkip={onSkipPage}
          onDataFieldUpdated={onDataFieldUpdated}
          onCustomFieldUpdated={onCustomFieldUpdated}
          onApplicationFieldUpdated={onApplicationFieldUpdated}
          application={application}
          completionError={completionError}
        />
      )}
    </Formik>
  )
}
