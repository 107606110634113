import { useState } from "react"

// @mui
import {
  Box,
  Button,
  CardContent,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material"

// components
import { DropzoneDialog } from "mui-file-dropzone"

import { useSnackbar } from "notistack"
import { isFileValid } from "src/utils/file-validator"

import { useAdobeExtractPdf } from "src/queries/vendors/useAdobeExtractPdf"
import { toBase64 } from "../utils"
import FinancialAnalysisResponse from "./components/FinancialAnalysisResponse"
import { Warning } from "@mui/icons-material"

// ----------------------------------------------------------------------
export default function FinancialAnalysisView() {
  const [uploadedFile, setUploadedFile] = useState<File | undefined>(undefined)
  const [uploadedFileBase64, setUploadedFileBase64] = useState<
    string | undefined
  >(undefined)
  const [uploadFileToggle, setUploadFileToggle] = useState(false)

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const {
    data: response,
    execute: uploadFiles,
    isLoading,
  } = useAdobeExtractPdf()
  const theme = useTheme()

  return (
    <Stack sx={{ padding: 2, marginBottom: 2 }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h4" gutterBottom>
          Financial Analysis <Chip label="Beta" variant="outlined" />
        </Typography>
      </Stack>
      <Stack
        sx={{ paddingLeft: 20, paddingRight: 20, marginBottom: 3 }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Stack>
          <Typography variant="body2" align="center" gutterBottom>
            NetNow's Financial Analysis tool uses state-of-the-art AI tools to
            help you read and summarize Income Statements or Profit and Loss
            statements in a matter of seconds. Please note this feature is in
            Beta, and we're continuously improving it.
          </Typography>
          <Typography variant="subtitle2">Here's How It Works:</Typography>
          <Typography
            variant="body2"
            gutterBottom
            align="left"
            sx={{ paddingLeft: 3 }}
          >
            <strong>1.</strong> Upload a P&L or Income Statement (must be in{" "}
            <strong>PDF</strong> format)
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            align="left"
            sx={{ paddingLeft: 3 }}
          >
            <strong>2.</strong> AI Analysis: Our tool summarizes key financials
            and calculates important ratios.
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            align="left"
            sx={{ paddingLeft: 3 }}
          >
            <strong>3.</strong> Download the report for further analysis
          </Typography>
          <Stack
            direction={"row"}
            spacing={1}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Warning sx={{ color: theme.palette.warning.main }} />
            <Typography
              variant="caption"
              gutterBottom
              align="center"
              sx={{ paddingTop: 0.2 }}
            >
              <strong>Important:</strong> This tool uses AI - please review the
              results for accuracy.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {!uploadedFile && (
        <Stack>
          <Paper
            sx={{
              width: 1,
              height: "max-content",
              padding: 3,
              marginBottom: 3,
            }}
            elevation={3}
          >
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Typography variant="h6">Attached Files</Typography>
              <Button
                variant="outlined"
                onClick={() => {
                  setUploadFileToggle(true)
                }}
              >
                Upload File
              </Button>
            </Stack>
            <Stack sx={{ paddingTop: "15%", paddingBottom: "15%" }}>
              <Typography variant="body2" color="textSecondary" align="center">
                No files attached - click the button to upload your financial
                statement
              </Typography>
            </Stack>
          </Paper>
        </Stack>
      )}
      {uploadedFile && (
        <Grid container spacing={4} style={{ height: "800px" }}>
          <Grid item xs={12} sm={6} sx={{ marginBottom: 2 }}>
            <Paper
              sx={{
                width: 1,
                height: "100%",
                padding: 3,
                position: "relative",
              }}
              elevation={3}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
              >
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                >
                  <Typography variant="h6">Attached Files</Typography>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setUploadFileToggle(true)
                    }}
                  >
                    Upload File
                  </Button>
                </Stack>

                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  height="100%"
                >
                  <Typography variant="h6" alignSelf="center">
                    {uploadedFile.name}
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    flexGrow={1}
                    position="relative"
                  >
                    {uploadedFileBase64 ? (
                      <iframe
                        src={uploadedFileBase64}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          width: "100%",
                          height: "100%",
                          border: "none",
                        }}
                        title="PDF Viewer"
                      />
                    ) : (
                      <CircularProgress />
                    )}
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginBottom: 2 }}>
            <Paper
              sx={{
                width: 1,
                height: "100%",
                padding: 3,
                position: "relative",
              }}
              elevation={3}
            >
              <CardContent>
                {!response?.key && isLoading && (
                  <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      pt: "25%",
                      pb: "25%",
                    }}
                  >
                    <CircularProgress />
                  </Stack>
                )}
                {response?.key && (
                  <FinancialAnalysisResponse
                    cache_key={response?.key}
                    fileName={uploadedFile.name}
                  />
                )}
              </CardContent>
            </Paper>
          </Grid>
        </Grid>
      )}
      <DropzoneDialog
        open={uploadFileToggle}
        onSave={(newFiles) => {
          if (newFiles.length > 0) {
            const formData = new FormData()
            let hasValidationError = false
            newFiles.forEach((file, index) => {
              setUploadedFile(file)
              hasValidationError = !isFileValid(file)
              if (hasValidationError) {
                return enqueueSnackbar(
                  "Invalid file type for file " + file.name,
                  {
                    variant: "error",
                  },
                )
              } else {
                toBase64(file)
                  .then((base64) => {
                    setUploadedFileBase64(base64 as string)
                    return
                  })
                  .catch((error) => {
                    console.error(error)
                  })
              }
              formData.append("file" + index, file)
            })
            if (!hasValidationError) {
              setUploadFileToggle(false)

              const key = enqueueSnackbar("Uploading file...", {
                variant: "info",
                autoHideDuration: null, // Prevent auto-hide, we'll close it manually
              })
              uploadFiles(formData, () => {
                if (key) {
                  closeSnackbar(key)
                }
                enqueueSnackbar("File uploaded", {
                  variant: "success",
                })
              })
            } else {
              setUploadFileToggle(false)
            }
          }
        }}
        acceptedFiles={["application/pdf"]}
        showPreviews={true}
        maxFileSize={31457280}
        onClose={() => {
          setUploadFileToggle(false)
        }}
        filesLimit={1}
        fileObjects={null}
        dropzoneText={"Drag and drop a PDF here or click to select a file"}
      />
    </Stack>
  )
}
