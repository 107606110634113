import { Business } from "src/types"

export const BUSINESS_PREFERENCES = {
  SEND_APPROVAL_EMAIL_DEFAULT: "sendApprovalEmailsDefault",
  HIDE_SENSITIVE_FIELDS_IN_PDF: "hideSensitiveFieldsInPdf",
  MANUAL_BANK_REFERENCE: "manualBankReference",
  ASK_NET_TERMS_IN_TRADE_REFERENCE: "askNetTermsInTradeReference",
  SIGNATURE_V3: "signatureV3",
  DASHBOARD_PERSONAL_GUARANTOR_COLUMN: "dashboardPersonalGuarantorColumn",
  MERGE_TERMS_IN_FIRST_PAGE: "mergeTermsInFirstPage",
}

export const usePreference = (
  key: string,
  business?: Pick<Business, "preferences">,
) => {
  // TODO: needs to be able to pull from UserToBusiness
  const biz = business
  return {
    preference: biz?.preferences?.[key],
  }
}
