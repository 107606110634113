import { Card, CardContent, CardHeader, useTheme } from "@mui/material"
import Chart from "react-apexcharts"
import { CreditSafeTDPIndustryBreakdown } from "src/types"

const CreditSafeTPDIndustryBreakdown = ({
  data,
}: {
  data: CreditSafeTDPIndustryBreakdown
}) => {
  const theme = useTheme()
  const chartOptions = {
    chart: {
      type: "bar" as const,
      stacked: true,
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: "55%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: data.map(
        (item) => `${item.industryDescription} (${item.industryCode})`,
      ),
      labels: {
        style: { fontSize: "12px" },
      },
    },
    yaxis: {
      title: { text: "Percentage (%)" },
      labels: {
        formatter: (value: number) => {
          if (typeof value === "number") {
            return `${value.toFixed(1)}%`
          }
          return value + "%"
        },
      },
    },
    legend: {
      position: "top" as const,
      horizontalAlign: "left" as const,
    },
    colors: [
      theme.palette.success.dark,
      theme.palette.grey[900],
      theme.palette.error.main,
      theme.palette.info.main,
      theme.palette.warning.main,
    ],
    tooltip: {
      y: {
        formatter: (value: number) => {
          if (typeof value === "number") {
            return `${value.toFixed(2)}%`
          }
          return value + "%"
        },
      },
    },
  }

  const series = [
    {
      name: "Current",
      data: data.map((item) =>
        Number.isNaN(item.currentPercent) || item.currentPercent === undefined
          ? 0
          : item.currentPercent,
      ),
    },
    {
      name: "1-30 Days",
      data: data.map((item) =>
        Number.isNaN(item.percent1to30) || item.percent1to30 === undefined
          ? 0
          : item.percent1to30,
      ),
    },
    {
      name: "31-60 Days",
      data: data.map((item) =>
        Number.isNaN(item.percent31to60) || item.percent31to60 === undefined
          ? 0
          : item.percent31to60,
      ),
    },
    {
      name: "61-90 Days",
      data: data.map((item) =>
        Number.isNaN(item.percent61to90) || item.percent61to90 === undefined
          ? 0
          : item.percent61to90,
      ),
    },
    {
      name: "90+ Days",
      data: data.map((item) =>
        Number.isNaN(item.percent91plus) || item.percent91plus === undefined
          ? 0
          : item.percent91plus,
      ),
    },
  ]

  return (
    <Card>
      <CardHeader title="Industry Payment Breakdown" />
      <CardContent>
        <Chart options={chartOptions} series={series} type="bar" height={600} />
      </CardContent>
    </Card>
  )
}

export default CreditSafeTPDIndustryBreakdown
