import { QueryClient } from "react-query"
import { CreditApplication } from "src/types"
import { useTypedMutation } from "../../useTypedMutation"

export function useSignV3(onSuccess?: (data: CreditApplication) => void) {
  const ENDPOINT = "/application/base/signv3"
  const postSign = useTypedMutation("post/application/base/signv3", onSuccess)

  const execute = (
    applicationId: string,
    signerFirst: string,
    signerLast: string,
    signerRole: string,
    fingerprintRequestId: string,
    b64Signature: string,
    applicationPdf: File,
  ) => {
    const formData = new FormData()
    formData.append("id", applicationId)
    formData.append("file0", applicationPdf)
    formData.append("signerFirst", signerFirst)
    formData.append("signerLast", signerLast)
    formData.append("signerRole", signerRole)
    formData.append("b64Signature", b64Signature)
    formData.append("visitorRequestId", fingerprintRequestId)
    postSign.mutate(
      {
        method: "post",
        endpoint: ENDPOINT,
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        timeout: 60000,
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
        },
      },
    )
  }
  return { ...postSign, execute }
}
