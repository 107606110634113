import { useTypedMutation } from "../../useTypedMutation"

export const REQUEST_TYPES = {
  PERSONAL_GUARANTY: "PERSONAL_GUARANTY",
  CREDIT_AGREEMENT: "CREDIT_AGREEMENT",
}

export function useDocumentV3() {
  const ENDPOINT = "/application/v3/download"
  const post = useTypedMutation<{
    signedPdf?: string
    unsignedPdf?: string
    requestId?: string
    requestType?: string
    applicationId?: string
  }>("post/application/v3/download")

  const fetch = (
    requestType: string,
    requestId?: string,
    applicationId?: string,
  ) => {
    return post.mutateAsync({
      endpoint: ENDPOINT,
      body: {
        requestId,
        requestType,
        applicationId,
      },
    })
  }
  return { ...post, fetch }
}
