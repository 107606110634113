import {
  CANADA,
  getCountries,
  getProvinces,
  getStates,
  MAPBOX_ACCESS_TOKEN,
  PROVINCES,
  STATES,
  UNITED_STATES,
} from "./Address"
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { AddressAutofill } from "@mapbox/search-js-react"
import * as yup from "yup"
import { TemplateCustomField } from "src/types"

export const addAddressToSchema = (
  customSchema: yup.AnyObjectSchema,
  field: TemplateCustomField,
) => {
  customSchema = customSchema.concat(
    yup.object({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      [field.id!]: yup
        .object({
          responseJson: yup.object({
            address: yup.string().required("Address is required"),
            city: yup.string().required("City is required"),
            country: yup
              .string()
              .required("Country is required")
              .oneOf([CANADA, UNITED_STATES]),
            region: yup
              .string()
              .required("Region is required")
              .oneOf([...STATES, ...PROVINCES]),
            postCode: yup.string().required("Post code is required"),
          }),
        })
        .required("Field is required"),
    }),
  )
  return customSchema
}

export default ({
  keyPrefix,
  keySuffix = "",
  data,
  onChange,
  errors,
  required = true,
  defaults = {},
}: {
  keyPrefix: string
  keySuffix?: string
  data: { [key: string]: string }
  errors: any
  onChange: (key: string, value: string | undefined) => void
  required?: boolean
  defaults?: {
    country?: string
  }
}) => {
  const matches = useMediaQuery("(max-width: 899px)")
  return (
    <>
      <Stack>
        <Box
          style={{
            width: "100%",
            display: "flex",
            flexDirection: matches ? "column" : "row",
            gap: !matches ? "56px" : "5%",
          }}
        >
          <Stack style={{ width: "100%" }}>
            <AddressAutofill
              options={{
                language: "en",
              }}
              accessToken={MAPBOX_ACCESS_TOKEN}
              onRetrieve={(res) => {
                onChange(
                  "address",
                  res.features[0].properties.feature_name.trim(),
                )
                onChange(
                  "city",
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  res.features[0].properties.place.trim(),
                )
                onChange(
                  "region",
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  res.features[0].properties.region.trim(),
                )
                onChange("postCode", res.features[0].properties.postcode)
                onChange("country", res.features[0].properties.country?.trim())
              }}
              onChange={(val) => {
                if (!val) {
                  onChange("address", "")
                  onChange("city", "")
                  onChange("region", "")
                  onChange("postCode", "")
                  onChange("country", "")
                } else {
                  if (data["address"] !== val) {
                    onChange("address", val)
                  }
                }
              }}
            >
              <TextField
                id={keyPrefix + "address-line1" + keySuffix}
                autoComplete="shipping address-line1"
                required={required}
                margin="normal"
                fullWidth
                name="address"
                label="Address"
                type="text"
                defaultValue={data["address"]}
                value={data["address"]}
                inputProps={{
                  style: { height: "56px" },
                }}
                error={errors ? Boolean(errors["address"]) : false}
              />
            </AddressAutofill>
          </Stack>
          <TextField
            id={keyPrefix + "address-line2" + keySuffix}
            autoComplete="shipping address-line2"
            margin="normal"
            fullWidth
            name="address2"
            placeholder={matches ? "Address Line 2" : ""}
            label={matches ? "" : "Address Line 2"}
            type="text"
            value={data["addressLine2"]}
            inputProps={{
              style: { height: "56px" },
            }}
            onChange={(event) => {
              onChange("addressLine2", event.target.value)
            }}
          />
        </Box>
        {data["address"] && (
          <Box
            sx={{
              display: "flex",
              flexDirection: matches ? "column" : "row",
              width: "100%",
              gap: matches ? "0" : "56px",
              alignItems: "center",
            }}
          >
            <TextField
              id={"city"}
              margin="normal"
              fullWidth
              required={required}
              placeholder={matches ? "City" : ""}
              label={matches ? "" : "City"}
              type="text"
              value={data["city"]}
              inputProps={{
                style: { height: "56px" },
              }}
              onChange={(event) => {
                onChange("city", event.target.value)
              }}
              error={errors ? Boolean(errors["city"]) : false}
            />
            <FormControl fullWidth margin="normal" required={required}>
              {!matches && (
                <InputLabel id="region-label">
                  {data[keyPrefix + "Country" + keySuffix] === UNITED_STATES
                    ? "State"
                    : "Province"}
                </InputLabel>
              )}

              <Select
                labelId="region-label"
                id={keyPrefix + "region-select" + keySuffix}
                value={data["region"] || ""}
                defaultValue={data["region"]}
                onChange={(event) => {
                  onChange("region", event.target.value)
                }}
                label={
                  matches
                    ? ""
                    : data[keyPrefix + "Country" + keySuffix] === UNITED_STATES
                      ? "State"
                      : "Province"
                }
                style={{ height: "56px" }}
                error={errors ? Boolean(errors["region"]) : false}
                renderValue={(selected) => {
                  if (selected === undefined || selected === "") {
                    if (matches) {
                      return (
                        <Typography sx={{ color: "text.disabled" }}>
                          {data[keyPrefix + "Country" + keySuffix] ===
                          UNITED_STATES
                            ? "State"
                            : "Province"}
                        </Typography>
                      )
                    }
                  }

                  return selected
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {data["country"] === UNITED_STATES && getStates()}
                {data["country"] === CANADA && getProvinces()}
              </Select>
            </FormControl>
            <TextField
              id={"postCode"}
              margin="normal"
              required={required}
              fullWidth
              placeholder={matches ? "Postal/Zip Code" : ""}
              label={matches ? "" : "Postal/Zip Code"}
              type="text"
              value={data["postCode"]}
              inputProps={{
                style: { height: "56px" },
              }}
              onChange={(event) => {
                onChange("postCode", event.target.value)
              }}
              error={errors ? Boolean(errors["postCode"]) : false}
            />
            <FormControl fullWidth margin="normal" required={required}>
              {!matches && <InputLabel id="country-label">Country</InputLabel>}
              <Select
                labelId="country-label"
                id={keyPrefix + "country-select" + keySuffix}
                value={data["country"] || ""}
                defaultValue={data["country"]}
                onChange={(event) => {
                  onChange("country", event.target.value)
                  onChange("region", undefined)
                }}
                label={matches ? "" : "Country"}
                style={{ height: "56px" }}
                error={errors ? Boolean(errors["country"]) : false}
                renderValue={(selected) => {
                  if (selected === undefined || selected === "") {
                    if (matches) {
                      return (
                        <Typography sx={{ color: "text.disabled" }}>
                          Country
                        </Typography>
                      )
                    }
                  }

                  return selected
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {getCountries()}
              </Select>
            </FormControl>
          </Box>
        )}
      </Stack>
    </>
  )
}
