// @mui

import {
  Card,
  Divider,
  Stack,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material"

import { useState } from "react"

// ----------------------------------------------------------------------

import { useCashApplication } from "src/queries/cod/useCashApplication"
import TwoColumnEditableCardSection from "../components/TwoColumnEditableCardSection"
import { usePatchCashApplication } from "src/queries/cod/usePatchCashApplication"
import { enqueueSnackbar } from "notistack"
import { camelCase } from "lodash"
import DocumentFieldsView from "./DocumentFieldsView"
import CashApplicationComments from "./components/CashApplicationComments"
import { renderCustomQuestionsFor } from "../utils"
import { useCashApplicationTemplate } from "src/queries/cod/useCashApplicationTemplate"
import { PAGE_LABEL_BUSINESS_DETAILS } from "../intake_sections/constants"
import CashPDFOrImageViewerDialog from "src/components/pdf/CashPDFOrImageViewerDialog"
import { customWordMapping } from "src/statics"

export default function ({ applicationId }: { applicationId: string }) {
  const { data: application, refetch: refetchApplication } =
    useCashApplication(applicationId)

  const { data: businessTemplate } = useCashApplicationTemplate()

  const { execute: patchApplication, isLoading: isPatching } =
    usePatchCashApplication(() => {
      refetchApplication()
      enqueueSnackbar("Application updated", { variant: "success" })
    })

  const [tab, setTab] = useState(0)

  const [addressType, setAddressType] = useState<string | null>("business")

  const handleAddressTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newType: string | null,
  ) => {
    if (newType) {
      setAddressType(newType)
    }
  }

  const [viewDocument, setViewDocument] = useState<string | undefined>(
    undefined,
  )

  let addressTypes = application?.template?.pages
    .find((page) => page.label === "Business Details")
    ?.config?.filter((config) => config.label.includes("Address"))
    ?.filter((c) => c.value === true)
    ?.map((c) => c.label)

  if (!addressTypes || addressTypes.length === 0) {
    addressTypes = ["Business Address", "Shipping Address"]
  }

  if (!application) {
    return <div>Loading...</div>
  }

  const renderUser = () => {
    return (
      <>
        <TwoColumnEditableCardSection
          title="Submitted By"
          data={application.data}
          applicationId={applicationId}
          patchApplication={patchApplication}
          isPatching={isPatching}
          rows={[
            [
              { name: "First Name", key: "firstName" },
              { name: "Last Name", key: "lastName" },
              { name: "Email", key: "email" },
              { name: "Phone", key: "userPhoneNumber" },
              { name: "Role", key: "role" },
            ],
            [
              { name: "Applicant Type", key: "applicantType" },
              { name: "Legal Name", key: "legalBusinessName" },
              { name: "DBA", key: "businessDba" },
              { name: "Annual Revenue", key: "revenue" },
            ],
          ]}
        />
        <Stack
          sx={{ typography: "body2" }}
          style={{ margin: "0 1.5rem 0 1.5rem" }}
        >
          {renderCustomQuestionsFor(
            businessTemplate,
            application,
            PAGE_LABEL_BUSINESS_DETAILS,
            () => {
              return
            },
            (file) => {
              setViewDocument(file)
            },
          )}
        </Stack>
      </>
    )
  }

  const renderAddress = (
    <TwoColumnEditableCardSection
      title="Address"
      subheader={
        <ToggleButtonGroup
          value={addressType}
          exclusive
          onChange={handleAddressTypeChange}
          size="small"
        >
          {addressTypes.map((type, i) => (
            <ToggleButton
              key={"addressSelector" + i}
              value={camelCase(type.replace(" Address", ""))}
            >
              {customWordMapping(
                type.replace("Business", "Mailing"),
                businessTemplate,
              )}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      }
      patchApplication={patchApplication}
      isPatching={isPatching}
      data={application.data}
      applicationId={applicationId}
      rows={[
        [
          { name: "Address Line 1", key: addressType + "Address" },
          { name: "Address Line 2", key: addressType + "AddressLine2" },
          { name: "City", key: addressType + "City" },
        ],
        [
          { name: "Region", key: addressType + "Region" },
          { name: "Country", key: addressType + "Country" },
          { name: "Zip/Postal Code", key: addressType + "PostCode" },
        ],
      ]}
    />
  )

  return (
    <Card style={{ margin: "0 24px 0 24px" }}>
      <>
        <Tabs
          value={tab}
          onChange={(event: React.SyntheticEvent, value: any) => {
            setTab(value)
          }}
          variant="fullWidth"
        >
          <Tab
            label="Applicant / Business"
            tabIndex={0}
            sx={{
              border: "solid",
              borderWidth: "1px",
              borderColor: "#eeeeee",
              background: "rgb(252, 252, 252)",
            }}
          />
          <Tab
            label="Secure Data"
            tabIndex={1}
            sx={{
              border: "solid",
              borderWidth: "1px",
              borderColor: "#eeeeee",
              background: "rgb(252, 252, 252)",
            }}
          />
          <Tab
            label="Comments"
            tabIndex={2}
            sx={{
              border: "solid",
              borderWidth: "1px",
              borderColor: "#eeeeee",
              background: "rgb(252, 252, 252)",
            }}
          />
        </Tabs>
        <Divider />
        {tab === 0 && (
          <>
            {renderUser()}

            <Divider sx={{ borderStyle: "dashed" }} />

            {renderAddress}
          </>
        )}

        {tab === 1 && <DocumentFieldsView />}
        {tab === 2 && <CashApplicationComments application={application} />}

        <CashPDFOrImageViewerDialog
          fileId={viewDocument}
          onClose={() => {
            setViewDocument(undefined)
          }}
        />
      </>
    </Card>
  )
}
