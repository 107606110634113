import { ArrowBack } from "@mui/icons-material"
import { Card, Container, IconButton, Stack, Tab, Tabs } from "@mui/material"
import { alpha } from "@mui/material/styles"
import { useCallback, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useInternalBusiness } from "src/queries/internal/useInternalBusiness"
import ClientBusinessDetails from "./ClientBusinessDetails"
import ClientUserDetails from "./ClientUserDetails"
import ClientCreditDetails from "./ClientCreditDetails"
import ClientCashDetails from "./ClientCashDetails"
import { Business } from "src/types"
import ClientApplicationSettings from "./ClientApplicationSettings"
import ClientExternalCredentialsDetails from "./ClientExternalCredentialsDetails"
import Label from "src/components/label"
import ClientStoresDetails from "./ClientStoresDetails"

export default function ClientAccountDetails() {
  const params = useParams()
  const { id } = params
  const { data: businessData, refetch: businessRefetch } = useInternalBusiness(
    id as string,
  )

  const navigate = useNavigate()
  const BUSINESS = "business"
  const USERS = "users"
  const CREDIT = "credit"
  const CASH = "cash"
  const BRAND_AND_TERMS_AND_CUSTOM_QUESTIONS = "brandAndTerms/customQuestions"
  const CREDENTIALS = "credentials"
  const STORES = "stores"
  const [currentTab, setCurrentTab] = useState(BUSINESS)

  const TABS = [
    {
      value: BUSINESS,
      label: "Business Details",
    },
    {
      value: USERS,
      label: "Users",
    },
    {
      value: CREDIT,
      label: "Credit Configuration",
    },
    {
      value: CASH,
      label: "Cash Configuration",
    },
    {
      value: BRAND_AND_TERMS_AND_CUSTOM_QUESTIONS,
      label: "Brand & Questions",
    },
    {
      value: CREDENTIALS,
      label: "Credentials",
    },
    {
      value: STORES,
      label: "Stores",
    },
  ]

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setCurrentTab(newValue)
    },
    [],
  )

  return (
    <>
      <Stack spacing={1} direction="row" alignItems="flex-start">
        <IconButton
          onClick={() => {
            navigate(-1)
          }}
        >
          <ArrowBack />
          {businessData && (
            <Label color={"secondary"} sx={{ marginLeft: 1 }}>
              {businessData.name}
            </Label>
          )}
        </IconButton>
      </Stack>
      <Container sx={{ my: 1 }}>
        <Card sx={{ p: 2 }}>
          <Stack spacing={2} sx={{ width: 1 }}>
            <Tabs
              value={currentTab}
              onChange={handleChangeTab}
              sx={{
                px: 2.5,
                boxShadow: (theme) =>
                  `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
              }}
            >
              {TABS.map((tab) => (
                <Tab key={tab.value} value={tab.value} label={tab.label} />
              ))}
            </Tabs>
            {TABS.map(
              (tab) =>
                tab.value === currentTab && (
                  <>
                    {tab.value === BUSINESS && (
                      <>
                        {businessData && (
                          <ClientBusinessDetails
                            key={tab.value}
                            id={id as string}
                            data={businessData}
                            refetch={businessRefetch}
                          />
                        )}
                      </>
                    )}
                    {tab.value === USERS && (
                      <ClientUserDetails
                        key={tab.value}
                        id={id as string}
                        business={businessData as Business}
                      />
                    )}
                    {tab.value === CREDIT && (
                      <ClientCreditDetails key={tab.value} id={id as string} />
                    )}
                    {tab.value === CASH && (
                      <ClientCashDetails key={tab.value} id={id as string} />
                    )}
                    {tab.value === BRAND_AND_TERMS_AND_CUSTOM_QUESTIONS && (
                      <ClientApplicationSettings
                        key={tab.value}
                        id={id as string}
                      />
                    )}
                    {tab.value === CREDENTIALS && (
                      <ClientExternalCredentialsDetails
                        key={tab.value}
                        id={id as string}
                      />
                    )}
                    {tab.value === STORES && (
                      <ClientStoresDetails key={tab.value} id={id as string} />
                    )}
                  </>
                ),
            )}
          </Stack>
        </Card>
      </Container>
    </>
  )
}
