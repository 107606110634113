import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  useTheme,
} from "@mui/material"
import { useEffect, useState } from "react"
import generatePDF, { Margin, usePDF } from "react-to-pdf"
import {
  CreditApplication,
  CreditSafePurchasedReport,
  CreditSafeUpdateReport,
} from "src/types"
import CreditSafeReport from "./CreditSafeReport"
import * as Sentry from "@sentry/react"
import { LoadingButton } from "@mui/lab"
import { triggerDownloadFromDataURI } from "src/sections/@dashboard/utils"
import { useSnackbar } from "notistack"
import { useUpdateCreditSafePurchaseReport } from "src/queries/vendors/useCreditSafeSearch"

const PDF_GENERATION_DELAY = 100
export default function CreditSafeReportDialog({
  application,
  report,
  refetchApplicationToolbar,
  open,
  onClose,
}: {
  application: CreditApplication
  report: CreditSafePurchasedReport
  refetchApplicationToolbar: () => void
  open: boolean
  onClose: () => void
}) {
  const theme = useTheme()

  const [creditReportPDF, setCreditReportPDF] = useState<string | undefined>(
    undefined,
  )
  const { execute: executeUpdateCreditSafeReport } =
    useUpdateCreditSafePurchaseReport()
  const [isPDFLoading, setIsPDFLoading] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const sanitizeFileName = (companyId?: string) => {
    const base = "CreditSafe Report.pdf"
    if (!companyId) return base
    const sanitized = companyId.replace(/[^a-z0-9]/gi, "_")
    return `${sanitized}-${base}`
  }
  const fileName = sanitizeFileName(report?.data?.companyId)
  const { targetRef } = usePDF({
    method: "build",
    filename: fileName,
  })

  const generatePDFWithOptions = (targetRef: any, fileName: string) =>
    generatePDF(targetRef, {
      method: "build",
      filename: fileName,
      page: { margin: Margin.SMALL },
      overrides: {
        pdf: { compress: true },
        canvas: { useCORS: true },
      },
    })

  const downloadPDF = () => {
    if (creditReportPDF) {
      triggerDownloadFromDataURI(document, creditReportPDF, fileName)
      setIsPDFLoading(false)
    } else {
      generatePDFWithOptions(targetRef, fileName)
        .then((pdf) => {
          if (!pdf) {
            return
          }
          const pdfData = pdf.output("dataurlstring")
          setCreditReportPDF(pdfData)
          triggerDownloadFromDataURI(document, pdfData, fileName)
          setIsPDFLoading(false)
          refetchApplicationToolbar()
          return
        })
        .catch((error) => {
          Sentry.captureException(error)
          enqueueSnackbar("Error Downloading Credit Safe PDF.", {
            variant: "error",
            preventDuplicate: true,
          })
          setIsPDFLoading(false)
          return
        })
    }
  }
  useEffect(() => {
    if (!report.url && !creditReportPDF) {
      setIsPDFLoading(true)
      const timeoutId = setTimeout(
        () =>
          generatePDFWithOptions(targetRef, fileName)
            .then((pdf) => {
              if (!pdf) {
                return
              }

              const pdfData = pdf.output("dataurlstring")
              setCreditReportPDF(pdfData)
              executeUpdateCreditSafeReport({
                application_id: application.id,
                credit_safe_report_id: report.id,
                file: pdfData,
              } as CreditSafeUpdateReport)
              setIsPDFLoading(false)
              return
            })
            .catch((error) => {
              Sentry.captureException(error)
              setIsPDFLoading(false)
              return
            }),
        PDF_GENERATION_DELAY,
      )
      return () => clearTimeout(timeoutId)
    }
  }, [
    application.id,
    creditReportPDF,
    executeUpdateCreditSafeReport,
    fileName,
    report,
    targetRef,
  ])
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"xl"}
      fullWidth
      sx={{ backgroundColor: theme.palette.grey[100] }}
    >
      <DialogContent sx={{ backgroundColor: theme.palette.grey[100] }}>
        <Stack ref={targetRef}>
          {report && <CreditSafeReport report={report} />}
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant={"outlined"}
          loading={isPDFLoading}
          onClick={() => {
            setIsPDFLoading(true)
            downloadPDF()
          }}
        >
          Download
        </LoadingButton>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
