import { ClientCreditRiskMonitorCredentials } from "src/types"
import { useTypedMutation } from "../useTypedMutation"
import { QueryClient } from "react-query"

export function useCreateInternalCreditRiskMonitorCredentials(
  onSuccess?: () => void,
) {
  const post = useTypedMutation<ClientCreditRiskMonitorCredentials>(
    "post/internal/credit-risk-monitor/",
    onSuccess,
  )
  const execute = (
    body: ClientCreditRiskMonitorCredentials,
    onSuccess: () => void,
  ) => {
    post.mutate(
      {
        method: "post",
        endpoint: "/internal/credit-risk-monitor/",
        body,
        headers: {
          "Content-Type": "application/json",
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: ["/internal/credit-risk-monitorn".replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...post, execute }
}
