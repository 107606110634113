import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material"
import parsePhoneNumberFromString, {
  isPossiblePhoneNumber,
} from "libphonenumber-js"
import { useState } from "react"
import { camelCase } from "lodash"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"
import { useCreditApplication } from "src/queries/credit/useCreditApplication"
import { PAGE_LABEL_BUSINESS_DETAILS } from "src/sections/@dashboard/intake_sections/constants"
import { getBusinessTypes } from "src/sections/@dashboard/utils"
import { APPLICANT_TYPES_OPTIONS, ROLES } from "src/statics"
import { COUNTRIES } from "src/sections/@dashboard/components/Address"

export default function ({ applicationId }: { applicationId: string }) {
  const { data: application } = useCreditApplication(applicationId)
  const { data: businessTemplate } = useApplicationTemplate()
  const [addressType, setAddressType] = useState<string | null>("business")
  const templateData = application?.template || businessTemplate?.formTemplate

  let addressTypes = templateData?.pages
    ?.find((page) => page.label === PAGE_LABEL_BUSINESS_DETAILS)
    ?.config?.filter((config) => config.label.includes("Address"))
    ?.filter((c) => c.value === true)
    ?.map((c) => c.label)

  if (!addressTypes || addressTypes.length === 0) {
    addressTypes = ["Business Address", "Shipping Address"]
  }

  const handleAddressTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newType: string | null,
  ) => {
    if (newType) {
      setAddressType(newType)
    }
  }

  if (!application) {
    return (
      <>
        <CircularProgress />
      </>
    )
  }

  const AddressSection = () => {
    const rows = [
      [
        { name: "Address Line 1", key: addressType + "Address" },
        { name: "Address Line 2", key: addressType + "AddressLine2" },
        { name: "City", key: addressType + "City" },
      ],
      [
        { name: "State/Province", key: addressType + "Region" },
        { name: "Country", key: addressType + "Country", options: COUNTRIES },
        { name: "Zip/Postal Code", key: addressType + "PostCode" },
      ],
    ]

    return (
      <>
        <Box
          sx={{ padding: 2 }}
          style={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <Stack direction="column">
            <ToggleButtonGroup
              value={addressType}
              exclusive
              onChange={handleAddressTypeChange}
              size="small"
            >
              {addressTypes &&
                addressTypes.map((type, i) => (
                  <ToggleButton
                    key={"addressSelector" + i}
                    value={camelCase(type.replace(" Address", ""))}
                  >
                    {type.replace("Business", "Mailing")}
                  </ToggleButton>
                ))}
            </ToggleButtonGroup>
            <Stack direction="row" sx={{ p: 3, gap: 3 }}>
              {displayData(rows)}
            </Stack>
          </Stack>
        </Box>
      </>
    )
  }

  const renderUser = () => {
    const rows = [
      [
        { name: "First Name", key: "firstName" },
        { name: "Last Name", key: "lastName" },
        { name: "Email", key: "email" },
        { name: "Phone", key: "userPhoneNumber" },
        { name: "Role", key: "role", options: ROLES },
        { name: "Role (if other)", key: "roleOther" },
      ],
      [
        {
          name: "Applicant Type",
          key: "applicantType",
          options: APPLICANT_TYPES_OPTIONS,
        },
        {
          name: "Business Type",
          key: "businessType",
          options: businessTemplate
            ? getBusinessTypes(businessTemplate).concat(["Other"])
            : [],
        },
        { name: "Business Type (if other)", key: "businessTypeOther" },
        { name: "Legal Name", key: "legalBusinessName" },
        { name: "DBA", key: "businessDba" },
        { name: "Annual Revenue", key: "revenue" },
      ],
    ]
    return (
      <>
        <Box
          style={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ paddingTop: 2, paddingLeft: 2 }}>
            Applicant / Business
          </Typography>
        </Box>
        <Stack direction="row" sx={{ p: 3, gap: 3 }}>
          {displayData(rows)}
        </Stack>
      </>
    )
  }

  const displayData = (rows: { name: string; key: string }[][]) => {
    return rows.map((column, index) => {
      return (
        <Stack
          key={index}
          spacing={1.5}
          alignItems="flex-start"
          sx={{ typography: "body2", width: "50%" }}
        >
          {column.map((item, index) => {
            return (
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  key={index}
                  style={{ height: "100%", width: "auto" }}
                >
                  <Box
                    key={"item" + index}
                    sx={{
                      color: "text.secondary",
                      width: 120,
                      flexShrink: 0,
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        color: "text.secondary",
                        justifyContent: "center",
                        display: "flex",
                        height: "100%",
                        flexDirection: "column",
                      }}
                    >
                      <span>{item.name}</span>
                    </Box>
                  </Box>
                  <>
                    <Typography variant="body2">
                      {application.data[item.key] &&
                      isPossiblePhoneNumber(application.data[item.key], "US")
                        ? `${parsePhoneNumberFromString(
                            application.data[item.key] || "",
                            "US",
                          )?.formatInternational()}`
                        : application.data[item.key]}
                    </Typography>
                    {application.data[item.key] &&
                      isPossiblePhoneNumber(
                        application.data[item.key],
                        "US",
                      ) && (
                        <Typography variant="body2">
                          {`ext. ${application.data[item.key + "Ext"] || "--"}`}
                        </Typography>
                      )}
                  </>
                </Stack>
              </>
            )
          })}
        </Stack>
      )
    })
  }
  return (
    <>
      <Card>
        <Paper>
          {renderUser()}
          <Divider />
          {AddressSection()}
        </Paper>
      </Card>
    </>
  )
}
