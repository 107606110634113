import { useCallback } from "react"

import MenuItem from "@mui/material/MenuItem"
import Checkbox from "@mui/material/Checkbox"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"
import OutlinedInput from "@mui/material/OutlinedInput"
import Select, { SelectChangeEvent } from "@mui/material/Select"

import { User, IApplicationTableFilterValue } from "src/types"
import { Theme, useTheme } from "@mui/material"
import { createStyles, makeStyles } from "@mui/styles"

// ----------------------------------------------------------------------

type Props = {
  values: User[]
  onFilters: (name: string, value: IApplicationTableFilterValue) => void
  //
  options: User[]
  title: string
  field: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
    },
  }),
)

export default function ApplicationListToolbar({
  title,
  field,
  values,
  onFilters,
  //
  options,
}: Props) {
  const classes = useStyles()

  const theme = useTheme()

  const handleFilterRole = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      onFilters(
        field,
        options.filter((option) => event.target.value.includes(option.id)),
      )
    },
    [field, onFilters, options],
  )

  return (
    <>
      <FormControl
        sx={{
          flexShrink: 0,
          width: { xs: 1, md: 160 },
        }}
      >
        <InputLabel
          style={values.length > 0 ? { color: theme.palette.primary.main } : {}}
        >
          {title}
        </InputLabel>

        <Select
          sx={
            values.length > 0
              ? {
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.primary.main,
                    borderWidth: 2,
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.primary.main,
                    borderWidth: 2,
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.primary.main,
                    borderWidth: 2,
                  },
                  ".MuiSvgIcon-root ": {
                    fill: theme.palette.primary.main + " !important",
                  },
                }
              : {}
          }
          autoWidth
          multiple
          value={values.map((v) => v.id)}
          onChange={handleFilterRole}
          input={
            <OutlinedInput
              label={title}
              classes={values.length > 0 ? classes : {}}
            />
          }
          renderValue={(selected) => selected.length + " selected"}
          MenuProps={{
            PaperProps: {
              sx: { maxHeight: 240 },
            },
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              <Checkbox
                disableRipple
                // size="small"
                checked={!!values.find((v) => v.id === option.id)}
              />
              {option.firstName
                ? `${option.firstName} ${option.lastName}`
                : option.email}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* 
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          flexGrow={1}
          sx={{ width: 1 }}
        >
          <TextField
            fullWidth
            value={filters.name}
            onChange={handleFilterName}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: "text.disabled" }}
                  />
                </InputAdornment>
              ),
            }}
          />

          <IconButton onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </Stack> */}

      {/* <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose()
          }}
        >
          <Iconify icon="solar:printer-minimalistic-bold" />
          Print
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose()
          }}
        >
          <Iconify icon="solar:import-bold" />
          Import
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose()
          }}
        >
          <Iconify icon="solar:export-bold" />
          Export
        </MenuItem>
      </CustomPopover> */}
    </>
  )
}
