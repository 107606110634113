import { AnonymousBusiness, CreditApplication } from "src/types"
import { useTypedMutation } from "../../useTypedMutation"

export type CreditSignatureRequest = {
  unsignedPdf: string
  seller: AnonymousBusiness
}

export function useCreditSignatureRequest() {
  const ENDPOINT = "/application/v3/resolve_request"
  const post = useTypedMutation<{
    visitorRequestId: string
    requestId: string
    verificationCode: string
  }>("post/application/v3/resolve_request")

  const verifyCode = (
    visitorRequestId: string,
    requestId: string,
    verificationCode: string,
    onSuccess?: (r: CreditSignatureRequest) => void,
  ) => {
    post.mutate(
      {
        endpoint: ENDPOINT,
        body: {
          visitorRequestId,
          requestId,
          verificationCode,
        },
      },
      {
        onSuccess: async (data) => {
          if (onSuccess) onSuccess(data as CreditSignatureRequest)
        },
      },
    )
  }
  return { ...post, verifyCode }
}
