import { ArticleOutlined } from "@mui/icons-material"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Alert,
  Paper,
  Typography,
} from "@mui/material"
import Label from "src/components/label"
import { Formik } from "formik"
import { useState } from "react"
import useDebounced from "src/hooks/useDebounced"
import { useCreditRiskMonitorSearch } from "src/queries/vendors/useCreditRiskMonitorSearch"
import {
  CreditApplication,
  CreditRiskMonitorSearchFormInput,
  CreditRiskMonitorSearchResponse,
  CreditRiskMonitorBusiness,
} from "src/types"
import ViewCreditRiskMonitorPDF from "src/sections/@dashboard/credit/components/ViewCreditRiskMonitorPDF"
import { LoadingButton } from "@mui/lab"
import { fDate } from "src/utils/formatTime"

type Props = {
  application: CreditApplication
  isSearchDialogOpen: boolean
  handleCloseSearchDialog: () => void
}

const creditRiskMonitorItem = (
  item: CreditRiskMonitorSearchResponse,
  key: number,
  currentCreditRiskMonitorBusiness: CreditRiskMonitorBusiness | undefined,
  setCreditRiskMonitorBusiness: (business: CreditRiskMonitorBusiness) => void,
) => {
  return (
    <Stack
      key={key}
      component={Paper}
      variant="outlined"
      spacing={1}
      sx={{
        borderRadius: 2,
        bgcolor: "unset",
        cursor: "pointer",
        position: "relative",
        p: { xs: 2.5, sm: 2 },
        marginBottom: 1,
        "&:hover": {
          bgcolor: "background.paper",
          boxShadow: (theme) => theme.shadows[20],
        },
      }}
    >
      <Stack direction="row" justifyContent={"space-between"}>
        <Stack direction="column">
          <Stack direction="row">
            <ArticleOutlined />{" "}
            <Typography variant="subtitle2">
              {item.PrimaryBusinessName}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="caption">
              {item.Address1} {item.Address2} {item.CityName} {item.CountryName}{" "}
              {item.PostalCode}
            </Typography>
          </Stack>
          <Stack direction="row">
            {item.FriskScore && (
              <>
                <Label color={"primary"} sx={{ marginRight: 1 }}>
                  FRISK®: {item.FriskScore}{" "}
                  {item.FriskScoreDate && (
                    <>{" as of " + fDate(item.FriskScoreDate)} </>
                  )}
                </Label>
              </>
            )}
            {item.PayceScore && (
              <>
                <Label color={"primary"} sx={{ marginRight: 1 }}>
                  PAYCE®: {item.PayceScore}{" "}
                  {item.PayceScoreDate && (
                    <>{" as of " + fDate(item.PayceScoreDate)} </>
                  )}
                </Label>
              </>
            )}
            {item.StockExchange && (
              <>
                <Label color={"warning"} sx={{ marginRight: 1 }}>
                  Stock Exchange: {item.StockExchange}
                </Label>
              </>
            )}
            {item.LienCount && (
              <>
                <Label color={"error"} sx={{ marginRight: 1 }}>
                  Lien Count: {item.LienCount}
                </Label>
              </>
            )}
            {item.JudgmentCount && (
              <>
                <Label color={"error"} sx={{ marginRight: 1 }}>
                  Judgement Count: {item.JudgmentCount}
                </Label>
              </>
            )}
            {item.BankruptcyCount && (
              <>
                <Label color={"error"} sx={{ marginRight: 1 }}>
                  Bankruptcy Count: {item.BankruptcyCount}
                </Label>
              </>
            )}
          </Stack>
        </Stack>
        <Stack direction={"row"} alignItems={"center"}>
          <LoadingButton
            variant="outlined"
            size="small"
            fullWidth
            loading={
              currentCreditRiskMonitorBusiness &&
              currentCreditRiskMonitorBusiness.businessId === item.BusinessId
            }
            onClick={() => {
              let address = ""
              if (item.Address1) {
                address += item.Address1
              }
              if (item.Address2) {
                address += ` ${item.Address2}`
              }
              setCreditRiskMonitorBusiness({
                businessId: item.BusinessId,
                businessName: item.PrimaryBusinessName,
                address: address,
                cityName: item.CityName,
                provinceName: item.StateCode,
                countryName: item.CountryName,
              } as CreditRiskMonitorBusiness)
            }}
          >
            View
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ({
  application,
  isSearchDialogOpen,
  handleCloseSearchDialog,
}: Props) => {
  const [searchQuery, setSearchQuery] = useState({
    businessId: application.seller?.id,
    businessName:
      application.data?.legalBusinessName ||
      application.data?.businessDba ||
      "",
  } as CreditRiskMonitorSearchFormInput)
  const debouncedSearchTerm = useDebounced(searchQuery)
  const { data, isLoading } = useCreditRiskMonitorSearch(debouncedSearchTerm)
  const [creditRiskMonitorBusiness, setCreditRiskMonitorBusiness] = useState<
    undefined | CreditRiskMonitorBusiness
  >(undefined)
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={isSearchDialogOpen}
      onClose={() => {
        handleCloseSearchDialog()
      }}
    >
      <DialogTitle id="alert-dialog-title">
        Credit Risk Monitor Search
      </DialogTitle>
      <DialogContent sx={{ p: 2 }}>
        {creditRiskMonitorBusiness && (
          <ViewCreditRiskMonitorPDF
            application={application}
            creditRiskMonitorBusiness={creditRiskMonitorBusiness}
            onClose={() => {
              setCreditRiskMonitorBusiness(undefined)
            }}
          />
        )}
        <Formik
          initialValues={searchQuery}
          onSubmit={(values) => {
            setSearchQuery(values)
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Stack spacing={1} sx={{ m: 1 }}>
                <TextField
                  id="businessName"
                  name="businessName"
                  margin="normal"
                  fullWidth
                  label="Business Name"
                  type="text"
                  value={props.values.businessName}
                  onChange={props.handleChange}
                  error={Boolean(props.errors?.businessName)}
                />
              </Stack>

              <Stack spacing={1} sx={{ m: 1 }}>
                <LoadingButton
                  variant="contained"
                  size="small"
                  fullWidth
                  loading={isLoading}
                  onClick={() => {
                    props.handleSubmit()
                  }}
                >
                  Search
                </LoadingButton>
              </Stack>
            </form>
          )}
        </Formik>
        {data &&
          (data.length > 0 ? (
            data.map((item, key) =>
              creditRiskMonitorItem(
                item,
                key,
                creditRiskMonitorBusiness,
                setCreditRiskMonitorBusiness,
              ),
            )
          ) : (
            <Alert severity="warning" sx={{ marginTop: 2 }}>
              {searchQuery && searchQuery.businessName ? (
                <>Match Not Found</>
              ) : (
                <>Business Name Search Query Was Empty</>
              )}
            </Alert>
          ))}
        {!data && isLoading && <Alert severity="info">Loading...</Alert>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseSearchDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
