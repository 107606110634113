import { LoadingButton } from "@mui/lab"
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { Formik } from "formik"
import { set } from "js-cookie"
import { enqueueSnackbar } from "notistack"
import { useState } from "react"
import { useSalesReps } from "src/queries/base/useSalesReps"
import { useCreateInternalStore } from "src/queries/internal/useCreateInternalStore"
import { usePostInternalBusiness } from "src/queries/internal/usePostInternalBusiness"
import { Business } from "src/types"
import * as yup from "yup"

export default function AddStoreDialog({
  businessId,
  onClose,
}: {
  businessId: string
  onClose: () => void
}) {
  const [name, setName] = useState("")
  const [salesReps, setSalesReps] = useState<string>("")

  const { execute, isLoading } = useCreateInternalStore()

  return (
    <Dialog open fullWidth maxWidth={"md"}>
      <DialogTitle>Add Store</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography>Enter the Store name</Typography>
          <TextField
            fullWidth
            label="Store Name"
            type="text"
            value={name}
            onChange={(event) => {
              setName(event.target.value)
            }}
          />

          <Typography>
            Enter the comma-separated list of Sales Rep emails below. This field
            should be used if you already have invited the users to the
            business. If you haven't invited the users yet, create the Store
            while leaving this field empty. Navigate to the Users tab and you
            can assign the users you are inviting to this store on that page.
          </Typography>
          <TextField
            label="Sales Reps"
            value={salesReps}
            onChange={(event) => {
              setSalesReps(event.target.value)
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={() => {
            execute(
              {
                business: businessId,
                name,
                salesReps,
              },
              () => {
                enqueueSnackbar("Store created", { variant: "success" })
                onClose()
              },
            )
          }}
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
