import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material"
import { usePDF, Margin } from "react-to-pdf"
import { OpenCorporateGetCompaniesInterface } from "src/types"
import OpenCorporateCompanyData from "./OpenCorporateCompanyData"

export default function OpenCorporateViewDialog({
  open,
  report,
  onClose,
}: {
  open: boolean
  report: OpenCorporateGetCompaniesInterface
  onClose: () => void
}) {
  const { toPDF, targetRef } = usePDF({
    filename:
      report && report?.companyName
        ? report?.companyName + " Report.pdf"
        : "RegistryReport.pdf",
    page: { margin: Margin.MEDIUM },
  })
  return (
    <Dialog maxWidth="md" fullWidth onClose={onClose} open={open}>
      <DialogTitle>Registry Lookup</DialogTitle>
      <DialogContent>
        <Stack ref={targetRef}>
          <OpenCorporateCompanyData
            companyData={report}
            jurisdictionCode={""}
            companyNumber={""}
            perPage={10}
            page={1}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => toPDF()}>
          Download
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
