import { Alert, AlertTitle, Box, Typography } from "@mui/material"
import { CreditApplication } from "src/types"

export default ({
  application,
  label,
}: {
  application: CreditApplication
  label: string
}) => {
  if (
    !application.data.skippedFields ||
    application.data.skippedFields.length === 0 ||
    !application.data.skippedFields.find((f: any) => f.label === label)
  ) {
    return <></>
  }
  return (
    // <Box
    //   style={{
    //     margin: "1rem 1rem 0 1rem",
    //     borderLeft: "solid",
    //     borderWidth: "6px",
    //     borderRadius: "4px",
    //     padding: "16px 12px 16px 12px",
    //     borderColor: "red",
    //     display: "flex",
    //     flexDirection: "column",
    //     backgroundColor: "rgba(255, 0, 0, 0.1)",
    //     flexWrap: "wrap",
    //     gap: "8px",
    //   }}
    // >
    //   <Typography style={{ wordBreak: "break-word" }}>
    //     User has skipped this page with the following reason:
    //   </Typography>
    //   <Typography style={{ wordBreak: "break-word" }}>
    //     {
    //       application.data.skippedFields.find((f: any) => f.label === label)
    //         ?.reason
    //     }
    //   </Typography>
    // </Box>
    <Alert
      severity="warning"
      style={{
        padding: "16px 12px 16px 12px",
        margin: "1rem 1rem 0 1rem",
        width: "95%",
        maxWidth: "none",
      }}
    >
      <AlertTitle>
        Buyer has skipped this section while filing an application with the
        following reason:
      </AlertTitle>
      <Typography style={{ wordBreak: "break-word" }}>
        {
          application.data.skippedFields.find((f: any) => f.label === label)
            ?.reason
        }
      </Typography>
    </Alert>
  )
}
