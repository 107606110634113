import { useTypedMutation } from "../useTypedMutation"
import { QueryClient } from "react-query"

type StoreToCreate = {
  business: string
  name: string
  salesReps: string // comma-separated list of emails
}

export function useCreateInternalStore(onSuccess?: () => void) {
  const post = useTypedMutation<StoreToCreate>(
    "post/internal/stores",
    onSuccess,
  )
  const execute = (body: StoreToCreate, onSuccess: () => void) => {
    post.mutate(
      {
        method: "post",
        endpoint: "/internal/stores/",
        body,
        headers: {
          "Content-Type": "application/json",
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: ["/internal/stores".replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...post, execute }
}
