import { Paper, useTheme } from "@mui/material"
import Chart from "react-apexcharts"
import { format } from "date-fns"
import { CreditSafePaidMonthlyBalanceHistories } from "src/types"

const PaidMonthlyBalanceHistoriesChart = ({
  data,
}: {
  data: CreditSafePaidMonthlyBalanceHistories
}) => {
  const theme = useTheme()
  const formattedData = data.map((item) => ({
    x: format(new Date(item.date), "MMM yyyy"),
    y:
      item.paidCurrent === undefined || Number.isNaN(item.paidCurrent)
        ? 0
        : item.paidCurrent,
  }))

  const options = {
    chart: {
      type: "area" as const,
      height: 350,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth" as const,
      width: 2,
    },
    xaxis: {
      type: "category" as const,
      categories: formattedData.map((item) => item.x),
      labels: {
        rotate: -45,
        rotateAlways: false,
      },
    },
    yaxis: {
      title: {
        text: `Balance (${data[0].currency ?? "USD"})`,
      },
    },
    title: {
      text: "Paid Monthly Balance History",
      align: "left" as const,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
    colors: [
      theme.palette.success.dark,
      theme.palette.grey[900],
      theme.palette.error.main,
      theme.palette.info.main,
      theme.palette.warning.main,
    ],
    tooltip: {
      y: {
        formatter: (value: number) => {
          if (typeof value === "number") {
            return `$${value.toFixed(2)}`
          }
          return value
        },
      },
    },
  }

  const series = [
    {
      name: "Paid Current",
      data: data.map((item) =>
        Number.isNaN(item.paidCurrent) || item.paidCurrent === undefined
          ? 0
          : item.paidCurrent,
      ),
    },
    {
      name: "Paid 1 to 30",
      data: data.map((item) =>
        Number.isNaN(item.paid1To30) || item.paid1To30 === undefined
          ? 0
          : item.paid1To30,
      ),
    },
    {
      name: "Paid 31 to 60",
      data: data.map((item) =>
        Number.isNaN(item.paid31To60) || item.paid31To60 === undefined
          ? 0
          : item.paid31To60,
      ),
    },
    {
      name: "Paid 61 to 90",
      data: data.map((item) =>
        Number.isNaN(item.paid61To90) || item.paid61To90 === undefined
          ? 0
          : item.paid61To90,
      ),
    },
    {
      name: "Paid 91 Plus",
      data: data.map((item) =>
        Number.isNaN(item.paid91Plus) || item.paid91Plus === undefined
          ? 0
          : item.paid91Plus,
      ),
    },
  ]

  return (
    <Paper sx={{ p: 3, mt: 4 }}>
      <Chart options={options} series={series} type="area" height={350} />
    </Paper>
  )
}

export default PaidMonthlyBalanceHistoriesChart
