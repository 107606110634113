import { useCallback } from "react"
import {
  Box,
  CircularProgress,
  Typography,
  Chip,
  Card,
  Grid,
  Grow,
} from "@mui/material"
import ChartColumnStacked from "../components/chart/ChartColumnStacked"
import { useRequestCreditDashboard } from "../queries/credit_dashboard/useRequestCreditDashboard"
import { CreditDashboard } from "src/types/credit_dashboard.types"
import {
  AccessTimeOutlined,
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  CalendarMonthOutlined,
  FolderCopyOutlined,
  PriceCheckOutlined,
} from "@mui/icons-material"

const StatsCard = ({
  title,
  value,
  change,
  CardIcon,
  changeSubtitle,
}: {
  title: string
  value: string | number
  change?: { value: number; type: "increase" | "decrease" }
  CardIcon: React.ElementType
  changeSubtitle?: string
}) => (
  <Grow in={true} style={{ transformOrigin: "0 0 0" }}>
    <Card
      style={{
        padding: "1.5rem",
        transition: "all 0.2s",
      }}
    >
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body2"
          style={{ fontWeight: 500, color: "var(--muted-foreground)" }}
        >
          {title}
        </Typography>
        <Box
          style={{
            padding: "0.5rem",
            borderRadius: "9999px",
            backgroundColor: "rgba(var(--primary), 0.1)",
          }}
        >
          <CardIcon
            style={{ width: "1rem", height: "1rem", color: "var(--primary)" }}
          />
        </Box>
      </Box>
      <Box style={{ marginTop: "0.5rem" }}>
        <Typography variant="h4" style={{ fontWeight: 700 }}>
          {value}
        </Typography>
        <Box style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <Typography
            variant="body2"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.25rem",
              color: !change
                ? "#16a34a00"
                : change?.type === "increase"
                  ? "#16a34a"
                  : "#dc2626",
            }}
          >
            {!change && "-"}
            {change?.type === "increase" && (
              <ArrowUpwardOutlined style={{ width: "1rem", height: "1rem" }} />
            )}
            {change?.type === "decrease" && (
              <ArrowDownwardOutlined
                style={{ width: "1rem", height: "1rem" }}
              />
            )}
            {!!change && `${Math.abs(change.value)}%`}
          </Typography>
          <Typography
            variant="body2"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.25rem",
            }}
          >
            {changeSubtitle}
          </Typography>
        </Box>
      </Box>
    </Card>
  </Grow>
)

export default function CreditDashboardPage() {
  const { data: creditDashboardData, isLoading } = useRequestCreditDashboard()

  const isPageLoading = useCallback(() => {
    if (creditDashboardData) {
      return false
    }

    if (isLoading) {
      return true
    }

    return false
  }, [creditDashboardData, isLoading])

  if (isPageLoading()) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    )
  }

  const {
    creditApplicationsInProcess,
    totalCreditApplicationsApprovedLimit,
    creditApplicationsYearly,
    sixMonthsStatusCounts,
    creditApplicationsMonthly,
    monthlyCreditApplicationsCountPercentDifference,
    creditApplicationsYearlyPercentDifference,
    yearlyTotalCreditApplicationsApprovedLimitPercentDifference,
  } = creditDashboardData as CreditDashboard

  const chartData = Object.entries(sixMonthsStatusCounts || {}).map(
    ([month, counts]: [string, any]) => ({
      month,
      approved: counts?.approved || 0,
      declined: counts?.declined || 0,
      incomplete: counts?.incomplete || 0,
      pending_Info: counts?.pendingInfo || 0,
    }),
  )

  return (
    <Box padding={3}>
      <Typography variant="h4" gutterBottom>
        Dashboard <Chip label="Beta" variant="outlined" />
      </Typography>

      {/* Summary Counts Section */}
      <Grid
        container
        spacing={3}
        style={{ marginBottom: "2rem", marginTop: "1rem" }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title="Applications - Last 30 Days"
            value={creditApplicationsMonthly ?? "--"}
            change={{
              value: monthlyCreditApplicationsCountPercentDifference ?? 0,
              type:
                monthlyCreditApplicationsCountPercentDifference >= 0
                  ? "increase"
                  : "decrease",
            }}
            CardIcon={CalendarMonthOutlined}
            changeSubtitle="vs the 30 days prior"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title="Applications In Process"
            value={creditApplicationsInProcess ?? "--"}
            CardIcon={AccessTimeOutlined}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title="Applications YTD"
            value={creditApplicationsYearly ?? "--"}
            change={{
              value: creditApplicationsYearlyPercentDifference ?? 0,
              type:
                creditApplicationsYearlyPercentDifference >= 0
                  ? "increase"
                  : "decrease",
            }}
            CardIcon={FolderCopyOutlined}
            changeSubtitle="vs same time last year"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title="Credit Approved YTD"
            value={`$${totalCreditApplicationsApprovedLimit?.toLocaleString()}`}
            change={{
              value:
                yearlyTotalCreditApplicationsApprovedLimitPercentDifference ??
                0,
              type:
                yearlyTotalCreditApplicationsApprovedLimitPercentDifference >= 0
                  ? "increase"
                  : "decrease",
            }}
            CardIcon={PriceCheckOutlined}
            changeSubtitle="vs same time last year"
          />
        </Grid>
      </Grid>

      {/* Chart Section */}
      <Card sx={{ p: 3 }}>
        <ChartColumnStacked
          data={chartData}
          title="Credit Applications by Month"
        />
      </Card>
    </Box>
  )
}
