import { useQuery } from "react-query"
import queryString from "query-string"
export interface TimelineResponse {
  timeline: Step[]
  activeStep: number
}

type Step = {
  label: string
  date: string
  completed: boolean
  metadata?: any
}

export function useApplicationTimeline(applicationId?: string) {
  return useQuery<TimelineResponse>(
    queryString.stringifyUrl({
      url: "/application/base/application_timeline",
      query: {
        application_id: applicationId,
      },
    }),
    {
      enabled: !!applicationId,
      retry: (failureCount, error) => {
        if (failureCount < 1) {
          return true
        }
        // do not retry on 404
        return (error as any)?.response?.status !== 404
      },
    },
  )
}
