import { Button, ButtonGroup, Typography } from "@mui/material"
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined"
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined"

type Props = {
  handleBack: (() => void) | undefined
  activeStep: number
  disable: boolean
  steps: string[]
}

export default function MobileNavUpperBlock({
  handleBack,
  activeStep,
  steps,
}: Props) {
  return (
    <>
      <ButtonGroup>
        <Button
          sx={{
            gap: "5px",
            textTransform: "none",
            color: "#344054",
            borderColor: "#344054",
            padding: "0 7px",
          }}
          onClick={handleBack}
          disabled={activeStep === 0 || activeStep === 99 || activeStep === -1}
        >
          <ArrowCircleLeftOutlinedIcon sx={{ width: "16px" }} />
          <Typography component="span" variant="body1" fontSize="12px">
            {activeStep === 0 ? "Prev" : steps[activeStep - 1]}
          </Typography>
        </Button>
        <Button
          sx={{
            gap: "5px",
            textTransform: "none",
            color: "#344054",
            borderColor: "#344054",
            padding: "0 7px",
            ":disabled": { color: "#344054", borderColor: "#D0D5DD" },
          }}
          disabled={true}
        >
          <Typography component="span" variant="body1" fontSize="12px">
            {activeStep < steps.length - 1 ? steps[activeStep + 1] : "Next"}
          </Typography>
          <ArrowCircleRightOutlinedIcon sx={{ width: "16px" }} />
        </Button>
      </ButtonGroup>
    </>
  )
}
