import { useEffect, useState } from "react"
import { Close } from "@mui/icons-material"
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  InputAdornment,
  Typography,
  TextField,
  Button,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  useMediaQuery,
} from "@mui/material"
import {
  CashApplication,
  CashCustomFieldResponse,
  CashCustomFieldResponseDownloaded,
  TemplateCustomField,
  CashUploadedFile,
} from "src/types"
import Label from "src/components/label"
import ReactPhoneInput from "react-phone-input-material-ui"
import { saveAs } from "file-saver"

import { DropzoneDialog } from "mui-file-dropzone"
import { confirm } from "src/components/confirm"
import { useCashApplicationTemplate } from "src/queries/cod/useCashApplicationTemplate"
import { useDeleteCashUploadedFile } from "src/queries/cod/useDeleteCashUploadedFile"
import { useLocation, useParams } from "react-router-dom"
import { info, error } from "src/utils/logger"
import { isEmail } from "src/utils/utils"
import { useValidateEmail } from "src/queries/vendors/useValidateEmail"
import { NumericFormat } from "react-number-format"

import FormLabel from "src/components/label/FormLabel"
import {
  hasConditions,
  hasCustomAddressQuestions,
  hasPassedConditions,
} from "../../utils"
import { FIELD_TYPES } from "src/statics"
import { useCashApplication } from "src/queries/cod/useCashApplication"
import { enqueueSnackbar } from "notistack"
// import CustomAddress from "../../credit/components/CustomAddress"

type Props = {
  application?: CashApplication
  associatedPage: string
  errors: CashApplication["data"] | undefined
  data?: { [key: string]: CashCustomFieldResponse }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataKeyResponses?: { [key: string]: any }
  onChange: (key: string, value: any) => void
  filesInMemory: Map<string, Array<File>>
  setFilesInMemory: (files: Map<string, Array<File>>) => void
  overrideHeight?: boolean
}

type FieldParams = {
  field: TemplateCustomField
  index: number
  required: boolean
}
export const sortByFieldType = (
  a: TemplateCustomField,
  b: TemplateCustomField,
) => {
  //  Attempts to sort by order first, then by fieldType, then by id
  // order is a number, fieldType is a string, id is a string
  //  lower order is higher priority
  //  lower fieldType is higher priority
  //  lower id is higher priority

  if (a.order && b.order && a.order !== b.order) {
    if (a.order > b.order) {
      return +1
    }
    return -1
  }
  if (a.fieldType === b.fieldType) {
    if (a.id && b.id && a.id > b.id) return -1
    return +1
  } else if (a.fieldType < b.fieldType) {
    return +1
  }
  return -1
}

export const getConditionalQuestionsForParent = (
  parentField: TemplateCustomField,
  data: { [key: string]: CashCustomFieldResponse } | undefined,
  dataKeyResponses: { [key: string]: any },
  associatedPage: string,
  customFields: TemplateCustomField[],
) => {
  //Return a list of fields that have conditions that are met by the parentField
  const result: FieldParams[] = []
  const conditionalCustomFields: TemplateCustomField[] = []
  for (const field of customFields) {
    if (
      field.conditions &&
      field.conditions.length > 0 &&
      field.conditions[0].conditionCustomField === parentField.id &&
      hasPassedConditions(field, data, dataKeyResponses, associatedPage)
    ) {
      conditionalCustomFields.push(field)
    }
  }
  conditionalCustomFields.sort(sortByFieldType)

  for (const field of conditionalCustomFields) {
    result.push({
      field: field,
      index: customFields.indexOf(field),
      required: Boolean(field.required),
    } as FieldParams)

    const conditionalQuestions = getConditionalQuestionsForParent(
      field,
      data,
      dataKeyResponses,
      associatedPage,
      customFields,
    ) as FieldParams[]
    for (const conditionalQuestion of conditionalQuestions) {
      result.push(conditionalQuestion) // Recursively get conditional questions
    }
  }
  return result
}

export default ({
  application,
  associatedPage,
  errors,
  data,
  dataKeyResponses = {},
  onChange,
  filesInMemory,
  setFilesInMemory,
  overrideHeight = false,
}: Props) => {
  const [pendingUploadFileFieldName, setPendingUploadFileFieldName] = useState<
    string | null
  >(null)

  const [showError, setShowError] = useState(false)
  const [fieldIdToValidate, setFieldIdToValidate] = useState("")
  const { data: emailValidationResult } = useValidateEmail(
    fieldIdToValidate && data?.[fieldIdToValidate]
      ? (data?.[fieldIdToValidate].response as string)
      : "",
    "CUSTOMQUESTION",
  )

  useEffect(() => {
    if (emailValidationResult?.verdict === "Invalid") {
      setShowError(true)
    }
  }, [emailValidationResult, fieldIdToValidate])
  const params = useParams()
  const { id } = params

  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const businessId = queryParams.get("business_id")
  const { data: template } = useCashApplicationTemplate(
    application?.seller?.id || businessId || undefined,
    true,
    id ? !application?.seller?.id : !businessId,
    id,
    dataKeyResponses,
  )

  const customFields = template?.customFields?.filter(
    (field) => field.associatedPage === associatedPage,
  )

  const { execute: deleteFile } = useDeleteCashUploadedFile(() => {
    refetch()
    enqueueSnackbar("File Deleted")
  })

  const { refetch } = useCashApplication(application?.id)

  const matches = useMediaQuery("(max-width: 1199px)")

  if (!customFields) return <></>

  const handleFileUpload = (field: string, files: Array<File> | undefined) => {
    // close the modal
    setPendingUploadFileFieldName(null)

    if (files) {
      filesInMemory.set(field, files)
      setFilesInMemory(filesInMemory)
    }
  }

  const handleTextChange = (field: string, response: string) => {
    const temp_field = customFields.find((f) => f.id === field)
    if (temp_field) {
      // temp_field.response = response
      if (response) {
        onChange(field, {
          field: temp_field,
          response,
        })
      } else {
        onChange(field, undefined)
      }
    }
  }

  const handleDictChange = (
    field: string,
    key: string,
    value: string | undefined,
  ) => {
    const temp_field = customFields.find((f) => f.id === field)
    if (temp_field) {
      const response = data?.[field]?.responseJson || {}
      if (value) {
        response[key] = value
        onChange(field, {
          field: temp_field,
          responseJson: response,
        })
      } else {
        if (
          temp_field.fieldType === FIELD_TYPES.ADDRESS &&
          key === "address" &&
          !response[key]
        ) {
          onChange(field, {
            field: temp_field,
            responseJson: undefined,
          })
        } else {
          if (response[key]) {
            delete response[key]
          }
          onChange(field, {
            field: temp_field,
            responseJson: response,
          })
        }
      }
    }
  }

  const getConditionalQuestionsForField = (
    parentField: TemplateCustomField,
  ) => {
    //Return a list of fields that have conditions that are met by the parentField
    const result = []
    for (const fieldParams of getConditionalQuestionsForParent(
      parentField,
      data,
      dataKeyResponses,
      associatedPage,
      customFields,
    )) {
      result.push(
        getField(fieldParams.field, fieldParams.index, fieldParams.required),
      )
    }

    return result
  }

  const getField = (
    field: TemplateCustomField,
    index: number,
    required = false,
  ) => {
    if (!!field.id) {
      if (field.fieldType === FIELD_TYPES.TEXT) {
        return (
          <Box key={index}>
            <FormControl fullWidth required={required}>
              <Box
                sx={
                  !matches
                    ? {
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5%",
                        alignItems: "center",
                      }
                    : {}
                }
              >
                <FormLabel
                  labelName={field.text}
                  required={required}
                  showInDesktop={true}
                  idFor={"custom-question-" + index}
                />
                <TextField
                  // margin={matches ? "none" : "normal"}
                  id={"custom-question-" + index}
                  fullWidth
                  type="text"
                  placeholder="Enter an Answer"
                  value={data?.[field.id] ? data?.[field.id].response : ""}
                  onChange={(event) => {
                    if (field.id) {
                      handleTextChange(field.id, event.target.value)
                    }
                  }}
                  inputProps={
                    overrideHeight ||
                    hasCustomAddressQuestions(
                      customFields,
                      associatedPage,
                      dataKeyResponses,
                    )
                      ? {
                          style: { height: "56px" },
                        }
                      : { style: { maxHeight: "56px", height: "100%" } }
                  }
                  error={Boolean(
                    errors?.customFields
                      ? errors.customFields[field.id || ""]
                      : false,
                  )}
                />
              </Box>
            </FormControl>
          </Box>
        )
      } else if (field.fieldType === FIELD_TYPES.EMAIL) {
        return (
          <Box key={index}>
            <FormControl fullWidth required={required}>
              <Box
                sx={
                  !matches
                    ? {
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5%",
                        alignItems: "center",
                      }
                    : {}
                }
              >
                <FormLabel
                  labelName={field.text}
                  required={required}
                  showInDesktop={true}
                  idFor={"custom-question-" + index}
                />
                <TextField
                  id={"custom-question-" + index}
                  // margin="normal"
                  fullWidth
                  type="email"
                  value={data?.[field.id] ? data?.[field.id].response : ""}
                  onChange={(event) => {
                    if (field.id) {
                      handleTextChange(field.id, event.target.value)
                    }
                  }}
                  onBlur={(event) => {
                    setFieldIdToValidate(field.id as string)
                  }}
                  error={Boolean(
                    errors?.customFields
                      ? errors.customFields[field.id || ""]
                      : data?.[field.id]
                        ? !isEmail(data?.[field.id].response as string)
                        : false,
                  )}
                  inputProps={
                    overrideHeight ||
                    hasCustomAddressQuestions(
                      customFields,
                      associatedPage,
                      dataKeyResponses,
                    )
                      ? {
                          style: { height: "56px" },
                        }
                      : {}
                  }
                />
              </Box>
            </FormControl>
          </Box>
        )
      } else if (field.fieldType === FIELD_TYPES.DATE) {
        return (
          <Box key={index}>
            <FormControl fullWidth required={required}>
              <Box
                sx={
                  !matches
                    ? {
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5%",
                        alignItems: "center",
                      }
                    : {}
                }
              >
                <FormLabel
                  labelName={field.text}
                  required={required}
                  showInDesktop={true}
                  idFor={"custom-question-" + index}
                />
                <TextField
                  id={"custom-question-" + index}
                  // margin="normal"
                  fullWidth
                  type="date"
                  value={data?.[field.id] ? data?.[field.id].response : ""}
                  onChange={(event) => {
                    if (field.id) {
                      handleTextChange(field.id, event.target.value)
                    }
                  }}
                  inputProps={
                    overrideHeight ||
                    hasCustomAddressQuestions(
                      customFields,
                      associatedPage,
                      dataKeyResponses,
                    )
                      ? {
                          style: { height: "56px" },
                        }
                      : {}
                  }
                  error={Boolean(
                    errors?.customFields
                      ? errors.customFields[field.id || ""]
                      : false,
                  )}
                />
              </Box>
            </FormControl>
          </Box>
        )
      } else if (field.fieldType === FIELD_TYPES.TIME) {
        return (
          <Box key={index}>
            <FormControl fullWidth required={required}>
              <Box
                sx={
                  !matches
                    ? {
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5%",
                        alignItems: "center",
                      }
                    : {}
                }
              >
                <FormLabel
                  labelName={field.text}
                  required={required}
                  showInDesktop={true}
                  idFor={"custom-question-" + index}
                />
                <TextField
                  // margin="normal"
                  id={"custom-question-" + index}
                  fullWidth
                  type="time"
                  value={data?.[field.id] ? data?.[field.id].response : ""}
                  onChange={(event) => {
                    if (field.id) {
                      handleTextChange(field.id, event.target.value)
                    }
                  }}
                  inputProps={
                    overrideHeight ||
                    hasCustomAddressQuestions(
                      customFields,
                      associatedPage,
                      dataKeyResponses,
                    )
                      ? {
                          style: { height: "56px" },
                        }
                      : {}
                  }
                  error={Boolean(
                    errors?.customFields
                      ? errors.customFields[field.id || ""]
                      : false,
                  )}
                />
              </Box>
            </FormControl>
          </Box>
        )
      } else if (field.fieldType === FIELD_TYPES.PHONE) {
        return (
          <Box key={index}>
            <FormControl fullWidth required={required}>
              <Box
                sx={
                  !matches
                    ? {
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5%",
                        alignItems: "center",
                      }
                    : {}
                }
              >
                <FormLabel
                  labelName={field.text}
                  required={required}
                  showInDesktop={true}
                />
                <ReactPhoneInput
                  value={data?.[field.id] ? data?.[field.id].response : "+1"}
                  country={"us"}
                  onChange={(event) => {
                    if (field.id) {
                      if (event !== "1") {
                        handleTextChange(field.id, "+" + event)
                      } else {
                        handleTextChange(field.id, "")
                      }
                    }
                  }}
                  countryCodeEditable={false}
                  component={TextField}
                  label=""
                  containerStyle={{
                    width: "100%",
                    // marginTop: "8px",
                    height: "56px",
                  }}
                  inputProps={{
                    id: "phone-number-" + index,
                    error: Boolean(
                      errors?.customFields
                        ? errors.customFields[field.id || ""]
                        : false,
                    ),
                  }}
                />
              </Box>
            </FormControl>
          </Box>
        )
      } else if (field.fieldType === FIELD_TYPES.AMOUNT) {
        return (
          <Box key={index}>
            <FormControl fullWidth required={required}>
              <Box
                sx={
                  !matches
                    ? {
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5%",
                        alignItems: "center",
                      }
                    : {}
                }
              >
                <FormLabel
                  labelName={field.text}
                  required={required}
                  showInDesktop={true}
                  idFor={"custom-question-" + index}
                />
                <NumericFormat
                  fullWidth
                  customInput={TextField}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    error: Boolean(
                      errors?.customFields
                        ? errors.customFields[field.id || ""]
                        : false,
                    ),
                  }}
                  value={data?.[field.id] ? data?.[field.id].response : ""}
                  onChange={(event) => {
                    if (field.id) {
                      handleTextChange(field.id, event.target.value)
                    }
                  }}
                  inputProps={{
                    style: { maxHeight: "56px", height: "100%" },
                  }}
                  allowLeadingZeros
                  thousandSeparator=","
                />
              </Box>
            </FormControl>
          </Box>
        )
      } else if (field.fieldType === FIELD_TYPES.FILE) {
        const inMemoryFiles = filesInMemory.get(field.id) || []
        const uploadedFiles = application?.customFieldFiles?.filter(
          (response: CashCustomFieldResponseDownloaded) =>
            response.field === field.id && response.file,
        )

        return (
          <Box key={index}>
            <FormControl fullWidth required={required}>
              <Box
                sx={
                  !matches
                    ? {
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5%",
                        alignItems: "center",
                      }
                    : {}
                }
              >
                <Stack style={{ width: "100%" }}>
                  <FormLabel
                    labelName={field.text}
                    required={required}
                    showInDesktop={true}
                  />
                  {field.templateFile && (
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "6px",
                        fontSize: "10pt",
                      }}
                    >
                      <a
                        // for some weird reason on some browsers, the download/href doesn't work
                        // and forces the tab to redirect, so we're using this instead
                        onClick={() => {
                          saveAs(
                            field.templateFile as string,
                            (field.templateFile as string).split("/").pop(),
                          )
                        }}
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          color: "blue",
                        }}
                      >
                        [download our fillable template]
                      </a>
                      or use your own
                    </Box>
                  )}
                </Stack>
                <Stack
                  style={{
                    alignContent: "right",
                  }}
                >
                  {inMemoryFiles.map((f, index) => (
                    <Box
                      style={{
                        display: "flex",
                        gap: "16px",
                        alignItems: "center",
                        justifyContent: "right",
                      }}
                      key={"inMemoryFile" + index}
                    >
                      <Label
                        color="warning"
                        key={`customFile-${field.id}-${index}`}
                        variant="filled"
                      >
                        {inMemoryFiles[index].name}
                      </Label>

                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          confirm(
                            "You are about to remove this file from your application.",
                          )
                            .then(
                              () => {
                                if (field.id) {
                                  filesInMemory.set(
                                    field.id,
                                    filesInMemory
                                      ?.get(field.id)
                                      ?.filter((_, i) => i !== index) || [],
                                  )
                                  return setFilesInMemory(filesInMemory)
                                }
                                return
                              },
                              () => {
                                info("cancelled")
                              },
                            )
                            .catch(() => {
                              error("cancelled")
                            })
                        }}
                      >
                        <Close />
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          window.open(window.URL.createObjectURL(f))
                        }}
                      >
                        View
                      </Button>
                    </Box>
                  ))}

                  {uploadedFiles?.map((f, index) => (
                    <Box
                      style={{
                        display: "flex",
                        gap: "16px",
                        alignItems: "top",
                        justifyContent: "right",
                      }}
                      key={"uploadedFile-" + index}
                    >
                      <Label
                        color="warning"
                        key={`customFile-${field.id}-${index}`}
                        variant="filled"
                      >
                        {uploadedFiles[index]?.file?.name}
                      </Label>

                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          confirm(
                            "You are about to remove this file from your application.",
                          )
                            .then(
                              () => {
                                if (field.id) {
                                  deleteFile(
                                    (
                                      uploadedFiles[index]
                                        ?.file as CashUploadedFile
                                    )?.id,
                                  )
                                }
                                return
                              },
                              () => {
                                info("cancelled")
                              },
                            )
                            .catch(() => {
                              error("cancelled")
                            })
                        }}
                      >
                        <Close />
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          window.open((f.file as CashUploadedFile)?.file)
                        }}
                        style={{
                          width: "64px",
                        }}
                      >
                        View
                      </Button>
                    </Box>
                  ))}
                </Stack>

                <Button
                  variant="contained"
                  onClick={() => {
                    if (field.id) setPendingUploadFileFieldName(field.id)
                  }}
                  size="large"
                  style={{ width: "40%" }}
                  color={
                    errors?.customFields && errors.customFields[field.id || ""]
                      ? "error"
                      : "primary"
                  }
                  sx={{
                    "&:active": {
                      backgroundColor: "#34405455",
                    },
                    "&:hover": {
                      backgroundColor: "#34405455",
                    },
                  }}
                >
                  Upload
                </Button>
              </Box>
            </FormControl>
          </Box>
        )
      } else if (field.fieldType === FIELD_TYPES.DROPDOWN) {
        return (
          <Box key={index}>
            <FormControl required={!!required} sx={{ width: "100%" }}>
              <Box
                sx={
                  !matches
                    ? {
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5%",
                        alignItems: "center",
                      }
                    : {}
                }
              >
                <FormLabel
                  labelName={field.text}
                  required={required}
                  showInDesktop={true}
                  idFor={`${index}-select`}
                />
                <FormControl required={!!required} sx={{ width: "100%" }}>
                  <InputLabel id={`${index}-label`}>Response</InputLabel>
                  <Select
                    labelId={`${index}-label`}
                    id={`${index}-select`}
                    value={data?.[field.id] ? data?.[field.id].response : ""}
                    defaultValue={
                      data?.[field.id] ? data?.[field.id].response : ""
                    }
                    onChange={(event) => {
                      if (field.id) {
                        handleTextChange(field.id, event.target.value || "")
                      }
                    }}
                    label="Response"
                    error={Boolean(
                      errors?.customFields
                        ? errors.customFields[field.id || ""]
                        : false,
                    )}
                    renderValue={(selected) => {
                      if (selected === undefined || selected === "") {
                        if (matches) {
                          return (
                            <Typography sx={{ color: "text.disabled" }}>
                              Select
                            </Typography>
                          )
                        }
                      }

                      return selected
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                    style={{
                      height: "56px",
                      width: "100%",
                    }}
                  >
                    {field.options &&
                      field.options?.split(",").map((option, optionIndex) => (
                        <MenuItem
                          value={option}
                          key={index + "-" + optionIndex}
                        >
                          {option}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </FormControl>
          </Box>
        )
      } else if (field.fieldType === FIELD_TYPES.MULTISELECT) {
        const getValue = () => {
          if (
            field.id &&
            data &&
            data[field.id] &&
            data[field.id].response &&
            data[field.id].response !== ""
          ) {
            return data[field.id].response?.split(",")
          }
          return []
        }
        return (
          <Box key={index}>
            <FormControl required={!!required} sx={{ width: "100%" }}>
              <Box
                sx={
                  !matches
                    ? {
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5%",
                        alignItems: "center",
                      }
                    : {}
                }
              >
                <FormLabel
                  labelName={field.text}
                  required={required}
                  showInDesktop={true}
                  idFor={`${index}-search`}
                />

                <Autocomplete
                  fullWidth
                  id={`${index}-search`}
                  multiple
                  options={field.options?.split(",") || []}
                  // defaultValue={[]}
                  value={getValue()}
                  onChange={(_, value) => {
                    if (field.id) {
                      handleTextChange(
                        field.id,
                        value.length > 0 ? value.toString() : "",
                      )
                    }
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        variant="standard"
                        {...params}
                        placeholder="Response"
                        error={Boolean(
                          errors?.customFields
                            ? errors.customFields[field.id || ""]
                            : false,
                        )}
                        sx={{ paddingTop: "16px" }}
                        // inputProps={{
                        //   style: { margin: "16px" },
                        // }}
                      />
                    )
                  }}
                />
              </Box>
            </FormControl>
          </Box>
        )
      } else if (field.fieldType === FIELD_TYPES.ADDRESS) {
        // if (field.id && data && data[field.id]) {
        //   console.log(data[field.id])
        // }
        const getValue = () => {
          if (
            field.id &&
            data &&
            data[field.id] &&
            data[field.id].responseJson
          ) {
            return data[field.id].responseJson
          }
          return {}
        }
        return (
          <Box key={index}>
            <Typography variant="subtitle1">
              {field.text} {required ? " *" : ""}
            </Typography>
            {/* <CustomAddress
              keyPrefix={field.text}
              data={getValue() as { [key: string]: string }}
              onChange={(_, value) => {
                if (field.id) {
                  handleDictChange(field.id, _, value)
                }
              }}
              required={required}
              errors={
                errors?.customFields
                  ? errors.customFields[field.id as string]?.responseJson
                  : {}
              }
            /> */}
          </Box>
        )
      }
    }
  }

  const triggerRerender = () => {
    setShowError(false)
    handleTextChange(fieldIdToValidate, "")
    setFieldIdToValidate((prev) => (prev === "" ? "dummy-value" : "")) // state update forces rerender
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "8px",
          margin: "16px 0 16px 0",
          alignItems: "left",
        }}
      >
        {/* Displays Required and Optional Data Key Based Conditional Questions */}
        {customFields
          ?.filter(
            (field) =>
              hasConditions(field) &&
              field.conditions[0].dataKey &&
              hasPassedConditions(
                field,
                data,
                dataKeyResponses,
                associatedPage,
              ),
          )
          ?.sort(sortByFieldType)
          .map((field: TemplateCustomField, index) => {
            return (
              <>
                {getField(field, index, Boolean(field.required))}
                {getConditionalQuestionsForField(field)}
              </>
            )
          })}
        {/* Displays Required Custom Questions Followed by their Corresponding Conditional Question 
        (Displayed after they fufill the condition)*/}
        {customFields
          ?.filter((field) => field.required && !hasConditions(field))
          ?.sort(sortByFieldType)
          .map((field: TemplateCustomField, index) => {
            return (
              <>
                {getField(field, index, true)}
                {getConditionalQuestionsForField(field)}
              </>
            )
          })}

        {customFields?.filter(
          (field) => !field.required && !hasConditions(field),
        )?.length > 0 && (
          <Typography
            component="h1"
            variant="subtitle1"
            style={{ marginTop: "8px" }}
          >
            Please answer the following if they apply to you. The more
            information you can provide, the faster we can process your request
            for credit.
          </Typography>
        )}
        {/* Displays Optional Custom Questions Followed by their Corresponding Conditional Question 
        (Displayed after they fufill the condition)*/}
        {customFields
          ?.filter((field) => !field.required && !hasConditions(field))
          ?.sort(sortByFieldType)
          .map((field: TemplateCustomField, index) => {
            return (
              <>
                {getField(field, index, Boolean(field.required))}
                {getConditionalQuestionsForField(field)}
              </>
            )
          })}
      </Box>

      {showError && (
        <Dialog open={showError}>
          <DialogTitle>Did you enter the right email?</DialogTitle>
          <DialogContent>
            <Typography>
              The email <b>{emailValidationResult?.email}</b> seems to not be
              correct. Please double check and make sure the address is valid to
              avoid delays in processing your application.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={triggerRerender}>OK</Button>
          </DialogActions>
        </Dialog>
      )}

      {pendingUploadFileFieldName && (
        <DropzoneDialog
          open={!!pendingUploadFileFieldName}
          onSave={(newFiles: File[]) => {
            handleFileUpload(pendingUploadFileFieldName, newFiles)
          }}
          acceptedFiles={[
            "image/jpeg",
            "image/png",
            "image/bmp",
            "application/pdf",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/doc",
            "application/ms-doc",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/rtf",
            "text/plain",
            "text/csv",
          ]}
          showPreviews={true}
          showFileNamesInPreview={true}
          getPreviewIcon={() => <></>}
          maxFileSize={32000000}
          onClose={() => {
            setPendingUploadFileFieldName(null)
          }}
          filesLimit={5}
          fileObjects={filesInMemory.get(pendingUploadFileFieldName) || []}
          // dropzoneText={field.text}
        />
      )}
    </>
  )
}
