import { LoadingButton } from "@mui/lab"
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material"
import { Stack } from "@mui/system"
import { usePatchApplication } from "src/queries/credit/usePatchApplication"
import { ApplicationTemplate, CreditApplication } from "src/types"
import { useState } from "react"
import { useSnackbar } from "notistack"
import { CUSTOM_WORD_MAPPINGS, customWordMapping } from "src/statics"

export default ({
  application,
  onClose,
  template,
}: {
  template?: ApplicationTemplate
  application: CreditApplication
  onClose: () => void
}) => {
  const [number, setNumber] = useState(application.data.customerId)

  const { enqueueSnackbar } = useSnackbar()

  const { isLoading, execute: patchApplication } = usePatchApplication()

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth={"sm"}>
      <DialogTitle>
        Edit {customWordMapping(CUSTOM_WORD_MAPPINGS.CUSTOMER_NUMBER, template)}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <TextField
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            label={customWordMapping(
              CUSTOM_WORD_MAPPINGS.CUSTOMER_NUMBER,
              template,
            )}
          />
        </Stack>
      </DialogContent>
      <Divider style={{}} />
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={() => {
            patchApplication(
              application.id as string,
              { data: { customerId: number } },
              () => {
                enqueueSnackbar("Application updated", {
                  variant: "success",
                })
                onClose()
              },
            )
          }}
          loading={isLoading}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
