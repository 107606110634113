import ReactMarkdown from "react-markdown"
import { Button, CircularProgress, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useFinancialAnalysis } from "src/queries/vendors/useFinancialAnalysis"
import { Margin, Resolution, usePDF } from "react-to-pdf"

export default function FinancialAnalysisResponse({
  cache_key,
  fileName,
}: {
  cache_key: string
  fileName: string
}) {
  const { data: response } = useFinancialAnalysis(cache_key)
  const [text, setText] = useState("")

  const pdfName = fileName
    ? fileName.split(".").length == 2
      ? fileName.split(".")[0] + "Analysis.pdf"
      : "Financial Statement Analysis.pdf"
    : "Financial Statement Analysis.pdf"
  const { toPDF, targetRef } = usePDF({
    filename: pdfName,
    resolution: Resolution.HIGH,
    page: {
      margin: Margin.MEDIUM,
    },
  })

  useEffect(() => {
    let timeoutId: NodeJS.Timeout
    if (response?.response) {
      timeoutId = setTimeout(() => {
        setText(
          text + response?.response.substring(text.length, text.length + 10),
        )
      }, 16)
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [response, text])
  return (
    <>
      <Stack
        direction="row"
        sx={{ marginBottom: 2 }}
        spacing={2}
        justifyContent="space-between"
      >
        <Typography variant="h6">Financial Summary</Typography>
        <Button
          variant="contained"
          disabled={response?.response ? false : true}
          onClick={() => {
            toPDF()
          }}
        >
          Download
        </Button>
      </Stack>
      <Stack>
        {!response?.response && (
          <Stack
            direction="column"
            spacing={2}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              pt: 20,
            }}
          >
            <CircularProgress />
          </Stack>
        )}

        {!!response?.response && (
          <Stack ref={targetRef}>
            <Stack>
              <ReactMarkdown>{text}</ReactMarkdown>
            </Stack>
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ mt: 1, mb: 1 }}
            >
              <Typography variant="caption" alignItems={"center"}>
                This analysis was performed by AI and may not be 100% accurate.
                Please check important info
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
    </>
  )
}
