import { useEffect, useState } from "react"
import { ArrowDownwardOutlined, ContactSupportOutlined } from "@mui/icons-material"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useTheme,
} from "@mui/material"
import { createStyles, makeStyles } from "@mui/styles"
import { Icon } from "@iconify/react"
import { GMAPS_LOADERS } from "../utils"

const useStyles = makeStyles(() =>
  createStyles({
    MuiAccordionroot: {
      "&:before": {
        display: "none",
      },
    },
  }),
)

export default ({
  idPrefix,
  addressData,
}: {
  idPrefix: string
  addressData: { [key: string]: string }
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const [accordionOpen, setAccordionOpen] = useState(false)
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [streetView, setStreetView] =
    useState<google.maps.StreetViewPanorama | null>(null)
  const map_id = idPrefix + "map"
  const pano_id = idPrefix + "pano"

  useEffect(() => {
    if (map && streetView) {
      map.setStreetView(streetView)
    }
  }, [map, streetView])

  useEffect(() => {
    async function setStreetViewProps() {
      const address =
        addressData?.address +
        "+" +
        addressData?.city +
        "+" +
        addressData?.region +
        "+" +
        addressData?.country +
        "+" +
        addressData?.postCode
      const res = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GMAPS_KEY}`,
      )
      const data = await res.json()
      const location = data.results[0]?.geometry?.location
      // Promise
      GMAPS_LOADERS.importLibrary("maps")
        .then((lib) => {
          if (
            document.getElementById(map_id) &&
            document.getElementById(pano_id)
          ) {
            setMap(
              new lib.Map(document.getElementById(map_id) as HTMLElement, {
                center: location,
                zoom: 14,
              }),
            )
            return
          }
          return
        })
        .then(() => {
          return GMAPS_LOADERS.importLibrary("streetView")
        })
        .then((lib) => {
          if (document.getElementById(pano_id)) {
            setStreetView(
              new lib.StreetViewPanorama(
                document.getElementById(pano_id) as HTMLElement,
                {
                  position: location,
                  pov: {
                    heading: 34,
                    pitch: 10,
                  },
                },
              ),
            )
          }
          return
        })
        .catch((e) => {
          console.log(e)
        })
    }
    if (accordionOpen) {
      setStreetViewProps()
    }
  }, [accordionOpen, addressData, map_id, pano_id])

  return (
    <>
      <Accordion
        style={{
          border: "solid",
          borderWidth: "1px",
          borderColor: theme.palette.primary.main,
          borderRadius: "16px",
          margin: "1.5rem 1.5rem 1.5rem 1.5rem",
        }}
        classes={{
          root: classes.MuiAccordionroot,
        }}
        TransitionProps={{ unmountOnExit: true }}
        expanded={accordionOpen}
        onChange={() => setAccordionOpen(!accordionOpen)}
      >
        <AccordionSummary expandIcon={<ArrowDownwardOutlined />}>
          <Icon
            icon="teenyicons:google-streetview-solid"
            width="24"
            height="24"
            style={{
              marginRight: "8px",
            }}
          />
          <Typography>See on Google StreetView</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              id={map_id}
              style={{
                width: "100%",
                height: "200px",
              }}
            />
            <div
              id={pano_id}
              style={{
                width: "100%",
                height: "200px",
              }}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  )
}
