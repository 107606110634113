import { AddressAutofill } from "@mapbox/search-js-react"

import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  Typography,
} from "@mui/material"
import { useEffect } from "react"
import { Application } from "src/types"

export const STATES = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Federated States of Micronesia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Marshall Islands",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palau",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Island",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
]

export const getStates = () => {
  return STATES.map((state) => (
    <MenuItem value={state} key={state}>
      {state}
    </MenuItem>
  ))
}

export const PROVINCES = [
  "Ontario",
  "Quebec",
  "British Columbia",
  "Alberta",
  "Manitoba",
  "Saskatchewan",
  "Nova Scotia",
  "New Brunswick",
  "Newfoundland and Labrador",
  "Prince Edward Island",
  "Northwest Territories",
  "Nunavut",
  "Yukon",
]

export const getProvinces = () => {
  return PROVINCES.map((province) => (
    <MenuItem value={province} key={province}>
      {province}
    </MenuItem>
  ))
}

export const UNITED_STATES = "United States"
export const CANADA = "Canada"

export const COUNTRIES = [UNITED_STATES, CANADA]

export const getCountries = () => {
  return COUNTRIES.map((country) => (
    <MenuItem value={country} key={country}>
      {country}
    </MenuItem>
  ))
}

// for mapbox
export const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1Ijoic29yb3VzaG5ldG5vdyIsImEiOiJjbGVzeGFkbDgwMGFvM3FsNDNnZjM4dWUzIn0.w391C51Mlz8fxYoDeNMmLQ"

export default ({
  keyPrefix,
  keySuffix = "",
  data,
  onChange,
  errors,
  required = true,
  defaults = {},
}: {
  keyPrefix: string
  keySuffix?: string
  data: Application["data"]
  errors: Application["data"] | undefined
  onChange: (key: string, value: string | undefined) => void
  required?: boolean
  defaults?: {
    country?: string
  }
}) => {
  useEffect(() => {
    if (
      !data[keyPrefix + "Address" + keySuffix] &&
      !data[keyPrefix + "Country" + keySuffix] &&
      !data[keyPrefix + "Region" + keySuffix]
    ) {
      onChange(
        keyPrefix + "Country" + keySuffix,
        defaults.country || UNITED_STATES,
      )
    }
  }, [data, keyPrefix, keySuffix, onChange, defaults.country])

  const matches = useMediaQuery("(max-width: 899px)")
  return (
    <>
      <Box
        style={{
          width: "100%",
          display: "flex",
          flexDirection: matches ? "column" : "row",
          gap: !matches ? "16px" : "5%",
        }}
      >
        <div style={{ width: "100%" }}>
          <AddressAutofill
            options={{
              language: "en",
            }}
            accessToken={MAPBOX_ACCESS_TOKEN}
            onRetrieve={(res) => {
              onChange(
                keyPrefix + "Address" + keySuffix,
                res.features[0].properties.feature_name.trim(),
              )
              onChange(
                keyPrefix + "City" + keySuffix,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                res.features[0].properties.place.trim(),
              )
              onChange(
                keyPrefix + "Region" + keySuffix,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                res.features[0].properties.region.trim(),
              )
              onChange(
                keyPrefix + "PostCode" + keySuffix,
                res.features[0].properties.postcode,
              )
              onChange(
                keyPrefix + "Country" + keySuffix,
                res.features[0].properties.country?.trim(),
              )
            }}
            onChange={(val) => {
              if (data[keyPrefix + "Address" + keySuffix] !== val) {
                onChange(keyPrefix + "Address" + keySuffix, val)
              }
            }}
          >
            <TextField
              id={keyPrefix + "address-line1" + keySuffix}
              autoComplete="shipping address-line1"
              required={required}
              margin={matches ? "none" : "normal"}
              fullWidth
              name="address"
              placeholder={matches ? "Address" : ""}
              label={matches ? "" : "Address"}
              type="text"
              defaultValue={data[keyPrefix + "Address" + keySuffix]}
              value={data[keyPrefix + "Address" + keySuffix]}
              inputProps={{
                style: { height: "56px" },
              }}
              error={
                errors
                  ? Boolean(errors[keyPrefix + "Address" + keySuffix])
                  : false
              }
            />
          </AddressAutofill>
        </div>
        <TextField
          id={keyPrefix + "address-line2" + keySuffix}
          autoComplete="shipping address-line2"
          margin="normal"
          fullWidth
          name="address2"
          placeholder={matches ? "Address Line 2" : ""}
          label={matches ? "" : "Address Line 2"}
          type="text"
          value={data[keyPrefix + "AddressLine2" + keySuffix]}
          inputProps={{
            style: { height: "56px" },
          }}
          onChange={(event) => {
            onChange(keyPrefix + "AddressLine2" + keySuffix, event.target.value)
          }}
        />
      </Box>
      {data[keyPrefix + "Address" + keySuffix] && (
        <Box
          sx={{
            display: "flex",
            flexDirection: matches ? "column" : "row",
            width: "100%",
            gap: matches ? "0" : "16px",
            alignItems: "center",
          }}
        >
          <TextField
            id={keyPrefix + "city" + keySuffix}
            margin="normal"
            fullWidth
            required={required}
            placeholder={matches ? "City" : ""}
            label={matches ? "" : "City"}
            type="text"
            value={data[keyPrefix + "City" + keySuffix]}
            inputProps={{
              style: { height: "56px" },
            }}
            onChange={(event) => {
              onChange(keyPrefix + "City" + keySuffix, event.target.value)
            }}
            error={
              errors ? Boolean(errors[keyPrefix + "City" + keySuffix]) : false
            }
          />
          <FormControl fullWidth margin="normal" required={required}>
            {!matches && (
              <InputLabel id="region-label">
                {data[keyPrefix + "Country" + keySuffix] === UNITED_STATES
                  ? "State"
                  : "Province"}
              </InputLabel>
            )}
            <Select
              labelId="region-label"
              id={keyPrefix + "region-select" + keySuffix}
              value={data[keyPrefix + "Region" + keySuffix] || ""}
              defaultValue={data[keyPrefix + "Region" + keySuffix]}
              onChange={(event) => {
                onChange(keyPrefix + "Region" + keySuffix, event.target.value)
              }}
              label={
                matches
                  ? ""
                  : data[keyPrefix + "Country" + keySuffix] === UNITED_STATES
                    ? "State"
                    : "Province"
              }
              style={{ height: "56px" }}
              error={
                errors
                  ? Boolean(errors[keyPrefix + "Region" + keySuffix])
                  : false
              }
              displayEmpty
              renderValue={(selected) => {
                if (selected === undefined || selected === "") {
                  if (matches) {
                    return (
                      <Typography sx={{ color: "text.disabled" }}>
                        {data[keyPrefix + "Country" + keySuffix] ===
                        UNITED_STATES
                          ? "State"
                          : "Province"}
                      </Typography>
                    )
                  }
                }

                return selected
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              {data[keyPrefix + "Country" + keySuffix] === UNITED_STATES &&
                getStates()}
              {data[keyPrefix + "Country" + keySuffix] === CANADA &&
                getProvinces()}
            </Select>
          </FormControl>
          <TextField
            id={keyPrefix + "postCode" + keySuffix}
            margin="normal"
            required={required}
            fullWidth
            placeholder={matches ? "Postal/Zip Code" : ""}
            label={matches ? "" : "Postal/Zip Code"}
            type="text"
            value={data[keyPrefix + "PostCode" + keySuffix]}
            inputProps={{
              style: { height: "56px" },
            }}
            onChange={(event) => {
              onChange(keyPrefix + "PostCode" + keySuffix, event.target.value)
            }}
            error={
              errors
                ? Boolean(errors[keyPrefix + "PostCode" + keySuffix])
                : false
            }
          />
          <FormControl fullWidth margin="normal" required={required}>
            {!matches && <InputLabel id="country-label">Country</InputLabel>}
            <Select
              labelId="country-label"
              id={keyPrefix + "country-select" + keySuffix}
              value={data[keyPrefix + "Country" + keySuffix] || ""}
              defaultValue={data[keyPrefix + "Country" + keySuffix]}
              onChange={(event) => {
                onChange(keyPrefix + "Country" + keySuffix, event.target.value)
                onChange(keyPrefix + "Region" + keySuffix, undefined)
              }}
              label={matches ? "" : "Country"}
              style={{ height: "56px" }}
              error={
                errors
                  ? Boolean(errors[keyPrefix + "Country" + keySuffix])
                  : false
              }
              displayEmpty
              renderValue={(selected) => {
                if (selected === undefined || selected === "") {
                  if (matches) {
                    return (
                      <Typography sx={{ color: "text.disabled" }}>
                        Country
                      </Typography>
                    )
                  }
                }

                return selected
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              {getCountries()}
            </Select>
          </FormControl>
        </Box>
      )}
    </>
  )
}
