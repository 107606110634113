import React, { useState } from "react"
import { DrawOutlined } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"

import {
  Box,
  Typography,
  TextField,
  FormControlLabel,
  useMediaQuery,
  Button,
  Alert,
  Checkbox,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grid,
  Paper,
  useTheme,
} from "@mui/material"

import SignaturePad from "react-signature-pad-wrapper"

import { usePostAnalytics } from "src/queries/analytics/usePostAnalytics"

import { useParams } from "react-router-dom"
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react"
import { DynamicThemeProvider } from "src/theme"
import { loadLogoIntoImg } from "src/utils/imageTools"
import { useAnonymousBusiness } from "src/queries/credit/useAnonymousBusiness"
import {
  CreditSignatureRequest,
  useCreditSignatureRequest,
} from "src/queries/credit/signing_v3/useCreditSignatureRequest"
import { useSignCreditTermsStandalone } from "src/queries/credit/signing_v3/useSignCreditTermsStandalone"

export default () => {
  const [signerFirstName, setSignerFirstName] = useState("")
  const [signerLastName, setSignerLastName] = useState("")
  const [signerRole, setSignerRole] = useState("")
  const [complete, setComplete] = useState(false)
  const [electronicTermsAccepted, setElectronicTermsAccepted] = useState(false)
  const [electronicTermsDialogOpen, setElectronicTermsOpen] = useState(false)
  const [data, setData] = useState<CreditSignatureRequest | null>(null)

  const [verificationCode, setVerificationCode] = useState<string | undefined>(
    undefined,
  )

  const { data: visitorData } = useVisitorData(
    { extendedResult: true },
    { immediate: true },
  )

  const { execute: saveAnalytics } = usePostAnalytics()

  const params = useParams()

  const { requestId } = params

  const { verifyCode, isLoading } = useCreditSignatureRequest()

  const theme = useTheme()

  const matches = useMediaQuery("(max-width: 899px)")
  const matchesXL = useMediaQuery("(max-width: 1199px)")

  const ref = React.useRef<SignaturePad | null>(null)

  const onClearSignature = () => {
    ref.current?.clear()
  }

  const { data: businessData } = useAnonymousBusiness(data?.seller?.id || "")

  React.useEffect(() => {
    if (businessData?.logo) {
      const logo = businessData?.logo
      loadLogoIntoImg(document.getElementById("company-logo-desktop"), logo)
    }
  }, [businessData])

  const { execute, isLoading: isSubmittingV3 } = useSignCreditTermsStandalone()

  const onButtonClicked = async () => {
    if (ref.current?.isEmpty()) {
      window.alert("Please draw your signature in the designated box.")
      return
    }
    const signature = ref.current?.toDataURL() as string
    execute(
      requestId || "",
      verificationCode || "",
      signerFirstName,
      signerLastName,
      signerRole,
      visitorData?.requestId || "",
      signature,
      () => {
        setComplete(true)
      },
    )
  }

  return (
    <DynamicThemeProvider>
      <>
        <Grid container component="main" sx={{ height: "100vh" }}>
          {!matches && (
            <Grid
              item
              lg={4}
              xl={4}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                width: "100%",
              }}
              sx={{ height: "auto" }}
            >
              <Box
                display={{ xs: "none", lg: "block", xl: "block" }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "10%",
                  alignContent: "center",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    padding: "10% 25% 20% 25%",
                    justifyContent: "center",
                  }}
                >
                  <img id="company-logo-desktop" />
                </Box>
                <Typography variant="h4">
                  Please sign the required documents to proceed with your
                  application.
                </Typography>
              </Box>

              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "16px",
                  bottom: "5%",
                  position: "absolute",
                }}
              >
                <Box
                  display={{ xs: "none", lg: "flex", xl: "flex" }}
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <img src="/assets/soc2.png" style={{ width: "72px" }} />
                  <img src="/assets/ssl.png" style={{ height: "72px" }} />
                </Box>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    powered by
                  </Typography>
                  <img
                    src="/assets/logo-simple.png"
                    style={{ width: "32px" }}
                  />
                  <Typography sx={{ fontWeight: "bold" }}>NetNow</Typography>
                  {/* <Button
                    variant="outlined"
                    startIcon={<LogoutOutlined />}
                    style={{ marginLeft: "5rem" }}
                    onClick={() => {
                      logout()
                    }}
                  >
                    Logout
                  </Button> */}
                </Box>
              </Box>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={8}
            xl={8}
            component={Paper}
            style={{
              backgroundColor: "#F9FAFB",
            }}
            elevation={4}
            square
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                padding: "5%",
                maxHeight: "100vh",
                overflow: "auto",
              }}
            >
              <Box
                style={{
                  display: matchesXL ? "flex" : "none",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingBottom: "5%",
                }}
              >
                <img id="company-logo-mobile" style={{ width: "200px" }} />
              </Box>
              <Box
                style={
                  matchesXL
                    ? {}
                    : {
                        textAlign: "left",
                        padding: "32px 10% 32px 10%",
                        border: "solid",
                        borderWidth: "1px",
                        borderRadius: "16px",
                        borderColor: theme.palette.primary.main,
                      }
                }
              >
                {!data && visitorData && (
                  <Dialog open>
                    <DialogTitle>Enter Verification Code</DialogTitle>
                    <DialogContent>
                      <Typography>
                        Please enter the verification code that was sent to your
                        email.
                      </Typography>
                      <TextField
                        id="verification-code"
                        fullWidth
                        margin="normal"
                        label="Verification Code"
                        type="text"
                        required
                        value={verificationCode}
                        onChange={(event) => {
                          setVerificationCode(event.target.value)
                        }}
                      />
                    </DialogContent>
                    <DialogActions>
                      <LoadingButton
                        loading={isLoading}
                        onClick={() => {
                          verifyCode(
                            visitorData?.requestId || "",
                            requestId || "",
                            verificationCode || "",
                            (r) => {
                              setData(r)
                            },
                          )
                        }}
                      >
                        Verify
                      </LoadingButton>
                    </DialogActions>
                  </Dialog>
                )}

                {!!data && !complete && (
                  <>
                    <Typography
                      component="h1"
                      variant="h5"
                      style={{ marginTop: "8px" }}
                    >
                      Sign Credit Agreement
                    </Typography>
                    <Alert severity="info" style={{ margin: "8px 0 8px 0" }}>
                      Please review Credit Terms and Conditions and verify your
                      application information below. Once reviewed, scroll to
                      the bottom of the page to enter your legal first and last
                      name as well as your role, draw your signature on the pad
                      below and <b>click Sign</b>. You will receive a copy of
                      this application via email. By signing this application,
                      you agree to these terms, you agree that you have the
                      authorization to sign the application and confirm that all
                      the questions in this application have been answered
                      turthfully.
                    </Alert>
                    {data && (
                      <iframe
                        src={data?.unsignedPdf as string}
                        width="100%"
                        height="500px"
                      />
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        gap: "32px",
                        alignItems: "center",
                        flexDirection: matches ? "column" : "row",
                      }}
                    >
                      <TextField
                        id="signer-first-name"
                        fullWidth
                        margin="normal"
                        label="First Name"
                        type="text"
                        required
                        value={signerFirstName}
                        onChange={(event) => {
                          setSignerFirstName(event.target.value)
                        }}
                      />
                      <TextField
                        id="signer-last-name"
                        required
                        margin="normal"
                        fullWidth
                        label="Last Name"
                        type="text"
                        value={signerLastName}
                        onChange={(event) => {
                          setSignerLastName(event.target.value)
                        }}
                      />
                      <TextField
                        id="signer-role"
                        margin="normal"
                        required
                        fullWidth
                        label="Role"
                        type="text"
                        value={signerRole}
                        onChange={(event) => {
                          setSignerRole(event.target.value)
                        }}
                      />
                    </Box>

                    <Typography style={{ margin: "4px" }}>
                      Signature *
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        border: "solid 3px",
                        borderRadius: "8px",
                        borderColor: "#aaaaaa",
                        margin: "16px 0 16px 0",
                        height: "250px",
                      }}
                    >
                      <SignaturePad
                        ref={ref}
                        height={250}
                        options={{
                          minWidth: 5,
                          maxWidth: 5,
                          penColor: "rgb(0, 0, 0)",
                        }}
                        redrawOnResize={true}
                      />
                    </Box>
                    <Button
                      fullWidth
                      onClick={onClearSignature}
                      style={{ margin: "16px 0 16px 0" }}
                    >
                      Clear Signature
                    </Button>
                  </>
                )}

                {complete && (
                  <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
                    The document has been successfully signed. You can now close
                    this window.
                  </Typography>
                )}

                {!!data && !complete && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "left",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="terms-checkbox"
                            value="remember"
                            sx={{ color: "#D0D5DD" }}
                            onChange={(event) => {
                              setElectronicTermsAccepted(event.target.checked)
                            }}
                            checked={electronicTermsAccepted}
                          />
                        }
                        label={
                          <div>
                            <Typography>
                              I confirm that I have read and understood the{" "}
                              <a onClick={() => setElectronicTermsOpen(true)}>
                                <u>
                                  Electronic Record and Signature Disclosure
                                </u>
                              </a>{" "}
                              and consent to use electronic records and
                              signatures.
                            </Typography>
                          </div>
                        }
                      />
                    </Box>

                    <LoadingButton
                      id="submit-sign"
                      onClick={() => {
                        onButtonClicked()
                      }}
                      loading={isLoading || isSubmittingV3}
                      size="large"
                      fullWidth
                      endIcon={<DrawOutlined />}
                      disabled={
                        !signerFirstName ||
                        !signerLastName ||
                        !electronicTermsAccepted
                      }
                      variant="contained"
                      sx={{ mt: 3, mb: 2, textTransform: "none" }}
                    >
                      Sign
                    </LoadingButton>
                  </>
                )}
                <Dialog
                  open={electronicTermsDialogOpen}
                  onClose={() => setElectronicTermsOpen(false)}
                >
                  <DialogTitle>
                    Electronic Record and Signature Disclosure
                  </DialogTitle>
                  <DialogContent>
                    Please read the following information carefully. By clicking
                    Sign, you agree that you have reviewed the following terms
                    and conditions and consent to transact business
                    electronically using NetNow electronic signature system. If
                    you do not agree to these terms, do not click the 'Sign'
                    button. Please note that NetNow ("we", "us" or "Company")
                    and any of our clients with whom NetNow is acting as a
                    service provider for the purposes of offering digital trade
                    credit applications will send all documents electronically
                    to you to the email address that you have given us during
                    the course of the business relationship unless you tell us
                    otherwise in accordance with the procedure explained herein.
                    Once you sign a document electronically, we will send a PDF
                    version of the document to you. You have the right to
                    request paper copies of these documents. Alternatively, you
                    also have the ability to download and print these documents
                    sent to you electronically, and re-upload a scanned copy of
                    the printed and physically signed documents. If you,
                    however, wish to request paper copies of these documents
                    sent to you electronically, you can write to us at
                    info@netnow.io. At any point in time during the course of
                    our business relationship, you have the right to withdraw
                    your consent to receive documents in electronic format. If
                    you wish to withdraw your consent, you can decline to sign a
                    document that we have sent to you and send an email to
                    info@netnow.io informing us that you wish to receive
                    documents only in paper format. If you need to change the
                    email address that you use to receive notices and
                    disclosures from us, write to us at info@netnow.io
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setElectronicTermsOpen(false)
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      onClick={() => {
                        setElectronicTermsAccepted(true)
                        setElectronicTermsOpen(false)
                      }}
                    >
                      Accept
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </>
    </DynamicThemeProvider>
  )
}
