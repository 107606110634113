import { QueryClient } from "react-query"
import { CashApplication } from "src/types"
import { useTypedMutation } from "../../useTypedMutation"

export function usePatchCashTags(onSuccess?: () => void) {
  const ENDPOINT = "/cod-tags"
  const patchTemplate = useTypedMutation<
    Pick<CashApplication, "id"> & Pick<CashApplication, "tags">
  >("patch/cod-tags", onSuccess)

  const execute = (
    body: Pick<CashApplication, "id"> & Pick<CashApplication, "tags">,
    onSuccess?: () => void,
  ) => {
    patchTemplate.mutate(
      {
        method: "patch",
        endpoint: ENDPOINT + `/${body.id}/`,
        body,
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...patchTemplate, execute }
}
