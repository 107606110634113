import { useState } from "react"
import { LoadingButton } from "@mui/lab"
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
  Stack,
  Grid,
  TextField,
  MenuItem,
  Select,
  Box,
} from "@mui/material"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"
import {
  ApplicationTemplate,
  CreditApplication,
  Page,
  TemplateCustomField,
} from "src/types"
import { usePostAdditionalDataRequest } from "src/queries/credit/additional/usePostAdditionalDataRequest"
import { useSnackbar } from "notistack"
import useGenerateApplicationPdf from "src/hooks/useGenerateApplicationPdf"
import {
  PAGE_LABEL_BANK_REFERENCE,
  PAGE_LABEL_BUSINESS_DETAILS,
  PAGE_LABEL_PERSONAL_GUARANTY,
  PAGE_LABEL_USER_DETAILS,
} from "../../intake_sections/constants"
import { confirm } from "src/components/confirm"
import { info } from "src/utils/logger"
import { useListAdditionalDataRequest } from "src/queries/credit/additional/useListAdditionalDataRequests"
import { usePostAdditionalDataRequestV3 } from "src/queries/credit/signing_v3/usePostAdditionalDataRequestV3"
import { useAnonymousBusiness } from "src/queries/credit/useAnonymousBusiness"
import { BUSINESS_PREFERENCES, usePreference } from "src/hooks/use-preference"

export default ({
  application,
  onClose,
  open,
}: {
  application: CreditApplication
  onClose: () => void
  open: boolean
}) => {
  const { data: template } = useApplicationTemplate(application.seller?.id)

  const { data: businessData } = useAnonymousBusiness(
    application.seller?.id || "",
  )

  const { preference: hasSignatureV3 } = usePreference(
    BUSINESS_PREFERENCES.SIGNATURE_V3,
    businessData,
  )

  const { pendingRequests, pendingPageLabels, refetch } =
    useListAdditionalDataRequest(application.id || "")

  const pendingRequest = pendingRequests ? pendingRequests[0] : null

  const { execute: generatePdf } = useGenerateApplicationPdf(application)

  const [recipient, setRecipient] = useState(application.data.email || "")
  const [recipientFirst, setRecipientFirst] = useState("")
  const [recipientLast, setRecipientLast] = useState("")

  const [selectedPages, setSelectedPages] = useState<
    ApplicationTemplate["formTemplate"]["pages"]
  >(
    pendingRequest
      ? template?.formTemplate?.pages.filter(
          (p) => pendingPageLabels?.includes(p.label),
        ) || []
      : [],
  )

  const [requestSignature, setRequestSignature] = useState(
    pendingRequest?.requestSignature || false,
  )

  const { enqueueSnackbar } = useSnackbar()

  const { execute, isLoading } = usePostAdditionalDataRequest(() => {
    enqueueSnackbar("Request sent successfully.", { variant: "success" })
    refetch()
  })

  const { execute: executeV3, isLoading: isLoadingV3 } =
    usePostAdditionalDataRequestV3(() => {
      enqueueSnackbar("Request sent successfully.", { variant: "success" })
      refetch()
    })

  const [instructions, setInstructions] = useState(
    pendingRequest?.instructions || "",
  )

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"}>
      <DialogTitle>Request Information</DialogTitle>
      <Divider style={{}} />
      <DialogContent>
        <Stack
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {pendingRequests && pendingRequests.length > 0 && (
            <Typography>
              You have an unfulfilled request for information. You can modify
              the request below and click <b>Update & Remind</b> to send the
              request to the customer.
            </Typography>
          )}

          <Grid container>
            <Grid item md={4}>
              <Typography>Recipient Email *</Typography>
            </Grid>
            <Grid item md={8}>
              <Select
                labelId="recipient-select"
                id="recipient-select"
                value={recipient}
                fullWidth
                onChange={(e) => {
                  setRecipient(e.target.value)
                }}
              >
                {typeof application.collaborators === "string"
                  ? application.collaborators?.split(",")?.map((email) => {
                      return (
                        <MenuItem value={email} key={email}>
                          {email}
                        </MenuItem>
                      )
                    })
                  : application.collaborators?.map((email) => {
                      return (
                        <MenuItem value={email} key={email}>
                          {email}
                        </MenuItem>
                      )
                    })}
              </Select>
            </Grid>
          </Grid>
          <Typography>
            We will send an email to <b>{recipient}</b> with a link to upload
            the requested information. You will receive a notification when
            completed.
          </Typography>

          <Typography>
            Select the information you would like to request.
          </Typography>
          {template?.formTemplate?.pages
            .filter((p: Page) => p.label !== PAGE_LABEL_USER_DETAILS)
            .map((page: Page) => (
              <FormControlLabel
                key={page.label}
                control={
                  <Checkbox
                    checked={
                      !!selectedPages.find((p) => p.label === page.label)
                    }
                    color="primary"
                    onChange={(e) => {
                      if (e.target.checked) {
                        if (
                          page.label === PAGE_LABEL_BANK_REFERENCE &&
                          !application.data?.businessCountry &&
                          !selectedPages.find(
                            (p) => p.label === PAGE_LABEL_BUSINESS_DETAILS,
                          )
                        ) {
                          enqueueSnackbar(
                            "Bank references are country specific. Please complete the business address section before requesting bank references or request Business Details from the user as well.",
                            {
                              variant: "warning",
                            },
                          )
                          e.preventDefault()
                          return
                        }
                        setSelectedPages([...selectedPages, page])
                        if (
                          template.singleDocument &&
                          page.label === PAGE_LABEL_PERSONAL_GUARANTY
                        ) {
                          setRequestSignature(true)
                        }
                      } else {
                        setSelectedPages(
                          selectedPages.filter(
                            (
                              p: ApplicationTemplate["formTemplate"]["pages"][0],
                            ) => p.label !== page.label,
                          ),
                        )
                        if (
                          template.singleDocument &&
                          page.label === PAGE_LABEL_PERSONAL_GUARANTY
                        ) {
                          setRequestSignature(false)
                        }
                      }
                    }}
                  />
                }
                label={page.label}
              />
            ))}
          {template?.customFields?.some(
            (field: TemplateCustomField) =>
              field.associatedPage === "Additional Questions",
          ) && (
            <FormControlLabel
              key={"Additional Questions"}
              control={
                <Checkbox
                  value="remember"
                  color="primary"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedPages([
                        ...selectedPages,
                        {
                          label: "Additional Questions",
                          description: "",
                          enabled: true,
                          required: true,
                          config: [],
                        },
                      ])
                    } else {
                      setSelectedPages(
                        selectedPages.filter(
                          (
                            p: ApplicationTemplate["formTemplate"]["pages"][0],
                          ) => p.label !== "Additional Questions",
                        ),
                      )
                    }
                  }}
                />
              }
              label={"Additional Questions"}
            />
          )}
          <Divider />
          <FormControlLabel
            control={
              <Checkbox
                id="request-signature-checkbox"
                onChange={(event) => {
                  setRequestSignature(event.target.checked)
                }}
                checked={requestSignature}
                disabled={
                  template?.singleDocument &&
                  selectedPages.find(
                    (v) => v.label === PAGE_LABEL_PERSONAL_GUARANTY,
                  )
                    ? true
                    : false
                }
              />
            }
            label="Request a new digital signature from the applicant."
          />

          {template?.singleDocument && requestSignature && (
            <Box style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
              <Box style={{ backgroundColor: "red", width: "16px" }} />
              <Typography>
                <b>
                  Important Note: Requesting a new signature will dismiss any
                  existing personal guarantor (indemnitor) signatures due to the
                  fact that your organization requires all signatures to be on
                  the same document. The contacts will be sent a new signature
                  request.
                </b>
              </Typography>
            </Box>
          )}

          {requestSignature && (
            <Typography>
              Note: Please ensure <b>{recipient}</b> is authorized to sign the
              application. If not, close this dialog, update the email in the
              Submitted By section and re-open this dialog.
            </Typography>
          )}

          {selectedPages.length === 0 && requestSignature && (
            <>
              <Typography>
                We require the first and last name of the recipient to request a
                standalone digital signature.
              </Typography>
              <Grid container>
                <Grid item md={4}>
                  <Typography>Recipient First Name *</Typography>
                </Grid>
                <Grid item md={8}>
                  <TextField
                    fullWidth
                    onChange={(e) => {
                      setRecipientFirst(e.target.value)
                    }}
                    value={recipientFirst}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={4}>
                  <Typography>Recipient Last Name *</Typography>
                </Grid>
                <Grid item md={8}>
                  <TextField
                    fullWidth
                    value={recipientLast}
                    onChange={(e) => {
                      setRecipientLast(e.target.value)
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )}

          <Grid container>
            <Grid item md={12}>
              <Typography>Applicant Instructions</Typography>
              <TextField
                type="text"
                fullWidth
                multiline
                placeholder="Enter any additional instructions for the applicant here. This will be included in the email."
                value={instructions}
                onChange={(e) => {
                  setInstructions(e.target.value)
                }}
              />
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <LoadingButton
          type="submit"
          disabled={
            (selectedPages.length === 0 && !requestSignature) ||
            (selectedPages.length === 0 &&
              requestSignature &&
              (recipientFirst === "" || recipientLast === ""))
          }
          variant="contained"
          loading={isLoading || isLoadingV3}
          onClick={() => {
            confirm(
              template?.singleDocument && requestSignature
                ? "You are about to request a new signature from the applicant. Please note that due to the configuration of your system, this action will dismiss any existing signatures including the credit agreement and personal guarantors (indemnitors). Are you sure you would like to proceed?"
                : "Are you sure you would like to proceed? Please note that if you request a new signature, any existing signed credit agreements will be dismissed.",
              {
                okLabel: "I understand",
                title: "Notice",
              },
            )
              .then(
                () => {
                  return generatePdf()
                },
                () => {
                  throw new Error("cancelled")
                },
              )
              .then((res) => {
                if (!hasSignatureV3) {
                  return execute(
                    application.id || "",
                    selectedPages,
                    instructions,
                    requestSignature,
                    recipient,
                    recipientFirst,
                    recipientLast,
                    new File([res as Blob], "application_pdf.pdf"),
                    () => {
                      onClose()
                    },
                  )
                } else {
                  return executeV3(
                    application.id || "",
                    selectedPages,
                    instructions,
                    requestSignature,
                    recipient,
                    recipientFirst,
                    recipientLast,
                    new File([res as Blob], "application_pdf.pdf"),
                    () => {
                      onClose()
                    },
                  )
                }
              })
              .catch(() => {
                info("cancelled")
              })
          }}
        >
          {pendingRequests && pendingRequests.length > 0
            ? "Update & Remind"
            : "Send"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
