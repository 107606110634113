import ReactApexChart from "react-apexcharts"
import { useTheme } from "@mui/material"
import { Box, Typography, Chip } from "@mui/material"
import { ApexOptions } from "apexcharts"

// Define the structure of the data for type safety
interface ChartData {
  month: string
  approved: number
  declined: number
  incomplete: number
  pending_Info: number
}
interface ChartColumnStackedProps {
  data: ChartData[]
  title: string
}

export default function ChartColumnStacked({
  data,
  title,
}: ChartColumnStackedProps) {
  const theme = useTheme()
  // Set up the color mapping for each field
  const colorMapping: { [key: string]: string } = {
    approved: theme.palette.success.light, // Green
    declined: theme.palette.error.light, // Red or f78383
    incomplete: theme.palette.warning.main, // Yellow
    pending_Info: theme.palette.grey[400], // Grey
  }
  // Define the labels for the legend
  const labelsMapping: { [key: string]: string } = {
    approved: "Approved",
    declined: "Declined",
    incomplete: "Incomplete",
    pending_Info: "Pending Info",
  }

  // maximum of all the data in data array
  const maxOfAllFields = Math.max(
    ...[
      ...data.map((item) => item.approved),
      ...data.map((item) => item.declined),
      ...data.map((item) => item.incomplete),
      ...data.map((item) => item.pending_Info),
    ],
  )

  const yStepSize = Math.max(5, Math.abs(maxOfAllFields / 5))

  // Prepare the series for each field (approved, declined, etc.)
  const series = Object.keys(colorMapping).map((field) => ({
    name: field,
    data: data.map((item) => item[field as keyof ChartData]), // Use the chartData structure to map each field's value
  }))

  // Define the chart options
  const options: ApexOptions = {
    dataLabels: {
      enabled: false,
    },
    grid: { show: true },
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const seriesName = w.config.series[seriesIndex].name
        const friendlyName = labelsMapping[seriesName] || seriesName
        const value = series[seriesIndex][dataPointIndex]
        return `
                <div style="
                    padding: 8px;
                    background-color: #fff;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
                    font-size: 14px;
                    color: #333;
                ">
                    <strong>${friendlyName}</strong>: ${value}
                </div>`
      },
    },
    xaxis: {
      categories: data.map((item) => item.month), // Use the months from chartData
    },
    yaxis: {
      tickAmount: 5,
      stepSize: yStepSize,
      labels: {
        formatter: function (val) {
          return val.toFixed(0)
        },
      },
    },

    plotOptions: {
      bar: {
        columnWidth: `${Math.max(25, 100 / data.length)}%`, // Dynamically adjust width based on data length
        borderRadius: 8,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "all",
      },
    },
    legend: {
      position: "top",
      show: false,
    },
    colors: Object.values(colorMapping), // Apply the color mapping to the chart
  }

  return (
    <Box>
      {/* Title and Legend */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
        padding={2}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {title}
        </Typography>
        <Box display="flex" gap={2}>
          <Chip
            label="Approved"
            style={{
              backgroundColor: theme.palette.success.light,
              color: theme.palette.success.dark,
            }}
          />
          <Chip
            label="Rejected"
            style={{
              backgroundColor: theme.palette.error.light,
              color: theme.palette.error.dark,
            }}
          />
          <Chip
            label="Incomplete"
            style={{
              backgroundColor: theme.palette.warning.main,
              color: theme.palette.warning.dark,
            }}
          />
          <Chip
            label="Pending Info"
            style={{
              backgroundColor: theme.palette.grey[400],
              color: theme.palette.grey[900],
            }}
          />
        </Box>
      </Box>

      {/* Chart */}
      <ReactApexChart
        options={options}
        series={series as any}
        type="bar"
        height={400}
      />
    </Box>
  )
}
