import { QueryClient } from "react-query"
import { TemplateCustomField } from "src/types"
import { useTypedMutation } from "../../useTypedMutation"

export function useDeleteCODTemplateCustomFields(
  onSuccess?: (data: TemplateCustomField) => void,
) {
  const ENDPOINT = "/internal/cod-custom-fields"
  const deleteField = useTypedMutation<Pick<TemplateCustomField, "id">>(
    "patch/internal/cod-custom-fields",
    onSuccess,
  )

  const execute = (
    body: Pick<TemplateCustomField, "id">,
    onSuccess?: () => void,
  ) => {
    deleteField.mutate(
      {
        method: "delete",
        endpoint: ENDPOINT + `/${body.id}/`,
        body: {},
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  const executeAsync = (
    body: Pick<TemplateCustomField, "id">,
    onSuccess?: () => void,
  ) => {
    return deleteField.mutateAsync(
      {
        method: "delete",
        endpoint: ENDPOINT + `/${body.id}/`,
        body: {},
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...deleteField, execute, executeAsync }
}
