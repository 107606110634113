import { Helmet } from "react-helmet-async"
import React, { useState, useCallback, useEffect } from "react"
// @mui
import {
  Box,
  Card,
  Table,
  Stack,
  TableBody,
  Popover,
  Container,
  Typography,
  Tab,
  TableContainer,
  TablePagination,
  Tabs,
  MenuItem,
  Button,
  Tooltip,
  TablePaginationOwnProps,
  InputAdornment,
  TextField,
  IconButton,
  TableRow,
  TableCell,
} from "@mui/material"
// sections
import ApplicationListHead from "src/sections/@dashboard/components/ApplicationListHead"
import {
  EditOutlined,
  FilterAltOffOutlined,
  FilterAltOutlined,
  InfoOutlined,
  PostAdd,
  Send,
  NotInterestedOutlined,
  PageviewOutlined,
  ArchiveOutlined,
  MailOutlined,
  Cancel,
} from "@mui/icons-material"
import { usePaginated } from "src/queries/usePaginated"
import { useNavigate } from "react-router-dom"
import Label from "src/components/label"
import { alpha } from "@mui/material/styles"
import { useApplicationCount } from "src/queries/credit/useApplicationCount"
import { confirm } from "src/components/confirm"
import { usePatchApplication } from "src/queries/credit/usePatchApplication"
import { useSnackbar } from "notistack"
import NewApplicationForm from "src/sections/@dashboard/credit/NewApplicationForm"
import ManualApplicationStartModal from "src/sections/@dashboard/credit/ManualApplicationStartModal"
import ApplicationListToolbar from "src/sections/@dashboard/components/ApplicationListToolbar"
import { useUser } from "src/queries"
import { info } from "src/utils/logger"
import { useUsers } from "src/queries/base/useUsers"
import {
  Group,
  CreditApplication,
  IApplicationTableFilterValue,
  IApplicationListFilters,
} from "src/types"
import ListRow from "src/sections/@dashboard/credit/list/ListRow"
import ApplicationLink from "src/sections/@dashboard/components/ApplicationLink"
import { APPLICATION_STAGE } from "src/statics"
import {
  sessionStorageGetObjectItem,
  sessionStorageSetItem,
} from "src/utils/storage-available"
import SendCompletionReminderDialog from "src/sections/@dashboard/credit/components/SendCompletionReminderDialog"
import StoreFilterMenu from "src/sections/@dashboard/components/StoreFilterMenu"
import { useSeller } from "src/queries/base/useSeller"
import { usePatchCreditApplicationUnarchiveStage } from "src/queries/credit/usePatchCreditApplicationUnarchiveStage"
import { FORM_INPUT_NAMES } from "src/sections/@dashboard/intake_sections/constants"
import { usePreference, BUSINESS_PREFERENCES } from "src/hooks/use-preference"
import Iconify from "src/components/iconify"
import useDebounced from "src/hooks/useDebounced"
import LoadingIcon from "src/assets/icons/loading-icon"
import { useTags } from "src/queries/credit/tags/useTags"
import TagFilterMenu from "src/sections/@dashboard/components/TagFilterMenu"

// ----------------------------------------------------------------------

const LastAccessdLabel = (
  <Tooltip
    title="This is the time this application was last accessed by the applicant."
    placement="bottom"
    arrow
  >
    <Box
      style={{
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      Last Accessed
      <InfoOutlined
        fontSize="small"
        style={{
          marginLeft: "0.2rem",
        }}
      />
    </Box>
  </Tooltip>
)

const TABLE_HEAD_IDS = {
  SUBMITTER: "submitter",
  DATE: "date",
  STATUS: "status",
  ASSIGNEE: "assignee",
  TERMS: "terms",
  REFERENCES: "references",
  PERSONAL_GUARANTOR: "personal_guarantor",
  LAST_ACCESSED: "last_accessed",
  ACTIONS: "actions",
}

const TABLE_HEAD = [
  { id: TABLE_HEAD_IDS.SUBMITTER, label: "Submitter", alignRight: false },
  { id: TABLE_HEAD_IDS.DATE, label: "Date", alignRight: false },
  { id: TABLE_HEAD_IDS.STATUS, label: "Status", alignRight: false },
  { id: TABLE_HEAD_IDS.ASSIGNEE, label: "Assignees", alignRight: false },
  { id: TABLE_HEAD_IDS.TERMS, label: "Terms", alignRight: false },
  {
    id: TABLE_HEAD_IDS.REFERENCES,
    label: "Trade References",
    alignRight: false,
  },
  {
    id: TABLE_HEAD_IDS.PERSONAL_GUARANTOR,
    label: "Guarantor",
    alignRight: false,
  },
  {
    id: TABLE_HEAD_IDS.LAST_ACCESSED,
    label: LastAccessdLabel,
    alignRight: false,
  },
  { id: TABLE_HEAD_IDS.ACTIONS },
]

const STATUS_OPTIONS = [
  { value: "all", label: "All" },
  { value: APPLICATION_STAGE.ONE, label: "Incomplete" },
  { value: APPLICATION_STAGE.MANUAL_INCOMPLETE, label: "Manual" },
  {
    value: APPLICATION_STAGE.AWAITING_SIGNATURE,
    label: "Awaiting Signature",
  },
  {
    value: APPLICATION_STAGE.COMPLETED_BY_CLIENT,
    label: "Completed By Client",
  },
  { value: APPLICATION_STAGE.APPROVED, label: "Approved" },
  { value: APPLICATION_STAGE.REJECTED, label: "Rejected" },
]

const defaultFilters: IApplicationListFilters = {
  status: "all",
  sales_rep: [],
  assignee: [],
  store: [],
  q: "",
  tags: [],
}

export default function CreditApplicationListPage() {
  const navigate = useNavigate()

  const [page, setPage] = useState(0)

  const { isCreditManager, isSales, data: userData } = useUser()

  const { data: users } = useUsers()

  const { data: business } = useSeller(
    userData?.userToBusiness?.business?.id || "",
  )

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [showReminderDialog, setShowReminderDialog] = useState(false)
  const [searchQuery, setSearchQuery] = useState("")
  const [previousSearchQuery, setPreviousSearchQuery] = useState("")
  const debouncedSearchTerm = useDebounced(searchQuery)

  const { enqueueSnackbar } = useSnackbar()

  const filterStorageKey = "credit_application_list_filters"

  const { execute: patchApplication } = usePatchApplication(() => {
    setOpenMenu(null)
    refetch()
    enqueueSnackbar("Application updated", { variant: "success" })
  })

  const { execute: patchCreditApplicationUnarchiveStage } =
    usePatchCreditApplicationUnarchiveStage(() => {
      setOpenMenu(null)
      refetch()
      enqueueSnackbar("Application updated", { variant: "success" })
    })

  const [openModal, setOpenModal] = useState<string | null>(null)

  const handleCloseModal = () => {
    setOpenModal(null)
  }

  const [openMenu, setOpenMenu] = useState<
    React.MouseEvent<HTMLButtonElement>["currentTarget"] | null
  >(null)

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenMenu(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setOpenMenu(null)
  }

  const [filters, setFilters] = useState(
    sessionStorageGetObjectItem(
      filterStorageKey,
      defaultFilters,
    ) as IApplicationListFilters,
  )

  const [showArchive, setShowArchive] = useState(
    filters.status === APPLICATION_STAGE.ARCHIVE,
  )
  const { data: applicationCount, refetch: refetchApplicationCount } =
    useApplicationCount()

  const filterConfig = {
    stage: filters.status !== "all" ? filters.status : undefined,
    email_verified: true,
    sales_rep:
      filters.sales_rep.length > 0
        ? filters.sales_rep.map((u) => u.id)
        : undefined,
    assignee:
      filters.assignee.length > 0
        ? filters.assignee.map((u) => u.id)
        : undefined,
    store:
      filters.store && filters.store.length > 0
        ? filters.store.map((s) => s.id)
        : undefined,
    q: debouncedSearchTerm,
    tags:
      filters.tags && filters.tags.length > 0
        ? filters.tags.map((t) => t.id)
        : undefined,
  }
  const {
    data,
    refetch: refetchPaginated,
    isFetching,
  } = usePaginated("application/", page, filterConfig)

  const isLoadingCreditApplications = () => {
    if (isFetching && searchQuery != previousSearchQuery) {
      return true
    }
    if (isFetching || !data) {
      return true
    }

    return false
  }

  const refetch = useCallback(() => {
    refetchApplicationCount()
    refetchPaginated()
  }, [refetchApplicationCount, refetchPaginated])

  useEffect(() => {
    sessionStorageSetItem(filterStorageKey, filters)
    refetchPaginated()
    setShowArchive(filters.status === APPLICATION_STAGE.ARCHIVE)
  }, [filters, refetchPaginated, debouncedSearchTerm])

  useEffect(() => {
    if (data) {
      setPreviousSearchQuery(searchQuery)
    }
  }, [data])

  useEffect(() => {
    sessionStorage.clear()
    setFilters(defaultFilters)
  }, [])

  const { preference: personalGuarantorColumnEnabled } = usePreference(
    BUSINESS_PREFERENCES.DASHBOARD_PERSONAL_GUARANTOR_COLUMN,
    business,
  )

  const { data: orgTags } = useTags()

  const handleFilters = useCallback(
    (name: string, value: IApplicationTableFilterValue) => {
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }))
      setPage(0)
    },
    [],
  )

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters("status", newValue)
    },
    [handleFilters],
  )

  const credit_apps = (data?.results as CreditApplication[]) || []

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage: TablePaginationOwnProps["onRowsPerPageChange"] =
    function (event) {
      setPage(0)
      setRowsPerPage(parseInt(event.target.value, 10))
    }

  const FILTERED_HEADS = TABLE_HEAD.filter((row) => {
    return (
      (isCreditManager || row.id !== TABLE_HEAD_IDS.TERMS) &&
      (personalGuarantorColumnEnabled ||
        row.id !== TABLE_HEAD_IDS.PERSONAL_GUARANTOR)
    )
  })

  const handleArchive = () => {
    if (!showArchive) {
      handleFilters("status", APPLICATION_STAGE.ARCHIVE)
    } else {
      handleFilters("status", "all")
    }
  }

  const handleSearch = useCallback(
    (event: any) => {
      setSearchQuery(event.target.value)
      setPage(0)
    },
    [setSearchQuery, setPage],
  )

  return (
    <>
      <Helmet>
        <title>Credit Applications</title>
      </Helmet>

      <Container maxWidth={false}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Credit Applications
          </Typography>
          <Stack alignItems="end" gap="8px">
            <ApplicationLink />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap="8px"
            >
              <Button
                color="primary"
                variant="contained"
                endIcon={<Send />}
                sx={{ textTransform: "capitalize" }}
                onClick={() => setOpenModal("INVITE")}
              >
                Invite New Applicant
              </Button>
              {isCreditManager && (
                <Button
                  color="primary"
                  variant="contained"
                  endIcon={<PostAdd />}
                  sx={{ textTransform: "capitalize" }}
                  onClick={() => setOpenModal("MANUAL")}
                >
                  Start Manual Application
                </Button>
              )}
            </Stack>
          </Stack>
        </Stack>

        <Card>
          {/* eslint-disable-next-line prettier/prettier */}
          {APPLICATION_STAGE.ARCHIVE !== filters.status ? (
            <>
              <Tabs
                value={filters.status}
                onChange={handleFilterStatus}
                sx={{
                  px: 2.5,
                  boxShadow: (theme) =>
                    `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                }}
              >
                {STATUS_OPTIONS.map((tab) => (
                  <Tab
                    key={tab.value}
                    iconPosition="end"
                    value={tab.value}
                    label={tab.label}
                    icon={
                      <Label
                        variant={
                          ((tab.value === "all" ||
                            tab.value === filters.status) &&
                            "filled") ||
                          "soft"
                        }
                        color={
                          (tab.value === APPLICATION_STAGE.APPROVED &&
                            "success") ||
                          ((tab.value as number) < APPLICATION_STAGE.APPROVED &&
                            "warning") ||
                          (tab.value === APPLICATION_STAGE.REJECTED &&
                            "error") ||
                          "default"
                        }
                      >
                        {tab.value === "all" && applicationCount
                          ? applicationCount?.incomplete +
                            applicationCount?.awaitingSignature +
                            applicationCount?.completedByClient +
                            applicationCount?.approved +
                            applicationCount?.rejected +
                            applicationCount?.manual
                          : ""}
                        {tab.value === APPLICATION_STAGE.ONE &&
                          applicationCount?.incomplete}
                        {tab.value === APPLICATION_STAGE.AWAITING_SIGNATURE &&
                          applicationCount?.awaitingSignature}
                        {tab.value === APPLICATION_STAGE.COMPLETED_BY_CLIENT &&
                          applicationCount?.completedByClient}
                        {tab.value === APPLICATION_STAGE.APPROVED &&
                          applicationCount?.approved}
                        {tab.value === APPLICATION_STAGE.REJECTED &&
                          applicationCount?.rejected}
                        {tab.value === APPLICATION_STAGE.MANUAL_INCOMPLETE &&
                          applicationCount?.manual}
                      </Label>
                    }
                  />
                ))}
              </Tabs>
              {/* eslint-disable-next-line prettier/prettier */}
            </>
          ) : (
            <></>
          )}
          <Stack
            spacing={2}
            alignItems={{ xs: "flex-end", md: "center" }}
            direction={{
              xs: "column",
              md: "row",
            }}
            sx={{
              p: 2.5,
              pr: { xs: 2.5, md: 1 },
            }}
          >
            {isCreditManager && (
              <>
                {(filters.assignee && filters.assignee.length > 0) ||
                (filters.sales_rep && filters.sales_rep.length > 0) ||
                (filters.store && filters.store.length > 0) ||
                (filters.tags && filters.tags.length > 0) ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    aria-label="remove filters"
                    onClick={() => {
                      const value = { ...defaultFilters }
                      if (filters.status === APPLICATION_STAGE.ARCHIVE) {
                        value.status = APPLICATION_STAGE.ARCHIVE
                      }
                      setFilters(value)
                    }}
                  >
                    <FilterAltOffOutlined />
                  </Button>
                ) : (
                  <FilterAltOutlined />
                )}

                <ApplicationListToolbar
                  title="Sales Rep"
                  field="sales_rep"
                  values={filters.sales_rep}
                  onFilters={handleFilters}
                  //
                  options={
                    users
                      ?.filter((user) => user.groups?.includes(Group.Sales))
                      ?.sort((a, b) => {
                        if (!a.firstName) {
                          return 1
                        }
                        if (!b.firstName) {
                          return -1
                        }
                        return a.firstName < b.firstName ? -1 : 1
                      }) || []
                  }
                />
                <ApplicationListToolbar
                  title="Credit Manager"
                  field="assignee"
                  values={filters.assignee}
                  onFilters={handleFilters}
                  options={
                    users
                      ?.filter(
                        (user) => user.groups?.includes(Group.CreditManager),
                      )
                      ?.sort((a, b) => {
                        if (!a.firstName) {
                          return 1
                        }
                        if (!b.firstName) {
                          return -1
                        }
                        return a.firstName < b.firstName ? -1 : 1
                      }) || []
                  }
                />
                {business?.stores && business?.stores.length > 1 && (
                  <StoreFilterMenu
                    title="Store/Location"
                    field={FORM_INPUT_NAMES.STORE}
                    values={filters.store}
                    onFilters={handleFilters}
                    options={business?.stores || []}
                  />
                )}

                {orgTags?.length && orgTags?.length > 0 ? (
                  <TagFilterMenu
                    title="Tags"
                    field={"tags"}
                    values={filters.tags}
                    onFilters={handleFilters}
                    options={orgTags}
                  />
                ) : (
                  <></>
                )}
              </>
            )}
            <TextField
              autoFocus
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify
                      icon="eva:search-fill"
                      width={24}
                      sx={{ color: "text.disabled" }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      setSearchQuery("")
                      setPage(0)
                    }}
                  >
                    {searchQuery ? (
                      <Cancel sx={{ color: "error.dark" }} />
                    ) : (
                      <></>
                    )}
                  </IconButton>
                ),
              }}
              inputProps={{
                sx: { typography: "body2" },
              }}
            />
            {isCreditManager && (
              <>
                <Button
                  variant="contained"
                  color={showArchive ? "primary" : "inherit"}
                  aria-label={
                    showArchive ? "Show Active Applications" : "Show Archived"
                  }
                  style={{ marginLeft: "auto" }}
                  onClick={handleArchive}
                >
                  {showArchive ? "Show Active Applications" : "Show Archived"}
                </Button>
              </>
            )}
          </Stack>

          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ApplicationListHead
                headLabel={FILTERED_HEADS}
                rowCount={1}
                numSelected={0}
              />

              <TableBody>
                {isLoadingCreditApplications() && (
                  <TableRow
                    hover
                    key={123}
                    tabIndex={-1}
                    style={{ width: "100%" }}
                  >
                    <TableCell colSpan={FILTERED_HEADS.length}>
                      <Box
                        style={{
                          justifyContent: "center",
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          padding: 1,
                        }}
                      >
                        <LoadingIcon />
                      </Box>
                    </TableCell>
                  </TableRow>
                )}

                {!isLoadingCreditApplications() &&
                  credit_apps &&
                  credit_apps
                    .slice(0, rowsPerPage)
                    .map((row, index: number) => {
                      return (
                        <ListRow
                          row={row}
                          key={index}
                          index={index}
                          handleOpenMenu={handleOpenMenu}
                        />
                      )
                    })}
              </TableBody>
            </Table>
          </TableContainer>

          {data && (
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={data?.count || ""}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>
      </Container>
      <Popover
        open={Boolean(openMenu)}
        anchorEl={openMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 170,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        {(isCreditManager || isSales) &&
          openMenu &&
          credit_apps &&
          credit_apps.find(
            (app) =>
              app.id === openMenu.getAttribute("row") &&
              ((isCreditManager &&
                app.stage !== APPLICATION_STAGE.MANUAL_INCOMPLETE) ||
                isSales),
          ) && (
            <MenuItem
              id="reminder-button"
              onClick={() => {
                navigate("/applications/" + openMenu?.getAttribute("row") + "/")
              }}
            >
              <Box style={{ display: "flex", gap: "1rem" }}>
                <PageviewOutlined />
                View
              </Box>
            </MenuItem>
          )}
        {isCreditManager &&
          openMenu &&
          credit_apps &&
          credit_apps.find(
            (app) =>
              app.id === openMenu.getAttribute("row") &&
              app.stage === APPLICATION_STAGE.MANUAL_INCOMPLETE,
          ) && (
            <MenuItem
              onClick={() => {
                navigate(
                  "/manual-application/" + openMenu.getAttribute("row") + "/",
                )
              }}
            >
              <Box style={{ display: "flex", gap: "1rem" }}>
                <EditOutlined />
                Continue
              </Box>
            </MenuItem>
          )}

        {isCreditManager &&
          openMenu &&
          credit_apps &&
          credit_apps.find(
            (app) =>
              app.id === openMenu.getAttribute("row") &&
              app.stage < APPLICATION_STAGE.APPROVED &&
              app.stage > APPLICATION_STAGE.MANUAL_INCOMPLETE,
          ) && (
            <>
              <MenuItem
                onClick={() => {
                  confirm("You are about to reject this application.", {
                    okColor: "error",
                    okLabel: "Reject",
                  })
                    .then(
                      () => {
                        return patchApplication(
                          openMenu?.getAttribute("row") || "",
                          {
                            stage: APPLICATION_STAGE.REJECTED,
                          },
                          () => undefined,
                          () => undefined,
                          { notifyBuyer: "1" },
                        )
                      },
                      () => {
                        info("cancelled")
                      },
                    )
                    .catch(() => {
                      info("cancelled")
                    })
                }}
              >
                <Box style={{ display: "flex", gap: "1rem" }}>
                  <NotInterestedOutlined />
                  Reject
                </Box>
              </MenuItem>
            </>
          )}
        {openMenu &&
          credit_apps &&
          credit_apps.find((app) => {
            return (
              app.id === openMenu.getAttribute("row") &&
              (app.stage == APPLICATION_STAGE.ONE ||
                app.stage == APPLICATION_STAGE.AWAITING_SIGNATURE)
            )
          }) && (
            <MenuItem
              onClick={() => {
                setShowReminderDialog(true)
              }}
            >
              <Box style={{ display: "flex", gap: "1rem" }}>
                <MailOutlined />
                Send Reminder
              </Box>
            </MenuItem>
          )}
        {isCreditManager &&
          openMenu &&
          credit_apps &&
          credit_apps.find(
            (app) =>
              app.id === openMenu.getAttribute("row") &&
              app.stage === APPLICATION_STAGE.ARCHIVE,
          ) && (
            <MenuItem
              onClick={() => {
                confirm("You are about to restore this application.", {
                  okColor: "error",
                  okLabel: "Restore",
                })
                  .then(
                    () => {
                      return patchCreditApplicationUnarchiveStage(
                        openMenu?.getAttribute("row") || "",
                      )
                    },
                    () => {
                      info("cancelled")
                    },
                  )
                  .catch(() => {
                    info("cancelled")
                  })
              }}
            >
              <Box style={{ display: "flex", gap: "1rem" }}>
                <ArchiveOutlined />
                Restore
              </Box>
            </MenuItem>
          )}

        {isCreditManager &&
          openMenu &&
          credit_apps &&
          credit_apps.find(
            (app) =>
              app.id === openMenu.getAttribute("row") &&
              app.stage != APPLICATION_STAGE.ARCHIVE,
          ) && (
            <MenuItem
              onClick={() => {
                confirm("You are about to archive this application.", {
                  okColor: "error",
                  okLabel: "Archive",
                })
                  .then(
                    () => {
                      return patchApplication(
                        openMenu?.getAttribute("row") || "",
                        {
                          stage: APPLICATION_STAGE.ARCHIVE,
                        },
                      )
                    },
                    () => {
                      info("cancelled")
                    },
                  )
                  .catch(() => {
                    info("cancelled")
                  })
              }}
            >
              <Box style={{ display: "flex", gap: "1rem" }}>
                <ArchiveOutlined />
                Archive
              </Box>
            </MenuItem>
          )}
      </Popover>

      {showReminderDialog && (
        <SendCompletionReminderDialog
          application={
            credit_apps.find((app) => {
              return app.id === openMenu?.getAttribute("row")
            }) as CreditApplication
          }
          onClose={() => {
            setShowReminderDialog(false)
            if (openMenu) {
              handleCloseMenu()
            }
          }}
        />
      )}

      {openModal == "INVITE" && (
        <NewApplicationForm
          open={openModal == "INVITE"}
          toggle={handleCloseModal}
          refetch={refetch}
          type="credit"
        />
      )}
      <ManualApplicationStartModal
        open={openModal == "MANUAL"}
        toggle={handleCloseModal}
        refetch={refetch}
      />
    </>
  )
}
