import { Stack } from "@mui/material"
import { useOpenCorporateGetCompaniesEvents } from "src/queries/vendors/useOpenCorporate"
import CompanyDataTable from "src/sections/@dashboard/components/company-data-table"
import EventsTable from "src/sections/@dashboard/components/events-table"
import FilingsTable from "src/sections/@dashboard/components/filings-table"
import OfficersTable from "src/sections/@dashboard/components/officers-table"
import { OpenCorporateGetCompaniesInterface } from "src/types"

export default function OpenCorporateCompanyData({
  companyData,
  jurisdictionCode,
  companyNumber,
  perPage,
  page,
}: {
  companyData: OpenCorporateGetCompaniesInterface
  jurisdictionCode: string
  companyNumber: string
  perPage: number
  page: number
}) {
  const {
    data: companyDataEvents,
    refetch: refetchCompanyEvents,
    isLoading: isLoadingDataEvents,
  } = useOpenCorporateGetCompaniesEvents(
    jurisdictionCode,
    companyNumber,
    perPage,
    page,
  )
  return (
    <Stack spacing={2}>
      <CompanyDataTable companyData={companyData} />
      <OfficersTable officers={companyData?.officers} />
      <FilingsTable filings={companyData?.filings} />
      <EventsTable events={companyDataEvents} />
    </Stack>
  )
}
