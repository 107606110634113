import { LoadingButton } from "@mui/lab"
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Grid,
  Typography,
  TextField,
  DialogActions,
  Button,
} from "@mui/material"
import { Stack } from "@mui/system"
import { useFormik } from "formik"
import { usePatchCashApplication } from "src/queries/cod/usePatchCashApplication"
import { CashApplication } from "src/types"

export default ({
  application,
  onClose,
  open,
}: {
  application: CashApplication
  onClose: () => void
  open: boolean
}) => {
  const { execute, isLoading } = usePatchCashApplication(() => {
    onClose()
  })

  const formik = useFormik({
    initialValues: {
      data: {
        customerId: application.data.customerId,
      },
      stage: 800,
    },
    enableReinitialize: true,
    onSubmit: (values: Partial<CashApplication>) => {
      execute(application.id || "", values)
    },
  })
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"md"}>
      <DialogTitle>Approve Application</DialogTitle>
      <Divider style={{}} />
      <DialogContent>
        <Stack
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Grid container>
            <Grid item md={8}>
              <Typography>Customer Number</Typography>
            </Grid>
            <Grid item md={4}>
              <TextField
                fullWidth
                value={formik.values.data?.customerId || ""}
                onChange={(e) => {
                  formik.setFieldValue("data.customerId", e.target.value)
                }}
              />
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
      <Divider style={{}} />
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={() => {
            formik.submitForm()
          }}
          loading={isLoading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
